import {Component, HostListener, OnInit} from '@angular/core';
import {ActivityService} from '@services/activity.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../base.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthService} from '@services/auth.service';
import {AnalyticsService} from '@services/analytics.service';
import {DeviceService} from 'src/app/services/device.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent extends BaseComponent implements OnInit {
  public back = 'activities';
  public id: string;
  public surveyId: string;
  public surveyData: any;
  public error = false;
  public isDevice = false;
  private deviceType = 'web';

  constructor(private activity: ActivityService,
              private route: ActivatedRoute,
              private router: Router,
              protected authService: AuthService,
              private sanitizer: DomSanitizer,
              private analytics: AnalyticsService,
              private device: DeviceService,
              private location: Location
  ) {
    super();
    this.deviceType = this.device.platforms();
    this.isDevice = this.device.isDevice();
  }

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.paramMap.get('id');
    this.getSurvey();
  }

  /**
   * Redirect to survey
   * @protected
   */
  protected getSurvey(): void {
    this.subscribe(this.activity.getId(this.surveyId), (result) => {
      this.surveyData = result.data;

      this.analytics.addEvent('survey_click', {
        page_title: this.surveyData.title,
        project: this.surveyData.project,
        activity: this.surveyId
      });

      if (this.isSurveyLinkValid()) {
        window.location.href = this.getSurveyLink(this.surveyData.survey);
      } else {
        this.error = true;
      }
    });
  }

  /**
   * Validate survey link
   *
   * @param baseLink
   * @private
   */
  private isSurveyLinkValid(): boolean {
    // tslint:disable-next-line:max-line-length
    return this.surveyData.survey !== '' && this.surveyData.survey !== undefined && this.surveyData.survey.includes('$');
  }

  /**
   * Function to replace dynamic params in survey link
   *
   * @param baseLink
   */
  private getSurveyLink(baseLink: string): string {
    const user = this.authService.currentUser();
    const country = user.country_alpha2;
    const specialty = user.main_specialty;
    const hono = this.surveyData.honoraria;
    const project = this.surveyData.project;
    const nxId = user.nx_id;

    baseLink = baseLink.replace('$country', country);
    baseLink = baseLink.replace('$specialty', specialty);
    baseLink = baseLink.replace('$nx_id', nxId);
    baseLink = baseLink.replace('$project', project);
    baseLink = baseLink.replace('$activity_id', this.surveyId);
    baseLink = baseLink.replace('$hono_amount', hono);
    baseLink = baseLink.replace('$source', this.deviceType);
    console.log('SURVEY LINK', baseLink);
    return baseLink;
  }
}
