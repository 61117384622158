import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange} from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.css']
})
export class PasswordStrengthComponent implements OnChanges {
  @Input() public passwordToCheck: string;
  @Output() passwordStrength = new EventEmitter<boolean>();
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  msg = '';

  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];

  private static checkStrength(p: string): number {
    let passedMatches = 0;

    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    const flags = [lowerLetters, upperLetters, numbers, symbols];

    if (p.length <= 6) {
      passedMatches = 0;
    }

    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    return passedMatches;
  }

  public ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordToCheck.currentValue;
    this.setBarColors(4, '#DDD');
    if (password && password !== '') {
      const pwdStrength = PasswordStrengthComponent.checkStrength(password);
      const c = this.getColor(pwdStrength);
      this.setBarColors(c.idx, c.col);
      this.passwordStrength.emit(pwdStrength === 4);
      switch (c.idx) {
        case 1:
          this.msg = 'Password must contain lower and upper case letters, numbers, special charachters.';
          break;
        case 2:
          this.msg = 'Password must contain lower and upper case letters, numbers, special charachters.';
          break;
        case 3:
          this.msg = 'Password must contain lower and upper case letters, numbers, special charachters.';
          break;
        case 4:
          this.msg = 'Your password is strong enough.';
          break;
      }
    } else {
      this.msg = '';
    }
  }

  private getColor(index: number): any {
    let id = (index > 5 ? 4 : index);
    return {
      idx: id,
      col: this.colors[id - 1]
    };
  }

  private setBarColors(count: number, col: string): void {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }
}
