import {Inject, Injectable} from '@angular/core';
import * as dayjs from 'dayjs';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable()
export class HelperService {

  constructor(@Inject(DOCUMENT) private document: any, private router: Router) {

  }
  public reload(): void {
    this.document.body.classList.remove('fuse-splash-screen-hidden');
    this.router.navigateByUrl('/', {replaceUrl: true}).then(() => {
      location.reload();
    });
  }
  public getBrowser(): any {
    // tslint:disable-next-line:one-variable-per-declaration
    let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return {name: 'Opera', version: tem[1]};
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    // tslint:disable-next-line:no-conditional-assignment
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }

  public getUnixTime(time: any = ''): number {
    const date = time ? new Date(time) : new Date();
    return Math.floor(date.getTime() / 1000);
  }

  public getExpirationIn(value: number, unit: dayjs.ManipulateType): number {
    return this.getUnixTime(dayjs(new Date()).add(value, unit));
  }

  public generateString(length: number = 6): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    let str = '';
    for (let i = 0; i < length; i++) {
      str += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return str;
  }

  public generateCode(length: number = 6): string {
    let code = '';
    for (let i = 0; i < length; i++) {
      code += Math.floor(Math.random() * 10);
    }
    return code;
  }

}
