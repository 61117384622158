import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../base.component';
import {environmentProd} from '@environments/environment';
import {HelperService} from '@services/helper.service';
import {AuthService} from '@services/auth.service';
import {AnalyticsService} from '@services/analytics.service';
import {Api2Service} from '@services/api2.service';
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent extends BaseComponent implements OnInit {
  public form: FormGroup;
  public logo = '';
  public post = false;
  public emailSent = false;
  public formError = undefined;
  public pageTitle = 'Forgot password?';
  public site: string = environmentProd.site;
  public description: string = environmentProd.description;
  public siteUrl: string = environmentProd.url;
  public passwordLoader = false;
  public missingEmail = false;
  constructor(protected formBuilder: FormBuilder,
              protected analytics: AnalyticsService,
              protected api2Service: Api2Service,
              private helper: HelperService,
              private auth: AuthService
  ) {
    super();
  }
  ngOnInit(): void {
    this.createForm();
  }
  /**
   * Get form fields
   */
  // tslint:disable-next-line:typedef
  get fields() {
    return this.form.controls;
  }
  /**
   * @function createForm
   * @summary Creating the form
   * @private
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  /**
   * @function sendPasswordResetEmail
   * @summary Sending the email to the given address
   */
  public async sendPasswordResetEmail(): Promise<any> {
    this.passwordLoader = !this.passwordLoader;
    if (this.form.invalid) {
      this.passwordLoader = !this.passwordLoader;
      return;
    }
    const resetData = {
      email: this.fields.email.value.toLowerCase(),
    };
    const emailOriginal = this.fields.email.value;
    this.analytics.addEvent('pwreset_try',{
      email: emailOriginal
    });
    const result = await this.api2Service.passwordReset(resetData.email);
    if (result.status === 200 ) {
      this.emailSent = true;
      this.passwordLoader = !this.passwordLoader;
      this.analytics.addEvent('pwreset_sent',{
        email: emailOriginal
      });
    } else {
      this.passwordLoader = !this.passwordLoader;
      this.missingEmail = true;
      this.analytics.addEvent('pwreset_failed',{
        email: emailOriginal
      });
    }
  }
}
