<!-- SECTION 1 -->
<nav class="click-to-section no-border">
  <div class="c-tabs-bar__wrapper">
    <ul class="c-tabs-bar__list">
      <ng-container *ngFor="let edc of education.touch_sections.sections;let tabindex = index;">
        <li class="c-tabs-bar__item" *ngIf="edc.video == activeVideo || isEmpty(edc.video)">
          <button (click)="setActiveTab(tabindex,edc.type)" class="c-tabs-bar__tab-button js-tabs-button"
                  [ngClass]="activeTab === tabindex ? 'active' : ''" data-section="author-interviews">

            <span class="c-tabs-bar__text" title="grid-columns">{{edc.title}}</span>
          </button>
        </li>
      </ng-container>
    </ul>
    <div class="c-tabs-bar__fade"></div><!-- /.c-tabs-bar__fade -->
  </div>
</nav>

<div *ngFor="let edc2 of education.touch_sections.sections;let tabsectionindex = index;">
  <div class="js-tabs learning-objectives-show" data-section="lo" *ngIf="activeTab === tabsectionindex && isTab(edc2.type)">
    <!-- TAB SECTION --->
    <section>
      <div class="c-tabs__tab js-tabs-tab t-ime" id="js-tab-learning-objectives" style="display: block;">
        <div class="u-pb-0">
          <div class="c-card c-card--large s-standard-content">
            <h5 class="h6 u-mb-2 u-text-grey">{{edc2.title}}</h5>
            <app-touch-readmore [text]="edc2.description"></app-touch-readmore>
          </div><!-- /.c-card -->
        </div><!-- /.o-section -->
      </div><!-- /.c-tabs__tab -->
    </section>
    <!-- TOOLKIT SECTION --->
  </div>
</div>
<!-- END SECTION 1 -->
