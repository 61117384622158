import {Directive, HostListener, Input} from '@angular/core';
import {EventEmitter} from 'events';

@Directive({
  selector: '[appNexttab]'
})
export class NexttabDirective {
  @Input() appNexttab;

  constructor() {
  }

  @HostListener('input', ['$event']) OnInput(event): void {
    const value = event.target.value;
    // tslint:disable-next-line:radix
    const tabindex = parseInt(this.appNexttab);
    if (value.length === 1) {
      const field = document.getElementById('code' + tabindex);
      if (field) {
        field.focus();
      }
    }
  }
}
