import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-redirect-mobile',
  templateUrl: './redirect-mobile.component.html',
  styleUrls: ['./redirect-mobile.component.css']
})
export class RedirectMobileComponent implements OnInit {
  public androidUrl = 'nuaxia://';
  public iosUrl = 'nuaxia://';

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.redirect();
  }

  public redirect(): void {
    const redirect = this.route.snapshot.queryParams.app;
    if (redirect === 'ios') {
      this.openIos();
    } else if (redirect === 'android') {
      this.openAndroid();
    }
  }

  public openIos(): void {
    location.replace(this.iosUrl);
  }

  public openAndroid(): void {
    location.replace(this.androidUrl);
  }

}
