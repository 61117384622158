import { Component, OnInit } from '@angular/core';
import {AuthService} from '@services/auth.service';
import {ActivityService} from '@services/activity.service';
import {ActivatedRoute} from '@angular/router';
import {DataSendingService} from '@services/data-sending.service';
import {AnalyticsService} from '@services/analytics.service';
import {BaseComponent} from '../base.component';

@Component({
  selector: 'app-education-summary',
  templateUrl: './education-summary.component.html',
  styleUrls: ['./education-summary.component.css']
})
export class EducationSummaryComponent extends BaseComponent implements OnInit {

  public id = '';
  public sections: any;
  public title: any;

  constructor(public auth: AuthService,
              private activity: ActivityService,
              private route: ActivatedRoute,
              protected dataSendingService: DataSendingService,
              public analytics: AnalyticsService
  ) {
    super();
  }

  /**
   * Load content
   *
   */
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getContent();
  }

  /**
   * Load education summary
   * @protected
   */
  protected getContent(): void {
    this.subscribe(this.activity.getId(this.id), (res) => {
      this.sections = res.sections.sections;
      this.title = res.title;
    });
  }
  public getVideoRef(video): string {
    return '#' + video;
  }
}
