import { Component, OnInit } from '@angular/core';
import { EducationComponent } from '../education.component';

@Component({
  selector: 'app-touch-mdt',
  templateUrl: './mdt.component.html',
  styleUrls: ['../education.component.scss']
})
export class EducationMdtComponent extends EducationComponent implements OnInit {
  public ngOnInit(): void {
    super.ngOnInit();
  }
}
