import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SingletonService {
  public notifier;
  constructor() {
      this.init();
  }

  private init() {
    this.notifier = new Subject();
  }

  unsubscribe() {
      this.notifier.next();
      this.notifier.complete();
      this.init();
  }
}