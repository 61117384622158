<!-- /.c-register-bar -->
<div class="o-wrapper" *ngIf="education">
  <footer class="c-site-footer">
    <div class="c-site-footer__top">
               <span class="c-site-footer__left">
                  <h6 class="h6 c-site-footer__title" *ngIf="education.footer_fundedby">This Activity Is Funded By:</h6>
                 <div *ngIf="education.footer_fundedby" [innerHTML]="education.footer_fundedby.footer_fundedby ? education.footer_fundedby.footer_fundedby : education.footer_fundedby"></div>
               </span>
      <span class="c-site-footer__right u-flex u-items-center" *ngIf="education.footer_images && education.footer_images.images">

               <img *ngFor="let image of education.footer_images.images" [src]="image.url" class="c-site-footer__logo u-mr-6" />

        </span><!-- /.c-site-footer__right -->
    </div>
    <!-- /.c-site-footer__top -->
    <div class="c-site-footer__bottom" *ngIf="education.footer_text">
      <div [innerHTML]="education.footer_text.footer_text ? education.footer_text.footer_text : education.footer_text"></div>
    </div>
    <div class="c-site-footer__bottom" *ngIf="education.footer_disclaimer">
      <div [innerHTML]="education.footer_disclaimer.footer_disclaimer ? education.footer_disclaimer.footer_disclaimer : education.footer_disclaimer"></div>
    </div>
    <!-- /.c-site-footer__bottom -->
  </footer>
  <!-- /.c-site-footer -->
</div>
<!-- /.o-wrapper -->
<div class="c-hcp-banner">
  <div class="o-wrapper c-hcp-banner__wrapper">
    <p>This content is intended for healthcare professionals only. Please confirm that you are a healthcare
      professional.
    </p>
    <a href="#" class="js-hcp-accept c-button c-button--ghost c-hcp-banner__button" id="cookie-bar-accept">Accept</a>
    <a href="../../../index.html" class="js-hcp-decline c-button c-button--ghost c-hcp-banner__button" id="cookie-bar-decline">Decline</a>
  </div>
  <!-- /.o-wrapper -->
</div>
