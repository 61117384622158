import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-readmore',
  templateUrl: './readmore.component.html',
  styleUrls: ['./readmore.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ReadmoreComponent implements OnInit {
  @Input() text = '';

  more = false;
  intro = '';
  full = '';
  separator = 'read less';

  constructor() {
  }

  ngOnInit(): void {
    if(this.text !== '' && this.text !== undefined){
      if (this.text.includes(this.separator)) {
        const text = this.text.split(this.separator);
        this.intro = text[0];
        this.full = text[1];
      } else {
        this.intro = this.text;
      }
    }
  }

  /**
   * Toggle read more
   */
  readMore(): void {
    this.more = !this.more;
  }

}
