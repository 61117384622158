<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
  <div class="flex w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16 overflow-hidden sm:bg-card">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">

        <!-- Title -->
        <ng-container *ngIf="isTokenExpired">
          <p>Login link has expired, please request a new one below.</p>
          <div class="mt-24 flex flex-col justify-center">
            <div class="button mt-2">
              <button data-testId="magic-link-button" mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="openDialog()">
                <mat-icon *ngIf="loader" data-testId="loading-spinner"><mat-spinner diameter="20">
                </mat-spinner></mat-icon>
                Re-send link
              </button>
            </div>
          </div>
          <div class="flex flex-col justify-center">
            <div class="button mt-2">
              <a [routerLink]="['/']" data-testId="magic-link-button" mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary">
                <span [routerLink]="['/']">Back to home</span>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>



