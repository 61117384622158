<div class="w-full">
  <div class="text-lg text-neutral-500">
    <p class="mb-6">Welcome,</p>
    <p>Please take a few moments to update your professional profile below:</p>
  </div>
  <form [formGroup]="form" class="mt-12">
    <p class="caps-field">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <mat-label>First name</mat-label>
        <input data-testId="first-name-field" formControlName="first_name" matInput placeholder="First name" (focus)="error = null">
      </mat-form-field>
      <span *ngIf="post && fields.first_name.errors">
        <mat-error *ngIf="fields.first_name.errors.required">First name is required.</mat-error>
        <mat-error *ngIf="fields.first_name.invalid">First name is not valid.</mat-error>
      </span>
    </p>
    <p class="caps-field">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <mat-label>Last name</mat-label>
        <input data-testId="last-name-field" formControlName="last_name" matInput placeholder="Last name" (focus)="error = null">
      </mat-form-field>
      <span *ngIf="post && fields.last_name.errors">
        <mat-error *ngIf="fields.last_name.errors.required">Last name is required.</mat-error>
        <mat-error *ngIf="fields.last_name.invalid">Last name is not valid.</mat-error>
      </span>
    </p>
    <p class="caps-field">
      <app-work-field id="workplace" [post]="post" (focus)="error" [formControl]="form.controls['workplace']"  [errors]="form.get('workplace').errors"></app-work-field>
    </p>
    <div class="button mt-6">
      <button data-testId="login-button" mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="submit()"><mat-icon *ngIf="loader" data-testId="loading-spinner"><mat-spinner diameter="20">
      </mat-spinner></mat-icon> Save</button>
    </div>
  </form>

</div>
