<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
  <div class="flex w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16 overflow-hidden sm:bg-card">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <div class="mt-8 text-md font-medium text-secondary">
          <a class="nuaxia-button-back" href="/"> <mat-icon class="icon-size-5" [svgIcon]="'nuaxia:back'"></mat-icon></a>
        </div>
        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">{{pageTitle}}</div>
        <ng-container *ngIf="!emailSent">
          <div class="mt-8 font-medium nuaxia-font-gray"> Enter the email address associated with your account, and we will email you a link to reset your password.</div>
          <form [formGroup]="form">
            <p class="u-pb-2">
              <mat-error *ngIf="formError">{{formError}}</mat-error>
            </p>
            <p class="mt-8">
              <mat-form-field class="fuse-mat-no-subscript w-full">
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput placeholder="Enter your email address"
                       (focus)="formError = null" appLowercase>
              </mat-form-field>
              <mat-error *ngIf="missingEmail">This email doesn't exist in system.</mat-error>
              <span *ngIf="post && fields.email.errors">
                <mat-error *ngIf="fields.email.errors.required">Email is required.</mat-error>
                <mat-error *ngIf="fields.email.invalid">Email is not valid.</mat-error>
              </span>
            </p>
            <p class="button">
              <button mat-flat-button  class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="sendPasswordResetEmail()">
                <mat-icon *ngIf="passwordLoader"><mat-spinner diameter="20"></mat-spinner></mat-icon>
                <span *ngIf="passwordLoader">Sending email, please wait</span>
                <span *ngIf="!passwordLoader">Send reset link</span>
              </button>
            </p>
          </form>
        </ng-container>
        <ng-container *ngIf="emailSent">
          <div class="mt-8 text-md font-medium text-secondary">
            <p> Thank you, we have sent an email to the provided address with the password reset instructions.</p>
          </div>
          <div class="text-center pt-4">
            <button mat-flat-button color="primary" class="nuaxia-button">
              <span [routerLink]="['/']">Back to login</span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
