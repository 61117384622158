import { Component,  OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckYourEmailModalComponent } from '../check-your-email-modal/check-your-email-modal.component';
import { Api2Service } from '@services/api2.service';
import { LoginFormComponent } from '../../auth/login/login-form.component';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { AnalyticsService } from '@services/analytics.service';
import {environmentProd} from '@environments/environment';
import { DataSendingService } from '@services/data-sending.service';
import {HelperService} from '@services/helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DeviceService} from '@services/device.service';

@Component({
  selector: 'app-signup-or-login-modal',
  templateUrl: './signup-or-login-modal.component.html'
})
export class SignupOrLoginModalComponent extends LoginFormComponent {
  public form: FormGroup;
  public post = false;
  public error!: string;
  public loader = false;
  public termsLink: string = environmentProd.termsLink;
  public privacyLink: string = environmentProd.privacyLink;

  constructor(public dialogRef: MatDialogRef<SignupOrLoginModalComponent>,
              protected router: Router,
              protected api2Service: Api2Service,
              protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected dialog: MatDialog,
              protected analytics: AnalyticsService,
              protected dataSendingService: DataSendingService,
              protected helper: HelperService,

  ) {

    super(router, api2Service, activatedRoute, formBuilder, dialog, analytics, dataSendingService, helper);
  }



  protected createForm(): void {

    this.subscribe(this.dataSendingService.getData('closeModals'), (result) => {
        this.close();
    });

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get fields(): any {
    return this.form.controls;
  }

  public async submit(): Promise<void> {
    await super.submitForm();
    if (this.error === undefined) {
      this.close();
      this.dialog.open(CheckYourEmailModalComponent, {
        data: {
          email: this.form.controls.email.value,
        }
      });
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public isSubmitDisabled(): boolean {
    return !this.form.dirty || this.form.status === 'INVALID';
  }

}
