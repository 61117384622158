import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Api2Service} from '@services/api2.service';

@Injectable({
  providedIn: 'root',
})
export class ApiGuardService implements CanActivate {
  constructor(private api2Service: Api2Service, private router: Router) {

  }

  canActivate(): boolean {
    if (this.api2Service.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
