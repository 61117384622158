import { AfterViewInit, Component, Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { DataSendingService } from '@services/data-sending.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-activity-item',
  template: '<div></div>'
})
export class AbstractActivityItemComponent extends BaseComponent implements AfterViewInit {
  @Input() activity: any;
  @Input() tab: string;

  constructor(protected dataSendingService: DataSendingService,
              public sanitizer: DomSanitizer) {
    super();
  }

  public getUrl(activity: any): string {
    if (activity.category === 'Education summary') {
      return '/education/' + activity.id;
    } else {
      if (activity.content_type === 'touchSATELLITE SYMPOSIUM') {
        return '/touch/satellite/' + activity.id;
      } else if (activity.content_type === 'touchPANEL DISCUSSION') {
        return '/touch/panel/' + activity.id;
      } else if (activity.content_type === 'touchEXPERT OPINIONS') {
        return '/touch/expert/' + activity.id;
      } else if (activity.content_type === 'touchROUNDTABLE') {
        return '/touch/roundtable/' + activity.id;
      } else if (activity.content_type === 'touchIN CONVERSATION') {
        return '/touch/touchin/' + activity.id;
      } else if (activity.content_type === 'touchMDT') {
        return '/touch/mdt/' + activity.id;
      } else if (activity.content_type === 'touchTALKS') {
        return '/touch/talks/' + activity.id;
      } else if (activity.content_type === 'touchCONGRESS') {
        return '/touch/congress/' + activity.id;
      } else if (activity.content_type === 'touchFeature') {
        return '/touch/feature/' + activity.id;
      } else if (activity.content_type === 'touch') {
        return '/touch/new/' + activity.id;
      } else {
        return '/activity/' + activity.id;
      }
    }
  }

  ngAfterViewInit(): void {
    this.dataSendingService.sendData('activitySeen', {
      activity: this.activity.id,
      page_title: this.activity.title,
      where: this.tab,
      project: this.activity.project_number
    });
  }

  public betterHtml(references: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(references);
  }

}
