<div>
  <div [innerHTML]="this.intro"></div>
  <p></p>
  <span (click)="readMore()" *ngIf="this.full !== ''" class="c-read-more js-read-more">

    <span *ngIf="more">read less</span>
     <span *ngIf="!more">read more</span>
  </span>
  <p></p>
  <div *ngIf="more" [innerHTML]="this.full"></div>
</div>

