<div class="w-full">
  <div class="flex justify-end">
    <span class="text-neutral-300 cursor-pointer font-medium text-xl" (click)="close()">x</span>
  </div>
  <div class="text-lg text-neutral-500">
    <p *ngIf="!hideThankYou">Thank you for providing the details.</p>
    <p class="mt-4 mb-6">Your profile is currently under review.</p>
    <p class="mt-4 mb-6">You will soon receive a notification from our team regarding the status of your access approval.</p>
  </div>
</div>
