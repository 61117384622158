<div class="flex pb-6" *ngIf="transaction && hasAmount(transaction)">
  <div class="flex flex-col px-4 justify-center">
    <div class="rounded-full border border-gray-300 flex flex-col p-4 justify-center items-center icon-size-4">
      <mat-icon [svgIcon]="'nuaxia:arrow-up'" *ngIf="isIncrease(transaction)"></mat-icon>
      <mat-icon [svgIcon]="'nuaxia:arrow-down'" *ngIf="!isIncrease(transaction)"></mat-icon>
    </div>
  </div>
  <div class="flex-auto">
    <div class="font-semibold pb-1 text-lg">{{transaction.description}}</div>
    <div class="text-gray-400">{{transaction.created_at | date: 'mediumDate'}}</div>
  </div>
  <div class="flex-none px-4 font-medium text-lg" [ngClass]="isIncrease(transaction) ? 'text-green-500' : 'text-red-500'">
    {{getTransactionAmount(transaction)}}<span class="text-uppercase"> {{getCurrency()}}</span>
  </div>
</div>
