import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { HonorariaTransaction } from '../interfaces/honoraria.interface';

@Injectable()
export class HonorariaService extends FirebaseService {

  protected dbPath = '/honoraria';

  public addTransaction(transaction: HonorariaTransaction): HonorariaTransaction {
    return this.create(transaction);
  }

}
