import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-honoraria-sum-info',
  templateUrl: 'honoraria-sum-info.component.html'
})
export class HonorariaSumInfoComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() value: number;
  @Input() currency: string;
}
