<!-- touchExpert-->
<div class="template-touch-expert" *ngIf="education.video_groups.video_groups[0].type === 'new_touch_expert'">

  <div data-section="videos" class="js-tabs">
    <nav class="c-activity-sidebar__internal">
      <ul class="c-activity-sidebar__list js-accordion">
        <li class="c-activity-sidebar__item--bg has-children js-accordion-item is-open">
          <div class="c-activity-sidebar__link c-activity-sidebar__link--parent c-activity-sidebar__link--title">
            <span class="c-activity-sidebar__title">Expert Interviews</span>
          </div>

          <ul class="c-activity-sidebar__list c-activity-sidebar__sub-list js-accordion-first-open show"  *ngFor="let groups of education.video_groups.video_groups;let index = index">
            <li class="c-activity-sidebar__item c-activity-sidebar__item--child c-activity-sidebar__item--child-nb" *ngFor="let video of groups.videos; let index2 = index;">

              <a (click)="videoSelect('mainPlayer', video,'group-'+index,index2,video.time);" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-multi mv-active"
                 [ngClass]="{'is-complete' : videoCompleted('group-'+index,index2),'is-active':tabs[index] && videoTab['group-'+index][index2]}"  [attr.id]="index2 === 0 ? 'videoStart' : null"
              >
                    <span class="o-avatar o-avatar--small u-mr-3">
                     <img src="{{video.avatar}}" class="u-cover" alt="">
                     <span class="c-activity-sidebar__progress c-activity-sidebar__progress-authors" *ngIf="videoCompleted('group-'+index,index2)">
                       <svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon">
                         <use xlink:href="#icon-tick"></use>
                       </svg>
                     </span>
                   </span>
                <span class="c-activity-sidebar__info t-ime">
       <span class="c-activity-sidebar__sub-text js-video-title u-mb-1 c-activity-sidebar__author-name">{{video.name}}</span>
       <span class="c-meta__text u-text-400 u-text-grey-medium-light u-mb-2" [innerHTML]="video.discloses"></span>
       <span class="c-meta">
         <span class="c-meta__item c-meta__item--first">
           <span class="c-meta__text">Duration:&nbsp;{{video.duration}}</span>
         </span>
       </span>
                  <!-- /.c-meta -->
     </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
       <span class="unique-play-button">
         <i class="fa-regular fa-circle-play"></i>
       </span>
       <span></span>
     </span>
              </a>
            </li>
          </ul>
        </li>
      </ul><!-- /.c-activity-sidebar__list -->
      <app-tips></app-tips>
    </nav><!-- /.c-activity-sidebar__internal -->
  </div>
</div>
<!-- touchExpert END-->
