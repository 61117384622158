import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['../../../education.component.scss', '../../touch.component.scss', './tips.component.css'],
})
export class TipsComponent implements OnInit {
  public tipsOpen = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  public toggleTips(): void {
    this.tipsOpen = !this.tipsOpen;
  }

  public scrollTo(el: string): void {
    const scroll = document.getElementById(el);
    if (scroll !== undefined && scroll !== null) {
      scroll.scrollIntoView();
    }
  }
  
  public goTo(elementId: string): void {
    document.getElementById(elementId).click();
    this.scrollTo(elementId);
  }
}
