<div class="flex flex-col flex-auto min-w-0 fuse-mat-rounded-2">
  <!-- Main -->

  <div class="flex flex-auto p-2">
    <div class="flex flex-col flex-auto w-full mx-auto">
      <h6 class="text-center mb-4"><strong>{{title}}</strong></h6>
        <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-8 mt-2">
          <ng-container *ngFor="let section of sections">
            <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card">
              <div class="p-6" *ngIf="section.type === 'video'">
                <app-education-player [elementId]="this.getVideoRef(section.video)" [mediaId]="section.video"></app-education-player>
              </div>
              <div class="p-6" *ngIf="section.type === 'text'" [innerHTML]="section.description"></div>
              <!-- Footer -->
              <div class="flex flex-col w-full mt-auto" *ngIf="section.url && section.url !== ''">
                <div class="px-6 py-4 text-right bg-gray-50 dark:bg-transparent flex justify-end">
                  <a target="_blank" [attr.href]="section.url"  mat-stroked-button class="float-right"><span class="inline-flex items-center">
                    <span>Download</span> <mat-icon class="ml-1.5 icon-size-5" [svgIcon]="'heroicons_solid:arrow-sm-right'"></mat-icon></span></a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
    </div>
  </div>
</div>
