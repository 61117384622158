import { Environment as EnvironmentType } from '@environments/enviroment.interface';

export const EnvElementaryAi: EnvironmentType = {
  site: 'Elementary-ai',
  title: 'elementary-ai Member Portal',
  url: 'https://app.elementary-ai.com/',
  description: '',
  logo: 'assets/images/elementary/logo-300x300.png',
  logoWidth: '150px',
  favicon: 'assets/images/elementary/icon-32x32.png',
  honorariaLink: 'https://www.nuaxia.com/honoraria-',
  privacyLink: 'https://www.elementary-ai.com/privacy-policy',
  termsLink: 'https://www.elementary-ai.com/terms',
  showLogout: false,
  production: true,
  introExpire: 30, // 30 Days
  gtmId: 'GTM-T2W87G6',
  testUser: 'john.doe@nuaxia.com',
  ios: {
    id: 'web.com.appelementaryai.externalportals',
    api: 'https://us-central1-externalportals-295311.cloudfunctions.net/macPushService/elementary-ai'
  },
  firebase: {
    apiKey: 'AIzaSyCPq-LdWTGU3L-yzzhYzOWw6Lr87MJCv4c',
    authDomain: 'externalportals-295311.firebaseapp.com',
    databaseURL: 'https://externalportals-295311.firebaseio.com',
    projectId: 'externalportals-295311',
    storageBucket: 'externalportals-295311.appspot.com',
    messagingSenderId: '369570063996',
    appId: '1:369570063996:web:327ed77908d2a7af4ccbf9',
    measurementId: 'G-ZYSFBTSXLY'
  },
  api: {
    url: 'http://localhost/api/app',
    key: 'BvXmfPHrmigL6NJpq41ttg0H9vZTLqZr'
  },
  onesignal: {
    enabled: true,
    appId: '34fda7d8-1050-4dc7-bf46-ae6a86066cc0'
  },
  push: {
    android: {
      senderId: '68975804284'
    }
  }
};
