<mat-form-field class="fuse-mat-no-subscript w-full">
  <mat-label>Workplace</mat-label>
  <input #searchField
         data-testId="workplace-field"
         matInput
         placeholder="Enter address"
         [formControl]="form.controls[id]"
         (input)="onInput($event)"
         (blur)="mapCheck($event)"
         (focus)="focus = null"
         [class.ng-invalid]="errors && (errors.length > 0)"
         [class.ng-touched]="errors && (errors.length > 0)"
  >
</mat-form-field>
<span *ngIf="post && errors">
  <mat-error *ngIf="errors.required">Workplace is required.</mat-error>
  <mat-error *ngIf="errors.invalid">Workplace is not valid.</mat-error>
</span>

