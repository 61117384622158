import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScriptService} from 'src/app/services/script.service';
import {environmentProd} from '../../../environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit, OnDestroy {

  public progressInterval: any;
  public load = false;

  constructor(public scripts: ScriptService) {
    this.loadScript();
  }

  public ngOnInit(): void {
    this.loadScript();
  }

  public ngOnDestroy(): void {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
  }
  /**
   * Load intercom script
   *
   */
  public loadScript(): void {
    setInterval(() => {
      if (this.load === true) {
        this.ngOnDestroy();
        return;
      } else {
        this.scripts.load('intercom').then(data => {
          console.log('script loaded ', data);
          this.load = true;
        }).catch(error => console.log(error));
      }
    }, 3000);
  }

}
