import {Component, Input, OnInit} from '@angular/core';
import {EducationComponent} from '../../../education.component';

@Component({
  selector: 'app-default-sidebar',
  templateUrl: './default-sidebar.component.html',
  styleUrls: ['./default-sidebar.component.css']
})
export class DefaultSidebarComponent extends EducationComponent implements OnInit {
  @Input() education: any;
}
