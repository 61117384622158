import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AuthService} from '../../services/auth.service';
import {environmentProd} from '../../../environments/environment';
import {AnalyticsService} from '../../services/analytics.service';
import {HelperService} from '../../services/helper.service';
import {SingletonService} from 'src/app/services/singleton.service';
import {DeviceService} from 'src/app/services/device.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public logoWidth: string;
  public showLogout: boolean;
  public isDevice = false;

  constructor(
    public auth: AuthService,
    protected analytics: AnalyticsService,
    private singleton: SingletonService,
    private device: DeviceService
  ) {
    this.isDevice = this.device.isDevice();
  }

  ngOnInit(): void {
    this.logoWidth = environmentProd.logoWidth;
    this.showLogout = environmentProd.showLogout;
  }

  /**
   * Toggle Menu
   */
  toggleMenu(): void {
    this.trigger.openMenu();
  }

  /**
   * Logout user and redirect to login page
   */
  logout(): void {
    this.singleton.unsubscribe();
    this.auth.logout().then(r => {
      location.reload();
    });
  }
}
