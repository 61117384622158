import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appMaxlength]'
})
export class MaxlengthDirective {
  @Input() appMaxlength;

  constructor() {
  }

  @HostListener('keydown', ['$event']) onKeydown(event): void {
    const value = event.target.value;
    // tslint:disable-next-line:radix
    const maxLength = parseInt(this.appMaxlength);
    const keycode = event.which || event.keycode;
    const allowedKeycodes = [8, 13, 46, 37, 38, 39, 40];
    const keyCodeIndex = allowedKeycodes.indexOf(keycode);
    if ((value.length > maxLength - 1) && (keyCodeIndex === -1)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
