<header class="c-header c-header__activity c-activity-container js-site-header" style="box-sizing: border-box;" *ngIf="templateColor" [ngClass]="getHeaderStyle()">
  <div class="o-wrapper c-header__wrapper"  [ngClass]="{'device': isDevice}"  style="width: 100%;max-width: 100%">
    <app-menu style="width: 100%"></app-menu>
  </div>
  <!-- /.o-wrapper -->
  <button class="c-header__title js-sidebar-toggle">
    <div class="c-header__title-wrap">
      <svg role="presentation" class="o-icon c-header__title-icon c-header__title-icon--menu js-sidebar-toggle-menu"  [ngClass]="{'is-active' : !mobileMenu}" (click)="toggleMenu()"  *ngIf="!mobileMenu">
        <use xlink:href="#icon-menu"/>
      </svg>
      <svg role="presentation" class="o-icon c-header__title-icon c-header__title-icon--cross js-sidebar-toggle-cross"  [ngClass]="{'is-active' : mobileMenu}" (click)="toggleMenu()" *ngIf="mobileMenu">
        <use xlink:href="#icon-cross"/>
      </svg>
    </div>
    <!-- /.c-header__title-wrap -->
    <span class="c-header__text"><span class="c-header__text-contents">Contents</span><span class="c-header__text-menu">Menu</span></span>
  </button>
  <!-- /.c-header__title -->
</header>
