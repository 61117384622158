<mat-form-field class="fuse-mat-no-subscript w-full">
  <mat-label>Title</mat-label>
  <mat-chip-list #titleList aria-label="Title">
    <input *ngIf="titleList" placeholder="Select or add your own"
           #titleInput
           [value]="this.titles"
           [formControl]="titleCtrl"
           [matAutocomplete]="titleAutocomplete"
           [matChipInputFor]="titleList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="titleAdd($event)"
           (blur)="titleCheck($event)">
  </mat-chip-list>
  <mat-autocomplete #titleAutocomplete="matAutocomplete" (optionSelected)="titleSelect($event)">
    <mat-option *ngFor="let title2 of titlesAll" [value]="title2">
      {{title2}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
