<div class="pt-3">
  <form [formGroup]="form" *ngIf="form">
    <p class="u-pb-2">
      <mat-error *ngIf="error" data-testId="login-error-message">{{error}}</mat-error>
    </p>
    <p class="u-pb-2" *ngIf="success">
      {{success}}
    </p>
    <div class="text-center pt-4"  *ngIf="success">
      <button mat-flat-button color="primary" class="nuaxia-button">
        <span [routerLink]="['/']">Back to login</span>
      </button>
    </div>
    <p [hidden]="success" class="caps-field">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <mat-label>Email</mat-label>
        <input data-testId="email-field" formControlName="email" matInput placeholder="Enter your email address" (focus)="error = null" appLowercase>
      </mat-form-field>
      <span *ngIf="post && fields.email.errors">
        <mat-error *ngIf="fields.email.errors.required">Email is required.</mat-error>
        <mat-error *ngIf="fields.email.invalid">Email is not valid.</mat-error>
      </span>
    </p>
    <div class="text-right" [hidden]="success">
      <a data-testId="password-reset-link" class="text-md font-medium hover:underline nuaxia-button-text-link" href="/auth/password-reset">Forgot your password?</a>
    </div>
    <div class="button mt-6" [hidden]="success">
      <button data-testId="login-button" mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="submitForm()"><mat-icon *ngIf="loader" data-testId="loading-spinner"><mat-spinner diameter="20">
      </mat-spinner></mat-icon> {{actionButton}}</button>
    </div>
    <div class="text-center mt-4" [hidden]="success"><p>Don't have an account?  <a data-testId="signup-button" class="nuaxia-lato nuaxia-button-text" [routerLink]="['/auth/signup']">Sign up</a></p></div>
    <div class="text-center mt-4" [hidden]="success"><p>Login with password?  <a data-testId="signup-button" class="nuaxia-lato nuaxia-button-text"  [routerLink]="['/auth/password']">Password login</a></p></div>
  </form>

</div>
