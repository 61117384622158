import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-touch-footer',
  templateUrl: './education-footer.component.html',
  styleUrls: ['../../education.component.scss'],
})
export class EducationFooterComponent implements OnInit {
  @Input() education: any;
  public ngOnInit(): void {

  }
}
