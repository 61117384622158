<body
  class="cme-education-template cme-education-template-templates template-in-conversation cme-education-template-templatestemplate-in-conversation-php single single-cme-education postid-54000 has-sidebar activity-template template-in-conversation"
  (scroll)="onWindowScroll($event);">

<header class="c-header c-header__activity c-activity-container js-site-header" style="box-sizing: border-box;"
        *ngIf="templateColor">
  <div class="o-wrapper c-header__wrapper" [ngClass]="{'device': isDevice}" style="width: 100%;max-width: 100%">
    <app-menu style="width: 100%"></app-menu>
  </div>
</header>

<div class="content-container o-wrapper" *ngIf="education">
  <section class="c-activity-content js-activity-content">
    <div class="c-video-section-wrap-new c-video-section-wrap in-conversation">
      <app-player [elementId]="'mainPlayer'"></app-player>
      <!-- title -->
      <div class="c-intro__title-wrap">
        <h1 class="h1 c-intro__title js-intro-title">
          <span class="js-activity-type">
            touchIN CONVERSATION
          </span>
          <!--div class="tooltip-overlay"></div>
          <div class="question-circle">
            <img alt="question-icon"
                 src="https://touchoncology.com/wp-content/themes/tmm/images/graphics/circle-question-regular.svg"/>
          </div>
          <span class="tooltip-text" style="display: none;">
            A relaxed discussion between two faculty focussed on real world clinical issues.
            Useful tips below will show how to navigate the activity. Join the conversation.
            <a class="close-tooltip">Close</a>
          </span-->
        </h1>
        <h1 class="activity-title">{{education.title}}</h1>
      </div>
      <!-- title end -->
      <!-- navigation -->
      <nav class="click-to-section">
        <a (click)="setActiveTab('objectives')" data-section="lo" class="tabLink"
           [ngClass]="activeTab === 'objectives' ? 'active' : ''">Learning
          Objectives &amp; Overview</a>
        <a (click)="setActiveTab('toolkit')" id="toolkit" data-section="toolkit" class="tabLink"
           [ngClass]="activeTab === 'toolkit' ? 'active' : ''">Toolkit</a>
      </nav>
      <!-- navigation -->
      <div class="js-tabs learning-objectives-show" data-section="lo" *ngIf="activeTab === 'objectives'">
        <section>
          <div class="c-tabs__tab js-tabs-tab t-ime" id="js-tab-learning-objectives" style="display: block;">

            <div class="u-pb-0">
              <div class="c-card c-card--large s-standard-content">
                <h5 class="h6 u-mb-2 u-text-grey">Learning Objectives</h5>
                <div [innerHTML]="education.learning_objectives"></div>
              </div><!-- /.c-card -->
            </div><!-- /.o-section -->
            <div class="u-pb-global">
              <div class="c-card c-card--large s-standard-content">
                <h5 class="h6 u-mb-2 u-text-grey" style="margin-top: 15px">Overview</h5>
                <app-readmore [text]="education.overview"></app-readmore>
              </div><!-- /.c-card -->
            </div><!-- /.o-section -->

          </div><!-- /.c-tabs__tab -->
        </section>
      </div>
      <div *ngIf="activeTab === 'toolkit'">
        <ul class="toolkit-section">
          <li class="toolkit-list" data-popup="downloads"><i class="fa-regular fa-download"
                                                             style="color: #fe5000; margin-right: 15px; font-weight: 300"></i><a
            (click)="openDownload()">Downloads</a></li>
          <li class="toolkit-list" data-popup="faculty"><i class="fa-regular  fa-people-group"
                                                           style="color: #fe5000; margin-right: 15px"></i>
            <a (click)="openFaculty()">Faculty
            &amp; Disclosures</a></li>
        </ul>
      </div>
    </div>
  </section>
  <div class="desktop-video-chapters">
    <nav class="c-activity-sidebar__internal">
      <ul class="c-activity-sidebar__list js-accordion js-accordion-first-open">
        <li *ngFor="let groups of education.video_groups.video_groups;let index = index" class="c-activity-sidebar__item c-activity-sidebar__item--bg has-children" [ngClass]="{'js-accordion-item is-open' : openGroup === index}" >
          <a (click)="setOpenGroup(index)" class="c-activity-sidebar__link c-activity-sidebar__link--parent js-accordion-header" >
            <span class="c-activity-sidebar__title"> {{groups.title}}
              <span style="margin-left: 1rem">
                <i class="fa-thin fa-plus" *ngIf="openGroup !== index"></i>
                <i class="fa-solid fa-minus" *ngIf="openGroup === index"></i>
              </span>
            </span>
          </a>
          <ul class="c-activity-sidebar__list c-activity-sidebar__sub-list js-accordion-content" [ngClass]="{'open' : openGroup === index}">
            <li *ngFor="let video of groups.videos; let index2 = index;" class="c-activity-sidebar__item c-activity-sidebar__item--child"  [ngClass]="{'video-active': (startedGroup === 'group-' + index) && startedVideo === index2}">
              <a (click)="videoSelect('mainPlayer', video,'group-'+index,index2,video.time);"
                 class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single-in-conversation"
                 [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}"
                 >
                <span class="c-activity-sidebar__progress" *ngIf="videoCompleted('group-' + index,index2)">
                        <svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon">
                          <use xlink:href="#icon-tick"></use>
                        </svg>
                </span>
                <span class="c-activity-sidebar__info">
                  <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                        <span class="unique-play-button">
                          <i class="fa-regular fa-circle-play"></i>
                        </span>
                        <span></span>
                </span>
              </a>
              <span class="c-meta__text c-meta--time">
                <span class="c-meta__text"><strong>Duration: </strong>{{video.duration}}</span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <div class="useful-tips-wrap">
        <div class="useful-tips">
          <div class="useful-tips-header"  (click)="toggleTips()">
            <a>Useful tips</a>
            <i class="fa-solid fa-chevron-down"></i>
          </div>
          <ul class="useful-tips-content" [ngClass]="{'open': tipsOpen === true}">
            <li>
              <i class="fa-solid fa-download"></i>
              <span>Downloads including slides are available for this activity in the <a class="useful-tips-toolkit" (click)="goTo('toolkit')">Toolkit</a></span>
            </li>

          </ul>
        </div>
      </div>
    </nav>

  </div>
</div>


<app-touch-footer [education]="education"></app-touch-footer>
<!-- Load SVG template file -->
<app-education-svg style="display:none"></app-education-svg>
</body>
