<div class="flex flex-col  flex-auto min-w-0 fuse-mat-rounded-2 pt-12">
  <h2 class="text-center text-strong p-3 pt-8">Got questions or feedback? We'd love to hear from you.</h2>
  <h2 class="text-center text-strong p-3">Our team will get back to you as quickly as we can.</h2>
  <h2 class="text-center text-strong p-3">Just click on the yellow chat icon in the bottom right corner to start a conversation.</h2>
  <div class="text-center mt-4">
    <button mat-flat-button color="primary" class="nuaxia-button">
      <span  [routerLink]="['/']">Back to activities</span>
    </button>
  </div>
</div>
