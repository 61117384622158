<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
  <div class="flex w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16 overflow-hidden sm:bg-card">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <div class="mt-8 text-md font-medium text-secondary">
          <a class="nuaxia-button-back" href="/activities"> <mat-icon class="icon-size-5" [svgIcon]="'nuaxia:back'"></mat-icon></a>
        </div>
        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">{{pageTitle}}</div>
        <ng-container *ngIf="!success">
          <div class="mt-8">Must be 8 or more characters and contain at least 1 number and 1 special character.</div>
          <form>
            <p class="u-pb-2">
              <mat-error *ngIf="error">{{error}}</mat-error>
            </p>
            <p class="mt-8">
              <mat-form-field class="fuse-mat-no-subscript w-full">
                <mat-label>New password</mat-label>
                <input name="password" [(ngModel)]="password" data-testId="password-field" (keydown.enter)="$event.preventDefault()"  matInput [type]="hidePassword ? 'password' : 'text'" appCapslock="password">
                <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                  <mat-icon [svgIcon]="hidePassword ? 'nuaxia:password' : 'visibility'"></mat-icon>
                </button>
              </mat-form-field>
              <app-password-strength
                [passwordToCheck]="password"
                (passwordStrength)="passwordValid($event)"
              ></app-password-strength>
              <mat-error id="caps-password" class="text-secondary"></mat-error>
            </p>
            <p class="button" >
              <button mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="sendPasswordResetEmail()" *ngIf="this.passwordIsValid">Reset password</button>
            </p>
          </form>
        </ng-container>
        <ng-container *ngIf="success">
          <div class="mt-8 text-md font-medium text-secondary">
            <p> {{success}}</p>
          </div>
          <div class="mt-8 text-md font-medium text-secondary">
            Return to <a class="text-md font-medium text-primary-500 hover:underline ng-tns-c175-11" href="/activities"> activities</a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
