import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AnalyticsService} from '@services/analytics.service';
import {Market} from '@ionic-native/market/ngx';
import {DeviceService} from '@services/device.service';
import {environmentProd} from '@environments/environment.prod';

@Component({
  selector: 'app-update',
  templateUrl: './app-update.component.html',
  styleUrls: ['./app-update.component.css']
})
export class AppUpdateComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<AppUpdateComponent>,
              private analytics: AnalyticsService,
              private market: Market,
              private device: DeviceService
  ) {
  }

  ngOnInit(): void {
  }


  /**
   * Close modal
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }

  public updateApplication(): void {
    if (this.device.deviceType() === 'ios') {
      this.market.open(environmentProd.package.ios);
    }
    if (this.device.deviceType() === 'android') {
      this.market.open(environmentProd.package.android);
    }
  }
}
