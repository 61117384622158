import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-honoraria-overview',
  templateUrl: 'honoraria-overview.component.html'
})
export class HonorariaOverviewComponent {
  @Input() sumProcessing: any;
  @Input() sumAccount: any;
  @Input() sumPaid: any;
  @Input() sumCollected: any;
  @Input() profileData: any;
}
