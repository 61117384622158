import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-activity',
  templateUrl: 'no-activity.component.html'
})
export class NoActivityComponent implements OnInit {
  @Input() type: string;
  public contentTypes: string;
  public contentType: string;

  public ngOnInit(): void {
    switch (this.type) {
      case 'education':
        this.contentType = 'Earn and Learn';
        this.contentTypes = 'Earn and Learn content';
        break;
      case 'survey':
        this.contentType = 'Microlearning';
        this.contentTypes = 'Microlearning content';
        break;
      case 'communication':
        this.contentType = 'medical communication';
        this.contentTypes = 'medical communication content';
        break;
      default:
        this.contentType = 'education';
        this.contentTypes = 'education content';
        break;
    }
  }


}
