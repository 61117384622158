<div class="w-full mt-9" *ngIf="profileData" data-testId="user-notifications">
  <div class="grid grid-cols-3 gap-6">
    <div class="leading-6 text-xl">Activities</div>
    <div class="ml-2">Email</div>
    <div class="ml-2">Push</div>
    <div class="leading-6 font-medium">Microlearning</div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.microlearning.email"
        [checked]="profileData.notifications.microlearning.email"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.microlearning.push"
        [checked]="profileData.notifications.microlearning.push"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
  </div>
  <div class="grid grid-cols-3 gap-6 mt-8">
    <div class="leading-6 font-medium">Earn and Learn</div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.earn_and_learn.email"
        [checked]="profileData.notifications.earn_and_learn.email"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.earn_and_learn.push"
        [checked]="profileData.notifications.earn_and_learn.push"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
    <!--div class="leading-6 font-medium">Exhibitions</div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.exhibitions.email"
        [checked]="profileData.notifications.exhibitions.email"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.exhibitions.push"
        [checked]="profileData.notifications.exhibitions.push"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
  </div>
  <div class="my-10 border-t"></div>
  <div class="grid grid-cols-3 gap-6 mt-8">
    <div class="leading-6 text-xl">News</div>
    <div class="ml-2">Mail</div>
    <div class="ml-2">Push</div>
    <div class="leading-6 font-medium">Drug developments</div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.drug_developments.email"
        [checked]="profileData.notifications.drug_developments.email"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.drug_developments.push"
        [checked]="profileData.notifications.drug_developments.push"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
    <div class="leading-6 font-medium">Clinical trials</div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.clinical_trials.email"
        [checked]="profileData.notifications.clinical_trials.email"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        class="ml-2"
        [color]="'primary'"
        [(ngModel)]="profileData.notifications.clinical_trials.push"
        [checked]="profileData.notifications.clinical_trials.push"
        (change)="handleChange()"
      ></mat-slide-toggle>
    </div>
  </div>-->
</div>
