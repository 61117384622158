import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { Userprofile } from '../../../interfaces/userprofile.interface';
import { DataSendingService } from '@services/data-sending.service';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html'
})
export class UserNotificationsComponent extends BaseComponent {
  @Input() profileData: Userprofile;

  constructor(protected dataSendingService: DataSendingService) {
    super();
  }

  /**
   * If any of the notification settings' changes, we notify the parent component,
   * so it can update the profile automatically
   */
  public handleChange(): void {
    this.dataSendingService.sendData('notificationChanged', true);
  }


}
