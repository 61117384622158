import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BaseComponent} from '../base.component';
import {AuthService} from '@services/auth.service';

/**
 * Component for Alchemer (SurveyGizmo) integration
 */
@Component({
  selector: 'app-alchemer',
  template: '<div  style="text-align:center;padding-top:100px;"><h2>Thank you for taking our survey. Your response is very important to us.</h2><button mat-raised-button color="primary" class="btn" [routerLink]="[\'/activities\']">Back to Activites</button></div>'
})
export class AlchemerComponent extends BaseComponent implements OnInit {

  constructor(protected route: ActivatedRoute, protected auth: AuthService) {
    super();
  }

  ngOnInit(): void {

  }
}
