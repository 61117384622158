import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextHelperService {

  /**
   * @summary Generating a random alphanumeric string
   * @param length
   */
  public generateRandomString(length: number = 8): string {

    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;

  }

  public upperCaseFirst(src: string): string {
    return src.length > 0 ? src.charAt(0).toUpperCase() + src.substring(1) : '';
  }

  /**
   * Converting snake_case_string to camelCase
   */
  public snakeToCamelCase(src: string): string {
    // If we do not have any _, then return
    if (!(/[_]/).test(src)) {
      return src;
    }
    const pattern = /(_[a-z])/g;
    return src.toLowerCase()
      .replace(pattern, found =>
        found.toUpperCase()
          .replace('_', '')
      );
  }
}
