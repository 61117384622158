import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DeviceService} from '@services/device.service';
import {SpecialtyService} from '@services/specialty.service';
import {BaseComponent} from '../../base.component';
import {DataSendingService} from '@services/data-sending.service';
import {Api2Service} from '@services/api2.service';

@Component({
  selector: 'app-specialty-field',
  templateUrl: './specialty-field.component.html',
  styleUrls: ['./specialty-field.component.css']
})
export class SpecialtyFieldComponent extends BaseComponent implements OnInit {
  @Input() model: string;

  public isDevice = false;
  public specialties: string[] = [];
  public specialty = '';
  public specialtyCtrl = new FormControl();
  public specialtyFiltered: Observable<string[]>;
  private specialtyNames: [] = [];
  private specialtyNames2 = [];
  private specialtyType = false;
  public specialtyRemovable = true;
  public specialtySelectable = true;
  public specialtyValidation = false;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('specialtyInput') specialtyInput: ElementRef<HTMLInputElement>;

  constructor(private device: DeviceService, private api2Service: Api2Service, protected dataSendingService: DataSendingService) {
    super();
    this.isDevice = this.device.isDevice();
    this.specialtyDropdown();
  }

  ngOnInit(): void {
    this.specialtySet();
  }

  protected specialtySet(): void {
    this.subscribe(this.api2Service.specialties(), (result) => {
      for (const spec of result.data.specialties) {
        this.specialties.push(spec.key);
        const key = spec.key;
        const value = spec.title;
        // @ts-ignore
        this.specialtyNames[key] = value;
        this.specialtyNames2.push(value.replace(' ', '_').toLowerCase());
      }

    });
  }

  /**
   * Show and filter dropdown
   *
   * @protected
   */
  protected specialtyDropdown(): void {
    this.specialtyFiltered = this.specialtyCtrl.valueChanges.pipe(startWith(''), map((name: string | null) => {
      if (name === '') {
        return this.specialties;
      }
      return name ? this.specialtyFilter(name) : this.specialties.slice();
    }));
  }

  /**
   * Primary specialty
   *
   * @param event
   */
  public specialtyAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.specialties.includes(value)) {
      this.specialty = value;
    }

    this.specialtyCtrl.setValue(this.specialty);

  }

  public specialtySelect(event: MatAutocompleteSelectedEvent): void {
    this.specialty = event.option.value;
    this.specialtyCtrl.setValue(this.specialty);
  }

  private specialtyFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.specialties.filter(name => name.toLowerCase().includes(filterValue));
  }

  public specialtyName(value: string): string {
    const filterValue = value.toLowerCase();
    return this.specialtyNames[value] ?? filterValue;
  }

  public specialtyCheck(event: FocusEvent): void {
    setTimeout(() => {
      this.specialtyInput.nativeElement.value = '';
      this.specialtyInput.nativeElement.blur();
      this.specialtyCtrl.setValue('');
      this.dataSendingService.sendData(this.model, this.specialty);
    }, 150);
  }

  public specialtyRemove(): void {
    this.specialty = '';
  }

}
