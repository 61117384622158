import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {DataSendingService} from '@services/data-sending.service';

@Component({
  selector: 'app-title-field',
  templateUrl: './title-field.component.html',
  styleUrls: ['./title-field.component.css']
})
export class TitleFieldComponent implements OnInit {
  @Input() model: string;
  /**
   * Titles
   */
  public titleCtrl = new FormControl();
  public titles = '';
  public titlesAll: string[] = ['Dr. Med.', 'Dr.', 'Professor', 'Mr', 'Mrs', 'Miss', 'Ms'];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;

  constructor(protected dataSendingService: DataSendingService) {
  }

  ngOnInit(): void {
  }

  /**
   * Titles
   *
   * @param event
   */
  public titleAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.titles = value;
    }

    this.titleCtrl.setValue(this.titles);

  }

  public titleSelect(event: MatAutocompleteSelectedEvent): void {
    this.titles = event.option.viewValue;
    this.titleCtrl.setValue(this.titles);
  }

  public titleCheck($event: FocusEvent): void {
    setTimeout(() => {
      this.titleInput.nativeElement.blur();
    }, 150);
    this.dataSendingService.sendData(this.model, this.titles);
  }

}
