import {Routes} from '@angular/router';
import {EducationComponent} from '../components/education/education.component';
import {NotfoundComponent} from '../components/notfound/notfound.component';
import {TermsComponent} from '../components/terms/terms.component';
import {PrivacyComponent} from '../components/privacy/privacy.component';
import {ActivitiesComponent} from '../components/activities/activities.component';
import {ProfileComponent} from '../components/profile/profile.component';
import {SurveyComponent} from '../components/survey/survey.component';
import {RedirectComponent} from '../components/redirect/redirect.component';
import {OutsideComponent} from '../theme/outside/outside.component';
import {LayoutComponent} from '../theme/inside/layout.component';
import {EmptyComponent} from '../theme/empty/empty.component';
import {SupportComponent} from '../components/support/support.component';
import {EducationSummaryComponent} from '../components/education-summary/education-summary.component';
import {RedirectMobileComponent} from '../components/redirect-mobile/redirect-mobile.component';
import {PasswordChangeComponent} from '../components/password-change/password-change.component';
import {LastStepComponent} from '../components/last-step/last-step.component';
import {HonorariaComponent} from '../components/honoraria/honoraria.component';
import {EducationCongressComponent} from '../components/education/congress/congress.component';
import {EducationConversationComponent} from '../components/education/conversation/conversation.component';
import {EducationMdtComponent} from '../components/education/mdt/mdt.component';
import {EducationPanelComponent} from '../components/education/panel/panel.component';
import {EducationSatelliteComponent} from '../components/education/satellite/satellite.component';
import {EducationTalksComponent} from '../components/education/talks/talks.component';
import {EducationFeatureComponent} from '../components/education/feature/feature.component';
import {EducationExpertComponent} from '../components/education/expert/expert.component';
import {EducationTouchComponent} from '../components/education/touch/touch.component';
import {ApiGuardService} from '@services/apiguard.service';
import {AlchemerComponent} from '../components/alchemer/alchemer.component';
import {AuthComponent} from '../components/auth/auth.component';
import {PasswordResetComponent} from '../components/password-reset/password-reset.component';
import {LearnsComponent} from '../components/learns/learns.component';


export const routing: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', component: LearnsComponent},
      {path: 'learn', component: LearnsComponent},
      {path: 'learns/:tab', component: LearnsComponent},
    ]
  },
  {
    path: '',
    component: OutsideComponent,
    children: [
      {path: 'auth/login', component: AuthComponent},
      {path: 'activities/:tab', component: ActivitiesComponent},
      {path: 'auth/password-reset', component: PasswordResetComponent},
      {path: 'auth/password', component: AuthComponent},
      {path: 'auth/token/:token', component: AuthComponent},
      {path: 'auth/signup', component: AuthComponent},
      {path: 'terms-of-service', component: TermsComponent},
      {path: 'privacy-policy', component: PrivacyComponent},
      {path: 'redirect-mobile', component: RedirectMobileComponent},
      {path: 'redirect', component: RedirectComponent},
      {path: 'support', component: SupportComponent},
    ]
  },
  {
    path: '',
    component: OutsideComponent,
    canActivate: [ApiGuardService],
    children: [
      {path: 'last-step', component: LastStepComponent},
      {path: 'password-change', component: PasswordChangeComponent},
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ApiGuardService],
    children: [
      {path: 'survey-complete', component: AlchemerComponent},
      {path: 'activities', component: ActivitiesComponent},
      {path: 'learns/:tab', component: LearnsComponent},
      {path: 'profile/:tab', component: ProfileComponent},
      {path: 'honoraria/:tab', component: HonorariaComponent},
      {path: 'redirect', component: RedirectComponent},
    ]
  },
  {
    path: '',
    component: EmptyComponent,
    children: [
      {path: 'education/:id', component: EducationSummaryComponent},
      {
        path: 'activity/:id',
        component: EducationComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/congress/:id',
        component: EducationCongressComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/conversation/:id',
        component: EducationConversationComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/mdt/:id',
        component: EducationMdtComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/expert/:id',
        component: EducationExpertComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/panel/:id',
        component: EducationPanelComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/satellite/:id',
        component: EducationSatelliteComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/talks/:id',
        component: EducationTalksComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/feature/:id',
        component: EducationFeatureComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/touchin/:id',
        component: EducationConversationComponent,
        canActivate: [ApiGuardService],
      },
      {
        path: 'touch/new/:id',
        component: EducationTouchComponent,
        canActivate: [ApiGuardService],
      },
      {path: 'survey/:id', component: SurveyComponent},
      {path: '**', component: NotfoundComponent}
    ]
  }
];
