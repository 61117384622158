import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { SingletonService } from './singleton.service';
import * as crypto from 'crypto-js';
import {HelperService} from '@services/helper.service';

@Injectable()
export class TokensService extends FirebaseService {

  protected dbPath = '/tokens';
  protected userDbPath = '/user_profiles';
  protected salt = 'hg5s4fg6bbXn';
  constructor(
    protected db: AngularFirestore,
    protected singleton: SingletonService,
    protected helperService: HelperService
  ) {
    super(db, singleton);
  }
  /**
   * Set encrypted data
   *
   * @param data
   */
  public set(data: any): any {
    const salt = this.helperService.generateString();
    const decrypt = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
    return salt + '' + decrypt;
  }

  /**
   * Decrypt data
   *
   * @param data
   */
  public get(data: any): any {
    const keyPrivate = data.slice(0, 6);
    const dataEncrypted = data.slice(6);
    const bytes = CryptoJS.AES.decrypt(dataEncrypted, keyPrivate);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}
