<body class="cme-education-template-default single single-cme-education postid-33707 has-sidebar activity-template" (scroll)="onWindowScroll($event);">

<app-touch-header [isDevice]="isDevice" [templateColor]="templateColor" [mobileMenu]="mobileMenu"></app-touch-header>

<!-- /.c-header -->
<div  class="c-activity-container" *ngIf="education">
  <aside class="c-activity-sidebar js-sidebar" [ngClass]="{'is-open ' : mobileMenu}" [ngClass]="templateColor">
    <nav class="c-activity-sidebar__internal" [ngStyle]="{'height':education.survey !== '' ? 'calc(100% - 62px - 140px)':'calc(100% - 0px - 140px)'}">
      <ul class="c-activity-sidebar__list js-accordion">
        <li class="c-activity-sidebar__item">
          <a (click)="showMenu(0,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link">
            Overview & Learning Objectives
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                    <use xlink:href="#icon-arrow-right-grey"/>
                 </svg>
                 </span>
          </a>
        </li>
        <li class="c-activity-sidebar__item"  *ngIf="education.is_faculty">
          <a (click)="showMenu(1,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link" >
            Faculty & Disclosures
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                    <use xlink:href="#icon-arrow-right-grey"/>
                 </svg>
                 </span>
          </a>
        </li>
        <li class="c-activity-sidebar__item"  *ngIf="education.is_faculty_about">
          <a (click)="showMenu(1,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link" >
            About {{education.faculty.faculty[0].name}}
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                    <use xlink:href="#icon-arrow-right-grey"/>
                 </svg>
                 </span>
          </a>
        </li>
        <li *ngFor="let groups of education.video_groups.video_groups;let index = index" class="c-activity-sidebar__item c-activity-sidebar__item--bg has-children" [ngClass]="{'js-accordion-item is-open' : tabs[index]}">
          <a (click)="toggleTabs(index)" class="c-activity-sidebar__link c-activity-sidebar__link--parent js-accordion-header">
                     <span class="c-activity-sidebar__title">
                        <svg role="presentation" class="o-icon o-icon c-activity-sidebar__title-icon" *ngIf="groups.type === 'review'">
                           <use xlink:href="#icon-webinar"/>
                        </svg>
                         <svg role="presentation" class="o-icon o-icon c-activity-sidebar__title-icon" *ngIf="groups.type === 'expert'">
                           <use xlink:href="#icon-interviews"/>
                        </svg>
                       {{groups.label ? groups.label + ':' : ''}} {{groups.title || 'Videos'}} ({{groups.videos.length}})
                     </span>
            <!-- /.c-activity-sidebar__title -->
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right c-activity-sidebar__arrow-right-accordion">
             <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                <use xlink:href="#icon-up-arrow"/>
             </svg>
             </span>
          </a>
          <ul class="c-activity-sidebar__list c-activity-sidebar__sub-list js-accordion-content js-accordion-first-open" *ngIf="tabs[index]">
            <li *ngFor="let video of groups.videos;let index2 = index" class="c-activity-sidebar__item c-activity-sidebar__item--child">
              <a (click)="videoSelect('mainPlayer', video,'group-'+index,index2,video.time)" *ngIf="groups.type === 'review'" [attr.id]="index2 === 0 ? 'playReviewVideo' : null" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single" [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}">

                <span class="c-activity-sidebar__progress">
                              <svg role="presentation" class="o-icon o-icon c-activity-sidebar__progress-icon">
                                 <use
                                   xlink:href="#icon-tick"/>
                              </svg>
                           </span>
                <span class="c-activity-sidebar__info">
                              <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                              <span class="c-meta">
                                 <span class="c-meta__item c-meta__item--first">
                                    <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                                      <use xlink:href="#icon-time"/></svg>
                                    <span class="c-meta__text"><strong>Time: </strong>{{video.time}} </span>
                                 </span>
                              </span>
                  <!-- /.c-meta -->
                           </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                           <svg role="presentation" class="o-icon o-icon o-arrow__icon">0
                              <use
                                xlink:href="#icon-arrow-right-grey"/>
                           </svg>
                           </span>
              </a>
              <a (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')" *ngIf="groups.type === 'panel'" [attr.id]="index2 === 0 ? 'playReviewVideo' : null" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single" [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}">

                <span class="c-activity-sidebar__progress">
                              <svg role="presentation" class="o-icon o-icon c-activity-sidebar__progress-icon">
                                 <use
                                   xlink:href="#icon-tick"/>
                              </svg>
                           </span>
                <span class="c-activity-sidebar__info">
                              <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                              <span class="c-meta">
                                 <span class="c-meta__item c-meta__item--first">
                                    <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                                      <use xlink:href="#icon-time"/></svg>
                                    <span class="c-meta__text"><strong>Time: </strong>{{video.time}} </span>
                                 </span>
                              </span>
                  <!-- /.c-meta -->
                           </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                           <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                              <use
                                xlink:href="#icon-arrow-right-grey"/>
                           </svg>
                           </span>
              </a>
            </li>
          </ul>
        </li>
        <li class="c-activity-sidebar__item" *ngIf="education.is_reference">
          <a (click)="showMenu(3,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link" >
            References
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                    <use xlink:href="#icon-arrow-right-grey"/>
                 </svg>
                 </span>
          </a>
        </li>
        <li class="c-activity-sidebar__item" *ngIf="education.is_download" >
          <a (click)="showMenu(2,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link">
        <span class="c-activity-sidebar__language-wrap">
            Downloads
                            <span class="c-activity-sidebar__language-internal">
                    <span class="u-text-300" *ngIf=" education.downloads.downloads.length>1">Available in:</span>
                    <ul class="c-activity-sidebar__languages" *ngIf=" education.downloads.downloads.length>1">
                          <li class="c-activity-sidebar__flag"  *ngFor="let download of education.downloads.downloads;let index3 = index">
                            <img src="https://www.touchrespiratory.com/wp-content/themes/tmm2020/images/flags/{{download.language}}.svg" class="o-icon o-icon--flag c-language-selector__flag--{{download.language}}">
                          </li>

                    </ul>
                </span>
                    </span>
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
            <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>        <span>
    </span></span></a>
        </li>
      </ul>
      <!-- /.c-activity-sidebar__list -->
    </nav>
    <!-- /.c-activity-sidebar__internal -->
    <div class="c-activity-sidebar__bottom">
      <ul class="c-activity-sidebar__list">
        <li class="c-activity-sidebar__item c-activity-sidebar__item--cta u-justify-center"  *ngIf="education.survey !== '' && education.survey !== undefined">
          <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta js-overlay-toggle" data-overlay=".js-overlay-cme-test"
             id="take-CME-test-button-sidebar">Take Survey</a>
        </li>
        <li class="c-activity-sidebar__item c-activity-sidebar__item--footer c-activity-sidebar__item--bg">
                  <span>
                     <a (click)="backButton()" class="c-arrow-link" id="back-to-cme-button">
                        <svg role="presentation" class="o-icon o-icon c-arrow-link__icon c-arrow-link__icon--left">
                           <use
                             xlink:href="#icon-arrow-right"/>
                        </svg>
                        Back to Activities
                     </a>
                  </span>
        </li>
      </ul>
    </div>
    <!-- /.c-activity-sidebar__bottom -->
  </aside>
  <!-- /.c-activity-sidebar -->
  <main class="c-activity-content js-activity-content" data-form-type="cme-test" data-video-type="both" *ngIf="education">

    <section class="o-section u-pb-0">
      <div class="o-wrapper">
        <header  class="c-intro c-intro--top s-standard-content" [ngClass]="templateColor">
          <div class="c-meta c-intro__bar">
            <span class="c-intro__badge-wrap  c-intro__badge-wrap-bottom">
              <span class="c-badge c-intro__badge">
                <span class="c-badge__text">{{education.tags}}</span>
              </span>
            </span>
            <span class="c-intro__right">
                        <span class="c-meta__item c-meta__item--first">
                           <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                              <use
                                xlink:href="#icon-time"/>
                           </svg>
                           <span
                             class="c-meta__text"><strong><span class="c-meta__text--limit">Watch</span> Time:</strong> {{education.watch_time}} mins</span>
                        </span>

                     </span>
            <!-- /.c-meta__right -->
          </div>
          <!-- /.c-top-bar -->
          <div class="c-intro__title-wrap">
            <h1 class="h1 c-intro__title js-intro-title">
              <span class="js-activity-type" *ngIf="education.content_title !== undefined && education.content_title !== ''">{{education.content_title}}</span>
              <span class="js-activity-type" *ngIf="education.content_title === undefined || education.content_title === ''">{{education.content_type}}</span>
              {{education.title}}
            </h1>
            <p><span [innerHTML]="education.description.description ? education.description.description : education.description"></span></p>
            <span *ngFor="let groups of education.video_groups.video_groups;let index = index">
              <p *ngIf="groups.label && template === 'review' && groups.type === 'review'">
                <strong>{{groups.label}}:</strong>
                <span *ngIf="template === 'review'" [innerHTML]="groups.description"></span>&nbsp;<a *ngIf="template === 'review'" (click)="videoSelect('mainPlayer',groups.videos[0],'group-'+index,groups.videos[0].time)">Watch Now</a>
                <br/>
              </p>
              <p *ngIf="groups.label && template === 'review' && groups.type === 'expert'">
                <strong>{{groups.label}}:</strong>
                <span *ngIf="template === 'review'" [innerHTML]="groups.description"></span>&nbsp;<a *ngIf="template === 'review'" (click)="videoSelect('expertPlayer',groups.videos[0],'group-'+index,0,'00:00')">Watch Expert Interviews</a>
                <br/>
              </p>
            </span>
            <p *ngIf="education.subtitle"><span [innerHTML]="education.subtitle.subtitle ? education.subtitle.subtitle : education.subtitle"></span></p>
          </div>
          <!-- /.c-intro__title-wrap -->
        </header>
      </div>
      <!-- /.o-wrapper -->
      <div *ngFor="let groups of education.video_groups.video_groups;let index = index">
        <section class="c-video-section-wrap" [ngClass]="templateColor" *ngIf="groups.type === 'panel'" id="{{ 'group-' + index }}" style="display:block">
          <div class="o-wrapper">
            <div class="c-video-container">
              <app-player [elementId]="'expertPlayer'"></app-player>
            </div>
          </div>
          <!-- /.o-wrapper -->
        </section>
        <section class="c-video-section-wrap" [ngClass]="templateColor" *ngIf="groups.type === 'panel'" style="display:block">
          <div class="o-wrapper">
            <div class="js-wistia-wrap-multi">
              <div *ngFor="let video of groups.videos;let index2 = index;">

                <div class="c-wistia c-wistia__multi js-wistia-video-wrap" style="display:block"  *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                  <div class="c-card c-card--flex c-card--row c-card--flex-top c-bio c-card--large s-standard-content">
                                                                    <span class="c-bio__left">
                                                          <span class="u-hide js-author-name">Video Chapters</span>
                                                                                              <h6 class="h6 c-bio__title js-author-institution">{{video.title}}</h6>
                                                 <p [innerHTML]="video.description"></p>
                  <a class="js-view-bios" (click)="showMenu(1,'js-activity-content-tabs')">view bio and disclosures</a>
                                                                                                                        </span>
                    <span class="c-bio__right"  *ngIf="(index2+1) < totalVideo">
                          <span class="c-card__count">
                              <span class="c-card__count-text"><span class="is-active">{{index2+1}}</span>/{{totalVideo}}</span>
                          </span>
                          <a   (click)="videoSelect('expertPlayer',groups.videos[index2+1], 'group-'+index,index2+1,'00:00')" class="c-button has-icon js-next-video" id="next-video-button">Next Chapter</a>
                   </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.o-grid -->
          </div>
          <!-- /.o-wrapper -->
        </section>
      </div>
      <!-- /.o-section -->
    </section>
    <!-- /.o-section -->
    <div class="js-tabs" [ngClass]="templateColor" id="js-activity-content-tabs">
      <div class="c-tabs-bar">
        <div class="o-wrapper c-tabs-bar__wrapper">
          <ul class="c-tabs-bar__list">
            <li class="c-tabs-bar__item"  (click)="showMenu(0,'js-activity-content-tabs')">
              <button class="c-tabs-bar__tab-button js-tabs-button"   [ngClass]="{'is-active' : menuTab === 0}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Overview <span class="c-tabs-bar__text--limit">& Learning Objectives</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(1,'js-activity-content-tabs')" *ngIf="education.is_faculty">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 1}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Faculty <span class="c-tabs-bar__text--limit">& Disclosures</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(1,'js-activity-content-tabs')" *ngIf="education.is_faculty_about">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 1}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">About <span class="c-tabs-bar__text--limit">{{education.faculty.faculty[0].name}}</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(3,'js-activity-content-tabs')" *ngIf="education.is_reference">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 3}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">References</span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(2,'js-activity-content-tabs')" *ngIf="education.is_download">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 2}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Downloads</span>
              </button>
            </li>
          </ul>
          <div class="c-tabs-bar__fade"></div>
          <!-- /.c-tabs-bar__fade -->
          <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta c-tabs-bar__button u-flex-shrink js-overlay-toggle" *ngIf="education.survey"
             data-overlay=".js-overlay-cme-test" id="take-CME-test-button-banner">Take Survey</a>
        </div>
        <!-- /.o-wrapper -->
      </div>
      <!-- /.c-tabs-bar -->
      <section class="o-section o-section--grey" [ngClass]="templateColor">
        <div class="o-wrapper">
          <div class="c-tabs__tab js-tabs-tab" [ngClass]="templateColor" id="js-tab-learning-objectives" style="display: block;" *ngIf="menuTab === 0">
            <div class="u-pb-global">
              <h6 class="h6 u-text-grey">Overview &amp; Learning Objectives</h6>
              <div class="c-card c-card--large s-standard-content">
                <h6 class="h6 u-mb-2 u-text-grey">Overview</h6>
                <app-readmore *ngIf="education.overview" [text]="education.overview.overview ? education.overview.overview : education.overview"></app-readmore>
              </div>
              <!-- /.c-card -->
            </div>
            <!-- /.o-section -->
            <div class="u-pb-0">
              <div class="c-card c-card--large s-standard-content">
                <h6 class="h6 u-mb-2 u-text-grey">Learning Objectives</h6>
                <app-readmore *ngIf="education.learning_objectives" [text]="education.learning_objectives.learning_objectives ? education.learning_objectives.learning_objectives : education.learning_objectives"></app-readmore>
              </div>
              <!-- /.c-card -->
            </div>
            <!-- /.o-section -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-faculty" style="display: block;" *ngIf="menuTab === 1">
            <div class="u-pb-0" *ngIf="!education.is_faculty_about">
              <h6 class="h6 u-text-grey">Faculty &amp; Disclosures</h6>
            </div><!-- /.u-pb-global -->
            <div class="u-pb-5" *ngFor="let faculty of education.faculty.faculty;let facultyIndex = index">
              <div class="u-pb-0" *ngIf="education.is_faculty_about">
                <h6 class="h6 u-text-grey">About {{faculty.name}}</h6>
              </div><!-- /.u-pb-global -->
              <div class="c-card c-card--large c-card--flex c-card--flex-top c-card--flex-wrap s-standard-content">
                    <span class="c-card__details c-card__details-alt u-mb-4">
                                                    <span class="o-avatar c-card__col">
                                <img src="{{faculty.image}}" class="u-cover" alt="">
                            </span><!-- /.o-avatar -->
                                                <span class="c-card__col c-card__col--name">
                                                            <h6 class="h6 u-mb-2"> {{faculty.name}}</h6>
                                                                                        <p class="u-mb-0 c-card__sub-title"> {{faculty.from}}</p>
                                                    </span>
                    </span>
                <span class="c-card__col c-card__col--last c-card__disclosures">
                    <p><app-readmore [text]="faculty.bio"></app-readmore></p>
                <p><app-readmore [text]="faculty.discloses"></app-readmore></p>

                                                                    </span>
              </div><!-- /.c-card -->
            </div><!-- /.u-pb-global -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-references" style="display: block;" *ngIf="menuTab === 3 && education.is_reference">
            <div class="u-pb-0">
              <h6 class="h6 u-text-grey">References</h6>
            </div><!-- /.u-pb-global -->
            <div class="c-card c-card--large s-standard-content">
              <h6 class="h6 u-mb-2 u-text-grey">References</h6>
              <p [innerHTML]="education.references"></p>
            </div><!-- /.c-card -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-author-downloads" style="display: block;" *ngIf="menuTab === 2">
            <div class="">
              <div *ngIf="education.downloads && education.downloads.downloads && education.downloads.downloads.length > 1">
                <div class="u-pb-global">
                  <h6 class="h6 u-text-grey">Downloads</h6>
                  <div class="c-card c-card--large s-standard-content">
                    <p>View and download resources from this activity to support your learning or share with colleagues.</p>
                  </div><!-- /.c-card -->
                </div><!-- /.o-section -->
                <div class="u-pb-global">
                  <div class="o-grid">
                    <div class="o-grid__col o-grid__col-4 o-grid__col-6@dl o-grid__col-12@tm">
                      <div class="c-language-selector js-language-selector">
                        <label for="resources-language-selector" class="u-hidden">Select Language</label>
                        <select name="language-selector" id="resources-language-selector" class="c-language-selector__select js-language-selector-select"  #p (change)="pdfDownload(p.value)">
                          <option value="uk" selected="">Select Language:</option>
                          <option *ngFor="let download of education.downloads.downloads;let index3 = index" value="{{download.language}}">{{download.language}}</option>
                        </select><!--.js-language-selector-select -->
                        <div class="c-language-selector__facade c-card c-card--direction c-card--flex c-card--button u-justify-end u-mt-3">
                          <div class="c-language-selector__icon c-card__button-icon">
                            <img  *ngFor="let download of education.downloads.downloads;let index3 = index" src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/{{download.language}}.svg" class="o-icon o-icon--flag c-language-selector__flag c-language-selector__flag--{{download.language}} js-language-selector-flag" data-language="{{download.language}}">
                          </div><!-- /.flag -->
                          <span class="o-arrow o-arrow--right c-language-selector__arrow">
                                    <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-up-arrow"></use></svg>                                </span>
                        </div><!-- /.c-language-selector__facade -->
                      </div><!-- /.c-language-selector -->
                    </div><!-- /.o-grid__col -->
                  </div><!-- /.o-grid -->
                </div><!-- /.u-pb-global -->
                <div class="u-pb-0" *ngIf="selectedPdf">
                  <div class="c-tabs__tab js-language-grid" id="js-{{selectedPdfLanguage}}"  [ngStyle]="{'display':(selectedPdfLanguage !== ''?'block':'none')}">
                    <div class="o-grid">
                      <div class="o-grid__col o-grid__col-4 o-grid__col-12@tm">
                        <a href="{{this.selectedPdf}}" class="c-card c-card--direction c-card--flex c-card--button c-card__link" target="_blank">
                          <svg role="presentation" id="" class="o-icon o-icon o-icon--medium o-icon--primary c-card__button-icon"><use xlink:href="#icon-podcast"></use></svg>                                    <span class="c-card__button-text">
                                                                                    Download Slides
                                                                            </span>
                          <span class="o-arrow o-arrow--right c-card__button-arrow">
                                        <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>                                    <span>
                                </span></span></a>
                      </div><!-- /.o-grid__col -->
                    </div><!-- /.o-grid -->
                  </div><!-- /.c-tabs__tab -->
                </div><!-- /.o-section -->
              </div>
              <div *ngIf="pdf && education.downloads.downloads.length === 1">
                <h6 class="h6 u-mb-2 u-text-grey">Downloads</h6>
                <p>View and download resources from this activity to support your learning or share with colleagues.</p>
                <div class="u-pb-0">
                  <div class="o-grid">
                    <div class="o-grid__col o-grid__col-4 o-grid__col-12@tm">
                      <a href="{{this.pdf}}" class="c-card c-card--direction c-card--flex c-card--button c-card__link" target="_blank">
                        <svg role="presentation" id="" class="o-icon o-icon o-icon--medium o-icon--primary c-card__button-icon"><use xlink:href="#icon-podcast"></use></svg>                                        <span class="c-card__button-text">
                                                                                            Download Slides
                                                                                    </span>
                        <span class="o-arrow o-arrow--right c-card__button-arrow">
                                            <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>                                        <span>
                                    </span></span></a>
                    </div><!-- /.o-grid__col -->
                  </div><!-- /.o-grid -->
                </div><!-- /.o-section -->
              </div>
              <div *ngIf="pdf === ''">
                <p>There is no downloadable files.</p>
              </div>
            </div><!-- /.c-card -->
          </div>
          <!-- /.c-tabs__tab -->
        </div>
        <!-- /.o-wrapper -->
      </section>
      <!-- /.o-section -->
    </div>
    <!-- /.js-tabs -->
  </main>
  <app-touch-footer [education]="education"></app-touch-footer>
</div>
<!-- /.c-activity-container -->
<!-- Load SVG template file -->
<!-- Load SVG template file -->
<app-education-svg style="display:none"></app-education-svg>

</body>
