import { Component, Input, OnInit } from '@angular/core';
import { HonorariaTransaction } from '../../interfaces/honoraria.interface';
import { DataSendingService } from '@services/data-sending.service';
import { BaseComponent } from '../base.component';
import { orderBy } from 'lodash-es';
import { AuthService } from '@services/auth.service';

import { ActivatedRoute } from '@angular/router';
import {Api2Service} from '@services/api2.service';

@Component({
  selector: 'app-honoraria',
  templateUrl: './honoraria.component.html',
  styleUrls: ['./honoraria.component.scss']
})
export class HonorariaComponent extends BaseComponent implements OnInit {

  @Input() profileData: any;

  protected user: any;
  public OVERVIEW = 'overview';
  public transactions: HonorariaTransaction[] = [];
  public transactionsToShow: HonorariaTransaction[] = [];
  public selectedTab = this.OVERVIEW;

  public sumProcessing = 0;
  public sumAccount = 0;
  public sumPaid = 0;
  public sumCollected = 0;

  constructor(private dataSendingService: DataSendingService,
              private auth: AuthService,
              private route: ActivatedRoute,
              private api2Service: Api2Service) {
    super();
  }

  ngOnInit(): void {
    this.getTransactions();
    this.subscribeToTabChanges();
  }


  ///////////////////////////////

  protected subscribeToTabChanges(): void {
    this.subscribe(this.route.paramMap, (map) => {
      this.selectedTab = map.get('tab');
      this.setTransactionsToShow();
    });
  }

  protected setTransactionsToShow(): void {
    if (this.selectedTab === this.OVERVIEW) {
      this.transactionsToShow = this.transactions.slice(0, 3);
    } else {
      this.transactionsToShow = this.transactions;
    }
  }

  protected getTransactions(): void {
    this.profileData = this.user = this.auth.currentUser();

    this.api2Service.getHonoraria().then(r => {
      this.transactions = orderBy(r.data.honoraria, ['created_at'], ['desc']);
      for (const transaction of this.transactions) {
        this.sumProcessing += transaction.processing;
        this.sumAccount += transaction.held_on_account;
        this.sumPaid += transaction.paid;
        this.sumCollected += transaction.collected;
      }
      this.setTransactionsToShow();
    });
  }
}
