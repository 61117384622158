import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';

import { CalendarComponent } from './components/calendar.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
]);

@NgModule({
  imports: [
    FullCalendarModule
  ],
  declarations: [
    CalendarComponent
  ],
  exports: [
    CalendarComponent
  ]
})
export class CalendarModule{}
