<div class="text-center pt-6 md:pt-12">
  <div>Earned</div>
  <div class="text-3xl md:text-6xl font-bold pb-8">{{sumCollected}} {{profileData.currency}}</div>
</div>

<div class="flex items-strech justify-center gap-1 md:gap-4">
  <app-honoraria-sum-info [icon]="'nuaxia:honoraria2'" [label]="'Processing'" [value]="sumProcessing" [currency]="profileData.currency"></app-honoraria-sum-info>
  <app-honoraria-sum-info [icon]="'nuaxia:honoraria3'" [label]="'Held on account'" [value]="sumAccount"  [currency]="profileData.currency"></app-honoraria-sum-info>
  <app-honoraria-sum-info [icon]="'nuaxia:honoraria4'" [label]="'Paid out'" [value]="sumPaid"  [currency]="profileData.currency"></app-honoraria-sum-info>
</div>
