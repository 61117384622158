import { Component } from '@angular/core';
import { AbstractActivityItemComponent } from '../activity-item/activity-item.component';

@Component({
  selector: 'app-communication-card',
  templateUrl: 'communication-card.component.html'
})
export class CommunicationCardComponent extends AbstractActivityItemComponent {

}
