import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {HelperService} from '@services/helper.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '@services/auth.service';
import {SingletonService} from '@services/singleton.service';
import {Router} from '@angular/router';
import {DataSendingService} from '@services/data-sending.service';
import {AnalyticsService} from '@services/analytics.service';
import {Api2Service} from '@services/api2.service';

@Component({
  selector: 'app-profile-delete',
  templateUrl: './profile-delete.component.html',
  styleUrls: ['./profile-delete.component.css']
})
export class ProfileDeleteComponent implements OnInit {
  public userDeleteLoader = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ProfileDeleteComponent>,
              private helper: HelperService,
              private api2Service: Api2Service,
              private message: MatSnackBar,
              private auth: AuthService,
              private singleton: SingletonService,
              private router: Router,
              private dataSendingService: DataSendingService,
              private analytics: AnalyticsService
  ) {
  }

  ngOnInit(): void {
  }

  public deleteProfile(): void {
    this.userDeleteLoader = !this.userDeleteLoader;

    this.api2Service.deleteAccount().then(t => {
      this.api2Service.refreshUser().then(r => {
        this.message.open('Profile deleted', '', {
          duration: 2000,
          panelClass: ['success']
        });
        this.analytics.addEvent('account_delete');
        this.closeDialog();
      });
    });
    this.userDeleteLoader = !this.userDeleteLoader;
  }

  /**
   * Close modal
   */
  public closeDialog(): void {
    this.dialogRef.close();
    this.dataSendingService.sendData('logout', true);
  }
}
