import { Environment as EnvironmentType } from '@environments/enviroment.interface';

export const EnvDevElementaryAi: EnvironmentType = {
  site: 'Elementary-ai',
  title: 'DEV elementary-ai Member Portal',
  url: 'https://test.app.elementary-ai.com/',
  description: '',
  logo: 'assets/images/elementary/logo-300x300.png',
  logoWidth: '150px',
  favicon: 'assets/images/elementary/icon-32x32.png',
  honorariaLink: 'https://www.nuaxia.com/honoraria-',
  privacyLink: 'https://www.elementary-ai.com/privacy-policy',
  termsLink: 'https://www.elementary-ai.com/terms',
  showLogout: true,
  production: false,
  introExpire: 30, // 30 Days
  gtmId: 'GTM-KHCJN58',
  testUser: 'john.doe@nuaxia.com',
  ios: {
    id: 'web.com.testappelementaryai.externalportals',
    api: 'https://us-central1-externalportals-dev-c2ced.cloudfunctions.net/macPushService/elementary-ai'
  },
  firebase: {
    apiKey: 'AIzaSyAl3tKrc_SPtir_N8kfS6lxO-DOHSP65bM',
    authDomain: 'externalportals-dev-c2ced.firebaseapp.com',
    databaseURL: 'https://externalportals-dev-c2ced.firebaseio.com',
    projectId: 'externalportals-dev-c2ced',
    storageBucket: 'externalportals-dev-c2ced.appspot.com',
    messagingSenderId: '68975804284',
    appId: '1:68975804284:web:90d356535f232cdd20eef9',
    measurementId: 'G-2Q2111DNZB'
  },
  api: {
    url: 'http://localhost/api/app',
    key: 'BvXmfPHrmigL6NJpq41ttg0H9vZTLqZr'
  },
  onesignal: {
    enabled: true,
    appId: 'c221d1a8-765a-4aac-bf8d-244e0c5be99d'
  },
  push: {
    android: {
      senderId: '68975804284'
    }
  }
};
