<mat-toolbar [ngClass]="{'device': isDevice}">
  <span class="example-spacer" *ngIf="!isDevice"></span>
  <div class="flex items-center pl-2 space-x-8 nuaxia-menu-header"  [ngClass]="{'device': isDevice}">
    <!-- Button -->
    <button  [routerLink]="['/activities']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" data-testId="home-link">
      <span class="menu-item-text">Home</span>
    </button >
    <button class="mr-8" [routerLink]="['/honoraria/overview']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" data-testId="honoraria-link">
      <span class="menu-item-text">Honoraria</span>
    </button>
    <button [routerLink]="['/profile/profile']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" data-testId="profile-link">
      <span class="menu-item-text">Profile</span>
    </button>
  </div>
</mat-toolbar>

