import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Observable} from 'rxjs';

@Injectable()
export class CountryService extends FirebaseService {

  protected dbPath = '/countries';

  /////////////////////////////////
  /**
   * Get Country By code
   *
   * @param code
   */
  public getCountryByCode(code: string): Observable<any> {
    return this.getEveryDocumentData(query => query.where('name', '==', code.toUpperCase()));
  }

  /**
   * Get Country by mautic name
   *
   * @param name
   */
  public getCountryByMauticName(name: string): Observable<any> {
    return this.getEveryDocumentData(query => query.where('mautic_code', '==', name));
  }
}
