<mat-form-field class="fuse-mat-no-subscript w-full">
  <mat-label>Primary specialty</mat-label>
  <mat-chip-list  #specialtyList aria-label="Specialty">
    <mat-chip *ngIf="this.specialty"
              [selectable]="specialtySelectable"
              [removable]="specialtyRemovable"
              (removed)="specialtyRemove()">
      {{specialtyName(this.specialty)}}
      <mat-icon matChipRemove *ngIf="specialtyRemovable">cancel</mat-icon>
    </mat-chip>
    <input *ngIf="specialtyList" placeholder="Add or update your primary specialty"
           #specialtyInput
           [formControl]="specialtyCtrl"
           [matAutocomplete]="specialtyAutocomplete"
           [matChipInputFor]="specialtyList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="specialtyAdd($event)"
           (blur)="specialtyCheck($event)">
  </mat-chip-list>
  <mat-autocomplete #specialtyAutocomplete="matAutocomplete" (optionSelected)="specialtySelect($event)">
    <mat-option *ngFor="let spec4 of specialtyFiltered | async" [value]="spec4">
      {{specialtyName(spec4)}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

