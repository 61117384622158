<div class="flex flex-auto w-full p-2" *ngIf="activity" [routerLink]="[getUrl(activity)]">
  <div class="flex flex-col flex-auto order-2 md:order-1">

    <div class="flex items-center leading-none mt-2 -ml-1 pb-2">
      <div class="flex items-center">
        <!-- Course category -->
        <ng-container>
          <div
            class="py-0.5 px-3 rounded-full text-sm font-semibold nuaxia-card-tags-education">
            {{activity.category}}
          </div>
        </ng-container>
      </div>
    </div>
    <div>
      <!-- Course title & description -->
      <div class="mt-1 text-lg font-medium nuaxia-card-title">{{activity.tags}}</div>
      <div class="font-semibold leading-tight nuaxia-card-description">{{activity.title}}</div>
    </div>

  </div>
  <div class="w-full md:w-40 rounded-lg overflow-hidden image-container">
    <img
      class="w-24 h-24 rounded-2xl object-cover nuaxia-bg-card-image"
      src="{{image(activity.image)}}"
      alt="{{activity.title}} image">
  </div>
</div>
<!-- Footer -->
<div class="flex flex-col w-full mt-auto">
  <!-- Course progress -->
  <div class="relative h-0.5 px-2 pt-2">
    <div
      class="z-10 absolute inset-x-0 h-6 -mt-3"
      [matTooltip]="activity.progress.progress+ '%'"
      [matTooltipPosition]="'above'"
      [matTooltipClass]="'-mb-0.5'"></div>
    <mat-progress-bar
      class="h-0.5"
      [value]="activity.progress.progress"></mat-progress-bar>
  </div>

  <!-- Course launch button -->
  <div class="p-2 text-right dark:bg-transparent flex justify-between justify-between">
    <div class="flex lh-17"><mat-icon [svgIcon]="'nuaxia:hour'" matPrefix></mat-icon> {{activity.watch_time}}<span>&nbsp;minutes</span></div>
    <div class="honoraria flex lh-17">{{activity.progress.progress}} % <span class="ml-1">completed</span></div>
  </div>
</div>
