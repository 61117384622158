import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseComponent} from '../../base.component';
import {DataSendingService} from '@services/data-sending.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpecialtyService} from '@services/specialty.service';

@Component({
  selector: 'app-work-field',
  templateUrl: './work-field.component.html',
  styleUrls: ['./work-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WorkFieldComponent),
      multi: true,
    },
  ],
})
export class WorkFieldComponent extends BaseComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() id: string;
  @Input() model: string;
  @Input() errors: any;
  @Input() post: boolean;
  @Input() focus: null;

  @ViewChild('searchField') searchField: ElementRef;
  public address: any;
  form: FormGroup;
  /**
   * User places
   */
  public userPlaces: string[] = [];
  private country = '';

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  constructor(private fb: FormBuilder, private dataSendingService: DataSendingService, private message: MatSnackBar, private specialtyService: SpecialtyService) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      [this.id]: [null],
    });
  }

  writeValue(value: any): void {
    this.form.get(this.id).setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  onInput(event: Event): void {
    this.onChange((event.target as HTMLInputElement).value);
  }

  onBlur(): void {
    this.onTouched();
  }

  /**
   * Get Address format
   * @param place
   */
  public getAddress(place): {} {
    let location = {};
    location['name'] = place.name || '';
    // tslint:disable-next-line:forin
    for (let i in place.address_components) {
      let item = place.address_components[i];

      location['formatted_address'] = place.formatted_address;
      if (item['types'].indexOf('locality') > -1) {
        location['locality'] = item['long_name'];
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
        location['admin_area_l1'] = item['short_name'];
      } else if (item['types'].indexOf('street_number') > -1) {
        location['street_number'] = item['short_name'];
      } else if (item['types'].indexOf('route') > -1) {
        location['route'] = item['long_name'];
      } else if (item['types'].indexOf('country') > -1) {
        location['country'] = item['long_name'];
        location['country_short'] = item['short_name'];
      } else if (item['types'].indexOf('postal_code') > -1) {
        location['postal_code'] = item['short_name'];
      }

    }

    return location;
  }

  /**
   * Setup autocomplete search
   */
  ngAfterViewInit(): void {
    const options = {
      strictBounds: false,
      fields: ['name', 'formatted_address', 'address_components'],
      types: ['establishment'],
    };

    const autocomplete = new google.maps.places.Autocomplete(this.searchField.nativeElement, options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.address = this.getAddress(autocomplete.getPlace());
      this.send();
    });
  }

  public send(): void {
    this.writeValue(this.address.name);
    this.dataSendingService.sendData('workplace', {
      address: this.address,
    });
  }

  public mapCheck(event: FocusEvent): void {
    setTimeout(() => {
      this.send();
    }, 150);
  }
}
