import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AuthService} from './auth.service';
import {environmentProd} from '@environments/environment.prod';
import {DeviceService} from './device.service';
import {Api2Service} from '@services/api2.service';

declare const window: any;

@Injectable()
export class PushService {
  private id = environmentProd.ios.id;
  private api = environmentProd.ios.api;
  newMessage = new BehaviorSubject(null);
  public isDevice = false;
  private notification: any;

  // tslint:disable-next-line:max-line-length
  constructor(private push: AngularFireMessaging, private notify: MatSnackBar, private auth: AuthService, private device: DeviceService, private api2Service: Api2Service) {
    this.isDevice = this.device.isDevice();
  }

  /**
   * Get user permission
   */
  public async init(): Promise<boolean> {
    if (!this.isDevice) {
      if ('safari' in window && 'pushNotification' in window.safari) {
        return this.appleToken();
      } else {
        return await this.googleToken();
      }
    } else {
      return true;
    }
  }

  /**
   * Get Google Token
   */
  public async googleToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.push.requestToken.subscribe(token => {
          let tokenList: string[] = [];
          console.log('GCM GRANTED!', token);
          if (token === null) {
            resolve(false);
            return false;
          }
          if (this.auth.currentUser().tokens !== undefined) {
            tokenList = this.auth.currentUser().tokens;
          }
          if (!tokenList.includes(token)) {
            tokenList.push(token);
            this.api2Service.updateDeviceToken({tokens: tokenList}).then(r => r);
            resolve(true);
            return true;
          } else {
            resolve(true);
            return true;
          }
        },
        (err) => {
          resolve(false);
          return false;
        });
    });
  }

  /**
   * Apple Token access
   */
  public appleToken(): boolean {
    if ('safari' in window && typeof window.safari !== undefined && 'pushNotification' in window.safari) {
      const permission = window.safari.pushNotification.permission(this.id);
      return this.appleTokenCheck(permission);
    } else {
      return false;
    }
  }

  /**
   * Apple Permission Check
   *
   * @param permission
   */
  public appleTokenCheck(permission: any): boolean {
    if (permission.permission === 'default') {
      console.log('MAC PUSH DEFAULT');
      window.safari.pushNotification.requestPermission(this.api, this.id, {}, subscription => {
        return this.appleTokenCheck(subscription);
      });
    } else if (permission.permission === 'granted') {
      console.log('MAC PUSH GRANTED', permission);
      this.appleTokenSave(permission.deviceToken);
      return true;
    } else if (permission.permission === 'denied') {
      console.log('MAC PUSH DENIED', permission);
      return false;
    }
  }

  /**
   * Apple Token Save
   *
   * @param deviceToken
   */
  public appleTokenSave(deviceToken: string): void {
    let appleTokenList: string[] = [];
    if (this.auth.currentUser().apple_tokens !== undefined) {
      appleTokenList = this.auth.currentUser().apple_tokens;
    }
    if (!appleTokenList.includes(deviceToken)) {
      appleTokenList.push(deviceToken);
      this.api2Service.updateDeviceToken({apple_tokens: appleTokenList}).then(r => r);
    }
  }

  /**
   * Save device tokens
   *
   */
  public saveDeviceToken(): void {
    const uuid = localStorage.getItem('uuid') ?? '';

    if (uuid === '' || this.auth.currentUser().nx_id === undefined || this.auth.currentUser().nx_id === '') {
      return;
    }

    if (this.device.deviceType() === 'android') {
      this.api2Service.updateDeviceToken({android_tokens: uuid}).then(r => r);
    }

    if (this.device.deviceType() === 'ios') {
      this.api2Service.updateDeviceToken({ios_tokens: uuid}).then(r => r);
    }
  }
}
