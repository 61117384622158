import { ChangeDetectorRef, Inject, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash-es';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@services/auth.service';
import { PushService } from '@services/push.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SchoolService } from '@services/school.service';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environmentProd } from '@environments/environment.prod';
import { SpecialtyService } from '@services/specialty.service';
import { AnalyticsService } from '@services/analytics.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { DataSendingService } from '@services/data-sending.service';
import { Observable, Subject } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { orderBy } from 'lodash-es';
import { SingletonService } from 'src/app/services/singleton.service';
import { DeviceService } from 'src/app/services/device.service';
import { UserTokenService } from '@services/user-tokens.service';
import { HelperService } from '@services/helper.service';
import { CountryService } from '@services/country.service';
import {PopupComponent} from '../fields/popup/popup.component';
import {ProfileDeleteComponent} from '../profile-delete/profile-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {Api2Service} from '@services/api2.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit, OnDestroy {
  private country = '';
  public showProfileReview = false;
  private workplaces = [];

  public get qualificationAll(): Array<number> {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 72)).fill('').map((v, idx) => now - idx) as Array<number>;
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private message: MatSnackBar,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private push: PushService,
    private changeDetectorRef: ChangeDetectorRef,
    private fuseMediaWatcherService: FuseMediaWatcherService,
    protected dataSendingService: DataSendingService,
    public specialtyService: SpecialtyService,
    public medicalSchools: SchoolService,
    private device: DeviceService,
    private router: Router,
    private singleton: SingletonService,
    protected tokenService: UserTokenService,
    protected helper: HelperService,
    protected countryService: CountryService,
    protected api2Service: Api2Service,
    public dialog: MatDialog,
  ) {
    super();
    this.secondarySpecialtyDropdown();
    this.specialtyDropdown();
    this.schoolDropdown();
    this.isDevice = this.device.isDevice();
    this.subscribe(this.dataSendingService.getData('logout'), (result) => {
      this.logout();
    });
  }


  /*
  |--------------------------------------------------------------------------
  | FORM SETTINGS
  |--------------------------------------------------------------------------
  */
  /**
   * Get form fields
   */
  // tslint:disable-next-line:typedef
  get fields() {
    return this.form.controls;
  }

  @ViewChild('drawer') drawer: MatDrawer;
  public form: FormGroup;
  public post = false;
  public user: any;
  public title = 'Profile';
  public profileDoc: string;
  public profileData: any;
  public newsletterSubscription: boolean;
  public pushSubscription: boolean;
  public honorariaIframe: string;
  public selectedPanel = 'profile';
  public drawerMode: 'over' | 'side' = 'side';
  public drawerOpened = true;
  private unsubscribeAll: Subject<any> = new Subject<any>();
  public isDevice = false;
  /**
   * Schools
   */

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  /**
   * Primary specialties
   */
  public specialties: string[] = [];
  public specialty = '';
  public specialtyCtrl = new FormControl();
  public specialtyFiltered: Observable<string[]>;
  private specialtyNames: [] = [];
  private specialtyNames2 = [];
  private specialtyType = false;
  public specialtyRemovable = true;
  public specialtySelectable = true;
  public specialtyValidation = false;

  @ViewChild('specialtyInput') specialtyInput: ElementRef<HTMLInputElement>;
  /**
   * Secondary specialties
   */
  public secondarySpecialtySelectable = true;
  public secondarySpecialtyRemovable = true;
  public secondarySpecialtyCtrl = new FormControl();
  public secondarySpecialtyFiltered: Observable<string[]>;
  public secondarySpecialties: string[] = [];
  @ViewChild('secondarySpecialtyInput') secondarySpecialtyInput: ElementRef<HTMLInputElement>;
  /**
   * Medical schools
   */
  public schoolCtrl = new FormControl();
  public schoolFiltered: Observable<string[]>;
  public schools = '';
  public schoolsAll: string[] = [];
  public schoolList: any;
  private schoolType = false;
  public schoolRemovable = true;
  public schoolSelectable = true;
  @ViewChild('schoolInput') schoolInput: ElementRef<HTMLInputElement>;
  /**
   * Titles
   */
  public titleCtrl = new FormControl();
  public titles = '';
  public titlesAll: string[] = ['Dr. Med.', 'Dr.', 'Professor', 'Mr', 'Mrs', 'Miss', 'Ms'];
  @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;
  /**
   * Qualification
   */
  public qualificationCtrl = new FormControl();
  public qualification = '';
  /**
   * Hide test user
   */
  public passwordLoader = false;
  private reviewStatus: string;

  @ViewChild('qualificationInput') qualificationInput: ElementRef<HTMLInputElement>;
  /**
   * Password change
   */
  public oldPassword: any;
  public password: any;
  public passwordConfirmation: any;
  /*
  * phone_auth: any;
   */
  // tslint:disable-next-line:variable-name
  public phone: any;
  /**
   * User places
   */
  public userPlaces: string[] = [];
  /**
   * Tab setup
   */
  public panels: any[] = [
    {
      id: 'profile',
      icon: 'heroicons_outline:user-circle',
      title: 'My professional profile',
      description: 'Manage your medical profile'
    },
    {
      id: 'honoraria',
      icon: 'heroicons_outline:credit-card',
      title: 'Honoraria overview',
      description: 'Transaction history for paid engagements'
    },
    {
      id: 'account',
      icon: 'heroicons_outline:user-circle',
      title: 'Account',
      description: 'Login details'
    },
    {
      id: 'notifications',
      icon: 'heroicons_outline:annotation',
      title: 'Notifications',
      description: 'Settings for notifications'
    },
    {
      id: 'events',
      icon: 'heroicons_outline:calendar',
      title: 'Events',
      description: 'Events you are subscribed to'
    },
  ];

  public passwordIsValid = false;

  /* Two  factor*/
  public is2Auth: boolean;
  public code: string;
  public code1: string;
  public code2: string;
  public code3: string;
  public code4: string;
  public code5: string;
  public is2AuthValid: boolean;
  public twofactorSaveLoader = false;
  public twofactorValidateLoader = false;

  protected notificationTimeout: any;
  public showInactiveProfile = false;
    // tslint:disable-next-line:max-line-length
  protected window: WindowProxy & typeof globalThis;
  /**
   * Setup form
   */
  ngOnInit(): void {
    this.tabSet();
    this.schoolSet();
    this.specialtySet();
    this.placeSet();
    this.userSet();
    this.formSet();
    this.responsiveSet();
    this.subscribeToNotificationChanges();
  }

  /**
   * On destroy
   */
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  /*
  |--------------------------------------------------------------------------
  | Secondary Specialty
  |--------------------------------------------------------------------------
  */

  /**
   * Show and filter secondary specialties
   *
   * @protected
   */
  protected secondarySpecialtyDropdown(): void {
    this.secondarySpecialtyFiltered = this.secondarySpecialtyCtrl.valueChanges.pipe(
      startWith(''),
      map((name: string | null) => {
        if (name === '') {
          return this.specialties;
        }
        return name ? this.specialtyFilter(name) : this.specialties.slice();
      }));
  }

  /**
   * Secondary specialties add
   */
  public secondarySpecialtyAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.specialties.includes(value) && this.secondarySpecialties.includes(value)) {
      this.secondarySpecialties.push(value);
    }

    // tslint:disable-next-line:no-non-null-assertion
    event.chipInput!.clear();
    this.secondarySpecialtyCtrl.setValue(null);
  }

  /**
   * Secondary specialties remove
   * @param name
   */
  public secondarySpecialtyRemove(name: string): void {
    const index = this.secondarySpecialties.indexOf(name);

    if (index >= 0) {
      this.secondarySpecialties.splice(index, 1);
    }
  }

  public secondarySpecialtyCheck(event: FocusEvent): void {
    setTimeout(() => {
      this.secondarySpecialtyInput.nativeElement.value = '';
      this.secondarySpecialtyInput.nativeElement.blur();
      this.secondarySpecialtyCtrl.setValue('');
    }, 150);
  }

  /**
   * Secondary specialty select
   *
   * @param event
   */
  public secondarySpecialtySelect(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value && this.specialties.includes(event.option.value) && !this.secondarySpecialties.includes(event.option.value)) {
      this.secondarySpecialties.push(event.option.value);
      this.secondarySpecialtyInput.nativeElement.value = '';
      this.secondarySpecialtyCtrl.setValue(null);
    } else {
      this.message.open('Sorry, this specialty already added.', '', {
        duration: 2000,
        panelClass: ['warning']
      });
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Primary Specialty
  |--------------------------------------------------------------------------
  */
  protected specialtySet(): void {
    this.subscribe(this.api2Service.specialties(), (result) => {
      for (const spec of result.data.specialties) {
        this.specialties.push(spec.key);
        const key = spec.key;
        const value = spec.title;
        // @ts-ignore
        this.specialtyNames[key] = value;
        this.specialtyNames2.push(value.replace(' ', '_').toLowerCase());
      }

    });
  }

  /**
   * Show and filter dropdown
   *
   * @protected
   */
  protected specialtyDropdown(): void {
    this.specialtyFiltered = this.specialtyCtrl.valueChanges.pipe(startWith(''), map((name: string | null) => {
      if (name === '') {
        return this.specialties;
      }
      return name ? this.specialtyFilter(name) : this.specialties.slice();
    }));
  }

  /**
   * Primary specialty
   *
   * @param event
   */
  public specialtyAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.specialties.includes(value)) {
      this.specialty = value;
    }

    this.specialtyCtrl.setValue(this.specialty);
  }

  public specialtySelect(event: MatAutocompleteSelectedEvent): void {
    this.specialty = event.option.value;
    this.specialtyCtrl.setValue(this.specialty);
  }

  private specialtyFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.specialties.filter(name => name.toLowerCase().includes(filterValue));
  }

  public specialtyName(value: string): string {
    const filterValue = value.toLowerCase();
    return this.specialtyNames[value] ?? filterValue;
  }

  public specialtyCheck(event: FocusEvent): void {
    setTimeout(() => {
      this.specialtyInput.nativeElement.value = '';
      this.specialtyInput.nativeElement.blur();
      this.specialtyCtrl.setValue('');
    }, 150);
  }

  /**
   * Secondary specialties remove
   * @param name
   */
  public specialtyRemove(): void {
    this.specialty = '';
  }

  /*
  |--------------------------------------------------------------------------
  | Medical Schools
  |--------------------------------------------------------------------------
  */
  protected schoolSet(): void {
    this.subscribe(this.api2Service.schools(), (result) => {
      // @ts-ignore
      for (const school of result.data.schools) {
        this.schoolsAll.push(school.title);
      }
    });
  }

  /**
   * Shoe and filter school dropdown
   *
   * @protected
   */
  protected schoolDropdown(): void {
    this.schoolFiltered = this.schoolCtrl.valueChanges.pipe(startWith(''), map((name: string | null) => {
      if (name === '') {
        return this.schoolsAll;
      }
      return name ? this.schoolFilter(name) : this.schoolsAll.slice();
    }));
  }

  /**
   * Add school to user
   *
   * @param event
   */
  public schoolAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.schoolsAll.includes(value)) {
      this.schools = value;
    }

    this.schoolCtrl.setValue(this.schools);
  }

  /**
   * Select school from dropdown
   *
   * @param event
   */
  public schoolSelect(event: MatAutocompleteSelectedEvent): void {
    this.schools = event.option.viewValue;
    this.schoolCtrl.setValue(this.schools);
  }

  /**
   * Filter string in dropdown search
   *
   * @param value
   * @private
   */
  private schoolFilter(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.schoolsAll.filter(name => name.toLowerCase().includes(filterValue));
    }
  }

  public schoolCheck(event: FocusEvent): void {
    setTimeout(() => {
      this.schoolInput.nativeElement.value = '';
      this.schoolInput.nativeElement.blur();
      this.schoolCtrl.setValue('');
    }, 150);
  }

  /**
   * Secondary specialties remove
   * @param name
   */
  public schoolRemove(): void {
    this.schools = '';
  }

  /**
   * Titles
   *
   * @param event
   */
  public titleAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.titles = value;
    }

    this.titleCtrl.setValue(this.titles);
  }

  public titleSelect(event: MatAutocompleteSelectedEvent): void {
    this.titles = event.option.viewValue;
    this.titleCtrl.setValue(this.titles);
  }

  public titleCheck($event: FocusEvent): void {
    setTimeout(() => {
      this.titleInput.nativeElement.blur();
    }, 150);
  }

  /**
   * Qualification
   *
   * @param event
   */
  public qualificationAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.qualificationAll.includes(Number(value))) {
      this.qualification = value;
      this.qualificationCtrl.setValue(this.qualification);
    } else {
      this.qualification = '';
    }
  }

  public qualificationSelect(event: MatAutocompleteSelectedEvent): void {
    this.qualification = event.option.viewValue;
    this.qualificationCtrl.setValue(this.qualification);
  }

  public qualificationCheck(event: FocusEvent): void {
    const value = this.qualificationInput.nativeElement.value;
    if (!this.qualificationAll.includes(Number(value))) {
      this.qualificationInput.nativeElement.value = '';
    }
  }

  protected formSet(): void {
    this.form = this.formBuilder.group({
      firstname: ['', Validators.required],
      middle_name: [''],
      lastname: ['', Validators.required],
      registration_number: [''],
      specialty: [''],
      qualification: [''],
      email_secondary: ['', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      phone: [''],
    });
  }

  /*
  |--------------------------------------------------------------------------
  | USER
  |--------------------------------------------------------------------------
  */
  protected async userSet(): Promise<void> {
    const result = await this.api2Service.me();
    if (result.status === 200 && this.api2Service.isAuthenticated()) {
      this.api2Service.storeUser(JSON.stringify(result.data.user));
      this.user = result.data.user;
      this.showProfileReview = this.user.is_review;
      this.userSetData(result.data.user);
    }
  }

  ///////////////////////////////

  /**
   * Set user profile data
   *
   * @param res
   * @protected
   */
  protected userSetData(res: any): void {
    this.profileData = res;
    if (this.profileData.is_published !== undefined && this.profileData.is_published === 0) {
      this.showInactiveProfile = true;
    }

    if (!this.profileData.notifications) {
      this.profileData.notifications = {
        microlearning: {
          email: true,
          push: true
        },
        earn_and_learn: {
          email: true,
          push: true
        },
      };
    }

    this.newsletterSubscription = this.profileData.newsletter_subscription || false;
    this.is2Auth = this.profileData.is2Auth || false;
    this.phone = this.profileData.phone ?? '';
    this.pushSubscription = this.profileData.push_subscription || false;
    this.form.patchValue(this.profileData);
    this.secondarySpecialties = this.profileData.additional_specialty ? this.profileData.additional_specialty : [];
    this.userPlaces = typeof this.profileData.pwork !== 'undefined' && this.profileData.pwork !== null ? Object.values(this.profileData.pwork) : [];
    this.workplaces = [];
    if (this.userPlaces.length > 0) {
      this.userPlaces.forEach(place => {
        this.workplaces.push({
          name: place
        });
      });
    }
    this.titles = this.profileData.title ?? '';
    this.specialty = this.profileData.main_specialty ?? '';
    this.schools = (this.profileData.schools.length > 0 ? this.profileData.schools : '');
    this.qualification = this.profileData.qualification ?? '';
    this.dataSendingService.sendData('profile', this.profileData);
  }

  /**
   * Update user profile
   */
  public userUpdate(): void {
    this.post = true;
    this.specialtyValidation = false;
    if (this.form.invalid) {
      return;
    }

    if (this.specialty === '' || this.specialty === null) {
      this.specialtyValidation = true;
      return;
    }

    const review = {
      firstname: this.fields.firstname.value,
      middle_name: this.fields.middle_name.value,
      lastname: this.fields.lastname.value,
      title: this.titles,
      registration_number: this.fields.registration_number.value,
      specialty: this.specialty,
      additional_specialty: this.secondarySpecialties,
      schools: this.schools,
      qualification: this.qualification,
      pwork: this.userPlaces,
      workplace: this.workplaces,
      email_secondary: this.fields.email_secondary.value,
      created_at: Date.now(),
      status: 'review',
      country: this.country,
    };
    console.log(review);
    this.api2Service.review(review).then(t => {
      this.api2Service.refreshUser().then(r => {
        this.analytics.addEvent('profile-review', {
          email: this.user.email,
        });
        this.message.open('Profile updated', '', {
          duration: 2000,
          panelClass: ['success']
        });
      });
    });
  }

  /**
   * Update password
   */
  public userPasswordUpdate(): void {
    this.passwordLoader = true;
    if (this.password !== this.passwordConfirmation) {
      this.analytics.addEvent('account_password_mismatch');
      this.message.open('Sorry, your passwords does not match.', '', {
        duration: 2000,
        panelClass: ['warning']
      });
      this.passwordLoader = false;
    } else {
      this.api2Service.updatePassword(this.oldPassword, this.password, this.passwordConfirmation).then(r => {

        if (r.success !== 200) {
          this.analytics.addEvent('account_password_mismatch');
          this.message.open('Sorry, your passwords does not match.', '', {
            duration: 2000,
            panelClass: ['warning']
          });

        } else {
          this.analytics.addEvent('account_password_changed');
          this.message.open('Your password changed.', '', {
            duration: 2000,
            panelClass: ['success']
          });
        }
        this.passwordLoader = false;
      });
    }
  }

  /*
  |--------------------------------------------------------------------------
  | TABS
  |--------------------------------------------------------------------------
  */

  /**
   * Set first tab
   *
   * @protected
   */
  protected tabSet(): void {
    this.subscribe(this.route.paramMap, (params) => {
      this.tabToggle(params.get('tab'));
    });
  }

  /**
   * Toggle tabs
   *
   * @param tab
   */
  public tabToggle(tab: string): void {
    this.selectedPanel = tab;
    this.userSet();
    switch (tab) {
      case 'profile':
        this.analytics.addEvent('profile');
        break;
      case 'account':
        this.analytics.addEvent('account');
        break;
      case 'notifications':
        this.analytics.addEvent('notifications');
        break;
      case 'events':
        break;
      default:
        this.analytics.addEvent('profile');
        this.selectedPanel = 'profile';
        break;
    }
  }

  /**
   * Get tab info
   *
   * @param id
   */
  public tabInfo(id: string): any {
    return this.panels.find(panel => panel.id === id);
  }

  /**
   * Auto saving notification changes
   */

  protected subscribeToNotificationChanges(): void {
    this.subscribe(this.dataSendingService.getData('notificationChanged'), () => {
        this.api2Service.updateNotifications(this.profileData.notifications).then((result) => {
            if (result.success) {
              this.analytics.addEvent('notification_updated');
              this.message.open('Profile updated', '', {
                duration: 2000,
                panelClass: ['success']
              });
            } else {
              this.analytics.addEvent('notification_failed');
              console.log(result.error);
            }
          });
    });
  }

  /*
  |--------------------------------------------------------------------------
  | PLACE
  |--------------------------------------------------------------------------
  */
  /**
   * Watch google map value select
   *
   * @protected
   */
  protected placeSet(): void {
    this.subscribe(this.dataSendingService.getData('selectedAddress'), (result) => {

      const workplaceExists = this.workplaces.some(workplace => workplace.name === result.name);

      if (!workplaceExists) {
        this.country = result.country;
        this.placeAdd(result.name);
        this.workplaces.push({
          name: result.name,
          address: result,
        });
      }
    });
  }

  /**
   * Add place to user
   *
   * @param name
   */
  public placeAdd(name): void {
    if (!this.userPlaces.includes(name)) {
      this.userPlaces.push(name);
    } else {
      this.message.open('Sorry, this place already added.', '', {
        duration: 2000,
        panelClass: ['warning']
      });
    }
  }

  /**
   * Remove place from user
   *
   * @param index
   * @param name
   */
  public placeRemove(index: number, name: string): void {
    if (index >= 0) {
      this.userPlaces.splice(index, 1);
      this.workplaces = this.workplaces.filter(workplace => workplace.name !== name);
    }
  }


  /**
   * Update profile notifications
   */

  /*public async updateTwoFactor(e): Promise<any> {
    let data = {};
    let msg = '';

    if (this.is2Auth === false) {
      if (this.phone === '') {
        this.message.open('Enter your phone number', '', {
          duration: 2000,
          panelClass: ['error']
        });
        this.is2Auth = false;
        e.source.checked = false;
        return;
      }
      else {
        this.is2Auth = true;
        msg = 'Two-auth enabled';
        e.source.checked = true;
      }
    } else {
      this.is2Auth = false;
      msg = 'Two-auth disabled';
      e.source.checked = false;
    }

    data = {
      phone: this.phone,
      is2Auth: this.is2Auth,
    };

    this.profile.update(this.profileDoc, data).then(r => {
      this.message.open(msg, '', {
        duration: 2000,
        panelClass: ['success']
      });
    });
  }*/


  public async updateSubscription(e, type: string): Promise<any> {

    let data = {};
    let msg = '';

    if (type === 'email') {
      if (this.newsletterSubscription === false) {
        this.newsletterSubscription = true;
        msg = 'Email notification enabled';
      } else {
        this.newsletterSubscription = false;
        msg = 'Email notification disabled';
      }

      data = {
        newsletter_subscription: this.newsletterSubscription,
      };
    }

    if (type === 'push') {
      if (this.pushSubscription === false) {
        const pushStatus = await this.push.init().then(r => r);
        if (pushStatus === true) {
          this.pushSubscription = true;
          msg = 'Push notification enabled';
        } else {
          this.pushSubscription = false;
          this.message.open('Sorry, Something went wrong. Check your browser settings, maybe you use browser in private mode', '', {
            duration: 2000,
            panelClass: ['warning']
          });
          e.source.checked = false;
          return false;
        }
      } else {
        this.pushSubscription = false;
        msg = 'Push notification disabled';
      }

      data = {
        push_subscription: this.pushSubscription,
      };
    }

    this.api2Service.updateDeviceToken(data).then(r => {
      this.message.open(msg, '', {
        duration: 2000,
        panelClass: ['success']
      });
    });
  }

  /**
   * Logout user and redirect to login page
   */
  public logout(): void {
    this.analytics.addEvent('logout');
    this.singleton.unsubscribe();
    this.api2Service.logout().then(r => {

      this.helper.reload();

    });
  }

  /**
   * Follow responsive ui changes
   *
   * @private
   */
  private responsiveSet(): void {
    // Subscribe to media changes
    this.fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(({ matchingAliases }) => {

        // Set the drawerMode and drawerOpened
        if (matchingAliases.includes('lg')) {
          this.drawerMode = 'side';
          this.drawerOpened = true;
        } else {
          this.drawerMode = 'over';
          this.drawerOpened = false;
        }

        // Mark for check
        this.changeDetectorRef.markForCheck();
      });
  }


  public passwordValid(event) {
    this.passwordIsValid = event;
  }

  /**
   * Toggle two factor
   * @param $event
   */
  public async twofactorToggle(e): Promise<any> {
    if (this.is2Auth === false) {
      this.is2Auth = true;
      e.source.checked = true;
    } else {
      this.is2Auth = false;
      e.source.checked = false;
      const profileData = {
        phone: this.phone,
        is2Auth: this.is2Auth,
      };
     /* this.profile.update(this.profileDoc, profileData).then(r => {
        this.message.open('Two-auth disabled', '', {
          duration: 2000,
          panelClass: ['success']
        });
      });*/
    }
  }

  /*public twofactorValidate(): void {
    this.twofactorValidateLoader = !this.twofactorValidateLoader;

    if (this.phone === '') {
      this.analytics.addEvent('account_2auth_empty_phonenumber');
      this.message.open('Enter your phone number', '', {
        duration: 2000,
        panelClass: ['error']
      });
      this.twofactorValidateLoader = !this.twofactorValidateLoader;
      return;
    }

    this.tokenService.deleteTokens(this.user.email, '2authValidate').then(r => {
      const codeData = {
        email: this.user.email,
        token: this.helper.generateCode(),
        expired_at: this.helper.getExpirationIn(3, 'minute'),
        type: '2authValidate'
      };

      this.tokenService.create(codeData).then(rc => {
        const smsConfig = {
          to: this.phone,
          body: this.smsTemplate.replace('{{code}}', codeData.token)
        };
        return this.smsService.send(smsConfig).then(() => {
          this.is2AuthValid = true;
          this.twofactorValidateLoader = !this.twofactorValidateLoader;
          this.analytics.addEvent('account_2auth_code_sent');
          this.message.open('Verify code sent. Check your phone.', '', {
            duration: 2000,
            panelClass: ['success']
          });
        });
      });
    });
  }*/

  /**
   * Two Factor Save
   */
  public twofactorSave(): void {
    this.twofactorSaveLoader = !this.twofactorSaveLoader;
    const code = this.twofactorCode();
    if (code.length < 6) {
      this.twofactorSaveLoader = !this.twofactorSaveLoader;
      this.message.open('Your verify code too short', '', {
        duration: 2000,
        panelClass: ['error']
      });
      this.analytics.addEvent('account_2auth_code_too_short');
    }

    this.subscribe(this.tokenService.checkCode(code, '2authValidate'), (data) => {
      if (typeof data !== undefined && data) {
        if (data[0].data.expired < this.helper.getUnixTime()) {
          this.twofactorSaveLoader = !this.twofactorSaveLoader;

          this.message.open('Your verify code expired', '', {
            duration: 2000,
            panelClass: ['error']
          });
          this.analytics.addEvent('account_2auth_code_expired');
        } else if (data[0].data.token !== code) {
          this.twofactorSaveLoader = !this.twofactorSaveLoader;
          this.message.open('Your verify code doesn\'t match.', '', {
            duration: 2000,
            panelClass: ['error']
          });
          this.analytics.addEvent('account_2auth_code_mismatch');
        } else {
          const profileData = {
            phone: this.phone,
            is2Auth: this.is2Auth,
          };
         /* this.profile.update(this.profileDoc, profileData).then(r => {
            this.message.open('Two-auth enabled', '', {
              duration: 2000,
              panelClass: ['success']
            });
            this.analytics.addEvent('account_2auth_enabled');
            this.twofactorSaveLoader = !this.twofactorSaveLoader;
          });*/
        }
      } else {
        this.message.open('Your verify code doesn\'t match.', '', {
          duration: 2000,
          panelClass: ['error']
        });
        this.analytics.addEvent('account_2auth_code_mismatch');
        this.twofactorSaveLoader = !this.twofactorSaveLoader;
      }
    }, (e) => {
      this.message.open('Sorry, we could not log you in. Please contact us.', '', {
        duration: 2000,
        panelClass: ['error']
      });
      this.analytics.addEvent('account_2auth_error');
      this.twofactorSaveLoader = !this.twofactorSaveLoader;
    });
  }

  /**
   * Two factor code
   *
   */
  private twofactorCode(): string {
    // tslint:disable-next-line:radix
    const code0 = parseInt(this.code);
    // tslint:disable-next-line:radix
    const code1 = parseInt(this.code1);
    // tslint:disable-next-line:radix
    const code2 = parseInt(this.code2);
    // tslint:disable-next-line:radix
    const code3 = parseInt(this.code3);
    // tslint:disable-next-line:radix
    const code4 = parseInt(this.code4);
    // tslint:disable-next-line:radix
    const code5 = parseInt(this.code5);

    return code0.toString() + code1.toString() + code2.toString() + code3.toString() + code4.toString() + code5.toString();
  }

  public userDelete(): void {
    this.dialog.open(ProfileDeleteComponent, {
      width: '600px',
      height: '600px',
      data: {
        icon: 'nuaxia:profile',
        title: 'Delete profile',
        description: `<p class="mb-2">This action will irreversibly delete your account all associated profile data you have submitted.</p>
        <p class="mb-2">Before proceeding, ensure that you have no outstanding balance with us<br /> (details available under <a class="nuaxia-button-text nuaxia-lato" href="/honoraria/overview">'Honoraria'</a>).</p>
        <p>As an alternative to deletion, you may choose to unsubscribe from notifications<br /> (see options in <a class="nuaxia-button-text nuaxia-lato" href="/profile/notifications">'Notifications'</a>)</p>`,
        email: this.user.email,
      },
    });

  }
}
