import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseComponent} from '../base.component';
import {AuthService} from '@services/auth.service';
import {PushService} from '@services/push.service';
import {AnalyticsService} from '@services/analytics.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IntroService} from '@services/intro.service';
import {DeviceService} from '@services/device.service';
import {DataSendingService} from '@services/data-sending.service';
import {Api2Service} from '@services/api2.service';
import {MatDialog} from '@angular/material/dialog';
import {SignupOrLoginModalComponent} from '../modals/signup-or-login-modal/signup-or-login-modal.component';
import {ProfileUpdateModalComponent} from '../modals/profile-update-modal/profile-update-modal.component';
import {PendingApprovalModalComponent} from '../modals/pending-approval-modal/pending-approval-modal.component';

@Component({
  selector: 'app-learns',
  templateUrl: './learns.component.html',
  styleUrls: ['./learns.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LearnsComponent extends BaseComponent implements OnInit {

  public title = 'Learning';
  public activities: any;
  public tabs = [];
  public back = 'activities';
  public userProfile: any;
  public seenActivites = [];
  public checkItem = false;
  public pager = 1;

  public surveyCategories = [
    'Microlearning',
    'Earn and Learn',
  ];

  public groupedActivities = {
    new: {
      microlearning: [],
      earnandlearn: [],
    }
  };
  public page = 'microlearning';
  public isDevice = false;
  public loading = true;

  public counter = [];

  @HostListener('window:scroll', ['$event'])
  public onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      this.pager++;
      this.getActivities();
    }
  }

  constructor(private auth: AuthService,
              private push: PushService,
              private analytics: AnalyticsService,
              protected dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private introService: IntroService,
              private device: DeviceService,
              private dataSendingService: DataSendingService,
              private api2Service: Api2Service
  ) {
    super();
    
    this.route.data.subscribe(data => {
      console.log('closemodals');
      this.dataSendingService.sendData('closeModals', true);
    });
  
    this.checkUserProfile();
    this.userProfile = this.auth.currentUser();
    this.getActivities();
  }

  async ngOnInit(): Promise<any> {
    this.tabSet();
    this.sendUserPushId();
    this.isDevice = this.device.isDevice();
    this.introService.start();
    this.push.saveDeviceToken();
    this.analytics.addEvent('portal_activities');
    this.subscribeToActivitySeen();

    setTimeout(() => {
      this.checkItem = true;
    }, 1000);
  }


  /**
   * Set first tab
   *
   * @protected
   */
  protected tabSet(): void {
    this.route.paramMap.subscribe(params => {
      this.tabToggle(params.get('tab'));
    });
  }

  public tabToggle(tab: string): void {
    if (tab !== '') {
      this.page = tab;
    }
  }

  refresh(): void {
    window.location.reload();
  }

  private subscribeToActivitySeen(): void {
    this.subscribe(this.dataSendingService.getData('activitySeen'), (eventData) => {
      if (this.seenActivites.includes(eventData.activity) || eventData.project === undefined || eventData.project === '') {
        return;
      } else {
        this.analytics.addEvent('activity_card_seen', eventData);
      }
    });
  }

  public getActivities(): void {
    this.loading = true;
    console.log('page', this.pager);
    this.subscribe(this.api2Service.activitiesByCategory(['Microlearning'], this.pager), (res) => {
      if (res.success === true) {
        this.groupedActivities.new.microlearning = this.groupedActivities.new.microlearning.concat(res.data.activities);
        this.loading = false;
      } else {
        // smg message
        this.loading = false;
      }
    });

    this.subscribe(this.api2Service.activitiesByCategory(['Earn and Learn'], this.pager), (res) => {
      if (res.success === true) {
        this.groupedActivities.new.earnandlearn = this.groupedActivities.new.earnandlearn.concat(res.data.activities);
        this.loading = false;
      } else {
        // smg message
        this.loading = false;
      }
    });
  }

  private async checkUserProfile(): Promise<any> {
    const profile = this.auth.currentUser();

    if ((profile.main_specialty === null || profile.main_specialty === undefined || profile.main_specialty === '') && profile.is_published === 0 && profile.is_review === 0) {
      this.dialog.open(ProfileUpdateModalComponent,{
          disableClose: true,
        });
    }

  }

  /**
   * Track tab change
   *
   * @param value
   */
  trackTabChange(value: any): void {
    switch (value.index) {
      case 0:
        this.analytics.addEvent('portal_activities');
        break;
      case 1:
        this.analytics.addEvent('portal_activities_comm');
        break;
      case 2:
        this.analytics.addEvent('portal_activities_library');
        break;
      default:
        this.analytics.addEvent('portal_activities');
        break;
    }
  }


  private sendUserPushId(): void {
    const uuid = localStorage.getItem('uuid') || false;
    const sent = localStorage.getItem('uuid_sent') || false;

    if ((uuid && !sent) || (uuid !== false && sent !== false && uuid !== sent)) {
      const pushData = {
        push_id: uuid,
        enabled: true,
        mobile: 1
      };
      localStorage.setItem('uuid_sent', uuid);
      this.api2Service.updateDeviceToken(pushData);
    }
  }

  public handleClick(event, activity): void {
    // hack to prevent the survey card to navigating away
    event.stopPropagation();
    if (!this.auth.isLoggedIn()) {
      this.dialog.open(SignupOrLoginModalComponent);
      return;
    }

    if (this.userProfile.is_published === 0) {
      this.dialog.open(PendingApprovalModalComponent, { data: {
          hideThankYou: true
        }});
      return;
    }

    if (!this.auth.hasToken()) {
      this.dialog.open(SignupOrLoginModalComponent);
      return;
    }

    this.router.navigateByUrl('survey/' + activity.id);
  }

}
