<div>
  <div class="close">
    <span (click)="onClose()">x</span>
  </div>
  <div class="dialog-container">
    <div class="img-graphic">
      <img alt="faculty" src="https://touchoncology.com/wp-content/themes/tmm/images/graphics/faculty.svg">
    </div>
    <div class="data-selection">
      <h5 class="popup-title">Faculty & Disclosures</h5>
      <div *ngIf="!selectedFaculty">
        <div class="faculty-card" *ngFor="let faculty of data.faculty">
          <div class="c-card__details" (click)="selectFaculty(faculty)">
            <div class="avatar-bio-wrap">
              <div class="avatar c-card__col">
                <img [src]="faculty.image" [alt]="faculty.name"/>
              </div>
              <div class="c-card__col">
                <h6 class="faculty-name">{{faculty.name}}</h6>
                <p class="c-card__sub_title">{{faculty.from}}</p>
              </div>
            </div>
            <i class="fa-solid fa-arrow-right-long"></i>
          </div>
        </div>
      </div>

      <div *ngIf="selectedFaculty">
        <div class="avatar-bio-wrap">
          <div class="avatar c-card__col">
            <img [src]="selectedFaculty.image" [alt]="selectedFaculty.name"/>
          </div>
          <div class="c-card__col">
            <h6 class="faculty-name">{{selectedFaculty.name}}</h6>
            <p class="c-card__sub_title">{{selectedFaculty.from}}</p>
          </div>
        </div>

        <div class="bio-discloses">
          <app-readmore [text]="selectedFaculty.bio"></app-readmore>
          <div class="disclosure" *ngIf="selectedFaculty.discloses">
            <strong>{{selectedFaculty.name}} discloses:</strong> {{selectedFaculty.discloses}}
          </div>
        </div>
        <div class="back-to-faculty" (click)="selectFaculty(null)">
          <i class="fa-solid fa-arrow-left-long"></i>
          Back to faculty
        </div>
      </div>

    </div>
  </div>
</div>

