import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../base.component';
import {AuthService} from '@services/auth.service';
import {environmentProd} from '@environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {Api2Service} from '@services/api2.service';
import {AnalyticsService} from '@services/analytics.service';


@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent extends BaseComponent implements OnInit {
  public form: FormGroup;
  public logo = '';
  public post = false;
  public formError = undefined;
  public pageTitle = 'Create a new password';
  public site: string = environmentProd.site;
  public description: string = environmentProd.description;
  public password: any;
  public passwordIsValid = false;
  public hidePassword = true;
  private uid: string;
  public error: string;
  public loader: boolean;
  public success: string;

  constructor(protected formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private auth: AuthService,
              private api2Service: Api2Service,
              private analytics: AnalyticsService,
  ) {
    super();
  }

  /**
   * Get form fields
   */
  // tslint:disable-next-line:typedef
  get fields() {
    return this.form.controls;
  }

  /**
   * @function createForm
   * @summary Creating the form
   * @private
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      password: ['']
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  /**
   * Check password valid
   *
   * @param event
   */
  public passwordValid(event): void {
    this.passwordIsValid = event;
  }

  public async sendPasswordResetEmail(): Promise<any> {
    this.post = true;
    this.error = undefined;
    this.loader = true;

    if (this.form.invalid) {
      this.loader = false;
      return;
    }

    const email = this.auth.currentUser().email;
    const password = this.password;

    try {
      this.analytics.addEvent('password_try', {
        email,
      });

      const result = await this.api2Service.passwordUpdate(email, password);

      if (result.status === 200) {
        this.analytics.addEvent('password_success', {
          email,
        });

        this.success = 'You successfully updated your password';
        this.loader = false;
      } else {

        this.analytics.addEvent('password_failed', {
          email,
        });
      }
    } catch (e) {
      if (e.message) {
        this.error = e.message;
      } else {
        this.error = 'Sorry, we could not log you in. Please contact us.';
      }

      this.analytics.addEvent('password_failed', {
        email,
      });
      this.loader = false;
    }
  }
}
