<div class="w-full">
  <div class="flex justify-end">
    <span class="text-neutral-300 cursor-pointer font-medium text-xl" (click)="close()">x</span>
  </div>
  <div class="text-lg text-neutral-500">
    <p>Please check your email, including spam folder.</p>
    <p class="mt-4 mb-6">We sent a magic link to<br/><strong>{{data.email}}</strong></p>
    <!--<div class="button mt-2" *ngIf="this.deviceService.isDevice()">
      <button data-testId="open-email-button" mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="openEmail()">
        {{openButton}}
      </button>
    </div>-->
  </div>
  <p class="u-pb-2">
    <mat-error *ngIf="error" data-testId="login-error-message">{{error}}</mat-error>
  </p>
  <div class="mt-24 flex flex-col justify-center" *ngIf="!data.redirectToLogin">
    <div class="button mt-2">
      <button data-testId="login-button" [disabled]="resendDisabled"  mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="resendEmail()">
        <mat-icon *ngIf="loader" data-testId="loading-spinner"><mat-spinner diameter="20">
        </mat-spinner></mat-icon>
         {{resendButton}}
      </button>
    </div>
  </div>

  <div class="mt-24 flex flex-col justify-center" *ngIf="data.redirectToLogin">
    <div class="button mt-2">
      <button data-testId="login-button" mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="backToLogin()">
        Back to Activities
      </button>
    </div>
  </div>
</div>
