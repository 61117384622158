import {Component, HostListener, OnDestroy} from '@angular/core';
import {Subscription, Observable} from 'rxjs';

/**
 * Parent class for components, providing common functionality.
 * Not a real component itself,
 * but the naming helps to understand the purpose.
 */
@Component({
  selector: 'app-base-component',
  template: '<div></div>'
})
export class BaseComponent implements OnDestroy {

  private subscriptions: Subscription[] = [];
  public isMobile = false;

  @HostListener('window:resize', [])
  onResize(): void {
    this.isMobileDevice();
  }

  ///////////////////////////////////

  ngOnDestroy(): void {
    this.cancelSubscriptions();
  }

  /**
   * Load image
   *
   * @param url
   */
  public image(url: string): string {
    if (url === '' || url === undefined) {
      return 'assets/images/no_image.png';
    }
    return url;
  }

  ///////////////////////////////////

  /**
   * @function subscribe
   * @summary Creating a new subscription for the component
   * @param obs - the target, we want to subscribe to
   * @param next - callback for success
   * @param error - callback for error
   * @param complete - callback for complete
   * @protected
   */
  protected subscribe<T>(obs: Observable<T>, next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    const subscription = obs.subscribe(next, error, complete);
    this.subscriptions.push(subscription);
    return subscription;
  }

  /**
   * @function cancelSubscriptions
   * @summary Cancelling all subscriptions on destroy. This prevents memory leaks
   * @protected
   */
  protected cancelSubscriptions(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * Converting firebase documents to a simple array of docs
   *
   * @param fireBaseDocs
   * @protected
   */
  protected getDocuments(fireBaseDocs: any): any[] {
    const result = [];

    fireBaseDocs.docs.forEach((doc) => {
      result.push(doc.data());
    });

    return result;
  }

  /**
   * Scroll to element
   *
   * @param el
   * @private
   */
  public scrollTo(el: string): void {
    const scroll = document.getElementById(el);
    if (scroll !== undefined && scroll !== null) {
      scroll.scrollIntoView();
    }
  }

  /**
   * Check if mobile device
   */
  public isMobileDevice(): void {
    this.isMobile = window.innerWidth <= 1025;
  }
}
