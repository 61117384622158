import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {SingletonService} from './singleton.service';

@Injectable()
export class AuthService {

  public user: any;

  /////////////////////////////////

  constructor(@Inject(PLATFORM_ID) private platformId,
              protected afAuth: AngularFireAuth,
              protected router: Router,
              private singleton: SingletonService) {
  }

  /**
   * Set user profile
   * @param data
   */
  public setProfile(data: any): void {
    if (isPlatformBrowser(this.platformId) && data) {
      const profileId = data[0].id;
      const profileData = data[0].data;
      const profile = JSON.stringify({...profileData, _id: profileId});
      localStorage.setItem('user', profile);
    } else {
      console.log('no profile data to set');
    }
  }

  /**
   * Logging out
   */
  public async logout(): Promise<boolean> {
    this.singleton.unsubscribe();

    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('user');
      localStorage.removeItem('tuser');
    }

    return true;
  }

  /**
   * Function to check, if the user is logged in
   */
  public isLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem('user')) ?? false;
    }
    return false;
  }
  /**
   * Function to token check, if the user is logged in
   */
  public hasToken(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('tuser');
      if ( token !== undefined && token !== null && token !== '') {
          return true;
      }
      return false;
    }
    return false;
  }
  /**
   * Get logged user profile
   */
  // tslint:disable-next-line:typedef
  public currentUser() {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem('user')) ?? [];
    }
    return [];
  }
}
