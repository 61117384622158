<body class="cme-education-template-default single single-cme-education postid-33707 has-sidebar activity-template" (scroll)="onWindowScroll($event);">

<header class="c-header c-header__activity c-activity-container js-site-header" style="box-sizing: border-box;" *ngIf="templateColor" [ngClass]="getTouchMdtHeader()">
  <div class="o-wrapper c-header__wrapper"  [ngClass]="{'device': isDevice}"  style="width: 100%;max-width: 100%">
    <app-menu style="width: 100%"></app-menu>
  </div>
  <!-- /.o-wrapper -->
  <button class="c-header__title js-sidebar-toggle"  *ngIf="education.content_type !== 'touchMDT'">
    <div class="c-header__title-wrap">
      <svg role="presentation"
           class="o-icon c-header__title-icon c-header__title-icon--menu js-sidebar-toggle-menu"  [ngClass]="{'is-active' : !mobileMenu}" (click)="toggleMenu()"  *ngIf="!mobileMenu">
        <use xlink:href="#icon-menu"/>
      </svg>
      <svg role="presentation" class="o-icon c-header__title-icon c-header__title-icon--cross js-sidebar-toggle-cross"  [ngClass]="{'is-active' : mobileMenu}" (click)="toggleMenu()" *ngIf="mobileMenu">
        <use xlink:href="#icon-cross"/>
      </svg>
    </div>
    <!-- /.c-header__title-wrap -->
    <span class="c-header__text"><span class="c-header__text-contents">Contents</span><span class="c-header__text-menu">Menu</span></span>
  </button>
  <!-- /.c-header__title -->
</header>

<!-- /.c-header -->
<div  class="c-activity-container" *ngIf="education" [ngClass]="{'is-active' : education.content_type === 'touchMDT'}">
  <div class="desktop-view"  *ngIf="education.content_type === 'touchMDT'">
    <div id="stickybar" class="c-sticky-bar js-sticky-bar">
      <div class="o-wrapper c-sticky-bar__wrapper">
        <ul class="c-sticky-bar__list">
          <li class="c-sticky-bar__item">
            <a  (click)="showMenu(0,'c-intro-hub')" class="c-sticky-bar__link js-patient-hub-link js-sticky-link">
              <span>The MDT Hub</span>
            </a>
          </li>
          <li class="c-sticky-bar__item">
            <a  (click)="showMenu(0,'js-activity-content-tabs')"  class="c-sticky-bar__link js-sidebar-tab-link js-sticky-link">
              <span>Overview <span class="c-sticky-bar__text--limit">&amp; Learning Objectives</span></span>
            </a>
          </li>
          <li class="c-sticky-bar__item" *ngIf="education.is_faculty">
            <a (click)="showMenu(1,'js-activity-content-tabs')" class="c-sticky-bar__link js-sidebar-tab-link js-sticky-link">
              <span class="c-sticky-bar__text">Faculty &amp; Disclosures</span>
            </a>
          </li>
          <li class="c-sticky-bar__item" *ngIf="education.is_download">
            <a (click)="showMenu(2,'js-activity-content-tabs')" class="c-sticky-bar__link js-sidebar-tab-link js-sticky-link">
              <span class="c-sticky-bar__text">Downloads</span>
            </a>
          </li>
        </ul>
      </div><!-- /.o-wrapper -->
    </div><!-- /.c-sticky-bar -->
  </div>
  <aside class="c-activity-sidebar js-sidebar" [ngClass]="{'is-open ' : mobileMenu}" [ngClass]="templateColor"  *ngIf="education.content_type !== 'touchMDT'">>
    <nav class="c-activity-sidebar__internal" [ngStyle]="{'height':education.survey !== '' ? 'calc(100% - 62px - 140px)':'calc(100% - 0px - 140px)'}">
      <ul class="c-activity-sidebar__list js-accordion">
        <li class="c-activity-sidebar__item">
          <a (click)="showMenu(0,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link">
            Overview & Learning Objectives
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                    <use xlink:href="#icon-arrow-right-grey"/>
                 </svg>
                 </span>
          </a>
        </li>
        <li class="c-activity-sidebar__item"  *ngIf="education.is_faculty">
          <a (click)="showMenu(1,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link" >
            Faculty & Disclosures
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                    <use xlink:href="#icon-arrow-right-grey"/>
                 </svg>
                 </span>
          </a>
        </li>
        <li class="c-activity-sidebar__item"  *ngIf="education.is_faculty_about">
          <a (click)="showMenu(1,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link" >
            About {{education.faculty.faculty[0].name}}
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                    <use xlink:href="#icon-arrow-right-grey"/>
                 </svg>
                 </span>
          </a>
        </li>
        <li *ngFor="let groups of education.video_groups.video_groups;let index = index" class="c-activity-sidebar__item c-activity-sidebar__item--bg has-children" [ngClass]="{'js-accordion-item is-open' : tabs[index]}">
          <a (click)="toggleTabs(index)" class="c-activity-sidebar__link c-activity-sidebar__link--parent js-accordion-header">
                     <span class="c-activity-sidebar__title">
                        <svg role="presentation" class="o-icon o-icon c-activity-sidebar__title-icon" *ngIf="groups.type === 'review'">
                           <use xlink:href="#icon-webinar"/>
                        </svg>
                         <svg role="presentation" class="o-icon o-icon c-activity-sidebar__title-icon" *ngIf="groups.type === 'expert'">
                           <use xlink:href="#icon-interviews"/>
                        </svg>
                        {{groups.label ? groups.label + ':' : ''}} {{groups.title || 'Videos'}} ({{groups.videos.length}})
                     </span>
            <!-- /.c-activity-sidebar__title -->
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right c-activity-sidebar__arrow-right-accordion">
             <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                <use xlink:href="#icon-up-arrow"/>
             </svg>
             </span>
          </a>
          <ul class="c-activity-sidebar__list c-activity-sidebar__sub-list js-accordion-content js-accordion-first-open" *ngIf="tabs[index]">
            <li *ngFor="let video of groups.videos;let index2 = index" class="c-activity-sidebar__item c-activity-sidebar__item--child">
              <a (click)="videoSelect('mainPlayer', video,'group-'+index,index2,video.time)" *ngIf="groups.type === 'review'" [attr.id]="index2 === 0 ? 'playReviewVideo' : null" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single" [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}">

                <span class="c-activity-sidebar__progress">
                              <svg role="presentation" class="o-icon o-icon c-activity-sidebar__progress-icon">
                                 <use
                                   xlink:href="#icon-tick"/>
                              </svg>
                           </span>
                <span class="c-activity-sidebar__info">
                              <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                              <span class="c-meta">
                                 <span class="c-meta__item c-meta__item--first">
                                    <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                                      <use xlink:href="#icon-time"/></svg>
                                    <span class="c-meta__text"><strong>Time: </strong>{{video.time}} </span>
                                 </span>
                              </span>
                  <!-- /.c-meta -->
                           </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                           <svg role="presentation" class="o-icon o-icon o-arrow__icon">0
                              <use
                                xlink:href="#icon-arrow-right-grey"/>
                           </svg>
                           </span>
              </a>
              <a (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')" *ngIf="groups.type === 'panel'  || groups.type === 'mdt'" [attr.id]="index2 === 0 ? 'playReviewVideo' : null" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single" [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}">

                <span class="c-activity-sidebar__progress">
                              <svg role="presentation" class="o-icon o-icon c-activity-sidebar__progress-icon">
                                 <use
                                   xlink:href="#icon-tick"/>
                              </svg>
                           </span>
                <span class="c-activity-sidebar__info">
                              <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                              <span class="c-meta">
                                 <span class="c-meta__item c-meta__item--first">
                                    <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                                      <use xlink:href="#icon-time"/></svg>
                                    <span class="c-meta__text"><strong>Time: </strong>{{video.time}} </span>
                                 </span>
                              </span>
                  <!-- /.c-meta -->
                           </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                           <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                              <use
                                xlink:href="#icon-arrow-right-grey"/>
                           </svg>
                           </span>
              </a>
              <a (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')" *ngIf="groups.type === 'satellite'" [attr.id]="index2 === 0 ? 'playReviewVideo' : null" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single" [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}">

                <span class="c-activity-sidebar__progress">
                              <svg role="presentation" class="o-icon o-icon c-activity-sidebar__progress-icon">
                                 <use
                                   xlink:href="#icon-tick"/>
                              </svg>
                           </span>
                <span class="c-activity-sidebar__info">
                              <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                              <span class="c-meta">
                                 <span class="c-meta__item c-meta__item--first">
                                    <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                                      <use xlink:href="#icon-time"/></svg>
                                    <span class="c-meta__text"><strong>Time: </strong>{{video.time}} </span>
                                 </span>
                              </span>
                  <!-- /.c-meta -->
                           </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                           <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                              <use
                                xlink:href="#icon-arrow-right-grey"/>
                           </svg>
                           </span>
              </a>
              <a (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')"  *ngIf="groups.type === 'expert'" [attr.id]="index2 === 0 ? 'playExpertVideo' : null" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-multi"  [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}">

              <span class="o-avatar o-avatar--small u-mr-3">
                                        <img :src="{{image(video.avatar)}}" class="u-cover" alt="">
                                        <span class="c-activity-sidebar__progress c-activity-sidebar__progress-authors">
                                            <svg role="presentation" class="o-icon o-icon c-activity-sidebar__progress-icon"><use xlink:href="#icon-tick"></use></svg>                                        </span>
                                    </span><!-- /.o-avatar -->
                <span class="c-activity-sidebar__info" [ngClass]="templateColor">
                                                                                                                <span class="c-activity-sidebar__sub-text js-video-title u-mb-1 c-activity-sidebar__author-name">{{video.name}}</span>
                                                                                                                <span class="c-meta__text u-text-400 u-text-grey-medium u-mb-2">{{video.from}}</span>
                                                                        <span class="c-meta">
                                                                          <span class="c-meta__item">
                                                        <span class="c-meta__text u-mr-1" *ngIf="video.languages !== undefined && video.languages.length>0"><strong>Available in:</strong></span>
                                                        <ul class="c-activity-sidebar__languages u-mr-2 u-ml-0" *ngIf="video.languages !== undefined && video.languages.length>0">
                                                          <li class="c-activity-sidebar__flag u-flex u-items-center"><img src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/English.svg" class="o-icon o-icon--flag c-language-selector__flag--English"></li>
                                                          <li class="c-activity-sidebar__flag u-flex u-items-center" *ngFor="let languages of video.languages"><img src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/{{languages.language}}.svg" class="o-icon o-icon--flag c-language-selector__flag--{{languages.language}}"></li>
                                                        </ul>
                                                    </span>
                                                                                                                                    <span class="c-meta__item c-meta__item--first">
                                                    <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-time"></use></svg>                                                    <span class="c-meta__text"><strong>Time: </strong>
                                                                                                                                      {{video.time}}                                                    </span>
                                                </span>
                                                                                                                                                                    </span><!-- /.c-meta -->
                                </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                                    <svg role="presentation" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>                                <span>
                                                    </span></span></a>
              <a (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')" *ngIf="groups.type === 'expertv2'" [attr.id]="index2 === 0 ? 'playReviewVideo' : null" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single" [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}">

                <span class="c-activity-sidebar__progress">
                              <svg role="presentation" class="o-icon o-icon c-activity-sidebar__progress-icon">
                                 <use
                                   xlink:href="#icon-tick"/>
                              </svg>
                           </span>
                <span class="c-activity-sidebar__info">
                              <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                              <span class="c-meta">
                                 <span class="c-meta__item c-meta__item--first">
                                    <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                                      <use xlink:href="#icon-time"/></svg>
                                    <span class="c-meta__text"><strong>Time: </strong>{{video.time}} </span>
                                 </span>
                              </span>
                  <!-- /.c-meta -->
                           </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                           <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                              <use
                                xlink:href="#icon-arrow-right-grey"/>
                           </svg>
                           </span>
              </a>
              <a (click)="videoSelect('mainPlayer', video,'group-'+index,index2,video.time)" *ngIf="groups.type === 'congress'" [attr.id]="index === 0 ? 'playReviewVideo' : null" class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single" [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}">

                <span class="c-activity-sidebar__progress">
                              <svg role="presentation" class="o-icon o-icon c-activity-sidebar__progress-icon">
                                 <use
                                   xlink:href="#icon-tick"/>
                              </svg>
                           </span>
                <span class="c-activity-sidebar__info">
                              <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                              <span class="c-meta">
                                 <span class="c-meta__item c-meta__item--first">
                                    <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                                      <use xlink:href="#icon-time"/></svg>
                                    <span class="c-meta__text"><strong>Time: </strong>{{video.time}} </span>
                                 </span>
                              </span>
                  <!-- /.c-meta -->
                           </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                           <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                              <use
                                xlink:href="#icon-arrow-right-grey"/>
                           </svg>
                           </span>
              </a>
            </li>
          </ul>
        </li>
        <li class="c-activity-sidebar__item" *ngIf="education.is_reference">
          <a (click)="showMenu(3,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link" >
            References
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                <svg role="presentation" class="o-icon o-icon o-arrow__icon">
                    <use xlink:href="#icon-arrow-right-grey"/>
                 </svg>
                 </span>
          </a>
        </li>
        <li class="c-activity-sidebar__item" *ngIf="education.is_download" >
          <a (click)="showMenu(2,'js-activity-content-tabs')" class="c-activity-sidebar__link js-sidebar-tab-link">
        <span class="c-activity-sidebar__language-wrap">
            Downloads
                            <span class="c-activity-sidebar__language-internal">
                    <span class="u-text-300" *ngIf=" education.downloads.downloads.length>1">Available in:</span>
                    <ul class="c-activity-sidebar__languages" *ngIf=" education.downloads.downloads.length>1">
                          <li class="c-activity-sidebar__flag"  *ngFor="let download of education.downloads.downloads;let index3 = index">
                            <img src="https://www.touchrespiratory.com/wp-content/themes/tmm2020/images/flags/{{download.language}}.svg" class="o-icon o-icon--flag c-language-selector__flag--{{download.language}}">
                          </li>

                    </ul>
                </span>
                    </span>
            <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
            <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>        <span>
    </span></span></a>
        </li>
      </ul>
      <!-- /.c-activity-sidebar__list -->
    </nav>
    <!-- /.c-activity-sidebar__internal -->
    <div class="c-activity-sidebar__bottom">
      <ul class="c-activity-sidebar__list">
        <li class="c-activity-sidebar__item c-activity-sidebar__item--cta u-justify-center"  *ngIf="education.survey !== '' && education.survey !== undefined">
          <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta js-overlay-toggle" data-overlay=".js-overlay-cme-test"
             id="take-CME-test-button-sidebar">Take Survey</a>
        </li>
        <li class="c-activity-sidebar__item c-activity-sidebar__item--footer c-activity-sidebar__item--bg">
                  <span>
                     <a (click)="backButton()" class="c-arrow-link" id="back-to-cme-button">
                        <svg role="presentation" class="o-icon o-icon c-arrow-link__icon c-arrow-link__icon--left">
                           <use
                             xlink:href="#icon-arrow-right"/>
                        </svg>
                        Back to Activities
                     </a>
                  </span>
        </li>
      </ul>
    </div>
    <!-- /.c-activity-sidebar__bottom -->
  </aside>
  <!-- /.c-activity-sidebar -->
  <main class="c-activity-content js-activity-content" data-form-type="cme-test" data-video-type="both" *ngIf="education">

    <section class="o-section u-pb-0">
      <div class="o-wrapper">
        <header  class="c-intro c-intro--top s-standard-content" [ngClass]="templateColor"  *ngIf="education.content_type !== 'touchMDT'">
          <div class="c-meta c-intro__bar">
            <span class="c-intro__badge-wrap  c-intro__badge-wrap-bottom">
              <span class="c-badge c-intro__badge">
                <span class="c-badge__text">{{education.tags}}</span>
              </span>
            </span>
            <span class="c-intro__right">
                        <span class="c-meta__item c-meta__item--first">
                           <svg role="presentation" class="o-icon o-icon o-icon--small c-meta__icon">
                              <use
                                xlink:href="#icon-time"/>
                           </svg>
                           <span
                             class="c-meta__text"><strong><span class="c-meta__text--limit">Watch</span> Time:</strong> {{education.watch_time}} mins</span>
                        </span>

                     </span>
            <!-- /.c-meta__right -->
          </div>
          <!-- /.c-top-bar -->
          <div class="c-intro__title-wrap">
            <h1 class="h1 c-intro__title js-intro-title">
              <span class="js-activity-type" *ngIf="education.content_title !== undefined && education.content_title !== ''">{{education.content_title}}</span>
              <span class="js-activity-type" *ngIf="education.content_title === undefined || education.content_title === ''">{{education.content_type}}</span>
              {{education.title}}
            </h1>
            <p><span [innerHTML]="education.description.description ? education.description.description : education.description"></span></p>
            <span *ngFor="let groups of education.video_groups.video_groups;let index = index">
              <p *ngIf="groups.label && template === 'review' && groups.type === 'review'">
                <strong>{{groups.label}}:</strong>
                <span *ngIf="template === 'review'" [innerHTML]="groups.description"></span>&nbsp;<a *ngIf="template === 'review'" (click)="videoSelect('mainPlayer',groups.videos[0],'group-'+index,groups.videos[0].time)">Watch Now</a>
                <br/>
              </p>
              <p *ngIf="groups.label && template === 'review' && groups.type === 'expert'">
                <strong>{{groups.label}}:</strong>
                <span *ngIf="template === 'review'" [innerHTML]="groups.description"></span>&nbsp;<a *ngIf="template === 'review'" (click)="videoSelect('expertPlayer',groups.videos[0],'group-'+index,0,'00:00')">Watch Expert Interviews</a>
                <br/>
              </p>
            </span>
            <p *ngIf="education.subtitle"><span [innerHTML]="education.subtitle.subtitle ? education.subtitle.subtitle : education.subtitle"></span></p>
          </div>
          <!-- /.c-intro__title-wrap -->
        </header>

        <!-- /.touchMDT Header-->
        <header id="c-intro-hub" class="c-intro c-intro--top s-standard-content"  [ngClass]="templateColor" *ngIf="education.content_type === 'touchMDT'">
          <div class="c-meta u-mb-3">
                <span class="c-intro__badge-wrap  c-intro__badge-wrap-bottom">
                   <span class="c-badge c-intro__badge">
                      <span class="c-badge__text">{{education.tags}}</span>
                  </span>
              </span>
            <span class="c-intro__right u-ml-2">
                <span class="c-meta__item c-meta__item--first">
                  <svg role="presentation" id="" class="o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-time"></use></svg>                                                <span class="c-meta__text"><strong><span class="c-meta__text--limit">Watch</span> Time:</strong> {{education.watch_time}} mins</span>
                </span>
              </span><!-- /.c-meta__right -->
          </div><!-- /.c-top-bar -->
          <div class="c-intro__title-wrap c-intro__mdt">
                <span>
                  <h1 class="h1 c-intro__title js-intro-title">
                    <span class="js-activity-type" *ngIf="education.content_title !== undefined && education.content_title !== ''">{{education.content_title}}</span>
                    <span class="js-activity-type" *ngIf="education.content_title === undefined || education.content_title === ''">{{education.content_type}}</span>
                    {{education.title}}
                  </h1>
                   <p><span [innerHTML]="education.description.description ? education.description.description : education.description"></span></p>
                </span>
            <a (click)="showMenu(0,'js-activity-content-tabs')" class="c-button has-icon c-intro__mdt-button js-sidebar-tab-link">Overview&nbsp;&amp;&nbsp;Learning&nbsp;Objectives</a>
          </div><!-- /.c-intro__title-wrap -->
        </header><!-- /.c-intro -->
        <!-- /.c-intro -->
      </div>
      <!-- /.o-wrapper -->
      <div *ngFor="let groups of education.video_groups.video_groups;let index = index">

        <section class="o-section c-spider__section js-spider-section"  [ngClass]="templateColor"  *ngIf="groups.type === 'mdt'" style="display:block">
          <div class="o-wrapper o-wrapper--medium">
            <div id="js-team-hub" class="c-spider__wrap js-spider-wrap c-spider__wrap--{{groups.videos.length}}" [ngStyle]="{'margin-bottom':blockMarginBottom(groups.videos.length)}">
              <div class="c-spider__middle js-spider-middle t-ime" [ngStyle]="{'top':blockMarginTop(groups.videos.length)}">
                <span>
                    <svg role="presentation" id="" class="o-icon o-icon c-spider__patient-icon"><use xlink:href="#icon-mdt_icon"></use></svg>
                  <p  *ngIf="education.subtitle" class="u-mb-0 c-spider__content-text" [innerHTML]="education.subtitle.subtitle ? education.subtitle.subtitle : education.subtitle"></p></span>
              </div><!-- /.c-spider__middle -->

                <div *ngFor="let video of groups.videos;let index2 = index;" class="c-spider c-spider--{{index2}} t-ime js-spider" data-card="{{index2}}" [ngStyle]="{'top':blockFromTop(index2),'height':blockHeight(groups.videos)}" style="margin-top: 0px;">
                  <div class="c-spider__int js-spider-internal js-sidebar-video-link-multi is-active">
                    <div class="c-spider__default">
                            <span>
                                                                                                                                    <img width="198" height="126"  :src="{{image(video.icon)}}" class="o-icon o-icon--color c-spider__icon" alt="" loading="lazy">                                                                                                    <h6 class="h6 c-spider__title u-mb-3">{{video.group_title}}</h6>
                                                                                                    <a   (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')" class="c-button c-button--cta has-icon watch-video-cta-button" id="mdtWatch">Watch Video</a>

                                                            </span>
                    </div><!-- /.c-spider__default -->
                    <div class="c-spider__reveal">
                            <span>
                                <span class="c-meta c-meta--rows u-mb-3">
                                                                            <span class="c-meta__item c-meta__item--small">
                                            <svg role="presentation" id="" class="o-icon o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-play"></use></svg>                                            <span class="c-meta__text u-text-small-medium u-text-left u-text-500 u-text-09">{{video.title}}</span>
                                        </span><!-- /.c-meta__item -->
                                                                                                                                                                                        <span class="c-meta__item c-meta__item--small">
                                            <svg role="presentation" id="" class="o-icon o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-avatar"></use></svg>                                            <span class="c-meta__text u-text-small-medium u-text-left u-text-500 u-text-09">
                                               {{otherExperts(video.name,video.other_experts)}}                                                                                         </span>
                                        </span><!-- /.c-meta__item -->
                                                                    </span><!-- /.c-meta -->
                                                                    <p class="u-mb-3 c-spider__small-text u-text-left">{{video.description}}</p>

                               <span   (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')" class="c-button c-button--cta has-icon watch-video-cta-button">Watch Video</span>
                                                            </span>
                    </div><!-- /.c-spider__reveal -->
                  </div><!-- /.c-spider__int -->
                </div><!-- /.c-spider -->

            </div><!-- /.c-spider__wrap -->
          </div><!-- /.o-wrapper -->
        </section><!-- /.o-section -->

        <section class="o-section t-ime u-pt-0 js-touch-other"  [ngClass]="templateColor" *ngIf="groups.type === 'mdt'" id="{{ 'group-' + index }}" style="display:block">
          <div class="o-wrapper">

            <div class="c-video-section-wrap t-ime" *ngFor="let video of groups.videos;let index3 = index;">
              <div class="js-wistia-wrap-multi t-ime" data-order="4" *ngIf="tabs[index] && videoTab['group-' + index][index3]">
                <div class="c-wistia js-wistia-video-wrap u-mb-0 is-visible" data-video-id="8w9b8m1sje">
                  <div class="c-card c-card--row c-card--flex-top c-card--large u-justify-start s-standard-content u-mb-5 c-spider__details">
                    <img width="287" height="126" :src="{{image(video.icon)}}" class="o-icon o-icon--color c-spider__icon u-mb-0 u-mr-6" alt="" loading="lazy">                                                <span>
                                                            <h5 class="h5 u-text-600 u-mb-1">{{video.group_title}}</h5>
                                                                                        <p class="u-mb-0">{{video.description}}</p>
                                                    </span>
                  </div><!-- /.c-card -->
                  <div class="o-grid">
                    <div class="o-grid__col o-grid__col-4 o-grid__col-12@tl o-grid__order-2@tl s-standard-content u-mb-0">

                      <div class="c-card c-card--large c-card--flex c-card--full-height c-spider__spotlight u-items-start">
                        <h5 class="h6 u-text-grey-medium-light u-text-400">Expert Spotlight</h5>


                        <!-- Additional Faculty Members -->
                        <div  *ngFor="let faculty of education.faculty.faculty">
                          <span class="c-bio__author-info u-mb-4 u-mt-4 u-mr-0" *ngIf="otherExperts('noname',video.other_experts,faculty.name)">
                          <span class="o-avatar o-avatar--small u-mr-3">
                              <img :src="{{image(faculty.image)}}" class="u-cover" alt="">
                              <span class="c-activity-sidebar__progress c-activity-sidebar__progress-authors"><svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon"><use xlink:href="#icon-tick"></use></svg></span>
                          </span><!-- /.o-avatar -->
                          <span>
                            <h6 class="h6 u-mb-0">{{faculty.name}}</h6>
                             <span class="c-meta__text u-text-400 u-text-grey-medium">{{faculty.from}}</span>
                          </span>
                          </span><!-- /.c-bio__author-info -->
                        </div>
                        <span class="c-bio__author-info u-mb-4 u-mr-0">
                          <span class="o-avatar o-avatar--small u-mr-3">
                            <img  :src="{{image(video.avatar)}}" class="u-cover" alt="">
                            <span class="c-activity-sidebar__progress c-activity-sidebar__progress-authors"><svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon"><use xlink:href="#icon-tick"></use></svg>
                          </span>
                        </span><!-- /.o-avatar -->
                          <span>
                            <h6 class="h6 u-mb-0">{{video.name}}</h6>
                            <span class="c-meta__text u-text-400 u-text-grey-medium">{{video.from}}</span>
                          </span>
                        </span><!-- /.c-bio__author-info -->
                        <span><p><span style="font-weight: 400;">{{video.about}}</span></p></span>
                        <!-- End Additional Faculty Members -->

                        <a (click)="videoSelect('expertPlayer', video,'group-'+index,index3,'00:00')"  class="c-button has-icon u-mt-5 u-mb-5 js-next-video expert-spotlight-next-video-mdt-cta-button">Learn more</a>
                        <span class="c-meta">
                            <span class="c-meta__item c-meta__item--first">
                                <svg role="presentation" id="" class="o-icon o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-time"></use></svg>                                                                                        <span class="c-meta__text"><strong>Time:&nbsp;</strong>{{video.time}}</span>
                            </span><!-- /.c-meta__item -->
                        </span><!-- /.c-meta -->
                      </div><!-- /.c-card -->
                    </div><!-- /.o-grid__col -->
                    <div class="o-grid__col o-grid__col-8 o-grid__col-12@tl o-grid__order-1@tl c-spider__video-col">
                      <div class="js-wistia-video-wrap">
                        <div class="c-video-container u-mb-0">
                          <app-player [elementId]="'expertPlayer'"></app-player>
                        </div><!-- /.c-video-container -->
                      </div><!-- /.js-wistia-video -->
                    </div><!-- /.o-grid__col -->
                  </div><!-- /.o-grid -->
                </div><!-- /.js-wistia-video -->
              </div><!-- /.js-wistia-wrap-multi -->
            </div>
          </div><!-- /.o-wrapper -->
        </section><!-- /.o-section -->
        <div class="c-tabs-bar"  [ngClass]="templateColor" *ngIf="groups.type === 'review'" id="{{ 'group-' + index }}" style="display:block">
          <div class="o-wrapper c-tabs-bar__wrapper">
            <ul class="c-tabs-bar__list c-tabs-bar__list-alt">
              <li class="c-tabs-bar__item">
                <span class="c-activity-sidebar__title">
                   <svg role="presentation"
                        class="o-icon o-icon c-activity-sidebar__title-icon">
                      <use
                        xlink:href="#icon-webinar"/>
                   </svg>
                   <span
                     class="c-tabs-bar__text h6 u-text-grey">{{groups.label ? groups.label + ': ' : ''}} {{groups.title}}</span>
                </span>
              </li>
            </ul>
            <div class="c-tabs-bar__fade"></div>
            <!-- /.c-tabs-bar__fade -->
            <div *ngFor="let video of groups.videos;let index2 = index;" style="position:absolute;right:0px;">
              <span class="u-flex u-items-center u-justify-end u-flex-wrap" *ngIf="tabs[index] && videoTab['group-' + index][index2] && groups.videos[index2+1] !== undefined">
                <a (click)="videoSelect('mainPlayer', groups.videos[index2+1], 'group-'+index,index2+1,groups.videos[index2+1].time)" class="c-button c-tabs-bar__button has-icon js-next-video u-ml-4">Next Chapter</a>
              </span>
              <span class="u-flex u-items-center u-justify-end u-flex-wrap" *ngIf="tabs[index] && videoTab['group-' + index][index2] && groups.videos[index2+1] === undefined">
                <a (click)="videoSelect('expertPlayer', groups.videos[0], 'group-1',0,'00:00')" class="c-button c-tabs-bar__button has-icon js-next-video u-ml-4">Watch Expert Interviews</a>
              </span>
            </div>
          </div>
          <!-- /.o-wrapper -->
        </div>
        <section class="o-section o-section--bg" [ngClass]="templateColor" *ngIf="groups.type === 'review'"  style="display:block">
          <div class="o-wrapper">
            <div *ngFor="let video of groups.videos;let index2 = index;">
            <div class="c-card__single js-video-content" *ngIf="tabs[index] && videoTab['group-' + index][index2]">
               <span>
                  <h6 class="h6 u-text-grey u-mb-3">{{video.title}}</h6>
                  <span
                    class="c-meta c-meta--cols">
                     <span class="c-meta__item c-meta__item--first">
                        <svg role="presentation"
                             class="o-icon o-icon o-icon--small c-meta__icon">
                           <use
                             xlink:href="#icon-time"/>
                        </svg>
                        <span
                          class="c-meta__text">
                        <strong><span
                          class="c-meta__text--limit">Watch</span> Time: </strong>
                          {{video.time}}                                                </span>
                     </span>
                  </span>
               </span>
            </div>
          </div>

            <!-- /.c-card__single -->
            <div class="js-wistia-wrap-single">
              <div class="js-wistia-video-wrap js-wistia-single-video" data-video-id="pwl38k1csr">
                <div class="o-grid">
                  <div
                    class="o-grid__col o-grid__col-5 o-grid__col-7--ol o-grid__col-6@dl o-grid__col-6--ol@dl o-grid__col-7@dm o-grid__col-5--ol@dm o-grid__col-12@tl o-grid__col-0--ol@tl">
                    <div class="c-card c-card__rangeslider c-card--flex u-items-start">
                      <div class="c-rangeslider">
                        <h6 class="h6 u-text-small-medium u-text-grey u-mb-0 u-mr-3 c-rangeslider__title">Video Player
                          Controls:
                        </h6>
                        <svg role="presentation" class="o-icon o-icon c-rangeslider__icon u-mr-2">
                          <use xlink:href="#icon-webinar"/>
                        </svg>
                        <mat-slider color="primary" step="1" min="1" max="7" [(ngModel)]="videosize" style="width:100%" (change)="pdfResize($event)"></mat-slider>
                        <svg role="presentation" class="o-icon o-icon c-rangeslider__icon u-ml-2">
                          <use xlink:href="#icon-scenery"/>
                        </svg>

                      </div>
                      <!-- /.c-rangeslide -->
                    </div>
                    <!-- /.c-card -->
                  </div>
                  <!-- /.o-grid__col -->
                </div>
                <!-- /.o-grid -->
                <div class="o-grid u-mt-6">
                  <div class="o-grid__col o-grid__col-6 o-grid__col-12@pvl js-rangeslider-video" [ngStyle]="{'max-width':videoSize()}">
                    <div class="c-video-container u-mb-0">
                      <div class="o-loader o-loader--center c-wistia__loader"></div>
                      <!-- /.o-loader -->
                      <app-player [elementId]="'mainPlayer'"></app-player>
                    </div>
                    <!-- /.c-video-container -->
                  </div>
                  <!-- /.o-grid__col -->
                  <div class="o-grid__col o-grid__col-6 o-grid__col-12@pvl js-rangeslider-slide"  [ngStyle]="{'max-width':rangeSize()}">
                    <div class="c-card">
                      <div class="c-activity-video-slide">
                        <pdf-viewer id="pdfview" [src]="pdf" [(page)]="pdfPage" [show-all]="false" [autoresize]="true" [original-size]='false' [fit-to-page]='true' [render-text]='false' style="display: block;"></pdf-viewer>
                      </div>
                      <!-- /.c-activity-video-slide -->
                    </div>
                    <!-- /.c-card -->
                  </div>
                  <!-- /.o-grid__col -->
                </div>
                <!-- /.o-grid -->

                <div class="js-tabs u-mt-6" [ngClass]="templateColor" id="js-author-content-tabs">
                  <div class="c-tabs-bar">
                    <div class="o-wrapper c-tabs-bar__wrapper">
                      <ul class="c-tabs-bar__list">
                        <li class="c-tabs-bar__item" (click)="showOverviewTab(0)">
                          <button class="c-tabs-bar__tab-button js-tabs-button" [ngClass]="{'is-active' : overviewTab === 0}"
                                  data-tab-target="#js-tab-author-overview">
                            <span class="c-tabs-bar__text" title="grid-columns">Overview</span>
                          </button>
                        </li>
                        <li class="c-tabs-bar__item" (click)="showOverviewTab(1)">
                          <div *ngFor="let video of groups.videos;let index2 = index;">
                            <button class="c-tabs-bar__tab-button js-tabs-button" data-tab-target="#js-tab-author-about" [ngClass]="{'is-active' : overviewTab === 1}" *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                              <span class="c-tabs-bar__text" title="grid-columns">About {{video.name !== undefined ? video.name:groups.videos[0].name}}</span>
                            </button>
                          </div>
                        </li>
                      </ul>
                      <div class="c-tabs-bar__fade"></div>
                      <!-- /.c-tabs-bar__fade -->
                      <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta c-tabs-bar__button u-flex-shrink js-overlay-toggle" *ngIf="education.survey"
                         data-overlay=".js-overlay-cme-test" id="take-CME-test-button-banner">Take Survey</a>
                    </div>
                    <!-- /.o-wrapper -->
                  </div>
                  <div *ngFor="let video of groups.videos;let index2 = index;">
                    <div  *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                  <!-- /.c-tabs-bar -->
                  <div class="c-tabs__tab js-tabs-tab" id="js-tab-author-overview" style="display: block;" *ngIf="overviewTab === 0">
                    <div class="c-card c-card--large c-card--rounded-bottom s-standard-content">
                      <h6 class="h6 u-mb-2 u-text-grey">Overview</h6>
                      <p [innerHTML]="video.description !== undefined && video.description !== '' ? video.description:groups.videos[0].description"></p>
                    </div>
                    <!-- /.c-card -->
                  </div>
                  <!-- /.c-tabs__tab -->
                  <div class="c-tabs__tab js-tabs-tab" id="js-tab-author-about" style="display: block;" *ngIf="overviewTab === 1">
                    <div class="c-card c-card--large c-card--rounded-bottom s-standard-content">
                      <h6 class="h6 u-mb-2 u-text-grey">About {{video.name !== undefined ? video.name:groups.videos[0].name}}</h6>
                      <app-readmore [text]="video.about !== undefined && video.about !== '' ? video.about : groups.videos[0].about"></app-readmore>
                      <div *ngIf="video.discloses !== undefined && video.discloses !== ''" [innerHTML]="video.discloses"></div>
                    </div>
                    <!-- /.c-card -->
                  </div>
                  <!-- /.c-tabs__tab -->
                    </div>
                  </div>
                  <!-- /.c-tabs__tab -->
                </div>
                <!-- /.js-tabs -->
              </div>
              <!-- /.js-wistia-video-wrap -->
            </div>
            <!-- /.js-wistia-wrap-single -->
          </div>
          <!-- /.o-wrapper -->
        </section>
        <div class="o-wrapper" *ngIf="(groups.type === 'panel' || groups.type === 'satellite') && education.is_touchpanel"  style="display:block">
          <div class="c-card c-card--large s-standard-content u-mb-6" [ngClass]="templateColor">
            <div class="o-grid o-grid--full u-mb-0">
              <div class="o-grid__col o-grid__col-7 o-grid__col-12@tm u-mb-0" *ngIf="countPanelist(education.faculty.faculty) === 1">
                <div *ngFor="let faculty of education.faculty.faculty">
                    <span class="c-card__details" *ngIf="faculty.panelist === true">
                       <span class="o-avatar o-avatar--small c-card__col">
                         <img src="{{faculty.image}}" class="u-cover" alt="">
                       </span><!-- /.o-avatar -->
                      <span class="c-card__col c-card__col--name">
                        <h6 class="h6 u-mb-1 u-text-500">{{faculty.name}}</h6>
                        <p class="u-mb-1 c-card__sub-title u-text-small">{{faculty.from}}</p>
                        <p class="u-text-uppercase u-text-grey u-text-500 u-mb-0">{{faculty.chair}}</p>
                      </span><!-- /.c-card__col -->
                    </span><!-- /.c-card__details -->
                </div>
              </div><!-- /.o-grid__col -->
              <div class="o-grid__col o-grid__col-7 o-grid__col-12@tm u-mb-0" *ngIf="countPanelist(education.faculty.faculty) > 1">
                <div>
                    <span class="c-card__details">
                       <span class="o-avatar o-avatar--small c-card__col" *ngFor="let faculty of education.faculty.faculty">
                         <img src="{{faculty.image}}" class="u-cover" alt="" *ngIf="faculty.panelist === true">
                       </span><!-- /.o-avatar -->
                      <span class="c-card__col c-card__col--name">
                        <h6 class="h6 u-mb-1 u-text-500"><span *ngFor="let faculty of education.faculty.faculty;let countFaculty = index"><span *ngIf="faculty.panelist === true">{{faculty.name}}  <span *ngIf="countPanelist(education.faculty.faculty) > (countFaculty+1)"> and </span> </span></span></h6>
                         <p class="u-mb-1 c-card__sub-title u-text-small"><span *ngFor="let faculty of education.faculty.faculty;let countFaculty = index"><span *ngIf="faculty.panelist === true">{{faculty.from}}  <span *ngIf="countPanelist(education.faculty.faculty) > (countFaculty+1)">, </span> </span></span></p>
                        <p class="u-text-uppercase u-text-grey u-text-500 u-mb-0"><span *ngFor="let faculty of education.faculty.faculty;let countFaculty = index">{{faculty.chair}}</span></p>
                      </span><!-- /.c-card__col -->
                    </span><!-- /.c-card__details -->
                </div>
              </div><!-- /.o-grid__col -->
              <div class="o-grid__col o-grid__col-5 o-grid__col-12@tm u-mb-0">
                <h6 class="u-text-grey u-text-500 u-mb-3 c-card__disclosures">Panelists:</h6>
                <span class="c-meta">
                                <span class="c-meta__item u-items-start">
                                    <svg role="presentation" id="" class="o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-avatar"></use></svg>
                                  <span class="c-meta__text">{{countNotPanelist()}}</span>
                                </span><!-- /.c-meta__item -->
                            </span><!-- /.c-meta -->
              </div><!-- /.o-grid__col -->
            </div><!-- /.o-grid -->
          </div><!-- /.c-card -->
        </div>
        <div class="c-tabs-bar" [ngClass]="templateColor" *ngIf="groups.type === 'expert' && template === 'review'" style="display:block" id="{{ 'group-' + index }}">
          <div class="o-wrapper c-tabs-bar__wrapper">
            <ul class="c-tabs-bar__list c-tabs-bar__list-alt">
              <li class="c-tabs-bar__item">
                  <span class="c-activity-sidebar__title">
                        <svg role="presentation" class="o-icon o-icon c-activity-sidebar__title-icon"><use xlink:href="#icon-interviews"></use></svg>
                    <span class="c-tabs-bar__text h6 u-text-grey">{{groups.label ? groups.label + ':': ''}} {{groups.title}}</span>
                  </span>

              </li>
            </ul>
            <div class="c-tabs-bar__fade"></div><!-- /.c-tabs-bar__fade -->
            <span class="u-flex u-items-center u-justify-end u-flex-wrap"></span>
          </div><!-- /.o-wrapper -->
        </div>
        <div class="c-tabs-bar" [ngClass]="templateColor" *ngIf="groups.type === 'satellite'" style="display:block" id="{{ 'group-' + index }}">
          <div class="o-wrapper c-tabs-bar__wrapper">
            <ul class="c-tabs-bar__list c-tabs-bar__list-alt">
              <li class="c-tabs-bar__item">
                  <span class="c-activity-sidebar__title">
                    <span class="c-tabs-bar__text h6 u-text-grey">{{groups.label}}</span>
                  </span>

              </li>
            </ul>
            <div class="c-tabs-bar__fade"></div><!-- /.c-tabs-bar__fade -->
            <span class="u-flex u-items-center u-justify-end u-flex-wrap"></span>
          </div><!-- /.o-wrapper -->
        </div>
        <div class="c-play-bar" [ngClass]="templateColor" *ngIf="groups.type === 'expert' && template === 'expert'" style="display:block" id="{{ 'group-' + index }}">
          <div class="o-wrapper c-play-bar__wrapper">
            <div class="c-play-bar__box" *ngFor="let video of groups.videos;let index2 = index;" [ngStyle]="{'display':tabs[index] && videoTab['group-' + index][index2] ? 'flex':'none'}">
            <span class="c-play-bar__col" *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                <h6 class="h6 u-text-grey u-mb-0 u-mr-4 c-play-bar__section-title">Now Playing:</h6>
                <button class="c-play-bar__item is-active">
                    <div class="c-play-bar__int">
                        <span>
                            <div class="c-play-bar__top">
                                  <svg role="presentation" id="" class="o-icon o-icon o-icon--small c-play-bar__icon"><use xlink:href="#icon-play-colour"></use></svg>
                              <h6 class="h6 u-text-500 u-mb-0 c-play-bar__title js-current-video-title">{{video.name}}</h6>
                            </div><!-- /.c-play-bar__top -->
                            <p class="c-play-bar__text js-current-video-institution">{{video.from}}</p>
                        </span>
                    </div><!-- /.c-play-bar__int -->
                </button><!-- /.c-play-bar__item-->
            </span>
              <span class="c-play-bar__col" >
                <h6 class="h6 u-text-grey u-mb-0 u-mr-4 c-play-bar__show c-play-bar__section-title">Up Next:</h6>
                <button class="c-play-bar__item c-play-bar__show js-play-bar-display-next" *ngIf="groups.videos[index2+1] !== undefined" (click)="videoSelect('expertPlayer', groups.videos[index2+1], 'group-'+index,index2+1,'00:00')">
                    <div class="c-play-bar__int">
                        <span>
                            <div class="c-play-bar__top">
                              <svg role="presentation" id="" class="o-icon o-icon o-icon--small c-play-bar__icon"><use xlink:href="#icon-play-colour"></use></svg>
                              <h6 class="h6 u-text-500 u-mb-0 c-play-bar__title js-next-video-title">{{groups.videos[index2+1].name}}</h6>
                            </div><!-- /.c-play-bar__top -->
                            <p class="c-arrow-link c-play-bar__text"><span class="js-next-video-institution">{{groups.videos[index2+1].from}}</span>
                              <svg role="presentation" id="" class="o-icon o-icon c-arrow-link__icon c-arrow-link__icon--right"><use xlink:href="#icon-arrow-right"></use></svg></p>
                        </span>
                    </div><!-- /.c-play-bar__int -->
                </button><!-- /.c-play-bar__item-->
                <div class="c-play-bar__cta js-play-bar-display-cta" *ngIf="groups.videos[index2+1] === undefined && education.survey">
                      <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta js-overlay-toggle" data-overlay=".js-overlay-cme-test" id="take-CME-test-button-final-play-bar">Take Survey</a>
                </div><!-- /.js-play-bar-display-cta -->
            </span>
            </div>
          </div>
        </div>
        <div class="c-play-bar" [ngClass]="templateColor" *ngIf="groups.type === 'expertv2'" style="display:block;" id="{{ 'group-' + index }}">
          <div class="o-wrapper c-play-bar__wrapper">
            <div class="c-play-bar__box" *ngFor="let video of groups.videos;let index2 = index;" [ngStyle]="{'display':tabs[index] && videoTab['group-' + index][index2] ? 'flex':'none'}">
            <span class="c-play-bar__col" *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                <h6 class="h6 u-text-grey u-mb-0 u-mr-4 c-play-bar__section-title">Now Playing:</h6>
                <button class="c-play-bar__item is-active">

                    <div class="c-play-bar__int">
                        <span>
                            <div class="c-play-bar__top">
                                  <svg role="presentation" id="" class="o-icon o-icon o-icon--small c-play-bar__icon"><use xlink:href="#icon-play-colour"></use></svg>
                              <h6 class="h6 u-text-500 u-mb-0 c-play-bar__title js-current-video-title">{{groups.label}}</h6>
                            </div><!-- /.c-play-bar__top -->
                            <p class="c-play-bar__text js-current-video-institution">{{video.name}}</p>
                        </span>
                    </div><!-- /.c-play-bar__int -->
                </button><!-- /.c-play-bar__item-->
            </span>
              <span class="c-play-bar__col" >
                <h6 class="h6 u-text-grey u-mb-0 u-mr-4 c-play-bar__show c-play-bar__section-title" *ngIf="groups.videos[index2+1] !== undefined || education.survey">Up Next:</h6>
                <button class="c-play-bar__item c-play-bar__show js-play-bar-display-next" *ngIf="groups.videos[index2+1] !== undefined" (click)="videoSelect('expertPlayer', groups.videos[index2+1], 'group-'+index,index2+1,'00:00')">
                    <div class="c-play-bar__int">
                        <span>
                            <div class="c-play-bar__top">
                              <svg role="presentation" id="" class="o-icon o-icon o-icon--small c-play-bar__icon"><use xlink:href="#icon-play-colour"></use></svg>
                              <h6 class="h6 u-text-500 u-mb-0 c-play-bar__title js-next-video-title">{{groups.videos[index2+1].name}}</h6>
                            </div><!-- /.c-play-bar__top -->
                            <p class="c-arrow-link c-play-bar__text"><span class="js-next-video-institution">{{groups.videos[index2+1].from}}</span>
                              <svg role="presentation" id="" class="o-icon o-icon c-arrow-link__icon c-arrow-link__icon--right"><use xlink:href="#icon-arrow-right"></use></svg></p>
                        </span>
                    </div><!-- /.c-play-bar__int -->
                </button><!-- /.c-play-bar__item-->
                <div class="c-play-bar__cta js-play-bar-display-cta" *ngIf="groups.videos[index2+1] === undefined && education.survey">
                      <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta js-overlay-toggle" data-overlay=".js-overlay-cme-test" id="take-CME-test-button-final-play-bar">Take Survey</a>
                </div><!-- /.js-play-bar-display-cta -->
            </span>
            </div>
          </div>
        </div>
        <section class="o-section o-section--bg" [ngClass]="templateColor" *ngIf="groups.type === 'expert'" style="display:block">
          <div class="o-wrapper">
            <div class="js-wistia-wrap-multi">
              <div *ngFor="let video of groups.videos;let index2 = index;">

              <div class="c-wistia c-wistia__multi js-wistia-video-wrap" style="display:block"  *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                <div *ngIf="template === 'review'" class="c-card c-card--flex c-card--row c-card--flex-top c-bio c-card--large s-standard-content u-mb-6">
                           <span class="c-bio__left u-w-full">
                              <span class="c-bio__author-info u-mb-4">
                                 <span class="o-avatar o-avatar--small u-mr-3">
                                    <img
                                      :src="{{image(video.avatar)}}"
                                      class="u-cover" alt=""/>
                                    <span
                                      class="c-activity-sidebar__progress c-activity-sidebar__progress-authors">
                                       <svg role="presentation"
                                            class="o-icon o-icon c-activity-sidebar__progress-icon">
                                          <use
                                            xlink:href="#icon-tick"/>
                                       </svg>
                                    </span>
                                 </span>
                                <!-- /.o-avatar -->
                                 <span>
                                    <span class="c-bio__author-int">
                                       <h6
                                         class="h6 c-bio__author-title u-mb-0 u-flex-shrink js-author-name">{{video.name}}</h6>
                                       <span
                                         class="c-meta__item c-meta__item--first">
                                          <svg role="presentation"
                                               class="o-icon o-icon o-icon--small c-meta__icon">
                                             <use
                                               xlink:href="#icon-time"/>
                                          </svg>
                                          <span class="c-meta__text">
                                          <strong><span
                                            class="c-meta__text--limit">Watch</span> Time: </strong>
                                         {{video.time}}                                                             </span>
                                       </span>
                                    </span>
                                   <!-- /.c-bio__author-int -->
                                    <span
                                      class="c-meta__text u-text-400 u-text-grey-medium js-author-institution u-mr-4">{{video.from}}</span>
                                 </span>
                                <div class="c-language-selector c-language-selector--video js-language-selector" *ngIf="video.languages !== undefined && video.languages.length>0">
                                      <select name="language-selector" id="resources-language-selector" class="c-language-selector__select c-language-selector__select-alt js-language-selector-select js-language-change-videos" #t (change)="videoLanguage(t.value)">
                                          <option [value]="videoLanguageData('expertPlayer', video,'group-'+index,index2,'',false)" selected="">English</option>
                                          <option *ngFor="let languages of video.languages" [value]="videoLanguageData('expertPlayer', video,'group-'+index,index2,languages.language,languages.media_id)">{{languages.language}}</option>
                                      </select><!--.js-language-selector-select -->
                                      <div class="c-language-selector__facade c-card c-card--direction c-card--flex c-card--button u-justify-end">
                                          <div class="c-language-selector__icon c-card__button-icon">
                                              <img src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/English.svg" class="o-icon o-icon--flag c-language-selector__flag c-language-selector__flag__absolute js-language-selector-flag" [ngClass]="{'is-active' : selectedLanguage === 'none'}" >
                                              <img  *ngFor="let languages of video.languages" src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/{{languages.language}}.svg" class="o-icon o-icon--flag c-language-selector__flag c-language-selector__flag__absolute c-language-selector__flag--Swedish js-language-selector-flag" [ngClass]="{'is-active' : selectedLanguage === languages.language}">
                                      </div><!-- /.flag -->
                                          <span class="o-arrow o-arrow--right c-language-selector__arrow-down">
                                              <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-up-arrow"></use></svg>                                                                </span>
                                      </div><!-- /.c-language-selector__facade -->
                                  </div>
                              </span>
                             <!-- /.c-bio__author-info -->
                               <p [innerHTML]="video.description"></p>
                           </span>
                  <!-- /.c-bio__left -->
                </div>
                <!-- /.c-card -->
                <div class="c-wistia js-wistia-video-wrap js-wistia-video-wrap-language is-visible"
                     data-video-id="wljmqhswb1">
                  <div class="c-video-container">

                    <app-player [elementId]="'expertPlayer'" [mediaId]="video.media_id" [title]="video.title"></app-player>

                  </div>
                  <!-- /.c-video-container -->
                </div>
                <div *ngIf="template === 'expert'" class="c-card c-card--flex c-card--row c-card--flex-top c-bio c-card--large s-standard-content" style="padding:30px;">

                                    <span class="c-bio__left">
                                        <span class="c-bio__author-info">
                                               <span class="o-avatar o-avatar--small u-mr-3">
                                                    <img :src="{{image(video.avatar)}}" class="u-cover" alt="">
                                                    <span class="c-activity-sidebar__progress c-activity-sidebar__progress-authors">
                                                        <svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon"><use xlink:href="#icon-tick"></use></svg>
                                                    </span>
                                                </span><!-- /.o-avatar -->

                                        <span>

                                              <span class="c-bio__author-int">
                                                   <h6 class="h6 c-bio__author-title u-mb-0 u-flex-shrink js-author-name">{{video.name}}</h6>
                                                   <span class="c-meta__text u-text-400 u-text-grey-medium js-author-institution">{{video.from}}</span>
                                                   </span><!-- /.c-bio__author-int -->
                                                   <h6 class="h6 u-text-grey u-mb-0">{{video.title}}</h6>

                                              </span>

                                        </span>
                                       <p [innerHTML]="video.description"></p>
                                    </span>
                   <span class="c-bio__right">
                          <span class="c-card__count">
                              <span class="c-card__count-text"><span class="is-active">{{index2+1}}</span>/{{totalVideo}}</span>
                          </span>
                          <a  (click)="videoSelect('expertPlayer', groups.videos[index2+1], 'group-'+index,index2+1,'00:00')" class="c-button has-icon js-next-video" id="next-video-button" *ngIf="(index2+1)<totalVideo">Next Interview</a>
                   </span>
                </div>

                <!-- /.c-wistia -->
                <div class="js-tabs u-mt-6" [ngClass]="templateColor" id="js-author-content-tabs">
                  <div class="c-tabs-bar">
                    <div class="o-wrapper c-tabs-bar__wrapper">
                      <ul class="c-tabs-bar__list">
                        <li class="c-tabs-bar__item" (click)="expertTabs(0)">
                          <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : expertTab === 0}"
                                  data-tab-target="#js-tab-author-interview-questions">
                            <span class="c-tabs-bar__text" title="grid-columns">Interview Questions</span>
                          </button>
                        </li>
                        <li class="c-tabs-bar__item" (click)="expertTabs(1)">
                          <button class="c-tabs-bar__tab-button js-tabs-button " data-tab-target="#js-tab-author-about" [ngClass]="{'is-active' : expertTab === 1}">
                            <span class="c-tabs-bar__text" title="grid-columns">About {{video.name}}</span>
                          </button>
                        </li>
                      </ul>
                      <div class="c-tabs-bar__fade"></div>
                      <!-- /.c-tabs-bar__fade -->
                      <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta c-tabs-bar__button u-flex-shrink js-overlay-toggle" *ngIf="education.survey"
                         data-overlay=".js-overlay-cme-test" id="take-CME-test-button-banner">Take Survey</a>
                    </div>
                    <!-- /.o-wrapper -->
                  </div>
                  <!-- /.c-tabs-bar -->
                  <div class="c-tabs__tab js-tabs-tab" id="js-tab-author-interview-questions" style="display:block" *ngIf="expertTab === 0" >
                    <div class="c-card c-card--large c-card--rounded-bottom s-standard-content" >
                      <h6 class="h6 u-mb-2 u-text-grey">Interview Questions</h6>
                      <app-readmore [text]="groups.interview_questions !== undefined && groups.interview_questions !== ''? groups.interview_questions : video.interview_questions"></app-readmore>
                    </div>
                    <!-- /.c-card -->
                  </div>
                  <!-- /.c-tabs__tab -->
                  <div class="c-tabs__tab js-tabs-tab" id="js-tab-author-about"  style="display:block"  *ngIf="expertTab === 1">
                    <div class="c-card c-card--large c-card--rounded-bottom s-standard-content">
                      <h6 class="h6 u-mb-2 u-text-grey">About {{video.name}}</h6>
                      <app-readmore [text]="video.about"></app-readmore>
                      <div *ngIf="video.discloses !== undefined && video.discloses !== ''" [innerHTML]="video.discloses"></div>
                    </div>
                    <!-- /.c-card -->
                  </div>
                  <!-- /.c-tabs__tab -->
                </div>
                <!-- /.js-tabs -->
              </div>
              </div>
            </div>
            <!-- /.js-wistia-wrap-multi -->
            <h6 class="h6 u-text-grey pt-3" *ngIf="template === 'review'">Please Select A Video:</h6>

            <div class="o-grid" *ngIf="template === 'review'">
              <div  *ngFor="let video of groups.videos;let index3 = index;" class="o-grid__col o-grid__col-4 o-grid__col-6@tl o-grid__col-12@pvl s-standard-content" style="padding:0;margin-bottom:50px;">
                <div class="c-card c-card--flex c-card--clickable c-card--full-height u-items-start js-sidebar-video-link-multi" style="margin:10px;"  [ngClass]="{'is-active' :tabs[index] && videoTab['group-' + index][index3]}" (click)="videoSelect('expertPlayer', video, 'group-'+index,index3,'00:00')">
                           <span class="c-bio__author-info u-mb-4 u-mr-0">
                              <span
                                class="o-avatar o-avatar--small u-mr-3">
                              <img :src="{{image(video.avatar)}}" class="u-cover" alt=""/>
                              </span><!-- /.o-avatar -->
                              <span class="c-bio__author-int">
                                 <h6
                                   class="h6 c-bio__author-title u-mb-0 u-flex-shrink u-mr-0">{{video.name}}</h6>
                              </span>
                             <!-- /.c-bio__author-int -->
                           </span>
                  <!-- /.c-bio__author-info -->
                  <span class="c-meta__text u-text-400 u-text-grey-medium u-mb-3">{{video.from}}</span>
                  <span class="c-meta u-mb-3">
                              <span class="c-meta__item c-meta__item--first">
                                 <svg role="presentation"
                                      class="o-icon o-icon o-icon--small c-meta__icon">
                                    <use
                                      xlink:href="#icon-time"/>
                                 </svg>
                                 <span
                                   class="c-meta__text"><strong>Watch Time:</strong> {{video.time}}</span>
                              </span>
                    <!-- /.c-meta__item -->
                           </span>
                  <!-- /.c-meta -->
                  <span class="c-meta u-mb-3">
                              <span class="c-meta__item c-meta__item--first">
                                 <span class="c-meta__text"><strong>Available in:</strong></span>
                                 <ul class="c-activity-sidebar__languages">
                                    <li class="c-activity-sidebar__flag"><img src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/English.svg" class="o-icon o-icon--flag"></li>
                                    <li class="c-activity-sidebar__flag" *ngFor="let languages of video.languages" ><img src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/{{languages.language}}.svg" class="o-icon o-icon--flag"></li>
                                 </ul>
                              </span>
                    <!-- /.c-meta__item -->
                           </span>
                  <!-- /.c-meta -->
                  <span class="c-button c-button__video-icon">Watch Video</span>
                </div>
                <!-- /.c-card -->
              </div>
              <!-- /.o-grid__col -->
            </div>
            <!-- /.o-grid -->
          </div>
          <!-- /.o-wrapper -->
        </section>
        <section class="o-section o-section--bg" [ngClass]="templateColor" *ngIf="groups.type === 'satellite'" style="display:block">
          <div class="o-wrapper">
            <div class="js-wistia-wrap-multi">
              <div *ngFor="let video of groups.videos;let index2 = index;">

                <div class="c-wistia c-wistia__multi js-wistia-video-wrap" style="display:block"  *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                  <div
                       class="c-card c-card--flex c-card--row c-card--flex-top c-bio c-card--large s-standard-content u-mb-6">
                           <span class="c-bio__left u-w-full">
                              <span class="c-bio__author-info u-mb-4">
                                 <span class="o-avatar o-avatar--small u-mr-3" *ngIf="video.avatar">
                                    <img
                                      :src="{{image(video.avatar)}}"
                                      class="u-cover" alt=""/>
                                    <span
                                      class="c-activity-sidebar__progress c-activity-sidebar__progress-authors">
                                       <svg role="presentation"
                                            class="o-icon o-icon c-activity-sidebar__progress-icon">
                                          <use
                                            xlink:href="#icon-tick"/>
                                       </svg>
                                    </span>
                                 </span>
                                <!-- /.o-avatar -->
                                 <span>
                                    <span class="c-bio__author-int">
                                       <h6
                                         class="h6 c-bio__author-title u-mb-0 u-flex-shrink js-author-name">{{video.name}}</h6>
                                       <span
                                         class="c-meta__item c-meta__item--first">
                                          <svg role="presentation"
                                               class="o-icon o-icon o-icon--small c-meta__icon">
                                             <use
                                               xlink:href="#icon-time"/>
                                          </svg>
                                          <span class="c-meta__text">
                                          <strong><span
                                            class="c-meta__text--limit">Watch</span> Time: </strong>
                                            {{video.time}}                                                             </span>
                                       </span>
                                    </span>
                                   <!-- /.c-bio__author-int -->
                                    <span
                                      class="c-meta__text u-text-400 u-text-grey-medium js-author-institution u-mr-4">{{video.from}}</span>
                                 </span>
                                <div class="c-language-selector c-language-selector--video js-language-selector" *ngIf="video.languages !== undefined && video.languages.length>0">
                                      <select name="language-selector" id="resources-language-selector" class="c-language-selector__select c-language-selector__select-alt js-language-selector-select js-language-change-videos" #t (change)="videoLanguage(t.value)">
                                          <option [value]="videoLanguageData('expertPlayer', video,'group-'+index,index2,'',false)" selected="">English</option>
                                          <option *ngFor="let languages of video.languages" [value]="videoLanguageData('expertPlayer', video,'group-'+index,index2,languages.language,languages.media_id)">{{languages.language}}</option>
                                      </select><!--.js-language-selector-select -->
                                      <div class="c-language-selector__facade c-card c-card--direction c-card--flex c-card--button u-justify-end">
                                          <div class="c-language-selector__icon c-card__button-icon">
                                              <img src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/English.svg" class="o-icon o-icon--flag c-language-selector__flag c-language-selector__flag__absolute js-language-selector-flag" [ngClass]="{'is-active' : selectedLanguage === 'none'}" >
                                              <img  *ngFor="let languages of video.languages" src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/{{languages.language}}.svg" class="o-icon o-icon--flag c-language-selector__flag c-language-selector__flag__absolute c-language-selector__flag--Swedish js-language-selector-flag" [ngClass]="{'is-active' : selectedLanguage === languages.language}">
                                      </div><!-- /.flag -->
                                          <span class="o-arrow o-arrow--right c-language-selector__arrow-down">
                                              <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-up-arrow"></use></svg>                                                                </span>
                                      </div><!-- /.c-language-selector__facade -->
                                  </div>
                              </span>
                             <!-- /.c-bio__author-info -->
                             <p [innerHTML]="video.description"></p>
                           </span>
                    <!-- /.c-bio__left -->
                  </div>
                  <!-- /.c-card -->
                  <div class="c-wistia js-wistia-video-wrap js-wistia-video-wrap-language is-visible"
                       data-video-id="wljmqhswb1">
                    <div class="c-video-container">

                      <app-player [elementId]="'expertPlayer'" [mediaId]="video.media_id" [title]="video.title"></app-player>

                    </div>
                    <!-- /.c-video-container -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.js-wistia-wrap-multi -->
            <!-- /.o-grid -->
          </div>
          <!-- /.o-wrapper -->
        </section>
        <section class="c-video-section-wrap" [ngClass]="templateColor" *ngIf="groups.type === 'panel'" id="{{ 'group-' + index }}" style="display:block">
          <div class="o-wrapper">
            <div class="c-video-container">
              <app-player [elementId]="'expertPlayer'"></app-player>
            </div>
          </div>
          <!-- /.o-wrapper -->
        </section>
        <section class="o-section o-section--bg" [ngClass]="templateColor" *ngIf="groups.type === 'expertv2'" id="{{ 'group-' + index }}" style="display:block">
          <div class="o-wrapper">
            <div class="c-video-container">
              <app-player [elementId]="'expertPlayer'"></app-player>
            </div>
            <div class="js-wistia-wrap-multi">
              <div *ngFor="let video of groups.videos;let index2 = index;">

                <div class="c-wistia c-wistia__multi js-wistia-video-wrap" style="display:block"  *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                  <div class="c-card c-card--flex c-card--row c-card--flex-top c-bio c-card--large s-standard-content">
                                                                    <span class="c-bio__left">
                                                          <span class="u-hide js-author-name">Video Chapters</span>
                                                                                              <h6 class="h6 c-bio__title js-author-institution">{{video.title}}</h6>
                                                 <p [innerHTML]="video.description"></p>
                  <a class="js-view-bios" (click)="showMenu(1,'js-activity-content-tabs')">view bio and disclosures</a>
                                                                                                                        </span>
                    <span class="c-bio__right"  *ngIf="(index2+1) < totalVideo">
                          <span class="c-card__count">
                              <span class="c-card__count-text"><span class="is-active">{{index2+1}}</span>/{{totalVideo}}</span>
                          </span>
                          <a   (click)="videoSelect('expertPlayer',groups.videos[index2+1], 'group-'+index,index2+1,'00:00')" class="c-button has-icon js-next-video" id="next-video-button">Next Chapter</a>
                   </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="js-wistia-wrap-multi">
              <div *ngFor="let video of groups.videos;let index2 = index;">
                <div class="c-wistia c-wistia__multi js-wistia-video-wrap" style="display:block"  *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                  <!-- /.c-wistia -->
                  <div class="js-tabs u-mt-6" [ngClass]="templateColor" id="js-author-content-tabs">
                    <div class="c-tabs-bar">
                      <div class="o-wrapper c-tabs-bar__wrapper">
                        <ul class="c-tabs-bar__list">
                          <li class="c-tabs-bar__item" (click)="expertTabs(0)">
                            <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : expertTab === 0}"
                                    data-tab-target="#js-tab-author-interview-questions">
                              <span class="c-tabs-bar__text" title="grid-columns">Interview Questions</span>
                            </button>
                          </li>
                        </ul>
                        <div class="c-tabs-bar__fade"></div>
                        <!-- /.c-tabs-bar__fade -->
                        <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta c-tabs-bar__button u-flex-shrink js-overlay-toggle" *ngIf="education.survey"
                           data-overlay=".js-overlay-cme-test" id="take-CME-test-button-banner">Take Survey</a>
                      </div>
                      <!-- /.o-wrapper -->
                    </div>
                    <!-- /.c-tabs-bar -->
                    <div class="c-tabs__tab js-tabs-tab" id="js-tab-author-interview-questions" style="display:block" *ngIf="expertTab === 0" >
                      <div class="c-card c-card--large c-card--rounded-bottom s-standard-content" >
                        <h6 class="h6 u-mb-2 u-text-grey">Interview Questions</h6>
                        <app-readmore [text]="groups.interview_questions !== undefined && groups.interview_questions !== ''? groups.interview_questions : video.interview_questions"></app-readmore>
                      </div>
                      <!-- /.c-card -->
                    </div>
                    <!-- /.c-tabs__tab -->
                  </div>
                  <!-- /.js-tabs -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.o-wrapper -->
        </section>
        <section class="c-video-section-wrap" [ngClass]="templateColor" *ngIf="groups.type === 'panel'" style="display:block">
          <div class="o-wrapper">
            <div class="js-wistia-wrap-multi">
              <div *ngFor="let video of groups.videos;let index2 = index;">

                <div class="c-wistia c-wistia__multi js-wistia-video-wrap" style="display:block"  *ngIf="tabs[index] && videoTab['group-' + index][index2]">
                  <div class="c-card c-card--flex c-card--row c-card--flex-top c-bio c-card--large s-standard-content">
                                                                    <span class="c-bio__left">
                                                          <span class="u-hide js-author-name">Video Chapters</span>
                                                                                              <h6 class="h6 c-bio__title js-author-institution">{{video.title}}</h6>
                                                  <p [innerHTML]="video.description"></p>
                  <a class="js-view-bios" (click)="showMenu(1,'js-activity-content-tabs')">view bio and disclosures</a>
                                                                                                                        </span>
                    <span class="c-bio__right"  *ngIf="(index2+1) < totalVideo">
                          <span class="c-card__count">
                              <span class="c-card__count-text"><span class="is-active">{{index2+1}}</span>/{{totalVideo}}</span>
                          </span>
                          <a   (click)="videoSelect('expertPlayer',groups.videos[index2+1], 'group-'+index,index2+1,'00:00')" class="c-button has-icon js-next-video" id="next-video-button">Next Chapter</a>
                   </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.o-grid -->
          </div>
          <!-- /.o-wrapper -->
        </section>
        <!--touchCONGRESS -->
        <div class="o-wrapper" *ngIf="groups.type === 'congress' && education.is_touchpanel"  style="display:block">
          <div class="c-card c-card--large s-standard-content u-mb-6" [ngClass]="templateColor">
            <div class="o-grid o-grid--full u-mb-0">
              <div class="o-grid__col o-grid__col-7 o-grid__col-12@tm u-mb-0" *ngIf="countPanelist(education.faculty.faculty) === 1">
                <div *ngFor="let faculty of education.faculty.faculty">
                    <span class="c-card__details" *ngIf="faculty.panelist === true">
                       <span class="o-avatar o-avatar--small c-card__col">
                         <img src="{{faculty.image}}" class="u-cover" alt="">
                       </span><!-- /.o-avatar -->
                      <span class="c-card__col c-card__col--name">
                        <h6 class="h6 u-mb-1 u-text-500">{{faculty.name}}</h6>
                        <p class="u-mb-1 c-card__sub-title u-text-small">{{faculty.from}}</p>
                        <p class="u-text-uppercase u-text-grey u-text-500 u-mb-0">{{faculty.chair}}</p>
                      </span><!-- /.c-card__col -->
                    </span><!-- /.c-card__details -->
                </div>
              </div><!-- /.o-grid__col -->
              <div class="o-grid__col o-grid__col-7 o-grid__col-12@tm u-mb-0" *ngIf="countPanelist(education.faculty.faculty) > 1">
                <div>
                    <span class="c-card__details">
                       <span class="o-avatar o-avatar--small c-card__col" *ngFor="let faculty of education.faculty.faculty">
                         <img src="{{faculty.image}}" class="u-cover" alt="" *ngIf="faculty.panelist === true">
                       </span><!-- /.o-avatar -->
                      <span class="c-card__col c-card__col--name">
                        <h6 class="h6 u-mb-1 u-text-500"><span *ngFor="let faculty of education.faculty.faculty;let countFaculty = index"><span *ngIf="faculty.panelist === true">{{faculty.name}}  <span *ngIf="countPanelist(education.faculty.faculty) > (countFaculty+1)"> and </span> </span></span></h6>
                         <p class="u-mb-1 c-card__sub-title u-text-small"><span *ngFor="let faculty of education.faculty.faculty;let countFaculty = index"><span *ngIf="faculty.panelist === true">{{faculty.from}}  <span *ngIf="countPanelist(education.faculty.faculty) > (countFaculty+1)">, </span> </span></span></p>
                        <p class="u-text-uppercase u-text-grey u-text-500 u-mb-0"><span *ngFor="let faculty of education.faculty.faculty;let countFaculty = index">{{faculty.chair}}</span></p>
                      </span><!-- /.c-card__col -->
                    </span><!-- /.c-card__details -->
                </div>
              </div><!-- /.o-grid__col -->
              <div class="o-grid__col o-grid__col-5 o-grid__col-12@tm u-mb-0">
                <h6 class="u-text-grey u-text-500 u-mb-3 c-card__disclosures">Panelists:</h6>
                <span class="c-meta">
                                <span class="c-meta__item u-items-start">
                                    <svg role="presentation" id="" class="o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-avatar"></use></svg>
                                  <span class="c-meta__text">{{countNotPanelist()}}</span>
                                </span><!-- /.c-meta__item -->
                            </span><!-- /.c-meta -->
              </div><!-- /.o-grid__col -->
            </div><!-- /.o-grid -->
          </div><!-- /.c-card -->
        </div>
        <section class="c-video-section-wrap" [ngClass]="templateColor" *ngIf="groups.type === 'congress' && index === 0" id="group-0" style="display:block">
          <div class="o-wrapper">
            <div class="c-video-container">
              <app-player [elementId]="'mainPlayer'"></app-player>
            </div>
          </div>
          <!-- /.o-wrapper -->
        </section>
        <section class="c-video-section-wrap" [ngClass]="templateColor"  *ngIf="groups.type === 'congress'">
          <div class="o-wrapper">
            <div class="js-wistia-wrap-multi">
              <div *ngFor="let video of groups.videos;let index2 = index;" >

                <div class="c-wistia c-wistia__multi js-wistia-video-wrap" style="display:block"  *ngIf="tabs[index] && videoTab['group-' + index][index2] && ('group-' + index) === startedGroup">
                  <div class="c-card c-card--flex c-card--row c-card--flex-top c-bio c-card--large s-standard-content">
                                                                    <span class="c-bio__left">
                                                          <span class="u-hide js-author-name">Video Chapters</span>
                                                                                              <h6 class="h6 c-bio__title js-author-institution">{{video.title}}</h6>
                                                  <p [innerHTML]="video.description"></p>
                  <a class="js-view-bios" (click)="showMenu(1,'js-activity-content-tabs')">view bio and disclosures</a>
                                                                                                                        </span>
                    <span class="c-bio__right"  *ngIf="(index2+1) < groups.videos.length">
                          <span class="c-card__count">
                              <span class="c-card__count-text"><span class="is-active">{{index2+1}}</span>/{{groups.videos.length}}</span>
                          </span>

                   </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.o-grid -->
          </div>
          <!-- /.o-wrapper -->
        </section>
      </div>
      <!-- /.o-section -->
    </section>
    <!-- /.o-section -->
    <div class="js-tabs" [ngClass]="templateColor" id="js-activity-content-tabs">
      <div class="c-tabs-bar">
        <div class="o-wrapper c-tabs-bar__wrapper">
          <ul class="c-tabs-bar__list">
            <li class="c-tabs-bar__item"  (click)="showMenu(0,'js-activity-content-tabs')">
              <button class="c-tabs-bar__tab-button js-tabs-button"   [ngClass]="{'is-active' : menuTab === 0}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Overview <span class="c-tabs-bar__text--limit">& Learning Objectives</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(1,'js-activity-content-tabs')" *ngIf="education.is_faculty">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 1}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Faculty <span class="c-tabs-bar__text--limit">& Disclosures</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(1,'js-activity-content-tabs')" *ngIf="education.is_faculty_about">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 1}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">About <span class="c-tabs-bar__text--limit">{{education.faculty.faculty[0].name}}</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(3,'js-activity-content-tabs')" *ngIf="education.is_reference">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 3}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">References</span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(2,'js-activity-content-tabs')" *ngIf="education.is_download">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 2}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Downloads</span>
              </button>
            </li>
          </ul>
          <div class="c-tabs-bar__fade"></div>
          <!-- /.c-tabs-bar__fade -->
          <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta c-tabs-bar__button u-flex-shrink js-overlay-toggle" *ngIf="education.survey"
             data-overlay=".js-overlay-cme-test" id="take-CME-test-button-banner">Take Survey</a>
        </div>
        <!-- /.o-wrapper -->
      </div>
      <!-- /.c-tabs-bar -->
      <section class="o-section o-section--grey" [ngClass]="templateColor">
        <div class="o-wrapper">
          <div class="c-tabs__tab js-tabs-tab" [ngClass]="templateColor" id="js-tab-learning-objectives" style="display: block;" *ngIf="menuTab === 0">
            <div class="u-pb-global">
              <h6 class="h6 u-text-grey">Overview &amp; Learning Objectives</h6>
              <div class="c-card c-card--large s-standard-content">
                <h6 class="h6 u-mb-2 u-text-grey">Overview</h6>
                <app-readmore *ngIf="education.overview" [text]="education.overview.overview ? education.overview.overview : education.overview"></app-readmore>
              </div>
              <!-- /.c-card -->
            </div>
            <!-- /.o-section -->
            <div class="u-pb-0">
              <div class="c-card c-card--large s-standard-content">
                <h6 class="h6 u-mb-2 u-text-grey">Learning Objectives</h6>
                <app-readmore *ngIf="education.learning_objectives" [text]="education.learning_objectives.learning_objectives ? education.learning_objectives.learning_objectives : education.learning_objectives"></app-readmore>
              </div>
              <!-- /.c-card -->
            </div>
            <!-- /.o-section -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-faculty" style="display: block;" *ngIf="menuTab === 1">
            <div class="u-pb-0" *ngIf="!education.is_faculty_about">
              <h6 class="h6 u-text-grey">Faculty &amp; Disclosures</h6>
            </div><!-- /.u-pb-global -->
            <div class="u-pb-5" *ngFor="let faculty of education.faculty.faculty;let facultyIndex = index">
              <div class="u-pb-0" *ngIf="education.is_faculty_about">
                <h6 class="h6 u-text-grey">About {{faculty.name}}</h6>
              </div><!-- /.u-pb-global -->
              <div class="c-card c-card--large c-card--flex c-card--flex-top c-card--flex-wrap s-standard-content">
                    <span class="c-card__details c-card__details-alt u-mb-4">
                                                    <span class="o-avatar c-card__col">
                                <img src="{{faculty.image}}" class="u-cover" alt="">
                            </span><!-- /.o-avatar -->
                                                <span class="c-card__col c-card__col--name">
                                                            <h6 class="h6 u-mb-2"> {{faculty.name}}</h6>
                                                                                        <p class="u-mb-0 c-card__sub-title"> {{faculty.from}}</p>
                                                    </span>
                    </span>
                <span class="c-card__col c-card__col--last c-card__disclosures">
                    <p><app-readmore [text]="faculty.bio"></app-readmore></p>
                <p><app-readmore [text]="faculty.discloses"></app-readmore></p>

                                                                    </span>
              </div><!-- /.c-card -->
            </div><!-- /.u-pb-global -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-references" style="display: block;" *ngIf="menuTab === 3 && education.is_reference">
            <div class="u-pb-0">
              <h6 class="h6 u-text-grey">References</h6>
            </div><!-- /.u-pb-global -->
            <div class="c-card c-card--large s-standard-content">
              <h6 class="h6 u-mb-2 u-text-grey">References</h6>
              <p [innerHTML]="education.references"></p>
            </div><!-- /.c-card -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-author-downloads" style="display: block;" *ngIf="menuTab === 2">
            <div class="">
              <div *ngIf="education.downloads && education.downloads.downloads && education.downloads.downloads.length > 1">
                <div class="u-pb-global">
                  <h6 class="h6 u-text-grey">Downloads</h6>
                  <div class="c-card c-card--large s-standard-content">
                    <p>View and download resources from this activity to support your learning or share with colleagues.</p>
                  </div><!-- /.c-card -->
                </div><!-- /.o-section -->
                <div class="u-pb-global">
                  <div class="o-grid">
                    <div class="o-grid__col o-grid__col-4 o-grid__col-6@dl o-grid__col-12@tm">
                      <div class="c-language-selector js-language-selector">
                        <label for="resources-language-selector" class="u-hidden">Select Language</label>
                        <select name="language-selector" id="resources-language-selector" class="c-language-selector__select js-language-selector-select"  #p (change)="pdfDownload(p.value)">
                          <option value="uk" selected="">Select Language:</option>
                          <option *ngFor="let download of education.downloads.downloads;let index3 = index" value="{{download.language}}">{{download.language}}</option>
                        </select><!--.js-language-selector-select -->
                        <div class="c-language-selector__facade c-card c-card--direction c-card--flex c-card--button u-justify-end u-mt-3">
                          <div class="c-language-selector__icon c-card__button-icon">
                            <img  *ngFor="let download of education.downloads.downloads;let index3 = index" src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/{{download.language}}.svg" class="o-icon o-icon--flag c-language-selector__flag c-language-selector__flag--{{download.language}} js-language-selector-flag" data-language="{{download.language}}">
                          </div><!-- /.flag -->
                          <span class="o-arrow o-arrow--right c-language-selector__arrow">
                                    <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-up-arrow"></use></svg>                                </span>
                        </div><!-- /.c-language-selector__facade -->
                      </div><!-- /.c-language-selector -->
                    </div><!-- /.o-grid__col -->
                  </div><!-- /.o-grid -->
                </div><!-- /.u-pb-global -->
                <div class="u-pb-0" *ngIf="selectedPdf">
                  <div class="c-tabs__tab js-language-grid" id="js-{{selectedPdfLanguage}}"  [ngStyle]="{'display':(selectedPdfLanguage !== ''?'block':'none')}">
                    <div class="o-grid">
                      <div class="o-grid__col o-grid__col-4 o-grid__col-12@tm">
                        <a href="{{this.selectedPdf}}" class="c-card c-card--direction c-card--flex c-card--button c-card__link" target="_blank">
                          <svg role="presentation" id="" class="o-icon o-icon o-icon--medium o-icon--primary c-card__button-icon"><use xlink:href="#icon-podcast"></use></svg>                                    <span class="c-card__button-text">
                                                                                    Download Slides
                                                                            </span>
                          <span class="o-arrow o-arrow--right c-card__button-arrow">
                                        <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>                                    <span>
                                </span></span></a>
                      </div><!-- /.o-grid__col -->
                    </div><!-- /.o-grid -->
                  </div><!-- /.c-tabs__tab -->
                </div><!-- /.o-section -->
              </div>
              <div *ngIf="pdf && education.downloads.downloads.length === 1">
                <h6 class="h6 u-mb-2 u-text-grey">Downloads</h6>
                <p>View and download resources from this activity to support your learning or share with colleagues.</p>
                <div class="u-pb-0">
                  <div class="o-grid">
                    <div class="o-grid__col o-grid__col-4 o-grid__col-12@tm">
                      <a href="{{this.pdf}}" class="c-card c-card--direction c-card--flex c-card--button c-card__link" target="_blank">
                        <svg role="presentation" id="" class="o-icon o-icon o-icon--medium o-icon--primary c-card__button-icon"><use xlink:href="#icon-podcast"></use></svg>                                        <span class="c-card__button-text">
                                                                                            Download Slides
                                                                                    </span>
                        <span class="o-arrow o-arrow--right c-card__button-arrow">
                                            <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>                                        <span>
                                    </span></span></a>
                    </div><!-- /.o-grid__col -->
                  </div><!-- /.o-grid -->
                </div><!-- /.o-section -->
              </div>
              <div *ngIf="pdf === ''">
                <p>There is no downloadable files.</p>
              </div>
            </div><!-- /.c-card -->
          </div>
          <!-- /.c-tabs__tab -->
        </div>
        <!-- /.o-wrapper -->
      </section>
      <!-- /.o-section -->
    </div>
    <!-- /.js-tabs -->
  </main>
  <!-- /.c-register-bar -->
  <div class="o-wrapper">
    <footer class="c-site-footer">
      <div class="c-site-footer__top">
               <span class="c-site-footer__left">
                  <h6 class="h6 c-site-footer__title" *ngIf="education.footer_fundedby">This Activity Is Funded By:</h6>
                 <div *ngIf="education.footer_fundedby" [innerHTML]="education.footer_fundedby.footer_fundedby ? education.footer_fundedby.footer_fundedby : education.footer_fundedby"></div>
               </span>
        <span class="c-site-footer__right u-flex u-items-center" *ngIf="education.footer_images && education.footer_images.images">

               <img *ngFor="let image of education.footer_images.images" [src]="image.url" class="c-site-footer__logo u-mr-6" />

        </span><!-- /.c-site-footer__right -->
      </div>
      <!-- /.c-site-footer__top -->
      <div class="c-site-footer__bottom" *ngIf="education.footer_text">
        <div [innerHTML]="education.footer_text.footer_text ? education.footer_text.footer_text : education.footer_text"></div>
      </div>
      <div class="c-site-footer__bottom" *ngIf="education.footer_disclaimer">
        <div [innerHTML]="education.footer_disclaimer.footer_disclaimer ? education.footer_disclaimer.footer_disclaimer : education.footer_disclaimer"></div>
      </div>
      <!-- /.c-site-footer__bottom -->
    </footer>
    <!-- /.c-site-footer -->
  </div>
  <!-- /.o-wrapper -->
  <div class="c-hcp-banner">
    <div class="o-wrapper c-hcp-banner__wrapper">
      <p>This content is intended for healthcare professionals only. Please confirm that you are a healthcare
        professional.
      </p>
      <a href="#" class="js-hcp-accept c-button c-button--ghost c-hcp-banner__button" id="cookie-bar-accept">Accept</a>
      <a href="../../../index.html" class="js-hcp-decline c-button c-button--ghost c-hcp-banner__button" id="cookie-bar-decline">Decline</a>
    </div>
    <!-- /.o-wrapper -->
  </div>
  <!-- /.c-hcp-banner -->
</div>
<!-- /.c-activity-container -->
<!-- Load SVG template file -->
<!-- Load SVG template file -->
<app-education-svg style="display:none"></app-education-svg>

</body>
