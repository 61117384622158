import {Component, ElementRef, Output, EventEmitter, OnInit, ViewChild, AfterViewInit, Input} from '@angular/core';
import {GoogleMap} from '@angular/google-maps';
import {DataSendingService} from '../../services/data-sending.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements AfterViewInit {
  @ViewChild('searchField') searchField: ElementRef;
  @Input() model: string;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  public address: {} = '';

  constructor(protected dataSendingService: DataSendingService) {

  }

  /**
   * Get Address format
   * @param place
   */
  /**
   * Get Address format
   * @param place
   */
  public getAddress(place): {} {
    let location = {};
    location['name'] = place.name || '';
    // tslint:disable-next-line:forin
    for (let i in place.address_components) {
      let item = place.address_components[i];

      location['formatted_address'] = place.formatted_address;
      if (item['types'].indexOf('locality') > -1) {
        location['locality'] = item['long_name'];
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
        location['admin_area_l1'] = item['short_name'];
      } else if (item['types'].indexOf('street_number') > -1) {
        location['street_number'] = item['short_name'];
      } else if (item['types'].indexOf('route') > -1) {
        location['route'] = item['long_name'];
      } else if (item['types'].indexOf('country') > -1) {
        location['country'] = item['long_name'];
        location['country_short'] = item['short_name'];
      } else if (item['types'].indexOf('postal_code') > -1) {
        location['postal_code'] = item['short_name'];
      }

    }

    return location;
  }

  /**
   * Setup autocomplete search
   */
  ngAfterViewInit(): void {
    const options = {
      strictBounds: false,
      fields: ['name', 'formatted_address', 'address_components'],
      types: ['establishment'],
    };

    const autocomplete = new google.maps.places.Autocomplete(this.searchField.nativeElement, options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.address = this.getAddress(autocomplete.getPlace());
      this.onSelect.emit(this.address);
      this.send();
    });
  }

  public send(): void {
    this.dataSendingService.sendData(this.model, this.address);
  }

  public mapCheck(event: FocusEvent): void {
    setTimeout(() => {
      this.send();
    }, 150);
  }
}
