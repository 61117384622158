import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../base.component';
import {environmentProd} from '@environments/environment';
import {UserTokenService} from '@services/user-tokens.service';
import {HelperService} from '@services/helper.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {PopupComponent} from '../fields/popup/popup.component';
import {AuthService} from '@services/auth.service';
import {DataSendingService} from '@services/data-sending.service';
import {AnalyticsService} from '@services/analytics.service';
import {Api2Service} from '@services/api2.service';

@Component({
  selector: 'app-last-step',
  templateUrl: './last-step.component.html',
  styleUrls: ['./last-step.component.scss']
})
export class LastStepComponent extends BaseComponent implements OnInit {
  public user: any;
  public logo = '';
  public post = false;
  public pageTitle = 'Last Step';
  public profileDoc: string;
  public site: string = environmentProd.site;
  public siteUrl: string = environmentProd.url;
  public postLoader = false;
  public error = false;
  private firstname = '';
  private lastname = '';
  private middlename = '';
  private pwork: any = '';
  private country = '';
  private specialty: any = '';
  private title = '';

  constructor(public dialog: MatDialog,
              private auth: AuthService,
              protected dataSendingService: DataSendingService,
              private api2Service: Api2Service,
              private analytics: AnalyticsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userSet();
  }

  protected userSet(): void {
    this.user = this.auth.currentUser();

    this.subscribe(this.dataSendingService.getData('field_firstname'), (result) => {
      this.firstname = result;
    });

    this.subscribe(this.dataSendingService.getData('field_title'), (result) => {
      this.title = result;
    });

    this.subscribe(this.dataSendingService.getData('field_lastname'), (result) => {
      this.lastname = result;
    });

    this.subscribe(this.dataSendingService.getData('field_middlename'), (result) => {
      this.middlename = result;
    });

    this.subscribe(this.dataSendingService.getData('field_specialty'), (result) => {
      this.specialty = result;
    });

    this.subscribe(this.dataSendingService.getData('field_pwork'), (result) => {
      this.country = result.address.country;
      this.pwork = result.address.name + ' (' + result.address.formatted_address + ')';
    });
  }


  /**
   * Send Post to api
   */
  public sendPost(): void {
    this.error = false;
    // tslint:disable-next-line:max-line-length
    if (this.firstname === '' || this.lastname === '' || this.specialty === '' || this.pwork === '' || this.title === '') {
      this.error = true;
      return;
    }

    const review = {
      first_name: this.firstname,
      middle_name: this.middlename ?? '',
      last_name: this.lastname,
      title: this.title,
      specialty: this.specialty,
      pwork: this.pwork,
      created_at: Date.now(),
      status: 'review',
      country: this.country
    };

    this.api2Service.review(review).then(t => {
      this.api2Service.refreshUser().then(r => {
        this.analytics.addEvent('last-step-review', {
          email: this.user.email,
        });

        this.dialog.open(PopupComponent, {
          width: '600px',
          height: '600px',
          data: {
            icon: 'nuaxia:success',
            title: 'Thank you for registering!',
            description: '<p>Our Member Services Manager will approve your profile within one business day.</p><p>We will send you an email once your account has been activated.</p><p>In rare cases, when we are unable to verify your profile, we might need to contact you for additional information.</p>',
            loader: false,
            button: 'activities',
            button_text: 'Open Activities',
          },
        });
      });
    });
  }
}
