import { Component, OnInit } from '@angular/core';
import { EducationComponent } from '../education.component';

@Component({
  selector: 'app-touch-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['../education.component.scss']
})
export class EducationExpertComponent extends EducationComponent implements OnInit {
  public ngOnInit(): void {
    super.ngOnInit();
  }

}
