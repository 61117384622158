import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from '@services/analytics.service';
import { Api2Service } from '@services/api2.service';
import { ActivatedRoute, Router } from '@angular/router';
import { redirectLoggedInTo } from '@angular/fire/auth-guard';
import {DeviceService} from '@services/device.service';

@Component({
  selector: 'app-check-your-email-modal',
  templateUrl: './check-your-email-modal.component.html'
})
export class CheckYourEmailModalComponent implements OnInit{

  public error = undefined;
  public success = undefined;
  public loader = false;
  public post = false;
  public resendButton = 'Re-send link';
  public openButton = 'Open Email App';
  public resendButtonName = 'Re-send link';
  public resendDisabled = false;
  public countdown = 60; // Initialize countdown
  public timeoutId: any = null;

  constructor(public dialogRef: MatDialogRef<CheckYourEmailModalComponent>,
              protected analytics: AnalyticsService,
              protected api2Service: Api2Service,
              protected router: Router,
              public deviceService: DeviceService,
              @Inject(MAT_DIALOG_DATA) public data: {email: string, redirectToLogin: boolean}
  ){}

  ngOnInit(): void {
    if (this.router.url.includes('/auth/')) {
      this.data.redirectToLogin = true;
    }
  }

  private updateCountdown():void {
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }

    this.timeoutId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
        this.resendButton = `Re-send after (${this.countdown} s)`;
      } else {
        clearInterval(this.timeoutId);
        this.resendDisabled = false;
        this.loader = false;
        this.timeoutId = null;
        this.resendButton = this.resendButtonName; // Reset to original text
      }
    }, 1000);
  }
  public close(): void {
    this.dialogRef.close();
    if (this.data.redirectToLogin === true) {
      this.backToLogin();
    }
  }

  public backToLogin(): void {
    this.router.navigateByUrl('/');
    this.dialogRef.close();
  }

  /**
   * Open email app
   */
  public openEmail(): void {

  }

  public async resendEmail(): Promise<void> {
    try {

      this.resendDisabled = true;
      this.countdown = 60; // Start with 60 seconds countdown
      this.resendButton = `Re-send after (${this.countdown} s)`;

      this.updateCountdown();

      this.analytics.addEvent('login_try', {
        emailOriginal: this.data.email,
      });

      const result = await this.api2Service.login(this.data.email);

      if (result.status === 200) {
        if (result.data.is_deleted === false) {
          this.analytics.addEvent('login_deleted', {
            emailOriginal: this.data.email,
          });
          this.stopLogin('Your account deleted.');
        }

        this.analytics.addEvent('login_with_magic_link', {
          emailOriginal: this.data.email,
        });
      } else {
        this.analytics.addEvent('login_failed', {
          emailOriginal: this.data.email,
          error: result.data.exception
        });
        this.stopLogin('Sorry, we could not log you in. Please contact us.');
      }
    } catch (e) {
      if (e.message) {
        this.error = e.message;
      } else {
        this.error = 'Sorry, we could not log you in. Please contact us.';
      }

      this.analytics.addEvent('login_failed', {
        emailOriginal: this.data.email,
        error: e.message
      });
      this.loader = false;
    }
  }

  public stopLogin(message: string): void {
    this.error = message;
    this.loader = false;
  }

  protected readonly redirectLoggedInTo = redirectLoggedInTo;
}
