<div class="flex flex-col flex-auto min-w-0">

  <mat-drawer-container class="flex-auto sm:h-full">
    <!-- Drawer content -->
    <mat-drawer-content class="flex flex-col">

      <!-- Main -->
      <div class="flex flex-col flex-auto w-full mx-auto">

        <!-- Panel header -->
        <div class="flex items-center">
          <!-- Panel title -->
          <div class="lg:ml-0 text-3xl font-bold tracking-tight leading-none" [attr.data-testId]="'tab-' + tabInfo(selectedPanel).title | lowercase">
            {{tabInfo(selectedPanel).title}}
          </div>
        </div>
        <div class="mt-8" *ngIf="showInactiveProfile">
          <ng-container>
            <div class="text-center mt-3" data-testId="registration-pending-info">
              Your registration is pending approval, we will be in touch soon about activating your account.
              Thank you for your patience.
            </div>
          </ng-container>
        </div>
        <div class="mt-8" *ngIf="!showInactiveProfile && showProfileReview">
          <ng-container>
            <div class="text-center mt-3" data-testId="review-info">
              Your profile under review. Thank you for your patience.
            </div>
          </ng-container>
        </div>
        <!-- Load settings panel -->
        <div class="mt-8" *ngIf="!showInactiveProfile && this.user">
          <ng-container>
            <!-- Profile -->
            <ng-container *ngIf="selectedPanel === 'profile'">
              <div class="w-full">
                <!-- Form -->
                <form [formGroup]="form">

                  <!-- Section -->
                  <div class="w-full" data-testId="qualification-fields">
                    <div class="text-xl">Qualification</div>
                  </div>
                  <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">

                    <!-- Registration number -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Registration number</mat-label>
                        <mat-icon
                          class="icon-size-5"
                          [svgIcon]="'heroicons_solid:user'"
                          matPrefix></mat-icon>
                        <input
                          [formControlName]="'registration_number'"
                          matInput placeholder="Enter your registration number">
                      </mat-form-field>
                    </div>
                    <!-- Primary specialty  -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Primary specialty</mat-label>
                        <mat-chip-list  #specialtyList aria-label="Title">
                          <mat-chip *ngIf="this.specialty"
                            [selectable]="specialtySelectable"
                            [removable]="specialtyRemovable"
                            (removed)="specialtyRemove()">
                            {{specialtyName(this.specialty)}}
                            <mat-icon matChipRemove *ngIf="specialtyRemovable">cancel</mat-icon>
                          </mat-chip>
                          <input *ngIf="specialtyList" placeholder="Add or update your primary specialty"
                                 #specialtyInput
                                 [formControl]="specialtyCtrl"
                                 [matAutocomplete]="specialtyAutocomplete"
                                 [matChipInputFor]="specialtyList"
                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 (matChipInputTokenEnd)="specialtyAdd($event)"
                                 (blur)="specialtyCheck($event)">
                        </mat-chip-list>
                        <mat-autocomplete #specialtyAutocomplete="matAutocomplete" (optionSelected)="specialtySelect($event)">
                          <mat-option *ngFor="let spec4 of specialtyFiltered | async" [value]="spec4">
                            {{specialtyName(spec4)}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <span *ngIf="specialtyValidation">
                          <mat-error *ngIf="specialtyValidation">Specialty is required.</mat-error>
                      </span>
                    </div>
                    <!-- Secondary specialty -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Additional specialties</mat-label>
                        <mat-chip-list #secondaryList aria-label="Select your additional specialties">
                          <mat-chip
                            *ngFor="let spec2 of secondarySpecialties"
                            [selectable]="secondarySpecialtySelectable"
                            [removable]="secondarySpecialtyRemovable"
                            (removed)="secondarySpecialtyRemove(spec2)">
                            {{specialtyName(spec2)}}
                            <mat-icon matChipRemove *ngIf="secondarySpecialtyRemovable">cancel</mat-icon>
                          </mat-chip>
                          <input *ngIf="secondaryList" placeholder="Add or update any additional specialties"
                            #secondarySpecialtyInput
                            [formControl]="secondarySpecialtyCtrl"
                            [matAutocomplete]="auto2"
                            [matChipInputFor]="secondaryList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="secondarySpecialtyAdd($event)"
                            (blur)="secondarySpecialtyCheck($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="secondarySpecialtySelect($event)">
                          <mat-option *ngFor="let spec3 of secondarySpecialtyFiltered | async" [value]="spec3">
                            {{specialtyName(spec3)}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <!-- Medical school  -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Medical school</mat-label>
                        <mat-chip-list #schoolList aria-label="Medical school">
                          <mat-chip *ngIf="this.schools"
                                    [selectable]="schoolSelectable"
                                    [removable]="schoolRemovable"
                                    (removed)="schoolRemove()" style="height:100%">
                            {{this.schools}}
                            <mat-icon matChipRemove *ngIf="schoolRemovable">cancel</mat-icon>
                          </mat-chip>
                          <input *ngIf="schoolList" placeholder="Click to add medical school"
                                 #schoolInput
                                 [formControl]="schoolCtrl"
                                 [matAutocomplete]="schoolAutocomplete"
                                 [matChipInputFor]="schoolList"
                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 (matChipInputTokenEnd)="schoolAdd($event)"
                                  (blur)="schoolCheck($event)">
                        </mat-chip-list>
                        <mat-autocomplete #schoolAutocomplete="matAutocomplete" (optionSelected)="schoolSelect($event)">
                          <mat-option *ngFor="let school2 of schoolFiltered | async" [value]="school2">
                            {{school2}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <!-- Year of qualification -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Year of qualification</mat-label>
                        <mat-chip-list #qualificationList aria-label="Year of qualification">
                          <input type="number" [maxLength]="4" [minLength]="4" *ngIf="qualificationList" placeholder="Select year of qualification"
                                 #qualificationInput
                                 [value]="this.qualification"
                                 [formControl]="qualificationCtrl"
                                 [matAutocomplete]="qualificationAutocomplete"
                                 [matChipInputFor]="qualificationList"
                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 (matChipInputTokenEnd)="qualificationAdd($event)"
                                 (blur)="qualificationCheck($event)">
                        </mat-chip-list>
                        <mat-autocomplete  #qualificationAutocomplete="matAutocomplete" (optionSelected)="qualificationSelect($event)">
                          <mat-option *ngFor="let year of this.qualificationAll" [value]="year">
                            {{year}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>

                  <!-- Divider -->
                  <div class="my-10 border-t"></div>

                  <!-- Section -->
                  <div class="w-full" data-testId="work-fields">
                    <div class="text-xl">Place of work</div>
                    <div class="text-secondary">If you practice at multiple locations, you can add each of them</div>
                  </div>
                  <!-- Place of work -->
                  <app-google-map model="selectedAddress"></app-google-map>
                  <div class="sm:col-span-4">
                    <mat-form-field class="fuse-mat-no-subscript w-full mt-3" *ngIf="userPlaces.length>0">
                      <mat-chip-list aria-label="Place of work">
                        <mat-chip *ngFor="let place of userPlaces; let i=index" (removed)="placeRemove(i,place)" style="height:100%">{{place}}<mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                  <!-- Divider -->
                  <div class="my-10 border-t"></div>
                  <!-- Section -->
                  <div class="w-full" data-testId="contact-fields">
                    <div class="text-xl">Contact details</div>
                  </div>
                  <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                    <!-- Title  -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Title</mat-label>
                        <mat-chip-list #titleList aria-label="Title">
                          <input *ngIf="titleList" placeholder="Select or add your own"
                                 #titleInput
                                 [value]="this.titles"
                                 [formControl]="titleCtrl"
                                 [matAutocomplete]="titleAutocomplete"
                                 [matChipInputFor]="titleList"
                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 (matChipInputTokenEnd)="titleAdd($event)"
                                 (blur)="titleCheck($event)">
                        </mat-chip-list>
                        <mat-autocomplete #titleAutocomplete="matAutocomplete" (optionSelected)="titleSelect($event)">
                          <mat-option *ngFor="let title2 of titlesAll" [value]="title2">
                            {{title2}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <!-- Firstname -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>First Name</mat-label>
                        <mat-icon
                          class="icon-size-5"
                          [svgIcon]="'heroicons_solid:user'"
                          matPrefix></mat-icon>
                        <input
                          [formControlName]="'firstname'"
                          matInput  placeholder="Enter your First Name">
                      </mat-form-field>
                      <span *ngIf="post && fields.firstname.errors">
                          <mat-error *ngIf="fields.firstname.errors.required">First Name is required.</mat-error>
                      </span>
                    </div>
                    <!-- Middlename -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Middle Name(s)</mat-label>
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'" matPrefix></mat-icon>
                        <input [formControlName]="'middle_name'" matInput>
                      </mat-form-field>
                    </div>
                    <!-- Lastname -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Last Name</mat-label>
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'" matPrefix></mat-icon>
                        <input [formControlName]="'lastname'" matInput placeholder="Enter your Last Name">
                      </mat-form-field>
                      <span *ngIf="post && fields.lastname.errors">
                          <mat-error *ngIf="fields.lastname.errors.required">Last Name is required.</mat-error>
                      </span>
                    </div>
                    <!-- Primary Email -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Primary Email</mat-label>
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:mail'" matPrefix></mat-icon>
                        <input disabled matInput [value]="this.user.email" appLowercase>
                      </mat-form-field>
                    </div>
                    <!-- Secondary Email -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Secondary Email</mat-label>
                        <mat-icon
                          class="icon-size-5"
                          [svgIcon]="'heroicons_solid:mail'"
                          matPrefix></mat-icon>
                        <input [formControlName]="'email_secondary'"  matInput appLowercase>
                      </mat-form-field>
                    </div>
                </div>
                  <!-- Divider -->
                  <div class="mt-11 mb-10 border-t"></div>

                  <!-- Actions -->
                  <div class="flex items-center justify-center">
                    <button
                      class="ml-4 w-100 nuaxia-button"
                      mat-flat-button
                      data-testId="profile-save-button"
                      type="button"
                      (click)="userUpdate()"
                      [color]="'primary'">Save Profile
                    </button>
                  </div>
                </form>
              </div>
            </ng-container>
            <!-- Notifications -->
            <ng-container *ngIf="selectedPanel === 'account'">
              <div class="w-full">
                <!-- Form -->
                <form [formGroup]="form">

                  <!-- Section -->
                  <div class="w-full" data-testId="login-fields">
                    <div class="text-xl">Login details</div>
                  </div>

                  <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                    <!-- Email -->
                    <div class="sm:col-span-4">
                      <mat-form-field class="fuse-mat-no-subscript w-full">
                        <mat-label>Email</mat-label>
                        <mat-icon
                          class="icon-size-5"
                          [svgIcon]="'heroicons_solid:mail'"
                          matPrefix></mat-icon>
                        <input disabled [value]="this.user.email" matInput>
                      </mat-form-field>

                      <div class="text-secondary">If you wish to change your login Email address, please contact us via the <a class="nuaxia-lato nuaxia-button-text" href="/support" data-testId="help-link">Help</a> menu point.</div>
                    </div>
                  </div>

                  <!-- Divider -->
                  <div class="mt-11 mb-10 border-t"></div>
                </form>
              </div>
              <!-- Two factor -->
              <!--<div class="w-full" data-testId="two-factor-field-">
                <div class="w-full text-xl">Two Factor</div>
                <div class="grid grid-cols-1 gap-6 w-full mt-8">
                <div class="sm:col-span-4">
                    <div class="flex items-center justify-between">
                      <div class="flex-auto cursor-pointer" (change)="twofactorToggle($event)" style="max-width:80%">
                        <div class="leading-6 font-medium">Enable two-factor authentication</div>
                        <div class="text-md text-secondary">You will receive a test message</div>
                      </div>
                      <div class="flex-auto">
                        <mat-slide-toggle
                          class="ml-2"
                          [color]="'primary'"
                          [ngModel]="is2Auth" [checked]="is2Auth" (change)="twofactorToggle($event)"
                          data-testId="two-factor-toggle"
                        >
                        </mat-slide-toggle>
                      </div>
                    </div>
                    <mat-form-field class="fuse-mat-no-subscript w-full" *ngIf="is2Auth">
                      <mat-label>Mobile number</mat-label>
                      <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:phone'" placeholder="Enter your mobile/cell number"
                        matPrefix></mat-icon>
                      <input type="number" [(ngModel)]="phone" matInput>
                    </mat-form-field>
                  <div [hidden]="!is2Auth || !is2AuthValid">
                    <div style="display:flex">
                      <div class="code-box">
                        <mat-form-field appearance="standard" class="w-45">
                          <input type="number" id="code" [(ngModel)]="code" class="custom-code" matInput  min="0" max="9"  maxlength="1" tabindex="1" appNexttab="1" appMaxlength="1"/>
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="w-45">
                          <input type="number" id="code1" [(ngModel)]="code1" class="custom-code" matInput min="0" max="9"  maxlength="1" tabindex="2" appNexttab="2" appMaxlength="1"/>
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="w-45">
                          <input type="number" id="code2" [(ngModel)]="code2" class="custom-code" matInput min="0" max="9" maxlength="1" tabindex="3" appNexttab="3" appMaxlength="1"/>
                        </mat-form-field>
                      </div>
                      <div class="code-box-separator">
                        —
                      </div>
                      <div class="code-box">
                        <mat-form-field appearance="standard"  class="w-45">
                          <input type="number" id="code3" [(ngModel)]="code3" class="custom-code" matInput min="0" maxlength="1" tabindex="4" appNexttab="4" appMaxlength="1"/>
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="w-45">
                          <input type="number" id="code4" [(ngModel)]="code4" class="custom-code" matInput min="0" maxlength="1" tabindex="5" appNexttab="5" appMaxlength="1"/>
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="w-45">
                          <input type="number" id="code5" [(ngModel)]="code5" class="custom-code" matInput min="0" maxlength="1" tabindex="6" appNexttab="6" appMaxlength="1"/>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="flex items-center justify-end mt-3">

                    <button class="ml-4 nuaxia-button"  mat-flat-button [color]="'primary'" *ngIf="is2Auth && !is2AuthValid" (click)="twofactorValidate()" #tooltip="matTooltip" matTooltip="Validate your phonenumber">
                      <mat-icon *ngIf="twofactorValidateLoader"><mat-spinner diameter="20">
                      </mat-spinner></mat-icon><span *ngIf="!twofactorValidateLoader">Validate your phone number</span>
                    </button>
                    <button  class="ml-4 nuaxia-button" mat-flat-button [color]="'primary'" *ngIf="is2Auth && is2AuthValid" (click)="twofactorSave()" #tooltip="matTooltip" matTooltip="Save Two factor">
                      <mat-icon *ngIf="twofactorSaveLoader"><mat-spinner diameter="20">
                      </mat-spinner></mat-icon>  <span *ngIf="twofactorSaveLoader">Saving...</span> <span *ngIf="!twofactorSaveLoader">Save Two Factor</span>
                    </button>
                  </div>
                  </div>
                </div>
              </div>-->
              <!-- Two factor END -->
             <!-- <div class="mt-11 mb-10 border-t"></div>-->
              <!-- Logout  -->
              <div class="w-full" data-testId="logout-fields">
                <!-- Form -->
                <!-- Section -->
                <div class="w-full text-xl">Logout</div>
                <div class="grid grid-cols-1 gap-6 w-full mt-8">
                  <!-- Log out -->
                  <div class="flex items-center justify-between">
                    <div class="flex-auto cursor-pointer" data-testId="logout-info">
                      <div class="text-md text-secondary">We encourage users to stay logged in, so they receive notifications about our activities</div>
                    </div>
                    <button mat-flat-button [color]="'primary'" class="nuaxia-button"  (click)="logout()" #tooltip="matTooltip" matTooltip="Sign out" data-testId="logout-button">Log out</button>
                  </div>
                </div>
              </div>
              <div class="mt-11 mb-10 border-t"></div>
              <!-- Delete profile section -->
              <div class="w-full" data-testId="deletion-fields">
                <!-- Form -->
                <!-- Section -->
                <div class="w-full text-xl">Account & Profile delete</div>
                <div class="grid grid-cols-1 gap-6 w-full mt-8">
                  <!-- Delete profile section -->
                  <div class="flex items-center justify-between">
                    <div class="flex-auto cursor-pointer" data-testId="deletion-warning">
                      <div class="text-md text-secondary">If you choose to delete your account and profile, please be aware that all associated data will be permanently lost.</div>
                    </div>
                    <button class="ml-4 nuaxia-button" mat-flat-button type="button" (click)="userDelete()" [color]="'primary'" #tooltip="matTooltip" matTooltip="Delete profile" data-testId="delete-profile-button">Delete</button>
                  </div>
                </div>
              </div>
              <!-- Delete profile section END -->
              <!-- Logout END -->
            </ng-container>

            <ng-container *ngIf="selectedPanel==='notifications'">
              <app-user-notifications [profileData]="profileData"></app-user-notifications>
            </ng-container>

            <ng-container *ngIf="selectedPanel==='events' && profileData">
              <app-user-events [userProfile]="profileData"></app-user-events>
          </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

