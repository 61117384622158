<div class="useful-tips-wrap">
  <div class="useful-tips">
    <div class="useful-tips-header"  (click)="toggleTips()">
      <a>Useful tips</a>
      <i class="fa-solid fa-chevron-down"></i>
    </div>
    <ul class="useful-tips-content" [ngClass]="{'open': tipsOpen === true}">
      <li>
        <i class="fa-solid fa-download"></i>
        <span>Downloads including slides are available for this activity in the <a class="useful-tips-toolkit" (click)="goTo('toolkit')">Toolkit</a></span>
      </li>

    </ul>
  </div>
</div>
