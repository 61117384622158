import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';
import {DataSendingService} from '../../services/data-sending.service';
import {Video} from '../../interfaces/video.interface';

declare var jwplayer: any;
  
  @Component({
    selector: 'app-education-player',
    templateUrl: './education-player.component.html',
    styleUrls: ['./education-player.component.css']
  })
  export class EducationPlayerComponent extends BaseComponent implements OnInit {
    protected firstLoaded = true;
    @Input() elementId: string;
    @Input() mediaId: string;
    protected player: any;
  
  
    constructor(protected dataSendingService: DataSendingService) {
      super();

    }
  
    ngOnInit(): void {
      setTimeout(() => {
        this.setupPlayer();
      }, 2);
    }
  
    /**
     * Setting up the jwPlayer
     * @param video
     * @param time
     * @param language
     * @protected
     */
    protected setupPlayer(): void {
      const config = {
        title: 'No title',
        playlist: `https://cdn.nuaxia.com/v2/media/${this.mediaId}`,
        aspectratio: '16:9',
        mute: false,
        autostart: false,
        primary: 'html5'
      };
  
      this.player = jwplayer(this.elementId).setup(config);
      
      // Broadcast video time
      this.player.on('time', event => {
        this.dataSendingService.sendData('educationVideoTime', event);
      });
  
      this.player.on('play', () => {
        this.dataSendingService.sendData('educationVideoStarted', {playerId: this.elementId});
      });
    }
  
    protected timeToSec(time: string): number {
      const timeArray = time.split(':'); // split it at the colons
      return (+timeArray[0]) * 60 + (+timeArray[1]);
    }
  }
  