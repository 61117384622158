import { Component, OnInit } from '@angular/core';
import { EducationComponent } from '../education.component';

@Component({
  selector: 'app-touch-congress',
  templateUrl: './congress.component.html',
  styleUrls: ['../education.component.scss']
})
export class EducationCongressComponent extends EducationComponent implements OnInit {

  public ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Set Group template
   */
  public setGroupTemplate(): void {
    this.template = 'expert';
  }
}
