import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { EventNotificationsService } from '@services/event-notifications.service';
import { CalendarEvent } from '../../../interfaces/eventNotification.interface';
import { CalendarOptions } from '@fullcalendar/angular';
import { DataSendingService } from '@services/data-sending.service';

@Component({
  selector: 'app-user-events',
  templateUrl: './user-events.component.html'
})
export class UserEventsComponent extends BaseComponent implements OnInit {

  @Input() userProfile;
  public events: CalendarEvent[] = [];
  public calendarOptions: CalendarOptions;
  public showCalendar = false;

  constructor(protected eventNotificationService: EventNotificationsService,
              protected dataSendingService: DataSendingService) {
    super();
  }

  ngOnInit(): void {
    const notificationSettings = this.getSavedNotifications();
    this.subscribeToEvents(notificationSettings);
    this.setOptions();
  }

  protected setOptions(): void {
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      eventTimeFormat: {
        meridiem: false,
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }
    };
  }

  /**
   * Getting the notification types, the user has a saved notification setting for
   * @protected
   */
  protected getSavedNotifications(): string[] {
    const result = [];
    const notifications = this.userProfile && this.userProfile.notifications;
    if (!notifications) {
      return result;
    }
    const notificationTypes = Object.keys(this.userProfile.notifications);

    notificationTypes.forEach(notificationType => {
      if (notifications[notificationType].email === true ||
        notifications[notificationType].push === true) {
        result.push(notificationType);
      }
    });

    return result;
  }

  protected subscribeToEvents(notificationTypes: string[]): void {
    if (notificationTypes.length > 0) {
      this.subscribe(this.eventNotificationService.getEveryDocumentData(query =>
        query.where('category', 'in', notificationTypes)), (result) => {
        this.showCalendar = false;
        result.forEach(currentEvent => {
          const newEvent = {
            id: currentEvent.id,
            title: currentEvent.data.event_name,
            start: currentEvent.data.event_time
          };
          this.events.push(newEvent);
        });
        // notifying the calendar to re-render
        this.dataSendingService.sendData('renderCalendar', 'eventsCalendar');
        this.showCalendar = true;
      });
    }
  }

}
