import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { EventNotification } from '../interfaces/eventNotification.interface';

@Injectable()
export class EventNotificationsService extends FirebaseService {

  protected dbPath = '/event_notifications';

}
