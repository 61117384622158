  <div class="flex flex-auto w-full p-2" *ngIf="activity">
    <div class="flex flex-col flex-auto order-2 md:order-1">

      <div class="flex items-center leading-none mt-2 -ml-1 pb-2">
        <div class="flex items-center">
          <!-- Course category -->
          <ng-container>
            <div
              class="py-0.5 px-3 rounded-full text-sm font-semibold nuaxia-card-tags-surveys">
              {{activity.tags}}
            </div>
          </ng-container>
        </div>
      </div>
      <div>
        <!-- Course completion -->
        <div class="font-semibold leading-tight nuaxia-card-description">{{activity.title}}</div>
      </div>

    </div>
    <div class="w-full md:w-40 rounded-lg overflow-hidden image-container">
      <img
        class="w-24 h-24 rounded-2xl object-cover nuaxia-bg-card-image"
        src="{{image(activity.image)}}"
        alt="{{activity.title}} image">
    </div>
  </div>
  <!-- Footer -->
  <div class="flex flex-col w-full mt-auto">
    <!-- Course progress -->
    <div class="p-2 text-right dark:bg-transparent flex justify-between max-40-height">
      <div class="flex lh-17">
        <span *ngIf="activity.card_left_icon" [innerHTML]="betterHtml(activity.card_left_icon)" class="max-40-height"></span>
        <span *ngIf="activity.card_left_text" [innerHTML]="betterHtml(activity.card_left_text)" class="pl-2 max-40-height"></span>
      </div>
      <div class="honoraria flex lh-17">
        <span *ngIf="activity.card_right_icon" [innerHTML]="betterHtml(activity.card_right_icon)" class="max-40-height"></span>
        <span *ngIf="activity.card_right_text" [innerHTML]="betterHtml(activity.card_right_text)" class="pl-2 max-40-height"></span>
      </div>
    </div>
  </div>
