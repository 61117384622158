
import { Environment as EnvironmentType } from '@environments/enviroment.interface';

export const EnvDevNuaxia: EnvironmentType = {
  site: 'nuaxia',
  title: 'DEV nuaxia Member Portal',
  url: 'https://test.app.nuaxia.com/',
  description: 'The healthcare information technology platform which empowers you to\n' + 'Learn, Earn and Grow Your Professional Network',
  logo: 'assets/images/nuaxia/logo-300x300-nuaxia.png',
  logoWidth: '120px',
  favicon: 'assets/images/nuaxia/icon-32x32.png',
  honorariaLink: 'https://www.nuaxia.com/honoraria-',
  privacyLink: 'https://www.nuaxia.com/privacy-policy',
  termsLink: 'https://www.nuaxia.com/terms-of-use',
  showLogout: true,
  production: true,
  introExpire: 30,
  gtmId: 'GTM-KHCJN58',
  testUser: 'john.doe@nuaxia.com',
  ios: {
    id: 'web.com.testappnuaxia.externalportals',
    api: 'https://us-central1-externalportals-dev-c2ced.cloudfunctions.net/macPushService/nuaxia'
  },
  firebase: {
    apiKey: 'AIzaSyAl3tKrc_SPtir_N8kfS6lxO-DOHSP65bM',
    authDomain: 'externalportals-dev-c2ced.firebaseapp.com',
    databaseURL: 'https://externalportals-dev-c2ced.firebaseio.com',
    projectId: 'externalportals-dev-c2ced',
    storageBucket: 'externalportals-dev-c2ced.appspot.com',
    messagingSenderId: '68975804284',
    appId: '1:68975804284:web:90d356535f232cdd20eef9',
    measurementId: 'G-2Q2111DNZB'
  },
  api: {
    url: 'https://engage-api.test1.nuaxiasystem.com/api/app',
    key: 'BvXmfPHrmigL6NJpq41ttg0H9vZTLqZr'
  },
  onesignal: {
    enabled: true,
    appId: 'c221d1a8-765a-4aac-bf8d-244e0c5be99d'
  },
  push: {
    android: {
      senderId: '369570063996'
    }
  }
};
