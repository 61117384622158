<div class="template-in-conversation" *ngIf="education.video_groups.video_groups[0].type === 'new_touch_in'">
  <nav class="c-activity-sidebar__internal">
    <ul class="c-activity-sidebar__list js-accordion js-accordion-first-open">
      <li *ngFor="let groups of education.video_groups.video_groups;let index = index" class="c-activity-sidebar__item c-activity-sidebar__item--bg has-children" [ngClass]="{'js-accordion-item is-open' : openGroup === index}" >
        <a (click)="setOpenGroup(index)" class="c-activity-sidebar__link c-activity-sidebar__link--parent js-accordion-header" [attr.id]="index === 0 ? 'videoStart' : null">
            <span class="c-activity-sidebar__title"> {{groups.title}}
              <span style="margin-left: 1rem">
                <i class="fa-thin fa-plus" *ngIf="openGroup !== index"></i>
                <i class="fa-solid fa-minus" *ngIf="openGroup === index"></i>
              </span>
            </span>
        </a>
        <ul class="c-activity-sidebar__list c-activity-sidebar__sub-list js-accordion-content" [ngClass]="{'open' : openGroup === index}">
          <li *ngFor="let video of groups.videos; let index2 = index;" class="c-activity-sidebar__item c-activity-sidebar__item--child"  [ngClass]="{'video-active': (startedGroup === 'group-' + index) && startedVideo === index2}">
            <a (click)="videoSelect('mainPlayer', video,'group-'+index,index2,video.time);"
               class="c-activity-sidebar__link u-items-start js-sidebar-video-link-single-in-conversation"
               [ngClass]="{'is-complete' : videoCompleted('group-' + index,index2),'is-active':tabs[index] && videoTab['group-' + index][index2]}"
            >
                <span class="c-activity-sidebar__progress" *ngIf="videoCompleted('group-' + index,index2)">
                      <svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon">
                        <use xlink:href="#icon-tick"></use>
                      </svg>
                </span>
              <span class="c-activity-sidebar__info">
                  <span class="c-activity-sidebar__sub-text js-video-title">{{video.title}}</span>
                </span>
              <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                        <span class="unique-play-button">
                          <i class="fa-regular fa-circle-play"></i>
                        </span>
                        <span></span>
                </span>
            </a>
            <span class="c-meta__text c-meta--time">
                <span class="c-meta__text"><strong>Start Time: </strong>{{video.time}}</span>
              </span>
          </li>
        </ul>
      </li>
    </ul>
    <app-tips></app-tips>
  </nav>
</div>
