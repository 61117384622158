import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Observable} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Injectable()
export class VersionService extends FirebaseService {

  protected dbPath = '/versions';

  /**
   * Get latest version
   *
   */
  public getLatestVersion(): Observable<any> {
    return this.db.collection(this.dbPath, query => query.orderBy('created_at', 'desc')).snapshotChanges().pipe(
      takeUntil(this.singleton.notifier),
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          };
        });
      })
    );
  }
}
