import { Component, Input } from '@angular/core';
import { HonorariaTransaction } from '../../../interfaces/honoraria.interface';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-honoraria-transaction',
  templateUrl: 'honoraria-transaction.component.html'
})
export class HonorariaTransactionComponent {

  @Input() transaction: HonorariaTransaction;
  @Input() currency: string;

  /**
   * Getting the amount of the transaction
   * Currently we only show collected and paid amounts
   */
  public getTransactionAmount(transaction: HonorariaTransaction): string | null {
    if (this.hasAmount(transaction)) {
      if (transaction.collected > 0) {
        return `+ ${transaction.collected}`;
      }

      if (transaction.paid > 0) {
        return `- ${transaction.paid}`;
      }
    }
    return null;
  }

  public isIncrease(transaction: HonorariaTransaction): boolean {
    if (this.hasAmount(transaction)) {
      if (transaction.collected > 0) {
        return true;
      }

      if (transaction.paid > 0) {
        return false;
      }
    }
  }

  /**
   * Transaction has amount
   */
  public hasAmount(transaction: HonorariaTransaction): boolean {
    return transaction.paid > 0 || transaction.collected > 0;
  }

  public getCurrency(): string {
    return this.currency;
  }
}
