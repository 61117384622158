import { Observable, Subject } from 'rxjs';

export class DataSendingService {

  /**
   * Function for sending data to the subscribers
   * @param dataKey
   * @param data
   */
  public sendData(dataKey: string, data?: any): void {
    // console.log('sending data with key: ', dataKey, data);
    this.setSubscription(dataKey);
    this[dataKey].next(data);
  }

  /**
   * Function for clearing the data for a subscription
   * @param dataKey
   */
  public clearData(dataKey: string): void {
    this[dataKey].next();
  }

  /**
   * Function for getting the data for a subscription
   * @param dataKey
   */
  public getData(dataKey: string): Observable<any> {
    this.setSubscription(dataKey);
    return this[dataKey].asObservable();
  }

  /**
   * Function for creating a subscription using a key
   * @param dataKey
   */
  private setSubscription(dataKey: string): void {
    if (!this[dataKey]) {
      this[dataKey] = new Subject<any>();
    }
  }
}

