import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-outside',
  templateUrl: './outside.component.html',
  encapsulation: ViewEncapsulation.None
})
export class OutsideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
