import {Component, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {BaseComponent} from './components/base.component';
import {environmentProd} from '@environments/environment';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {PushService} from '@services/push.service';
import {AnalyticsService} from '@services/analytics.service';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from '@services/auth.service';
import {filter, take} from 'rxjs/operators';
import {ScriptService} from '@services/script.service';
import {HelperService} from '@services/helper.service';
import {Platform} from '@ionic/angular';
import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {DeviceService} from '@services/device.service';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {ProfileDeleteComponent} from './components/profile-delete/profile-delete.component';
import {AppUpdateComponent} from './components/app-update/app-update.component';
import {AppUpdateService} from '@services/appupdate.service';
import {Api2Service} from '@services/api2.service';
import {interval} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

declare var UXCam: any;
declare var PushNotification: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  public title = 'externalportals';
  public isDevice = true;
  // tslint:disable-next-line:max-line-length
  protected window: WindowProxy & typeof globalThis;

  // @ts-ignore
  private updateEvent: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: any,
    public auth: AuthService,
    public scripts: ScriptService,
    public helper: HelperService,
    private push: PushService,
    private analytics: AnalyticsService,
    private router: Router,
    private device: Platform,
    private deviceService: DeviceService,
    private splash: SplashScreen,
    private deeplinks: Deeplinks,
    private zone: NgZone,
    public dialog: MatDialog,
    public appUpdateService: AppUpdateService,
    private platform: Platform,
    private api2Service: Api2Service,

    protected message: MatSnackBar
  ) {
    super();

    this.isDevice = this.deviceService.isDevice();
    // Middleware
    this.device.ready().then(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.setLogo();
        this.hideFuseSplash();
        this.googleTag();
        this.uxCam();
        this.checkUpdate();
        this.checkUser();
      }
      this.hideSplash();
      this.initPushNotification();
      this.setupDeeplinks();
    });
  }

  ngOnInit(): void {
  }

  //
  // use follow this link format:
  // https://app.nuaxia.com/<ng-route-link>
  //
  // eg.:
  // https://app.nuaxia.com/password-reset
  // https://app.nuaxia.com/activate-account/token
  //
  // android debug:
  // adb shell am start -W -a android.intent.action.VIEW -d "https://app.nuaxia.com/activate-account/token" com.nuaxia.app
  //
  // this url format (three deep), you can use navigate user to custom content (eg. activity, survey, etc.)
  public setupDeeplinks(): void {
    if (this.deviceService.isDevice()) {
      this.deeplinks.route({
        '/:slug1': '',
        '/:slug1/:slug2': '',
        '/:slug1/:slug2/:slug3': ''
      }).subscribe(match => {
        let internalPath = `/${match.$args['slug1']}`;

        if (typeof match.$args['slug2'] !== 'undefined') {
          internalPath += `/${match.$args['slug2']}`;
        }

        if (typeof match.$args['slug3'] !== 'undefined') {
          internalPath += `/${match.$args['slug3']}`;
        }

        this.zone.run(() => {
          this.router.navigateByUrl(internalPath);
        });

      }, nomatch => {
        console.error('Deeplink didn\'t match', JSON.stringify(nomatch));
      });
    }
  }

  // GCM & Analytics
  public googleTag(): void {
    // Fake Middleware :D
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const eventData = {
          event: 'page',
          page_title: item.url
        };
        if (item.url === '/support') {
          this.setIntercom();
        } else {

        }
        this.analytics.updateEvent();
        this.analytics.addEvent(item.url, eventData);
        if (this.updateEvent) {
          clearInterval(this.updateEvent);
        }
        this.updateEvent = setInterval(() => {
          this.analytics.updateEvent();
        }, 20000);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.updateEvent) {
      clearInterval(this.updateEvent);
    }
  }

  /**
   * Set logo
   * @private
   */
  private setLogo(): void {
    // @ts-ignore
    this.document.querySelector('#favicon').href = environmentProd.favicon;
    this.document.querySelector('title').innerText = environmentProd.title;
    this.document.getElementsByClassName('siteLogo')[0].src = environmentProd.logo;
  }

  /**
   * Set intercom
   *
   * @private
   */
  private setIntercom(): void {
    this.window = this.document.defaultView;

    if (this.auth.currentUser().nx_id !== undefined && this.auth.currentUser().nx_id !== '') {
      const name = (this.auth.currentUser().first_name || '') + ' ' + (this.auth.currentUser().last_name || '');
      this.window['intercomSettings'] = {
        app_id: 'gudo0kw8',
        name,
        email: this.auth.currentUser().email,
        user_id: this.auth.currentUser().nx_id,
        nx_id: this.auth.currentUser().nx_id,
      };
    } else {
      this.window['intercomSettings'] = {
        app_id: 'gudo0kw8',
      };
    }
  }

  /**
   * hide Splash
   * @private
   */
  private hideSplash(): void {
    if (this.isDevice) {
      this.splash.hide();
    }
  }

  /**
   * Add scripts to view
   * @private
   */
  private addScripts(): void {
    this.scripts.load('gmaps').then(data => {
      console.log('script loaded ', JSON.stringify(data));
    }).catch(error => console.log(error));

    if (!this.isDevice) {
      this.scripts.load('intercom').then(data => {
        console.log('script loaded ', JSON.stringify(data));
      }).catch(error => console.log(error));
    }
  }

  /**
   * Hide Fuse Splash
   * @private
   */
  private hideFuseSplash(): void {
    if (!this.device) {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd), take(1)).subscribe(() => {
        this.document.body.classList.add('fuse-splash-screen-hidden');
      });
    } else {
      this.document.body.classList.add('fuse-splash-screen-hidden');
    }
  }

  // tslint:disable-next-line:typedef
  async initPushNotification() {
    if (this.platform.is('cordova')) {

      await PushNotification.hasPermission();

      let pushConfig = {
        android: {},
        ios: {
          alert: 'true',
          badge: 'true',
          sound: 'true'
        }
      };

      let push = PushNotification.init(pushConfig);

      push.on("registration", (data: any) => {
        const uuid = data.registrationId;
        localStorage.setItem('uuid', uuid);

        console.log('received uuid: ', uuid);
      })

      push.on('error', (e) => {
        console.log("Push error: ", JSON.stringify(e));
      });
    }
  }

  private uxCam(): void {
    if (this.deviceService.isDevice()) {
      UXCam.startWithConfiguration({
        optInSchematicRecordings: true,
        userAppKey: 'ae5og17a9dw4n3z',
      });
    }
  }

  private checkUpdate(): void {
    this.appUpdateService.checkForUpdate().then(updateAvailable => {
      if (updateAvailable) {
        this.dialog.open(AppUpdateComponent, {
          width: '400px',
          height: '400px',
          data: {
            icon: 'nuaxia:success',
            title: 'New app version update available.',
          },
        });
      } else {
        console.log('App is up to date!');
      }
    })
      .catch(error => {
        console.error('Error checking for update:', error);
      });
  }

  private async checkUser(): Promise<void> {
    if (this.api2Service.isAuthenticated()) {
      const user = this.api2Service.currentUser();
      const cacheTime = user ? user.cache ?? 60 : 60;
      interval(cacheTime * 1000).subscribe(async () => {
        const result = await this.api2Service.me();
        if (result.status === 200) {
          this.api2Service.storeUser(JSON.stringify(result.data.user));
        }
      });
    }
  }
}
