<div class="relative flex flex-col flex-auto min-w-0" *ngIf="profileData">
 <app-honoraria-overview [profileData]="profileData" [sumAccount]="this.sumAccount"  [sumPaid]="this.sumPaid"  [sumCollected]="this.sumCollected" [sumProcessing]="this.sumProcessing"  *ngIf="selectedTab === OVERVIEW"></app-honoraria-overview>
  <!-- Header & Cards -->
  <div class="relative  pb-12 sm:pb-24 max-h-max pt-6">
    <!-- Card -->
    <div class="flex flex-col items-center">
      <div class="flex-col w-full lg:max-w-7xl honoraria rounded-2xl bg-gray-50" *ngIf="profileData">
          <div *ngIf="transactionsToShow" class="pt-6">
            <h2 class="font-bold pl-4 pt-1 text-lg pb-3 mb-3">{{selectedTab === OVERVIEW ? 'Last transactions' : 'Transactions'}}</h2>
            <div class="scroll-smooth">

              <ng-container *ngFor="let transaction of transactionsToShow">
                <app-honoraria-transaction [transaction]="transaction" [currency]="profileData.currency"></app-honoraria-transaction>
              </ng-container>
              <p class="mb-3 ml-3" *ngIf="transactionsToShow.length === 0">No transactions have been recorded yet.</p>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
