import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pending-approval-modal',
  templateUrl: './pending-approval-modal.component.html'
})
export class PendingApprovalModalComponent implements OnInit {

  public hideThankYou = false;

  constructor(public dialogRef: MatDialogRef<PendingApprovalModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {hideThankYou: boolean} ) {
  }

  ngOnInit(): void {
    this.hideThankYou = !!this.data.hideThankYou;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
