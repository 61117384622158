import {Injectable} from '@angular/core';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {Platform} from '@ionic/angular';
import {VersionService} from '@services/version.service';
import {DeviceService} from '@services/device.service';

@Injectable()
export class AppUpdateService {

  constructor(
    private platform: Platform,
    private appVersion: AppVersion,
    private version: VersionService,
    private device: DeviceService
  ) {
  }

  checkForUpdate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.platform.is('cordova')) {
        this.appVersion.getVersionNumber().then((currentVersion: string) => {
          // Get the current version of the app
          this.version.getLatestVersion().subscribe(result => {
            let latestVersion = '';
            if (result && result.length > 0 
                && (this.device.deviceType() === 'ios' || this.device.deviceType() === 'android')
            ){
              let data = result[0].data;

              if (this.device.deviceType() === 'ios') {
                latestVersion = data.ios_version;
              }
              if (this.device.deviceType() === 'android') {
                latestVersion = data.android_version;
              }

              if (latestVersion === '') {
                resolve(false);
              }

              if (this.compareVersions(currentVersion, latestVersion) < 0) {
                // The app is outdated
                resolve(true);
              } else {
                // The app is up to date
                resolve(false);
              }
            }
            else {
              resolve(false);
            }
          });
        }).catch(error => {
          // Error retrieving the app version
          console.error('Error getting app version:', error);
          reject(error);
        });
      } else {
        // App is not running on Cordova platform
        resolve(false);
      }
    });
  }

  compareVersions(a: string, b: string): number {
    const segmentsA = a.split('.');
    const segmentsB = b.split('.');

    for (let i = 0; i < segmentsA.length; i++) {
      // tslint:disable-next-line:radix
      const segmentA = parseInt(segmentsA[i]);
      // tslint:disable-next-line:radix
      const segmentB = parseInt(segmentsB[i]);

      if (segmentA > segmentB) {
        return 1;
      }

      if (segmentA < segmentB) {
        return -1;
      }
    }

    return 0;
  }

}
