import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-touch-faculty-tab-dialog',
  templateUrl: './touch-faculty-tab-dialog.component.html',
  styleUrls: ['../../css/dialog.scss', '././touch-faculty-tab-dialog.component.css']
})
export class TouchFacultyTabDialogComponent {

  public selectedFaculty: any | null = null;

  constructor(
    public dialogRef: MatDialogRef<TouchFacultyTabDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { faculty: any },
  ) {
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public selectFaculty(faculty: any): void {
    this.selectedFaculty = faculty;
  }

  public back(): void {
    this.selectedFaculty = null;
  }
}
