import { Component, OnInit } from '@angular/core';
import { EducationComponent } from '../education.component';

@Component({
  selector: 'app-touch-satellite',
  templateUrl: './satellite.component.html',
  styleUrls: ['../education.component.scss']
})
export class EducationSatelliteComponent extends EducationComponent implements OnInit {
  public ngOnInit(): void {
    super.ngOnInit();
  }
}
