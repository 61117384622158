import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { DataSendingService } from '@services/data-sending.service';
import { BaseComponent } from '../../../components/base.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html'
})
export class CalendarComponent extends BaseComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @Input() events = [];
  @Input() calendarOptions: CalendarOptions = {};
  @Input() calendarId = 'calendar';

  constructor(protected dataSendingService: DataSendingService) {
    super();
  }

  ngOnInit(): void {
    this.transformEvents();
    this.setDefaultView();
    this.subscribeToCalendarRender();
  }

  /**
   * If a component wants to re-render the calendar, it can send a 'renderCalendar' event, with the id
   * of the selected calendar, that will call the render() method on itself
   * @protected
   */
  protected subscribeToCalendarRender(): void {
    this.subscribe(this.dataSendingService.getData('renderCalendar'), (calendarId: string) => {
      if (this.calendarId === calendarId){
        this.transformEvents();
        this.calendarComponent.getApi().render();
      }
    });
  }

  protected setDefaultView(): void {
   if (!this.calendarOptions.initialView) {
     this.calendarOptions.initialView = 'dayGridMonth';
   }
 }

  protected transformEvents(): void {
    this.calendarOptions.events = this.events;
  }

}
