<div class="flex flex-col flex-auto min-w-0 fuse-mat-rounded-2">
  <!-- Main -->
  <div class="flex flex-auto p-2">

    <div class="flex flex-col flex-auto w-full mx-auto">
      <div tourAnchor="tour-activities">
        <div label="Microlearning" *ngIf="(page === null || page === '' || page === 'microlearning')">
          <ng-container>
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              <ng-container *ngFor="let act of groupedActivities.new.microlearning">
                <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card nuaxia-bg-card-blue" (click)="handleClick($event, act)">
                  <app-survey-card [activity]="act" [tab]="'new'"></app-survey-card>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="groupedActivities.new.microlearning.length === 0">
              <div class="grid grid-cols-1 sm:grid-cols-1 gap-4 mt-2">
                <app-no-activity [type]="'survey'"></app-no-activity>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div label="Earn and Learn" *ngIf="page === 'earnandlearn'" data-testId="education-list">
          <ng-container>
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              <ng-container *ngFor="let act of groupedActivities.new.earnandlearn">
                <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card nuaxia-bg-card-green" (click)="handleClick($event, act)">
                  <app-survey-card [activity]="act" [tab]="'new'"></app-survey-card>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="groupedActivities.new.earnandlearn.length === 0">
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
              <app-no-activity [type]="'education'"></app-no-activity>
            </div>
          </ng-container>
        </div>
      </div>
      <div style="height:5px;width:100%;">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>
