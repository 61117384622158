import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { SingletonService } from './singleton.service';

@Injectable()
export class UserTokenService extends FirebaseService {

  protected dbPath = '/user_tokens';
  protected userDbPath = '/user_profiles';

  constructor(
    protected db: AngularFirestore,
    public auth: AuthService,
    protected singleton: SingletonService
  ) {
    super(db, singleton);
  }

  // @ts-ignore
  /**
   * Get activity by slug
   */
  public getId(token: string): Observable<any> {
    return this.db.doc(this.dbPath + '/' + token).snapshotChanges().pipe(take(1),
      map((doc: any) => {
        const data = doc.payload.data();
        const id = doc.payload.id;
        return { id, ...data };
      }));
  }

  /**
   * Check code
   *
   * @param code
   * @param type
   */
  public checkCode(code: string, type: string): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.db.collection(this.dbPath, query => query.where('token', '==', code).where('type', '==', type)).snapshotChanges().pipe(take(1),
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          };
        });
      })
    );
  }

  public getProfileByEmail(email: string, type: string): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.db.collection(this.dbPath, query => query.where('email', '==', email).where('type', '==', type)).snapshotChanges().pipe(take(1),
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          };
        });
      })
    );
  }

  // tslint:disable-next-line:typedef
  public async deleteTokens(email: string, type: string): Promise<boolean> {
    const deleteTokens = await this.deleteOldTokens(email, type);
    if (deleteTokens.length > 0) {
      for (let i = 0; i <= deleteTokens.length; i++) {
        this.delete(deleteTokens[i]).then(r => r);
      }
      return true;
    } else {
      return true;
    }
  }

  /**
   * Delete old tokens
   *
   * @param email
   */
  private async deleteOldTokens(email: string, type: string): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      const deleteSubscribe = this.getProfileByEmail(email, type).subscribe(res => {
        if (res) {
          const deleteIds = new Array<string>();
          res.forEach(token => {
            deleteIds.push(token.id);
          });
          deleteSubscribe.unsubscribe();
          resolve(deleteIds);
        } else {
          const deleteIds = new Array<string>();
          resolve(deleteIds);
        }
      }, error => reject(error));
    });
  }
}
