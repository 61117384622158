<div>
  <div class="close">
    <span (click)="onClose()">x</span>
  </div>
  <div class="dialog-container">
    <div class="img-graphic">
      <img alt="faculty" src="https://touchoncology.com/wp-content/themes/tmm/images/graphics/downloads.svg">
    </div>
    <div class="data-selection">
      <p>View and download resources from this activity to support your learning and share with colleagues</p>
      <h5 class="popup-title">Downloads</h5>
      <div class="faculty-card">
        <mat-form-field>
          <mat-select [(value)]="selected" [placeholder]="'Select language'" class="download-select">
            <mat-option *ngFor="let download of data.downloads"
                        value="{{download.url}}">{{download.language}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="selected">
        <a href="{{selected}}" target="_blank" class="c-card-button-text">
          <span class="c-card__button-text">Download slides</span>
        </a>
      </div>
    </div>
  </div>
</div>
