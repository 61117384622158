<!-- SECTION 2 -->
<nav class="click-to-section">
  <ng-container *ngFor="let edc of education.touch_sections2.sections;let index2 = index;">
    <a (click)="setActiveTab(index2)" data-section="lo" class="tabLink" *ngIf="!edc.video || edc.video == activeTab" [attr.id]="edc.type" [ngClass]="activeTab === index2 ? 'active' : ''" >{{edc.title}}</a>
  </ng-container>
</nav>

<div *ngFor="let edc2 of education.touch_sections2.sections;let tabsectionindex = index;">
  <div class="js-tabs learning-objectives-show" data-section="lo" *ngIf="activeTab === tabsectionindex">
    <!-- TAB SECTION --->
    <section>
      <div class="c-tabs__tab js-tabs-tab t-ime" id="js-tab-learning-objectives" style="display: block;">
        <div class="u-pb-0">
          <div class="c-card c-card--large s-standard-content">
            <h5 class="h6 u-mb-2 u-text-grey">{{edc2.title}}</h5>
            <app-touch-readmore [text]="edc2.description"></app-touch-readmore>
          </div><!-- /.c-card -->
        </div><!-- /.o-section -->
      </div><!-- /.c-tabs__tab -->
    </section>
    <!-- TOOLKIT SECTION --->
  </div>
  <div *ngIf="activeTab === tabsectionindex && edc2.type === 'toolkit'">
    <ul class="toolkit-section">
      <li class="toolkit-list" data-popup="downloads"><i class="fa-regular fa-download" style="color: #fe5000; margin-right: 15px; font-weight: 300"></i><a
        (click)="openDownload()">Downloads</a></li>
      <li class="toolkit-list" data-popup="faculty"><i class="fa-regular  fa-people-group" style="color: #fe5000; margin-right: 15px"></i>
        <a (click)="openFaculty()">Faculty &amp; Disclosures</a></li>
    </ul>
  </div>
</div>
<!-- END SECTION 2 -->
