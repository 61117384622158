import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {IStepOption} from 'ngx-ui-tour-core';
import {TourService} from 'ngx-ui-tour-md-menu';
import {isPlatformBrowser} from '@angular/common';
import * as dayjs from 'dayjs';
import {environmentProd} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class IntroService {

  private intro = true;

  private steps = [{
    anchorId: 'tour-activities',
    title: 'Welcome',
    content: 'Welcome to the tour!',
    enableBackdrop: true
  },
    {
      anchorId: 'tour-library',
      title: 'Welcome',
      content: 'Check your activites',
      enableBackdrop: true
    },
    {
      anchorId: 'tour-honoraria',
      title: 'Welcome',
      content: 'Check your honoraria',
      enableBackdrop: true
    },
    {
      anchorId: 'tour-profile',
      title: 'Welcome',
      content: 'Edit your profile',
      enableBackdrop: true
    },
  ];

  constructor(@Inject(PLATFORM_ID) private readonly platformId, private readonly tourService: TourService) {
  }

  /**
   * Init Tour
   */
  start(): void {
   /* this.showIntro();
    this.tourService.end$.subscribe((steps: IStepOption[]) => {
      this.hideIntro();
    });*/
  }

  /**
   * Hide intro if end
   *
   * @private
   */
  private hideIntro(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const item = {intro: true, expiry: dayjs(new Date()).add(environmentProd.introExpire, 'days').format('YYYY-MM-DD')};
    localStorage.setItem('intro', JSON.stringify(item));
  }

  /**
   * Intro time set to 30 days
   *
   * @private
   */
  private showIntro(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.intro = localStorage.getItem('intro') === null;
    // tslint:disable-next-line:max-line-length
    this.intro = !this.intro ? (new Date(JSON.parse(localStorage.getItem('intro')).expiry) < new Date(dayjs(new Date()).format('YYYY-MM-DD'))) : true;

    if (this.intro) {
      this.tourService.initialize(this.steps);
      this.tourService.start();
    }
  }
}
