import {Component, OnInit} from '@angular/core';
import {environmentProd} from '@environments/environment.prod';
import {AuthService} from '@services/auth.service';
import {DeviceService} from 'src/app/services/device.service';
import {NavigationEnd, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  SignupOrLoginModalComponent
} from '../../components/modals/signup-or-login-modal/signup-or-login-modal.component';


@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  // public email: string = JSON.parse(localStorage.getItem('user')).email;
  public year: number = new Date().getFullYear();
  public site: string = environmentProd.site;
  public isDevice: boolean;
  public supportUrl: any;
  public url: any;

  protected MICROLEARNING_URL = 'learns/microlearning';
  protected EARN_AND_LEARN_URL = 'learns/earnandlearn';

  public menu: any[] = [
    {
      id: '',
      title: 'Microlearning',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Medical education in 5 mins or less',
      header: [
        {url: this.MICROLEARNING_URL, title: 'Microlearning'},
        {url: this.EARN_AND_LEARN_URL, title: 'Earn and Learn'},
      ],
      footer: [

        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: true},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},

      ],
    },
    {
      id: 'learn',
      title: 'Microlearning',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Medical education in 5 mins or less',
      header: [
        {url: this.MICROLEARNING_URL, title: 'Microlearning'},
        {url: this.EARN_AND_LEARN_URL, title: 'Earn and Learn'},
      ],
      footer: [

        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: true},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},

      ],
    },
    {
      id: 'learns_microlearning',
      title: 'Microlearning',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Medical education in 5 mins or less',
      header: [
        {url: this.MICROLEARNING_URL, title: 'Microlearning'},
        {url: this.EARN_AND_LEARN_URL, title: 'Earn and Learn'},
      ],
      footer: [

        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: true},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},

      ],
    },
    {
      id: 'learns_earnandlearn',
      title: 'Earn and Learn',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Earn honoraria by participating in research',
      header: [
        {url: this.MICROLEARNING_URL, title: 'Microlearning'},
        {url: this.EARN_AND_LEARN_URL, title: 'Earn and Learn'},
      ],
      footer: [

        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: true},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},

      ],
    },

    {
      id: 'activities_education',
      title: 'Earn and learn',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Earn honoraria by participating in research',
      header: [
        {url: 'activities/surveys', title: 'Microlearning'},
        {url: 'activities/education', title: 'Earn and learn'},
        // {url: 'activities/communication', title: 'Communication'},
        // {url: 'profile/events', title: 'Event'},
      ],
      footer: [

        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: true},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},

      ],
    },
    {
      id: 'activities_library',
      title: 'My Library',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Library of medical education content',
      header: [
        {url: 'activities/library-communication', title: 'Communication'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},
      ],
    },
    {
      id: 'activities_library_surveys',
      title: 'My Library',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Library of medical education content',
      header: [
        {url: 'activities/library-communication', title: 'Communication'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},
      ],
    },
    {
      id: 'activities_library_education',
      title: 'Library education',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Library of medical education content',
      header: [
        {url: 'activities/library-communication', title: 'Communication'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},
      ],
    },
    {
      id: 'activities_library_communication',
      title: 'My Library',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: 'Library of medical communication content',
      header: [
        {url: 'activities/library-communication', title: 'Communication'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},
      ],
    },
    {
      id: 'profile_profile',
      title: 'Me',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: '',
      header: [
        {url: 'profile/profile', title: 'Me'},
        {url: 'profile/account', title: 'Account'},
        {url: 'profile/notifications', title: 'Notifications'},
        {url: 'support', title: 'Help'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: true},
      ],
    },
    {
      id: 'honoraria_overview',
      title: 'Honoraria overview',
      hasTitleTop: false,
      hasTitleBottom: true,
      hasCenteredTitle: true,
      backgroundColor: '#D5E9F0',
      hasMenu: true,
      description: '',
      header: [
        {url: 'honoraria/overview', title: 'Balance'},
        {url: 'honoraria/transactions', title: 'Transactions'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: true},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},
      ],
    },
    {
      id: 'honoraria_transactions',
      title: 'Honoraria transaction history',
      hasTitleTop: false,
      hasTitleBottom: true,
      backgroundColor: '#D5E9F0',
      hasMenu: true,
      description: '',
      header: [
        {url: 'honoraria/overview', title: 'Balance'},
        {url: 'honoraria/transactions', title: 'Transactions'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: true},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: false},
      ],
    },
    {
      id: 'profile_account',
      title: 'Account',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: '',
      header: [
        {url: 'profile/profile', title: 'Me'},
        {url: 'profile/account', title: 'Account'},
        {url: 'profile/notifications', title: 'Notifications'},
        {url: 'support', title: 'Help'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: true},
      ],
    },
    {
      id: 'profile_notifications',
      title: 'Notifications',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: '',
      header: [
        {url: 'profile/profile', title: 'Me'},
        {url: 'profile/account', title: 'Account'},
        {url: 'profile/notifications', title: 'Notifications'},
        {url: 'support', title: 'Help'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: true},
      ],
    },
    {
      id: 'profile_events',
      title: 'Events',
      hasTitleTop: false,
      hasTitleBottom: false,
      hasMenu: true,
      description: '',
      header: [
        {url: 'profile/profile', title: 'Me'},
        {url: 'profile/account', title: 'Account'},
        {url: 'profile/notifications', title: 'Notifications'},
        {url: 'support', title: 'Help'},
      ],
      footer: [
        {url: this.MICROLEARNING_URL, title: 'Home', icon: 'nuaxia:home', iconHover: 'nuaxia:home-active', iconActive: false},
        {url: 'honoraria/overview', title: 'Honoraria', icon: 'nuaxia:honoraria', iconHover: 'nuaxia:honoraria-active', iconActive: false},
        {url: 'profile/profile', title: 'Profile', icon: 'nuaxia:profile', iconHover: 'nuaxia:profile-active', iconActive: true},
      ],
    },
  ];

  public menuSelected: any[];

  /**
   * Constructor
   */
  constructor(
    private auth: AuthService,
    private device: DeviceService,
    protected dialog: MatDialog,
    private router: Router
  ) {
    this.menuSelected = this.menu[0];
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const url = item.url.substring(1).replace('/', '_').replace('-', '_');
        this.menuSelected = this.menu.filter((obj) => {
          return obj.id === url;
        });
      }
    });
  }


  /**
   * On init
   */
  ngOnInit(): void {
    this.isDevice = this.device.isDevice();
    let query = '';

    if (this.auth.currentUser().nx_id !== undefined && this.auth.currentUser().nx_id !== '') {
        query = '?nx_id=' + this.auth.currentUser().nx_id + '&email=' + this.auth.currentUser().email;
    }

    this.supportUrl = 'https://www.nuaxia.com/help' + query;
  }

  public isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  public hasToken(): boolean {
    return this.auth.hasToken();
  }

  public showLoginModal(): void {
    this.dialog.open(SignupOrLoginModalComponent);
  }
}
