import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from '../../base.component';
import {AnalyticsService} from '@services/analytics.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Api2Service} from '@services/api2.service';
import {AuthComponent} from '../auth.component';
import { MatDialog } from '@angular/material/dialog';
import { SignupOrLoginModalComponent } from '../../modals/signup-or-login-modal/signup-or-login-modal.component';
import { DataSendingService } from '@services/data-sending.service';
import {HelperService} from '@services/helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DeviceService} from '@services/device.service';

@Component({
  selector: 'app-login-form',
  templateUrl: 'login-form.component.html'
})
export class LoginFormComponent extends AuthComponent implements OnInit {
  public form: FormGroup;
  public hidePassword = true;
  public action = 'Login';
  public actionButton = 'Log in';

  constructor(protected router: Router,
              protected api2Service: Api2Service,
              protected activatedRoute: ActivatedRoute,
              protected formBuilder: FormBuilder,
              protected dialog: MatDialog,
              protected analytics: AnalyticsService,
              protected dataSendingService: DataSendingService,
              protected helper: HelperService
  ) {
    super(router, api2Service, activatedRoute, dialog, helper);
  }

  public async ngOnInit(): Promise<void> {

    this.createForm();
  }

  /**
   * Get form fields
   */
  // tslint:disable-next-line:typedef
  get fields() {
    return this.form.controls;
  }


  /**
   * Creating the form
   * @protected
   */
  protected createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  async submitForm(): Promise<any> {

    this.post = true;
    this.error = undefined;
    this.loader = true;

    if (this.form.invalid) {
      this.error = true;
      this.loader = false;
      return;
    }

    const email = this.fields.email.value.toLowerCase();
    const emailOriginal = this.fields.email.value;

    try {
      this.analytics.addEvent('login_try', {
        emailOriginal,
      });

      const result = await this.api2Service.login(email);

      if (result.status === 200) {
        if (result.data.is_deleted === false) {
          this.analytics.addEvent('login_deleted', {
            emailOriginal,
          });
          this.stopLogin('Your account deleted.');
        }

        this.analytics.addEvent('login_with_magic_link', {
          emailOriginal,
        });
        this.success = 'We have sent you a magic link to log in to the app.';
        this.loader = false;
      } else {
        this.analytics.addEvent('login_failed', {
          emailOriginal,
          error: result.data.exception
        });
        this.stopLogin('Sorry, we could not log you in. Please contact us.');
      }
    } catch (e) {
      if (e.message) {
        this.error = e.message;
      } else {
        this.error = 'Sorry, we could not log you in. Please contact us.';
      }

      this.analytics.addEvent('login_failed', {
        emailOriginal,
        error: e.message
      });
      this.loader = false;
    }
  }
}
