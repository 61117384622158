<div class="flex flex-col flex-auto min-w-0 fuse-mat-rounded-2">
  <!-- Main -->
  <div class="text-center mat-body-strong mt-3" *ngIf="showInactiveProfile">
    Your registration is pending approval, we will be in touch soon about activating your account.
    Thank you for your patience.
  </div>
  <div class="flex flex-auto p-2">

    <div class="flex flex-col flex-auto w-full mx-auto">
      <div *ngIf="!showInactiveProfile" tourAnchor="tour-activities">
        <div label="Surveys" *ngIf="(page === null || page === '' || page === 'surveys')">
          <ng-container>
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              <ng-container *ngFor="let act of groupedActivities.new.surveys">
                <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card nuaxia-bg-card-blue">
                  <app-survey-card [activity]="act" [tab]="'new'"></app-survey-card>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="groupedActivities.new.surveys.length === 0">
              <div class="grid grid-cols-1 sm:grid-cols-1 gap-4 mt-2">
                <app-no-activity [type]="'survey'"></app-no-activity>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div label="Education" *ngIf="page === 'education'" data-testId="education-list">
          <ng-container>
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              <ng-container *ngFor="let act of groupedActivities.new.education">
                <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card nuaxia-bg-card-green">
                  <app-education-card [activity]="act" [tab]="'new'"></app-education-card>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="groupedActivities.new.education.length === 0">
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
              <app-no-activity [type]="'education'"></app-no-activity>
            </div>
          </ng-container>
        </div>
        <div label="Communication" *ngIf="page === 'communication'" data-testId="communication-list">
          <ng-container>
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              <ng-container *ngFor="let act of groupedActivities.new.communication">
                <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card nuaxia-bg-card-yellow">
                  <app-communication-card [activity]="act" [tab]="'communication'"></app-communication-card>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="groupedActivities.new.communication.length === 0">
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
              <app-no-activity [type]="'communication'"></app-no-activity>
            </div>
          </ng-container>
        </div>
        <div label="Surveys" *ngIf="page === 'library-surveys'" data-testId="library-survey-list">
          <ng-container>
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              <ng-container *ngFor="let act of groupedActivities.library.surveys">
                <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card nuaxia-bg-card-blue">
                <app-survey-card [activity]="act" [tab]="'library'"></app-survey-card>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="groupedActivities.library.surveys.length === 0">
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
              <app-no-activity [type]="'survey'"></app-no-activity>
            </div>
          </ng-container>
        </div>
        <div label="Education" *ngIf="page === 'library-education'" data-testId="library-education-list">
          <ng-container>
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              <ng-container *ngFor="let act of groupedActivities.library.education">
                <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card  nuaxia-bg-card-green">
                <app-education-card [activity]="act" [tab]="'library'"></app-education-card>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="groupedActivities.library.education.length === 0">
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
              <app-no-activity [type]="'education'"></app-no-activity>
            </div>
          </ng-container>
        </div>
        <div label="Communication" *ngIf="page === 'library-communication'" data-testId="library-communication-list">
          <ng-container>
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
              <ng-container *ngFor="let act of groupedActivities.library.communication">
                <div class="flex flex-col shadow rounded-2xl overflow-hidden bg-card  nuaxia-bg-card-yellow">
                  <app-communication-card [activity]="act" [tab]="'communication'"></app-communication-card>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="groupedActivities.library.communication.length === 0">
            <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4 mt-2">
              <app-no-activity [type]="'communication'"></app-no-activity>
            </div>
          </ng-container>
        </div>
      </div>
      <div style="height:5px;width:100%;">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>
