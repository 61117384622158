import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SingletonService } from './singleton.service';

@Injectable()
export class FirebaseService {

  // the path of the collection like '/users'
  protected dbPath: string;
  protected collectionRef: AngularFirestoreCollection<any> = null;

  /////////////////////////////

  constructor(
    protected db: AngularFirestore,
    protected singleton: SingletonService
  ) {
  }

  /////////////////////////////

  /**
   * Getting the whole collection
   */
  public getAll<T>(queryFunction?: any): AngularFirestoreCollection<T> {
    return this.db.collection(this.dbPath, queryFunction);
  }

  public getById<T>(id: string): AngularFirestoreDocument<T> {
    return this.db.collection(this.dbPath).doc(id);
  }


  /**
   * Getting every document with it's id in the current collection
   * @example
   * this.activityService.getEveryDocumentData(query => query.where('publish_specialties', '!=', null))
   */
   public getEveryDocumentData<T>(queryFunction?: any): Observable<any> {
    return this.getAll(queryFunction).snapshotChanges().pipe(
      takeUntil(this.singleton.notifier),
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          };
        });
      })
    );
  }

  /**
   * Creating new document
   *
   */
  public create<T>(singleDocument: T): any {
    return this.db.collection(this.dbPath).add({...singleDocument});
  }

  /**
   * Creating new document
   *
   */
  public set<T>(uid: string, singleDocument: T): any {
    return this.db.collection(this.dbPath).doc(uid).set({...singleDocument});
  }

  /**
   * Updating a document
   * @param id
   * @param data
   */
  public update<T>(id: string, data: any): Promise<void> {
    return this.db.collection(this.dbPath).doc(id).update(data);
  }

  /**
   * Deleting a document
   * @param id - id of the document
   */
  delete(id: string): Promise<void> {
    return this.db.collection(this.dbPath).doc(id).delete();
  }

}
