import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { environmentProd } from '../environments/environment.prod';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AppRoutingModule } from './app-routing.module';
import { CalendarModule } from './modules/calendar/calendar.module';

import { ActivityService } from '@services/activity.service';
import { DataSendingService } from '@services/data-sending.service';
import { FirebaseService } from '@services/firebase.service';
import { ApiService } from '@services/api.service';
import { PushService } from '@services/push.service';
import { AnalyticsService } from '@services/analytics.service';
import { HonorariaService } from '@services/honoraria.service';
import { LoginFormComponent } from './components/auth/login/login-form.component';
import { BaseComponent } from './components/base.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './app-material.module';
import { AuthService } from '@services/auth.service';
import { ProfileComponent } from './components/profile/profile.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { LearnsComponent } from './components/learns/learns.component';
import { MenuComponent } from './components/menu/menu.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { SurveyComponent } from './components/survey/survey.component';
import { PlayerComponent } from './components/player/player.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReadmoreComponent } from './components/readmore/readmore.component';
import { LayoutComponent } from './theme/inside/layout.component';
import { OutsideComponent } from './theme/outside/outside.component';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseTailwindService } from '@fuse/services/tailwind';
import { IconsModule } from './core/icons/icons.module';
import { FuseModule } from '@fuse/fuse.module';
import { FuseConfigModule } from '@fuse/services/config';
import { appConfig } from './core/config/app.config';
import { EmptyComponent } from './theme/empty/empty.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationTemplateComponent } from './components/notification/notification-template.component';
import { FuseCardModule } from '@fuse/components/card';
import { HonorariaComponent } from './components/honoraria/honoraria.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { CountryService } from '@services/country.service';
import { ErrorTrackingService } from '@services/error-tracking.service';
import { AppUpdateService } from '@services/appupdate.service';
import { GraylogService } from '@services/graylog.service';
import { NotificationService } from '@services/notification.service';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { IntroService } from '@services/intro.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SpecialtyService } from '@services/specialty.service';
import { MatChipsModule } from '@angular/material/chips';
import { SchoolService } from '@services/school.service';
import { HelperService } from '@services/helper.service';
import { ScriptService } from '@services/script.service';
import { SupportComponent } from './components/support/support.component';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { IonicModule } from '@ionic/angular';
import { SingletonService } from '@services/singleton.service';
import { UserTokenService } from '@services/user-tokens.service';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { DeviceService } from '@services/device.service';
import { RedirectMobileComponent } from './components/redirect-mobile/redirect-mobile.component';
import { TextHelperService } from '@services/text-helper.service';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { LowercaseDirective } from './directives/lowercase.directive';
import { MaxlengthDirective } from './directives/maxlength.directive';
import { CapslockDirective } from './directives/capslock.directive';
import { NexttabDirective } from './directives/nexttab.directive';

import { EventNotificationsService } from '@services/event-notifications.service';
import { UserNotificationsComponent } from './components/profile/notifications/user-notifications.component';
import { UserEventsComponent } from './components/profile/events/user-events.component';
import { TokensService } from '@services/tokens.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LastStepComponent } from './components/last-step/last-step.component';
import { TitleFieldComponent } from './components/fields/title-field/title-field.component';
import { SpecialtyFieldComponent } from './components/fields/specialty-field/specialty-field.component';
import { TextFieldComponent } from './components/fields/text-field/text-field.component';
import { WorkFieldComponent } from './components/fields/work-field/work-field.component';
import { PopupComponent } from './components/fields/popup/popup.component';
import { AbstractActivityItemComponent } from './components/activities/activity-item/activity-item.component';
import { SurveyCardComponent } from './components/activities/survey-card/survey-card.component';
import { CommunicationCardComponent } from './components/activities/communication-card/communication-card.component';
import { SafeHtmlPipe } from './pipes/safaehtml.pipe';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { NoActivityComponent } from './components/activities/no-activity/no-activity.component';
import { HonorariaOverviewComponent } from './components/honoraria/honoraria-overview/honoraria-overview.component';
import { HonorariaSumInfoComponent } from './components/honoraria/honoraria-sum-info/honoraria-sum-info.component';
import {
  HonorariaTransactionComponent
} from './components/honoraria/honoraria-transaction/honoraria-transaction.component';

// Education
import { EducationComponent } from './components/education/education.component';
import { EducationCardComponent } from './components/activities/education-card/education-card.component';
import { EducationCongressComponent } from './components/education/congress/congress.component';
import { EducationConversationComponent } from './components/education/conversation/conversation.component';
import { EducationLinkButtonComponent } from './components/education/shared/touch-button/link-button.component';
import { EducationMdtComponent } from './components/education/mdt/mdt.component';
import { EducationExpertComponent } from './components/education/expert/expert.component';
import { EducationPanelComponent } from './components/education/panel/panel.component';
import { EducationPlayerComponent } from './components/education-player/education-player.component';
import { EducationSatelliteComponent } from './components/education/satellite/satellite.component';
import { EducationSummaryComponent } from './components/education-summary/education-summary.component';
import { EducationSvgComponent } from './components/education/shared/svg/education-svg.component';
import { EducationTalksComponent } from './components/education/talks/talks.component';
import { EducationFeatureComponent } from './components/education/feature/feature.component';
import { EducationHeaderComponent } from './components/education/layout/header/education-header.component';
import { EducationFooterComponent } from './components/education/layout/footer/education-footer.component';
import { DownloadDialogComponent, FacultyDialogComponent } from './components/education/conversation/conversation.component';
import { ProfileDeleteComponent } from './components/profile-delete/profile-delete.component';
import {EducationTouchComponent} from './components/education/touch/touch.component';
import { TouchExpertSidebarComponent } from './components/education/touch/sidebars/touch-expert-sidebar/touch-expert-sidebar.component';
import { TouchInSidebarComponent } from './components/education/touch/sidebars/touch-in-sidebar/touch-in-sidebar.component';
import { TipsComponent } from './components/education/touch/parts/tips/tips.component';
import { TouchCongressSidebarComponent } from './components/education/touch/sidebars/touch-congress-sidebar/touch-congress-sidebar.component';
import { TouchTabsComponent } from './components/education/touch/parts/touch-tabs/touch-tabs.component';
import { TouchTabsSecondComponent } from './components/education/touch/parts/touch-tabs-second/touch-tabs-second.component';
import { TouchDownloadTabDialogComponent } from './components/education/touch/parts/touch-download-tab-dialog/touch-download-tab-dialog.component';
import { TouchFacultyTabDialogComponent } from './components/education/touch/parts/touch-faculty-tab-dialog/touch-faculty-tab-dialog.component';
import { DefaultSidebarComponent } from './components/education/touch/sidebars/default-sidebar/default-sidebar.component';
import {TouchReadmoreComponent} from './components/education/touch/parts/touch-readmore/touch-readmore.component';
import {AppUpdateComponent} from './components/app-update/app-update.component';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {VersionService} from '@services/version.service';
import {Market} from '@ionic-native/market/ngx';
import {Api2Service} from '@services/api2.service';
import {AlchemerComponent} from './components/alchemer/alchemer.component';
import {AuthComponent} from './components/auth/auth.component';
import {PasswordResetComponent} from './components/password-reset/password-reset.component';
import { SignupOrLoginModalComponent } from './components/modals/signup-or-login-modal/signup-or-login-modal.component';
import {
  CheckYourEmailModalComponent
} from './components/modals/check-your-email-modal/check-your-email-modal.component';
import { ProfileUpdateModalComponent } from './components/modals/profile-update-modal/profile-update-modal.component';
import {
  PendingApprovalModalComponent
} from './components/modals/pending-approval-modal/pending-approval-modal.component';

const COMPONENTS = [
  AlchemerComponent,
  AppComponent,
  AuthComponent,
  LoginFormComponent,
  BaseComponent,
  ProfileComponent,
  AbstractActivityItemComponent,
  SurveyCardComponent,
  EducationComponent,
  EducationCardComponent,
  EducationCongressComponent,
  EducationConversationComponent,
  EducationLinkButtonComponent,
  EducationMdtComponent,
  EducationExpertComponent,
  EducationTouchComponent,
  EducationPanelComponent,
  EducationPlayerComponent,
  EducationSatelliteComponent,
  EducationSummaryComponent,
  EducationSvgComponent,
  EducationTalksComponent,
  EducationFeatureComponent,
  EducationHeaderComponent,
  EducationFooterComponent,
  DownloadDialogComponent,
  FacultyDialogComponent,
  CommunicationCardComponent,
  NoActivityComponent,
  NotfoundComponent,
  TermsComponent,
  HonorariaSumInfoComponent,
  HonorariaOverviewComponent,
  HonorariaTransactionComponent,
  PrivacyComponent,
  ActivitiesComponent,
  MenuComponent,
  SurveyComponent,
  PlayerComponent,
  ReadmoreComponent,
  TouchReadmoreComponent,
  LastStepComponent,
  NotificationComponent,
  NotificationTemplateComponent,
  HonorariaComponent,
  RedirectComponent,
  LayoutComponent,
  OutsideComponent,
  EmptyComponent,
  GoogleMapComponent,
  SupportComponent,
  PasswordStrengthComponent,
  RedirectMobileComponent,
  PasswordResetComponent,
  PasswordChangeComponent,
  LowercaseDirective,
  CapslockDirective,
  MaxlengthDirective,
  NexttabDirective,
  UserNotificationsComponent,
  UserEventsComponent,
  TextFieldComponent,
  SpecialtyFieldComponent,
  TitleFieldComponent,
  WorkFieldComponent,
  PopupComponent,
  SafeHtmlPipe,
  LearnsComponent,
  SignupOrLoginModalComponent,
  CheckYourEmailModalComponent,
  ProfileUpdateModalComponent,
  PendingApprovalModalComponent,
];

const SERVICES = [
  ActivityService,
  AnalyticsService,
  ApiService,
  Api2Service,
  AuthService,
  DataSendingService,
  FirebaseService,
  HonorariaService,
  PushService,
  FuseMediaWatcherService,
  FuseTailwindService,
  CountryService,
  ErrorTrackingService,
  AppUpdateService,
  GraylogService,
  IntroService,
  SpecialtyService,
  SchoolService,
  NotificationService,
  HelperService,
  TextHelperService,
  HelperService,
  ScriptService,
  SplashScreen,
  SingletonService,
  DeviceService,
  UserTokenService,
  EventNotificationsService,
  TokensService,
  AppVersion,
  VersionService,
  Market,
];


const MODULES = [
  AppRoutingModule,
  IonicModule.forRoot({ mode: 'ios' }),
  FuseModule,
  FuseConfigModule.forRoot(appConfig),
  AngularFireModule.initializeApp(environmentProd.firebase),
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularFireAuthGuardModule,
  AngularFireMessagingModule,
  AngularFireAnalyticsModule,
  MatDialogModule,
  MaterialModule,
  HttpClientModule,
  BrowserModule,
  ServiceWorkerModule.register('pwa.js', { enabled: environmentProd.production }),
  BrowserAnimationsModule,
  PdfViewerModule,
  IconsModule,
  FuseCardModule,
  TourMatMenuModule.forRoot(),
  GoogleMapsModule,
  MatAutocompleteModule,
  MatChipsModule,
  CalendarModule,
  FormsModule,
];



/*Modules*/
@NgModule({
  declarations: [
    ...COMPONENTS,
    ProfileDeleteComponent,
    AppUpdateComponent,
    TouchExpertSidebarComponent,
    TouchInSidebarComponent,
    TipsComponent,
    TouchCongressSidebarComponent,
    TouchTabsComponent,
    TouchTabsSecondComponent,
    TouchDownloadTabDialogComponent,
    TouchFacultyTabDialogComponent,
    DefaultSidebarComponent,
  ],
  imports: [
    ...MODULES,
  ],
  providers: [
    ...SERVICES,
    [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
    [{ provide: ErrorHandler, useClass: ErrorTrackingService }],
    Deeplinks
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
