import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {map, takeUntil} from 'rxjs/operators';
import {AuthService} from './auth.service';
import { SingletonService } from './singleton.service';
import {Api2Service} from '@services/api2.service';


@Injectable()
export class ActivityService  {
  protected activityCompleted: [];
  protected activityProgress: [];

  constructor(
    public auth: AuthService,
    private api2Service: Api2Service,
    protected singleton: SingletonService
  ) {

  }

  /**
   * Update activity progress and completed fields
   */
  public userActivityStatus(): void {
    this.activityCompleted = this.auth.currentUser().completed_surveys ?? [];
    this.activityProgress = this.auth.currentUser().video_progress ?? [];
  }

  /**
   * Get activities list
   */
  public list(): Observable<any> {
    return this.api2Service.activities();
  }

  /**
   * Get activity by id
   * @param activityId
   */
  public getId(activityId: string): Observable<any> {
    return this.api2Service.activityById(activityId);
  }

  /**
   * Transform activity list
   *
   * @param id
   * @param data
   * @private
   */
  private transform(id: string, data: any): [] {
    this.userActivityStatus();
    const completed = this.addCompleted(id, data);
    const progress = this.addProgress(id, completed);
    return progress;
  }

  /**
   * Add completed field
   *
   * @param id
   * @param data
   * @private
   */
  private addCompleted(id: string, data: any): [] {
    let completed = false;
    this.activityCompleted.filter(survey => {
      // @ts-ignore
      if (survey.activityId === id) {
        completed = true;
      }
    });
    data.completed = completed;
    return data;
  }

  /**
   * Add progress field
   *
   * @param id
   * @param data
   * @private
   */
  private addProgress(id: string, data: any): [] {
    data.progress = this.activityProgress[id] !== undefined ? this.activityProgress[id].toFixed(0) : 0;
    return data;
  }
}
