import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DataSendingService} from '@services/data-sending.service';

@Component({
  selector: 'app-touch-header',
  templateUrl: './education-header.component.html',
  styleUrls: ['../../education.component.scss'],
})
export class EducationHeaderComponent implements OnInit {
  @Input() isDevice: any;
  @Input() templateColor: any;
  @Input() mobileMenu: any;

  constructor(protected dataSendingService: DataSendingService) {}

  public ngOnInit(): void {

  }

  public toggleMenu(): void {
    this.mobileMenu = !this.mobileMenu;
    this.dataSendingService.sendData('menuToggle', this.mobileMenu);
  }

  public getHeaderStyle(): string {
      let classes = '';
      classes += this.templateColor;
      return classes;
  }
}
