import { Component, OnInit } from '@angular/core';
import { EducationComponent } from '../education.component';

@Component({
  selector: 'app-touch-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['../education.component.scss']
})
export class EducationFeatureComponent extends EducationComponent implements OnInit {
  public ngOnInit(): void {
    super.ngOnInit();
  }
}
