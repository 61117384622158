import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {DeviceService} from './device.service';
import {EventData, EventTimeUpdate} from '../interfaces/event.interface';
import {environmentProd} from '@environments/environment';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {Api2Service} from '@services/api2.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private isLoaded = false;
  public version: string = environmentProd.version;
  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor(private auth: AuthService,
              private api2Service: Api2Service,
              private deviceService: DeviceService) {
  }

  public removeLeadingSlash(str: string): string {
    if (str.startsWith('/')) {
      return str.substring(1);
    }
    return str;
  }

  /**
   * Add Event Track
   *
   * @param eventName
   * @param eventData
   */
  public addEvent(eventName: string, eventData?: any): void {
    const eventTitle = this.removeLeadingSlash(eventName);
    let nxId = 'no_nx_id';
    let userCountry = 'no country';
    let userSpecialty = 'no specialty';

    if (eventData !== undefined && eventData.nx_id !== undefined && eventData.nx_id !== '') {
      nxId = eventData.nx_id;
    }

    if (this.auth.currentUser().nx_id !== undefined && this.auth.currentUser().nx_id !== '') {
      nxId = this.auth.currentUser().nx_id;
    }

    if (eventData !== undefined && eventData.country !== undefined && eventData.country !== '') {
      userCountry = eventData.country;
    }

    if (this.auth.currentUser().country !== undefined && this.auth.currentUser().country !== '') {
      userCountry = this.auth.currentUser().country;
    }

    if (eventData !== undefined && eventData.specialty !== undefined && eventData.specialty !== '') {
      userSpecialty = eventData.specialty;
    }

    if (this.auth.currentUser().specialty !== undefined && this.auth.currentUser().specialty !== '') {
      userSpecialty = this.auth.currentUser().specialty;
    }

    const clientId = nxId + '.' + nxId;
    const eventProperties = {
      client_id: clientId,
      user_id: nxId,
      nx_id: nxId,
      browser: navigator.userAgent,
      language: navigator.language,
      country: userCountry,
      specialty: userSpecialty,
      channel: this.deviceService.platforms(),
      uuid: localStorage.getItem('uuid'),
      email: this.auth.currentUser().email,
      data: {
        version: this.version,
      }
    };

    const analyticsData = {
      ...eventProperties,
      ...eventData
    };
   // console.log('EVENT', analyticsData);
    this.sendToMautic(eventTitle, analyticsData);
  }

  /**
   * Update event time
   *
   * @param time
   */
  public updateEvent(): void {
    // tslint:disable-next-line:radix
    const id = parseInt(localStorage.getItem('lastEvent'));
    //console.log('LAST ID', id);

    if (id > 0) {
      const mauticEvent: EventTimeUpdate = {
        id,
      };
      try {
        this.api2Service.updateEvent(mauticEvent).then(() => {
        }).catch(e => {
          console.log('error on updating event: ', JSON.stringify(mauticEvent));
        });
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  /**
   * Storing the data in mautic too
   * @param eventName
   * @param eventData
   * @private
   */
  private sendToMautic(eventName: string, eventData: any): void {
    const mauticEvent: EventData = {
      type: eventName,
      ...eventData
    };

    try {
      this.api2Service.saveEvent(mauticEvent).then((e) => {
        if (eventName !== 'activity_card_seen' && eventName !== 'activity_click' && eventName !== 'survey_click') {
          localStorage.setItem('lastEvent', e.data.id);
        }
      }).catch(e => {
        console.log('error on saving event: ', JSON.stringify(mauticEvent));
      });
    } catch (e) {
      console.log(e.message);
    }
  }
}
