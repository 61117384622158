import {Component} from '@angular/core';
import {AbstractActivityItemComponent} from '../activity-item/activity-item.component';

@Component({
  selector: 'app-education-card',
  templateUrl: 'education-card.component.html'
})
export class EducationCardComponent extends AbstractActivityItemComponent {

}
