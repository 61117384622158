import {Component, Inject, Input, OnInit} from '@angular/core';
import {DefaultSidebarComponent} from '../default-sidebar/default-sidebar.component';

@Component({
  selector: 'app-touch-congress-sidebar',
  templateUrl: './touch-congress-sidebar.component.html',
  styleUrls: ['../../../education.component.scss', '../../touch.component.scss', './touch-congress-sidebar.component.css']
})
export class TouchCongressSidebarComponent extends DefaultSidebarComponent {

}
