import {EnvLocal} from './enviroment.local';
import {EnvElementaryAi} from './enviroment.elementary-ai.prod';
import {EnvNuaxia} from './enviroment.nuaxia.prod';
import {EnvDevNuaxia} from './enviroment.nuaxia.dev';
import {EnvDevElementaryAi} from './enviroment.elementary-ai.dev';
import {Environment} from './enviroment.interface';

let config: Environment;

const location = (typeof window !== 'undefined') ? window.location.hostname : 'app.elementary-ai.com';

switch (location) {
  case 'localhost':
    config = EnvNuaxia;
    break;
  case 'test.app.nuaxia.com':
    config = EnvDevNuaxia;
    break;
  case 'test.app.elementary-ai.com':
    config = EnvDevElementaryAi;
    break;
  case 'externalportals-dev-c2ced.web.app':
    config = EnvDevNuaxia;
    break;
  case 'externalportals-dev-c2ced.firebaseapp.com':
    config = EnvDevElementaryAi;
    break;
  case 'app.nuaxia.com':
    config = EnvNuaxia;
    break;
  case 'app.elementary-ai.com':
    config = EnvElementaryAi;
    break;
  default:
    config = EnvNuaxia;
    break;
}
export const environmentProd = {
  ...config,
  version: '3.0.0',
  package: {
    ios: 'com.nuaxia.app',
    android: 'com.nuaxia.app',
  }
};
