import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Platform} from '@ionic/angular';
import {isPlatformBrowser} from '@angular/common';

declare const window: any;

@Injectable()
export class DeviceService {
  public isRealDevice = false;
  public realDeviceType = 'web';

  constructor(private device: Platform, @Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      this.isRealDevice = (this.device.is('android') || this.device.is('ios'));
      if (this.device.is('mobileweb')) {
        this.realDeviceType = 'ios';
      } else if (this.device.is('android')) {
        this.realDeviceType = 'android';
      } else if (this.device.is('ios')) {
        this.realDeviceType = 'ios';
      } else {
        this.realDeviceType = 'web';
      }
    }
  }

  public isDevice(): boolean {
    return this.isRealDevice;
  }

  public deviceType(): string {
    return this.realDeviceType;
  }

  public platforms(): string {
    return this.device.platforms().join(',');
  }
}
