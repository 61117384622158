import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html'
})
export class RedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const redirect = this.route.snapshot.queryParams.redirect;
    if (redirect) {
      location.replace(redirect);
    } else {
      location.replace('activities');
    }
  }

}
