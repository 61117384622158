import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Observable} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {AuthService} from './auth.service';
import { SingletonService } from './singleton.service';

@Injectable()
export class SpecialtyService extends FirebaseService {
  protected dbPath = '/specialties';
  protected collectionRef: AngularFirestoreCollection;

  constructor(
    protected db: AngularFirestore,
    protected singleton: SingletonService
  ) {
    super(db, singleton);
  }

  /**
   * Get activities list
   */
  public list(): Observable<any> {
    return this.db.collection(this.dbPath, query => query.orderBy('name', 'asc')).snapshotChanges().pipe(
      takeUntil(this.singleton.notifier),
      map(changes => {
        return changes.map(doc => {
          return {
            id: doc.payload.doc.id,
            data: doc.payload.doc.data()
          };
        });
      })
    );
  }
}
