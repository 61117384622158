import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../base.component';
import {environmentProd} from '@environments/environment';
import {Api2Service} from '@services/api2.service';
import { SignupOrLoginModalComponent } from '../modals/signup-or-login-modal/signup-or-login-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {HelperService} from '@services/helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DeviceService} from '@services/device.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponent implements OnInit {
  public site: string = environmentProd.site;
  public description: string = environmentProd.description;
  public pageTitle: string;
  public token: string;
  public loginType: string;
  public isTokenExpired = false;
  public error = undefined;
  public success = undefined;
  public loader = false;
  public post = false;

  constructor(protected router: Router,
              protected api2Service: Api2Service,
              protected activatedRoute: ActivatedRoute,
              protected dialog: MatDialog,
              protected helper: HelperService
  ) {
    super();
  }


  /**
   * Check user logged and setup form
   */
  ngOnInit(): void {
    this.loginWithToken();
  }

  public openDialog(): void {
    this.dialog.open(SignupOrLoginModalComponent);
  }
  /**
   * Redirect user to education page
   */
  public async loginWithToken(): Promise<void> {
    const token = this.getRouteParam('token');

    try {
      if (token !== '' && token !== null && typeof token !== 'undefined') {
        this.api2Service.deleteToken();
        this.api2Service.deleteUser();

        if (this.api2Service.storeTokenPromise(token)) {

          const result = await this.api2Service.token();

          if (result.status === 200 && this.api2Service.isAuthenticated()) {
            this.api2Service.storeUser(JSON.stringify(result.data.user));
            this.api2Service.storeToken(result.data.token);
            this.helper.reload();
            //await this.router.navigate(['learns/microlearning']);
          } else {
            this.isTokenExpired = true;
          }
        }
      }
      else {
        await this.router.navigate(['learns/microlearning']);
      }
    } catch (error) {

      this.isTokenExpired = true;
    }

  }
  /**
   * Wrapper function for getting route params' value for shorter syntax
   * @param routeParam
   * @private
   */
  private getRouteParam(routeParam: string): any {
    return this.activatedRoute.snapshot.paramMap.get(routeParam);
  }


  public stopLogin(message: string): void {
    this.error = message;
    this.loader = false;
  }
}
