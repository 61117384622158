<!-- touchCongress -->
<div class="cme-education-template-template-congress"
     *ngIf="education.video_groups.video_groups[0].type === 'new_touch_congress'">
  <div data-section="videos" class="js-tabs">
    <h1 class="h1 c-intro__title js-intro-title p-l-30"><span>Course Modules</span></h1>
    <nav class="c-activity-sidebar__internal">
      <ul class="c-activity-sidebar__list js-accordion">
        <li class="c-activity-sidebar__item--bg has-children js-accordion-item is-open"
            *ngFor="let groups of education.video_groups.video_groups;let index = index">
          <div class="c-activity-sidebar__link c-activity-sidebar__link--parent c-activity-sidebar__link--title">
            <span class="c-activity-sidebar__title">{{groups.title}}</span>
          </div>

          <ul class="c-activity-sidebar__list c-activity-sidebar__sub-list show">
            <li class="c-activity-sidebar__item c-activity-sidebar__item--child c-activity-sidebar__item--child-nb"
                *ngFor="let video of groups.videos; let index2 = index;">
                <a (click)="videoSelect('mainPlayer', video,'group-'+index,index2,video.time,0);" class="c-activity-sidebar__link js-sidebar-video-link-multi  mv-active"  [attr.id]="index === 0 ? 'videoStart' : null"
                   [ngClass]="{'is-complete' : videoCompleted('group-'+index,index2),'is-active':tabs[0] && videoTab['group-'+index][index2]}"
                >
                  <span class="c-activity-sidebar__progress">
                    <svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon"><use
                      xlink:href="#icon-tick"></use></svg></span>
                <span class="c-activity-sidebar__info">
                     <span class="c-activity-sidebar__sub-text js-video-title"><img src="{{video.icon}}" class="tc-icon"
                                                                                    alt=""> {{video.title}}</span>

                    </span>
                <span class="o-arrow o-arrow--right c-activity-sidebar__arrow-right">
                    <span class="unique-play-button"><i class="fa-regular fa-circle-play"></i></span>
                    <span>
                    </span></span></a>
            </li>
          </ul>
        </li>
      </ul><!-- /.c-activity-sidebar__list -->
      <app-tips></app-tips>
    </nav><!-- /.c-activity-sidebar__internal -->
  </div>
</div>
<!-- touchCongress END -->
