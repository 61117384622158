import {Component, Input, OnInit} from '@angular/core';
import {DataSendingService} from '@services/data-sending.service';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.css']
})
export class TextFieldComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() model: string;
  public data: string;

  constructor(protected dataSendingService: DataSendingService) {
  }

  ngOnInit(): void {
  }

  sendData($event: FocusEvent): void {
    this.dataSendingService.sendData(this.model, this.data);
  }
}
