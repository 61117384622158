import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-touch-download-tab-dialog',
  templateUrl: './touch-download-tab-dialog.component.html',
  styleUrls: ['../../css/dialog.scss', './touch-download-tab-dialog.component.css']
})
export class TouchDownloadTabDialogComponent {

  public selected: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<TouchDownloadTabDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { downloads: any },
  ) {
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
