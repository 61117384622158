import {Injectable} from '@angular/core';
import {environmentProd} from '@environments/environment';
import {Api2Service} from '@services/api2.service';



@Injectable()
export class GraylogService extends Api2Service {

  public endPoint = `${environmentProd.api.url}/log`;

  public stringifyCircular(circularObj: any): string {
    const cache = [];

    return JSON.stringify(circularObj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
  }

  // @ts-ignore
  /**
   * Send e-mail
   */

  public log(error: any): void {

    const message = error ? this.stringifyCircular(error) : 'No idea what happened';
    if(message.startsWith('{"rejection":{},"promise":{"')){
      return;
    }
    const data = {
      level: 'error',
      message
    };

    this.sendPostJson(this.endPoint, data);
  }
}
