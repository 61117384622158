import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {TokenInterface} from '../interfaces/token.interface';
import {TokensService} from '@services/tokens.service';
import {catchError} from 'rxjs/operators';
import {DeviceService} from '@services/device.service';
import {environmentProd} from '@environments/environment';


@Injectable()
export class ApiService {

  protected options: any = {};
  public apiKey = `${environmentProd.api.key}`;

  //////////////////////////


  constructor(protected http: HttpClient, protected tokenService: TokensService, protected deviceService: DeviceService) {
    this.setDefaultOptions();
  }

  //////////////////////////

  protected setDefaultOptions(): void {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'X-API-KEY': this.apiKey
      })
    };
  }

  /**
   * @function sendGet
   * @summary Wrapper function around GET requests
   * @param apiToCall
   * @param withoutCache - if the request should not use cache
   */
  public sendGet(apiToCall: string, withoutCache?: boolean): Observable<any> {
    if (!apiToCall) {
      return;
    }
    this.addToken();
    return this.http.get(apiToCall, this.options).pipe(
      catchError(this.handleError)
    );

  }

  public sendPost2(apiToCall: string, data: any, withoutToken?: boolean): Observable<any> {
    if (!apiToCall) {
      return;
    }
    if (withoutToken !== true) {
      this.addToken();
    }

    return this.http.post(apiToCall, data, this.options).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @function sendPost
   * @summary Wrapper function around POST requests
   * @param apiToCall
   * @param data
   */
  public sendPostJson(apiToCall: string, data: any): Observable<any> {
    if (!apiToCall) {
      return;
    }
    return this.http.post(apiToCall, data, this.options).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @function sendPut
   * @summary Wrapper function around PUT requests
   * @param apiToCall
   * @param data
   * @param encrypt
   */
  public sendPut(apiToCall: string, data: any, encrypt?: boolean): Observable<any> {
    if (!apiToCall) {
      return;
    }
    this.addToken();
    return this.http.put(apiToCall, (encrypt ? this.tokenService.set(data) : data), this.options).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @function sendPatch
   * @summary Wrapper function around PATCH requests
   * @param apiToCall
   * @param data
   * @param encrypt
   */
  public sendPatch(apiToCall: string, data: any, encrypt?: boolean): Observable<any> {
    if (!apiToCall) {
      return;
    }
    this.addToken();
    return this.http.patch(apiToCall, (encrypt ? this.tokenService.set(data) : data), this.options).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @function sendDelete
   * @summary Wrapper function around DELETE requests
   * @param apiToCall
   */
  public sendDelete(apiToCall: string): Observable<any> {
    if (!apiToCall) {
      return;
    }
    this.addToken();
    return this.http.delete(apiToCall).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @function addToken
   * @summary Attaching the token to the requests
   * @param uniqueToken - If we want to attach a unique token instead of the default
   * @protected
   */
  protected addToken(uniqueToken?: string): void {
    if (!this.options.headers.has('Authorization')) {
      const token = uniqueToken ? uniqueToken : localStorage.getItem('tuser');
      if (token) {
        let newHeaders: HttpHeaders;
        newHeaders = this.options.headers.append('Authorization', 'Bearer ' + token);
        this.options.headers = newHeaders;
      }
    }
  }

  /**
   * Setting custom token for api calls
   * @param uniqueToken
   */
  public changeToken(uniqueToken: TokenInterface): void {
    let newHeaders: HttpHeaders;
    const type = uniqueToken.type ? uniqueToken.type : 'Bearer';
    const token = uniqueToken.token;
    newHeaders = this.options.headers.delete('Authorization');
    newHeaders = newHeaders.append('Authorization', type + ' ' + token);
    this.options.headers = newHeaders;
  }

  /**
   * Resetting the default token for the application
   */
  public setDefaultToken(): void {
    let newHeaders: HttpHeaders;
    const token = localStorage.getItem('tuser');
    if (token) {
      newHeaders = this.options.headers.delete('Authorization');
      newHeaders = this.options.headers.append('Authorization', 'Bearer ' + token);
      this.options.headers = newHeaders;
    }
  }

  /**
   * @function handleError
   * @summary handling errors in http requests
   * @param error
   * @protected
   */
  protected handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent
    ) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status},
         body was: ${error.error.message}`);
    }
    return throwError('');
  }

}
