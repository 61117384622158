import {Component, Inject, Input, OnInit} from '@angular/core';
import {DefaultSidebarComponent} from '../default-sidebar/default-sidebar.component';

@Component({
  selector: 'app-touch-in-sidebar',
  templateUrl: './touch-in-sidebar.component.html',
  styleUrls: ['../../../education.component.scss', '../../touch.component.scss', './touch-in-sidebar.component.css']
})
export class TouchInSidebarComponent extends DefaultSidebarComponent {
  public openGroup = 0;

  public setOpenGroup(index: number): void {
    this.openGroup = index;
  }
}
