import {Component, HostListener, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {ActivityService} from '@services/activity.service';
import {ActivatedRoute} from '@angular/router';
import {BaseComponent} from '../base.component';
import {DataSendingService} from '@services/data-sending.service';
import {Video, VideoGroup} from '../../interfaces/video.interface';
import {MatSliderChange} from '@angular/material/slider';
import {environmentProd} from '@environments/environment.prod';
import {AnalyticsService} from '@services/analytics.service';
import {DOCUMENT, Location} from '@angular/common';
import {DeviceService} from '@services/device.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Api2Service} from '@services/api2.service';


@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EducationComponent extends BaseComponent implements OnInit {
  public id = '';
  public tabs = [];
  public videoTab = [];
  public expertTab = 0;
  public menuTab = 0;
  public overviewTab = 0;
  public education: any;
  public totalVideo = 0;
  public completedVideos = [];
  public activityId: string;
  public pdf = '';
  public pdfPage = 1;
  public pdfTimes: any;
  public pdfTime = 999;
  public videosize: any = '4';
  public title = '';
  public back = 'education';
  public firstLoad = 0;
  public mobileMenu = false;
  public startedVideo = 0;
  public startedGroup: any = 0;
  public startedTime: any = 0;
  public startedType: any = '';
  private videoTimes: any;
  public selectedLanguage = 'none';
  public selectedPdfLanguage = 'none';
  public selectedPdf = '';
  public template = '';
  public templateColor = '';
  public logo = environmentProd.logo;
  public isDevice = false;
  public innerWidth: any;

  constructor(@Inject(DOCUMENT) document,
              public auth: AuthService,
              protected activity: ActivityService,
              protected api2Service: Api2Service,
              protected route: ActivatedRoute,
              protected dataSendingService: DataSendingService,
              public analytics: AnalyticsService,
              protected location: Location,
              protected device: DeviceService,
              public sanitizer: DomSanitizer
  ) {
    super();
    this.isDevice = this.device.isDevice();
    this.innerWidth = window.innerWidth;
  }


  @HostListener('window:scroll', ['$event'])

  public onWindowScroll(e): void {
    /* if (window.pageYOffset > 100) {
       let element = document.getElementById('stickybar');
       element.classList.add('is_sticky');
     } else {
       let element = document.getElementById('stickybar');
       element.classList.remove('is_sticky');
     }*/
  }


  public ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.activityLoad();
    this.pdfLoad();
    this.videoStart();
  }

  public betterHtml(references: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(references);
  }

  /**
   * Back button
   */
  public backButton(): void {
    this.location.back();
  }

  /////////////////////////////////////////////////////
  // ACTIVITY FUNCTIONS
  ////////////////////////////////////////////////////
  /**
   * Get activity data
   *
   * @protected
   */
  protected activityLoad(): void {
    this.subscribe(this.activity.getId(this.id), (res) => {
      this.education = res.data;
      this.setTemplate();
      this.setGroupTemplate();
      this.activityId = this.education.id;
      this.totalVideo = this.videoCount();
      this.videoTimes = this.videoTable.length > 0 ? this.videoTimes : this.videoTable();
      this.title = this.education.title;
      if (this.education.downloads && this.education.downloads.downloads) {
        this.pdf = this.education.downloads.downloads[0].url !== undefined ? this.education.downloads.downloads[0].url : '';
      }

      if (this.education.slides && this.education.slides.slides !== undefined && this.education.slides.slides.length > 0) {
        this.pdfTimes = this.education.slides.slides !== undefined ? this.education.slides.slides : [];
      }

      this.videosCompleted();

      this.analytics.addEvent('activity_click', {
        page_title: this.education.title,
        project: this.education.project_number,
        activity: this.activityId
      });
      this.analytics.addEvent('education_page', {
        page_title: this.education.title,
        project: this.education.project_number,
        activity: this.activityId
      });
    });

    this.toggleTabs(0);
    this.toggleTabs(1);
  }

  /**
   * Set template
   */
  public setTemplate(): void {
    if (typeof this.education.template_color === undefined || this.education.template_color === 'orange') {
      this.templateColor = 't-ime';
    } else {
      this.templateColor = 't-tmc';
    }
  }

  /**
   * Set Group template
   */
  public setGroupTemplate(): void {
    const reviewTemplates = ['touchCONGRESS'];

    if (reviewTemplates.includes(this.education.content_type)) {
      this.template = 'review';
    } else {
      this.template = 'expert';
    }
  }

  /////////////////////////////////////////////////////
  // PDF FUNCTIONS
  ////////////////////////////////////////////////////

  /**
   * Subscribe to video time to show pdf page
   *
   * @protected
   */
  protected pdfLoad(): void {
    this.subscribe(this.dataSendingService.getData('videoTime'), (result) => {
      this.pdfPageSet(result.currentTime);
      this.nextVideo(result);
    });
  }

  /**
   * Start next video
   *
   * @param time
   */
  public nextVideo(time): void {
    let group = this.startedGroup;

    if (this.startedGroup.includes('group-')) {
      group = this.startedGroup.replace('group-', '');
    }

    let nextVideo: number;
    let nextTime: any;
    nextVideo = this.startedVideo;
    if (this.startedType === 'mainPlayer') {
      nextVideo = this.startedVideo + 1;
    }

    // tslint:disable-next-line:max-line-length
    if (this.education.video_groups.video_groups[group].videos[nextVideo] !== undefined) {
      nextTime = this.timeToSec(this.education.video_groups.video_groups[group].videos[nextVideo].time);
    } else {
      nextTime = time.seekRange.end;
    }

    // if next video we just to next video
    if ((time.currentTime >= nextTime)) {
      this.updateProgress(this.startedGroup, this.startedVideo);
      this.startedVideo++;

      if (this.education.video_groups.video_groups[group].videos[this.startedVideo] !== undefined) {
        // tslint:disable-next-line:max-line-length
        this.startedTime = this.education.video_groups.video_groups[group].videos[this.startedVideo].time !== undefined ? this.education.video_groups.video_groups[group].videos[this.startedVideo].time : 0;
        if (this.startedType === 'expertPlayer') {
          // tslint:disable-next-line:max-line-length
          this.videoSelect(this.startedType, this.education.video_groups.video_groups[group].videos[this.startedVideo], this.startedGroup, this.startedVideo, '00:00');
        } else {
          // tslint:disable-next-line:max-line-length
          this.videoSelect(this.startedType, this.education.video_groups.video_groups[group].videos[this.startedVideo], this.startedGroup, this.startedVideo, this.startedTime, true);
        }
      }

    }
  }

  /**
   * Set pdf page
   *
   * @param time
   */
  public pdfPageSet(time: number): void {

    if (this.pdfTimes !== undefined) {
      let leave = false;

      for (const pdf of this.pdfTimes) {
        if (time <= this.timeToSec(pdf.time) && leave !== true) {
          this.pdfPage = pdf.page;
          leave = true;
        }
      }
    }
  }

  /**
   * Resize pdf window
   *
   * @param $event
   */
  public pdfResize($event: MatSliderChange): void {
    const el = document; // This can be your element on which to trigger the event
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    el.dispatchEvent(event);
  }

  /**
   * Download pdf by language
   *
   * @param language
   */
  public pdfDownload(language: string): void {
    if (language === 'English') {
      this.selectedPdfLanguage = 'English';
      this.selectedPdf = this.pdf;
    }
    for (const download of this.education.downloads.downloads) {
      if (download.language === language) {
        this.selectedPdf = download.url;
        this.selectedPdfLanguage = download.language;
      }
    }
  }

  /////////////////////////////////////////////////////
  // VIDEO FUNCTIONS
  ////////////////////////////////////////////////////

  /**
   * Start playing first video
   */
  public videoStart(): void {
    setTimeout(() => {
      if (document.getElementById('playExpertVideo') !== null) {
        document.getElementById('playExpertVideo').click();
      }
      if (document.getElementById('playReviewVideo') !== null) {
        document.getElementById('playReviewVideo').click();
      }

      if (this.education.content_type === 'touchMDT') {
        document.getElementById('mdtWatch').click();
      }

      if (this.education.video_groups.video_groups[0].length > 0) {
        this.completedVideos['group-1'] = []; // need foreach later
      }
    }, 500);
  }

  public videoLanguage(data: any): any {
    const language = JSON.parse(data);
    if (language.language !== '') {
      this.selectedLanguage = language.language;
    } else {
      this.selectedLanguage = 'none';
    }
    this.videoSelect(language.playerName, language.video, language.group, language.selectedVideo, '00:00', false, language.mediaId);
  }

  public videoLanguageData(playerName: string, video: Video, group: string, selectedVideo: number, language: string, mediaId: string): any {
    return JSON.stringify({
      playerName,
      video,
      group,
      selectedVideo,
      language,
      mediaId
    });
  }

  /**
   * Sending the selected video to the subscribers
   *
   * @param playerName - the elementId of the player, we want to play the selected video
   * @param video - video to be played
   * @param group
   * @param selectedVideo
   * @param time
   * @param noreload
   * @param language
   */
  public videoSelect(playerName: string, video: Video, group?: string, selectedVideo?: number, time?: string, noreload: boolean = false, language: string = ''): void {
    const data = {
      playerId: playerName,
      startTime: time,
      video,
      mediaId: language,
    };
    if (!noreload) {
      this.dataSendingService.sendData('selectedVideo', data);
      this.dataSendingService.sendData('activeVideo', selectedVideo);
    }

    this.startedVideo = selectedVideo;
    this.startedGroup = group;
    this.startedTime = time;
    this.startedType = playerName;

    this.videoTab[group] = [];
    this.videoTab[group][selectedVideo] = true;
    if (this.firstLoad > 0) {
      this.scrollTo(group);
    }
    this.firstLoad++;
    this.closeMenu();
  }

  /**
   * Video Size
   */
  public videoSize(): string {
    if (this.videosize === 1) {
      return '80%';
    }
    if (this.videosize === 2) {
      return '70%';
    }
    if (this.videosize === 3) {
      return '60%';
    }
    if (this.videosize === 4) {
      return '50%';
    }
    if (this.videosize === 5) {
      return '40%';
    }
    if (this.videosize === 6) {
      return '30%';
    }
    if (this.videosize === 7) {
      return '20%';
    }
  }

  /**
   * Count videos in education
   */
  protected videoCount(): number {
    let count = 0;
    for (const groups of this.education.video_groups.video_groups) {
      count += groups.videos.length;
    }
    return count;
  }

  /**
   * Count videos in education
   */
  protected videoTable(): any[] {
    const videoTable = [];
    let i = 1;
    let j = 1;
    for (const groups of this.education.video_groups.video_groups) {
      for (const video of groups.videos) {
        if (videoTable['group-' + i] === undefined) {
          videoTable['group-' + i] = [];
        }
        videoTable['group-' + i][j] = video.time;
        j++;
      }
      i++;
    }
    return videoTable;
  }

  /**
   * Set completed videos
   *
   * @protected
   */
  protected videosCompleted(): void {
    if (this.auth.currentUser().video_completed !== undefined) {
      if (this.auth.currentUser().video_completed[this.activityId] !== undefined) {
        this.completedVideos = this.auth.currentUser().video_completed[this.activityId];
      }
    }
  }

  /**
   * Count completed videos
   */
  protected videoCompletedCount(): number {
    let count = 0;

    for (const video of Object.keys(this.completedVideos)) {
      count += this.completedVideos[video].length;
    }
    return count;
  }

  /**
   * Check step completed
   *
   * @param group
   * @param id - Simple index
   */
  public videoCompleted(group: string, id: number): boolean {
    if (this.completedVideos[group] === undefined) {
      this.completedVideos[group] = [];
      return false;
    } else {
      return this.completedVideos[group].includes(id);
    }
  }

  /////////////////////////////////////////////////////
  // NAVIGATION FUNCTIONS
  ////////////////////////////////////////////////////

  /**
   * Toggle Tabs
   *
   * @param id
   */
  public toggleTabs(id: number): void {
    this.tabs[id] = !this.tabs[id];
    this.videoTab['group-' + id] = [];
    this.videoTab['group-' + id][0] = true;
  }

  /**
   * Show expert tab
   *
   * @param id
   */
  public expertTabs(id: number): void {
    this.expertTab = id;
  }

  /**
   * Show expert tab
   *
   * @param id
   * @param element
   */
  public showMenu(id: number, element: string): void {
    this.closeMenu();
    this.scrollTo(element);
    this.menuTab = id;
  }

  /**
   * Show expert tab
   *
   * @param id
   */
  public showOverviewTab(id: number): void {
    this.overviewTab = id;
  }

  /**
   * Update user progress
   *
   * @param group
   * @param selectedVideo - Simple index
   */
  public updateProgress(group: string, selectedVideo: number): void {
    let videoCompleted;
    let videoProgress;

    if (!this.videoCompleted(group, selectedVideo)) {
      this.completedVideos[group].push(selectedVideo);
    }

    const percentage = ((this.videoCompletedCount() / this.totalVideo) * 100);

    if (this.auth.currentUser().video_completed === undefined) {
      videoCompleted = {
        [this.activityId]: Object.assign({}, this.completedVideos)
      };
    } else {
      videoCompleted = this.auth.currentUser().video_completed;
      videoCompleted[this.activityId] = Object.assign({}, this.completedVideos);
    }

    if (this.auth.currentUser().video_progress === undefined) {
      videoProgress = {
        [this.activityId]: (percentage > 100 ? 100 : percentage)
      };
    } else {
      videoProgress = this.auth.currentUser().video_progress;
      videoProgress[this.activityId] = (percentage > 100 ? 100 : percentage);
    }

    this.api2Service.update({
        activity_id: this.activityId,
        video_progress: (percentage > 100 ? 100 : percentage),
        video_completed: Object.assign({}, this.completedVideos)
      }
    ).then(r => r);

    const profile = JSON.parse(localStorage.getItem('user'));
    const updatedProfile = JSON.stringify({...profile, video_progress: videoProgress, video_completed: videoCompleted});
    localStorage.setItem('user', updatedProfile);
  }

  /////////////////////////////////////////////////////
  // OTHER FUNCTIONS
  ////////////////////////////////////////////////////


  /**
   * Calculate second from 00:00 time
   *
   * @param time
   * @protected
   */
  protected timeToSec(time: string): number {
    const timeArray = time.split(':'); // split it at the colons
    return (+timeArray[0]) * 60 + (+timeArray[1]);
  }

  /**
   * Slider size
   */
  public rangeSize(): string {
    if (this.videosize === 1) {
      return '20%';
    }
    if (this.videosize === 2) {
      return '30%';
    }
    if (this.videosize === 3) {
      return '40%';
    }
    if (this.videosize === 4) {
      return '50%';
    }
    if (this.videosize === 5) {
      return '60%';
    }
    if (this.videosize === 6) {
      return '70%';
    }
    if (this.videosize === 7) {
      return '80%';
    }
  }

  /**
   * Toggle mobile menu
   */
  public toggleMenu(): void {
    /* if (this.isMobile) {*/
    this.mobileMenu = !this.mobileMenu;
    /* }*/
  }

  /**
   * Watch menu toggle
   */
  public watchMenu(): void {
    this.subscribe(this.dataSendingService.getData('menuToggle'), (result) => {
      this.mobileMenu = result;
    });
  }

  /**
   * Close mobile menu
   */
  public closeMenu(): void {
    /* if (this.isMobile) {*/
    this.mobileMenu = false;
    /* }*/
  }

  public countPanelist(panelists): number {
    let count = 0;
    for (const panelist of panelists) {
      if (panelist.panelist === true) {
        count++;
      }
    }
    return count;
  }

  public countNotPanelist(): string {
    let notPanelists = '';
    for (const panelist of this.education.faculty.faculty) {
      if (panelist.panelist === false || panelist.panelist === undefined) {
        notPanelists += panelist.name + ', ';
      }
    }
    return notPanelists.slice(0, -2);
  }


  /**
   * Other experts
   * @param primary
   * @param others
   * @param exists
   */
  public otherExperts(primary: string, others: any, exists?: string): any {
    if (others === undefined) {
      return primary;
    }
    const experts = [];
    experts.push(primary);

    for (const exptert of others) {
      experts.push(exptert.name);
    }
    if (exists !== undefined) {
      return experts.includes(exists);
    }
    return experts.join(', ');
  }


  public blockFromTop(index: number): string {
    const defaultTop = 0;
    switch (index) {
      case 0:
        return '0px';
        break;
      case 1:
        return '0px';
        break;
      case 2:
        return '0px';
        break;
      case 3:
        return '0px';
        break;
      case 4:
        return (defaultTop * 2) + 'px';
        break;
      case 5:
        return (defaultTop * 2) + 'px';
        break;
      case 6:
        return (defaultTop * 3) + 'px';
        break;
      case 7:
        return (defaultTop * 3) + 'px';
        break;
      case 8:
        return (defaultTop * 4) + 'px';
        break;
      case 9:
        return (defaultTop * 4) + 'px';
        break;
      default:
        break;
    }
  }

  public blockHeight(videos): string {
    if (this.innerWidth < 800) {
      return 'auto';
    }
    let defaultSize = 130;
    let longestText = 0;
    for (const video of videos) {
      longestText = video.description.length > longestText ? video.description.length : longestText;
    }

    return longestText + defaultSize + 'px';
  }

  public blockMarginBottom(index): string {
    switch (index) {
      case 3:
        return '0px';
        break;
      case 4:
        return '0px';
        break;
      default:
        return '0px';
        break;
    }
  }

  public blockMarginTop(index): string {
    if (this.innerWidth < 800) {
      return '0px';
    }
    switch (index) {
      case 3:
        return '-67.9765px';
        break;
      case 4:
        return '-73.4765px';
        break;
      default:
        return '0 px';
        break;
    }
  }

  public getTouchMdtHeader(): string {
    let classes = '';
    classes += this.templateColor;
    if (this.education.content_type === 'touchMDT') {
      classes += ' is-active';
    }
    return classes;
  }
}
