import {Component, Inject, OnInit, Sanitizer, ViewEncapsulation} from '@angular/core';
import {EducationComponent} from '../education.component';
import {DOCUMENT, Location} from '@angular/common';
import {AuthService} from '@services/auth.service';
import {ActivityService} from '@services/activity.service';
import {ActivatedRoute} from '@angular/router';
import {DataSendingService} from '@services/data-sending.service';
import {AnalyticsService} from '@services/analytics.service';
import {DeviceService} from '@services/device.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {DownloadDialogComponent, FacultyDialogComponent} from '../conversation/conversation.component';
import {Api2Service} from '@services/api2.service';

@Component({
  selector: 'app-touch-touch',
  templateUrl: './touch.component.html',
  styleUrls: ['../education.component.scss', 'touch.component.scss']
})
export class EducationTouchComponent extends EducationComponent implements OnInit {
  public activeTab = 0;
  public tipsOpen = true;
  public openGroup = 0;
  public activeGroup = 0;
  public activeVideo = 0;
  public loadvideo = false;

  constructor(@Inject(DOCUMENT) document,
              public auth: AuthService,
              protected activity: ActivityService,
              protected api2Service: Api2Service,
              protected route: ActivatedRoute,
              protected dataSendingService: DataSendingService,
              public analytics: AnalyticsService,
              protected location: Location,
              protected device: DeviceService,
              public dialog: MatDialog,
              public sanitizer: DomSanitizer
  ) {
    super(document, auth, activity, api2Service, route, dataSendingService, analytics, location, device, sanitizer);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.videoStart();
    this.watchDialog();
  }

  public videoStart(): void {
    if (!this.loadvideo) {
      setTimeout(() => {
        document.getElementById('videoStart').click();
        this.loadvideo = true;
      }, 1000);
    }
  }

  public setActiveTab(tab: number): void {
    this.activeTab = tab;
  }

  public toggleTips(): void {
    this.tipsOpen = !this.tipsOpen;
  }

  public goTo(elementId: string): void {
    //this.setActiveTab(elementId);
    this.scrollTo(elementId);
  }

  public setOpenGroup(index: number): void {
    this.openGroup = index;
  }

  public watchDialog(): void {
    this.subscribe(this.dataSendingService.getData('openModal'), (result) => {
      if (result === 'openDownload') {
        this.openDownload();
      }
      if (result === 'openFaculty') {
        this.openFaculty();
      }
    });
  }

  public openDownload(): void {
    const dialogRef = this.dialog.open(DownloadDialogComponent, {
      data: {
        downloads: this.education.downloads.downloads
      },
    });
  }

  public openFaculty(): void {
    const dialogRef = this.dialog.open(FacultyDialogComponent, {
      height: '90%',
      width: '90%',
      data: {
        faculty: this.education.faculty.faculty
      }
    });
  }
}

