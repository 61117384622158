<div  id="app-header" class="cme-education-template cme-education-template-templates template-in-conversation cme-education-template-templatestemplate-in-conversation-php single single-cme-education has-sidebar activity-template template-in-conversation" (scroll)="onWindowScroll($event);">

<header class="c-header c-header__activity c-activity-container js-site-header" style="box-sizing: border-box;" *ngIf="templateColor">
  <div class="o-wrapper c-header__wrapper" [ngClass]="{'device': isDevice}" style="width: 100%;max-width: 100%">
    <app-menu style="width: 100%"></app-menu>
  </div>
</header>

<div class="content-container o-wrapper" *ngIf="education">
  <section class="c-activity-content js-activity-content">
    <div class="c-video-section-wrap-new c-video-section-wrap in-conversation">
      <app-player [elementId]="'mainPlayer'"></app-player>
      <!-- title -->
      <div class="c-intro__title-wrap">
        <h1 class="h1 c-intro__title js-intro-title">
          <span class="js-activity-type">
            {{education.content_title}}
          </span>
        </h1>
        <h1 class="activity-title">{{education.title}}</h1>
      </div>
      <!-- title end -->
      <app-touch-tabs [education]="education"></app-touch-tabs>
      <app-touch-tabs-second [education]="education"></app-touch-tabs-second>
    </div>
  </section>
  <!-- touchIn-->
  <app-touch-expert-sidebar class="desktop-video-chapters" [education]="education"></app-touch-expert-sidebar>
  <app-touch-congress-sidebar class="desktop-video-chapters" [education]="education"></app-touch-congress-sidebar>
  <app-touch-in-sidebar class="desktop-video-chapters" [education]="education"></app-touch-in-sidebar>
  <!-- touchIn END-->
</div>


<app-touch-footer [education]="education"></app-touch-footer>
<!-- Load SVG template file -->
<app-education-svg style="display:none"></app-education-svg>
</div>
