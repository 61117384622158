import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-touch-button',
  templateUrl: 'link-button.component.html',
  styleUrls: ['link-button.component.scss']
})
export class EducationLinkButtonComponent {
  @Input() icon: string;
  @Input() text: string;
  @Input() link = '#';
}
