import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Api2Service } from '@services/api2.service';
import { AnalyticsService } from '@services/analytics.service';
import { AuthService } from '@services/auth.service';
import { PendingApprovalModalComponent } from '../pending-approval-modal/pending-approval-modal.component';
import {DataSendingService} from '@services/data-sending.service';
import {BaseComponent} from '../../base.component';

@Component({
  selector: 'app-profile-update-modal',
  templateUrl: './profile-update-modal.component.html'
})
export class ProfileUpdateModalComponent extends BaseComponent implements OnInit {
  public form: FormGroup;
  public post = false;
  public loader = false;
  public error = null;
  protected user = this.auth.currentUser();
  private country: any;
  private address: any;
  private workplace: string;

  constructor(public dialogRef: MatDialogRef<ProfileUpdateModalComponent>,
              public dialog: MatDialog,
              private auth: AuthService,
              private api2Service: Api2Service,
              private analytics: AnalyticsService,
              protected dataSendingService: DataSendingService,
              @Inject(MAT_DIALOG_DATA) public data: {name: string},
              protected formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void{
    this.createForm();
    this.subscribe(this.dataSendingService.getData('workplace'), (result) => {
      this.country = result.address.country_short;
      this.address = result.address;
      this.workplace = result.address.name + ' (' + result.address.formatted_address + ')';
    });
  }

  protected createForm(): void {
    this.form = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.min(3)]],
      last_name: ['', [Validators.required, Validators.min(3)]],
      workplace: ['', [Validators.required, Validators.min(3)]],
    });
  }

  public setField(fieldName: string, value: any): void {
    this.form.get(fieldName).setValue(value);
  }

  get fields(): any {
    return this.form.controls;
  }

  public submit(): void {
    this.error = undefined;
    this.loader = true;
    this.post = true;
    if (this.form.invalid) {
      this.error = true;
      this.loader = false;
      return;
    }
    const workplaceName = this.workplace;
    const review = {
      first_name: this.fields.first_name.value,
      last_name: this.fields.last_name.value,
      country: this.country,
      pwork: [workplaceName],
      workplace: [{
        name: this.workplace,
        type: this.fields.workplace.value,
        country: this.country,
        address: this.address,
      }]
    };

    this.api2Service.review(review).then(() => {
      this.api2Service.refreshUser().then( () => {
        this.analytics.addEvent('last-step-review', {
          email: this.user.email,
        });
        this.dialogRef.close();
        this.dialog.open(PendingApprovalModalComponent);
      });
    });
  }
}
