import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivityService } from '@services/activity.service';
import { BaseComponent } from '../base.component';
import { AuthService } from '@services/auth.service';
import { PushService } from '@services/push.service';
import { AnalyticsService } from '@services/analytics.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { ActivatedRoute, Router } from '@angular/router';
import { IntroService } from '@services/intro.service';
import { DeviceService } from '@services/device.service';
import { DataSendingService } from '@services/data-sending.service';
import { Api2Service } from '@services/api2.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ActivitiesComponent extends BaseComponent implements OnInit {

  public title = 'Activities';
  public activities: any;
  public tabs = [];
  public back = 'activities';
  public interval: any;
  public userProfile: any;
  public showMissingProfile = false;
  public seenActivites = [];
  public checkItem = false;
  public medicalContentCategories = [
    'Medical communication'
  ];
  public surveyCategories = [
    'Short Survey',
    'My Emotions',
  ];
  public categories = [
    'My Emotions',
    'Short Survey',
    'Medical Education',
    'Education',
    'Education & Survey',
  ];

  public groupedActivities = {
    library: {
      surveys: [],
      communication: [],
      education: []
    },
    new: {
      surveys: [],
      communication: [],
      education: []
    }
  };
  public page = 'surveys';
  public isDevice = false;
  public loading = true;
  public showInactiveProfile = false;

  public counter = [];

  constructor(private activity: ActivityService,
              private auth: AuthService,
              private push: PushService,
              private analytics: AnalyticsService,
              public tourService: TourService,
              private router: Router,
              private route: ActivatedRoute,
              private introService: IntroService,
              private device: DeviceService,
              private dataSendingService: DataSendingService,
              private api2Service: Api2Service
  ) {
    super();
    this.checkUserProfile();
    this.userProfile = this.auth.currentUser();
    this.getActivities();
  }

  async ngOnInit(): Promise<any> {
    this.tabSet();
    this.sendUserPushId();
    this.isDevice = this.device.isDevice();
    this.introService.start();
    this.push.saveDeviceToken();
    this.analytics.addEvent('portal_activities');
    this.subscribeToActivitySeen();

    setTimeout(() => {
      this.checkItem = true;
    }, 1000);
  }

  private async checkUserProfile(): Promise<any> {
    const profile = this.auth.currentUser();
    console.log(profile);

    if ((profile.main_specialty === null || profile.main_specialty === undefined || profile.main_specialty === '') && profile.is_published === 0 && profile.is_review === 0) {
      location.replace('last-step');
    }

    if (profile.is_published !== undefined && profile.is_published === 0) {
      this.showInactiveProfile = true;
    }
  }

  /**
   * Set first tab
   *
   * @protected
   */
  protected tabSet(): void {
    this.route.paramMap.subscribe(params => {
      this.tabToggle(params.get('tab'));
    });
  }

  public tabToggle(tab: string): void {
    if (tab !== '') {
      this.page = tab;
    }
  }

  refresh(): void {
    window.location.reload();
  }

  private subscribeToActivitySeen(): void {
    this.subscribe(this.dataSendingService.getData('activitySeen'), (eventData) => {
      if (this.seenActivites.includes(eventData.activity) || eventData.project === undefined || eventData.project === '') {
        return;
      } else {
        this.analytics.addEvent('activity_card_seen', eventData);
      }
    });
  }

  /**
   * Get activities
   */
  public getActivities(): void {
    this.loading = true;

    this.subscribe(this.api2Service.activities(), (res) => {
      if (res.success === true) {
        this.activities = res.data.activities;
        this.groupActivities();
        this.loading = false;
      } else {
        // smg message
        this.loading = false;
      }
    });
  }

  protected groupActivities(): void {
    this.groupedActivities.new.surveys = [];
    this.groupedActivities.library.surveys = [];
    this.groupedActivities.new.communication = [];
    this.groupedActivities.library.communication = [];
    this.groupedActivities.new.education = [];
    this.groupedActivities.library.education = [];

    this.activities.forEach((activity) => {
      switch (activity.category) {
        case 'Short Survey':
          if (this.isNew(activity) && this.canBeShown(activity)) {
            this.groupedActivities.new.surveys.push(activity);
          }
          if (this.isInLibrary(activity) && this.canBeShown(activity)) {
            this.groupedActivities.library.surveys.push(activity);
          }
          break;
        case 'My Emotions':
          if (this.isNew(activity) && this.canBeShown(activity)) {
            this.groupedActivities.new.surveys.push(activity);
          }
          if (this.isInLibrary(activity) && this.canBeShown(activity)) {
            this.groupedActivities.library.surveys.push(activity);
          }
          break;
        case 'Medical communication':
          if (this.isNew(activity) && this.canBeShown(activity) && this.showMedicalContent(activity)) {
            this.groupedActivities.new.communication.push(activity);
          }
          if (this.isInLibrary(activity) && this.canBeShown(activity) && this.showMedicalContent(activity)) {
            this.groupedActivities.library.communication.push(activity);
          }
          break;
        default:
          if (this.isNew(activity) && this.canBeShown(activity)) {
            this.groupedActivities.new.education.push(activity);
          }
          if (this.isInLibrary(activity) && this.canBeShown(activity)) {
            this.groupedActivities.library.education.push(activity);
          }
          break;
      }
    });
  }

  /**
   * @summary Function to decide, if the activity should be shown in the new tab
   * @param activity - activity to be checked
   */
  public isNew(activity: any): boolean {
    if (!this.isAllowedPublishStatus(activity)) {
      return false;
    }

    if (activity.start_in === 'Library' || activity.start_in === '' || activity.start_in === null || activity.start_in === 'null') {
      return false;
    }

    if (activity.progress.progress > 0 || activity.progress.video_completed === true) {
      return false;
    }

    return true;
  }

  /**
   * @summary Function to decide if the activity should be shown in the Library
   * @param activity - activity to be checked
   */
  public isInLibrary(activity: any): boolean {
    if (!this.isAllowedPublishStatus(activity)) {
      return false;
    }

    if (activity.start_in === '' || activity.start_in === null || activity.start_in === 'null') {
      return false;
    }

    return (activity.progress.progress > 0 || activity.progress.video_completed === true || activity.start_in === 'Library');
  }

  /**
   * @summary Check if activity is published, or the current user can see unpublished content
   * @param activity
   */
  protected isAllowedPublishStatus(activity: any): boolean {
    return activity.is_published === true || activity.is_published === 1 || this.userProfile?.can_see_unpublished_content === true;
  }

  /**
   * @summary Function to decide, if the activity can be shown to the current user
   * @param activity - the activity to be checked
   */
  public canBeShown(activity: any): boolean {
    if (!activity.publish_countries || !activity.publish_specialties) {
      return false;
    }

    if (this.userProfile?.hidden_activities && this.userProfile?.hidden_activities.includes(activity.id)) {
      return false;
    }

    return this.matchByMultiple(activity);
  }

  /**
   * Function to check by one country_alpha2 and one specialty
   *
   * @param activity
   * @protected
   */
  protected singleMatch(activity): boolean {
    return (activity.publish_country_alpha2 === this.userProfile?.country_alpha2) && (activity.publish_specialty === this.userProfile?.main_specialty);
  }

  /**
   * Function to check against multiple countries and specialties
   *
   * @param activity
   * @protected
   */
  protected matchByMultiple(activity): boolean {
    return activity.publish_countries?.includes(this.userProfile?.country_alpha2) && activity.publish_specialties?.includes(this.userProfile?.main_specialty);
  }

  /**
   * Track tab change
   *
   * @param value
   */
  trackTabChange(value: any): void {
    switch (value.index) {
      case 0:
        this.analytics.addEvent('portal_activities');
        break;
      case 1:
        this.analytics.addEvent('portal_activities_comm');
        break;
      case 2:
        this.analytics.addEvent('portal_activities_library');
        break;
      default:
        this.analytics.addEvent('portal_activities');
        break;
    }
  }

  /**
   * Show hide medical content
   *
   * @param activity
   */
  public showMedicalContent(activity: any): boolean {
    if (!this.isAllowedPublishStatus(activity)) {
      return false;
    }
    return this.medicalContentCategories.includes(activity.category);
  }


  private sendUserPushId(): void {
    const uuid = localStorage.getItem('uuid') || false;
    const sent = localStorage.getItem('uuid_sent') || false;

    if ((uuid && !sent) || (uuid !== false && sent !== false && uuid !== sent)) {
      const pushData = {
        push_id: uuid,
        enabled: true,
        mobile: 1
      };
      localStorage.setItem('uuid_sent', uuid);
      this.api2Service.updateDeviceToken(pushData);
    }
  }
}
