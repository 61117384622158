import {ErrorHandler, Injectable} from '@angular/core';
import {GraylogService} from '@services/graylog.service';

declare var window: any;

@Injectable()
export class ErrorTrackingService implements ErrorHandler {

  constructor(public graylog: GraylogService) {

  }

  handleError(error: any): void {
    console.log('DATA', error);
    this.graylog.log(error);
  }
}
