<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
  <div class="flex w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16 overflow-hidden sm:bg-card">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
      <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">{{pageTitle}}</div>
        <ng-container>
          <div class="mt-8 font-medium nuaxia-font-gray">Our services are currently open to Health Care Professionals only. Please fill in your professional details below.</div>
          <form>
          <span class="text-center mt-2 nuaxia-error" *ngIf="error">
            Please fill all required fields.
          </span>
            <app-title-field model="field_title"></app-title-field>
            <app-text-field title="First Name" model="field_firstname"></app-text-field>
            <app-text-field title="Middle Name(s)"  model="field_middlename"></app-text-field>
            <app-text-field title="Last Name"  model="field_lastname"></app-text-field>
            <app-specialty-field  model="field_specialty"></app-specialty-field>
            <app-work-field  model="field_pwork"></app-work-field>
            <p class="button">
              <button mat-flat-button  class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" (click)="sendPost()">
                <mat-icon *ngIf="postLoader"><mat-spinner diameter="20"></mat-spinner></mat-icon>
                <span *ngIf="!postLoader">Submit</span>
              </button>
            </p>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
