
import { Environment as EnvironmentType } from '@environments/enviroment.interface';

export const EnvNuaxia: EnvironmentType = {
  site: 'nuaxia',
  title: 'nuaxia Member Portal',
  url: 'https://app.nuaxia.com/',
  description: 'The healthcare information technology platform which empowers you to\n' + 'Learn, Earn and Grow Your Professional Network',
  logo: 'assets/images/nuaxia/logo-300x300-nuaxia.png',
  logoWidth: '120px',
  favicon: 'assets/images/nuaxia/icon-32x32.png',
  honorariaLink: 'https://www.nuaxia.com/honoraria-',
  privacyLink: 'https://www.nuaxia.com/privacy-policy',
  termsLink: 'https://www.nuaxia.com/terms-of-use',
  showLogout: false,
  production: true,
  introExpire: 30,
  gtmId: 'GTM-T2W87G6',
  testUser: 'john.doe@nuaxia.com',
  ios: {
    id: 'web.com.appnuaxia.externalportals',
    api: 'https://us-central1-externalportals-295311.cloudfunctions.net/macPushService/nuaxia'
  },
  firebase: {
    apiKey: 'AIzaSyCPq-LdWTGU3L-yzzhYzOWw6Lr87MJCv4c',
    authDomain: 'externalportals-295311.firebaseapp.com',
    databaseURL: 'https://externalportals-295311.firebaseio.com',
    projectId: 'externalportals-295311',
    storageBucket: 'externalportals-295311.appspot.com',
    messagingSenderId: '369570063996',
    appId: '1:369570063996:web:327ed77908d2a7af4ccbf9',
    measurementId: 'G-ZYSFBTSXLY'
  },
  api: {
    url: 'https://engage-api.prod.nuaxiasystem.com/api/app',
    key: '7b264ac9-c107-47fc-bcd4-b23791d21e8c'
  },
  onesignal: {
    enabled: true,
    appId: '34fda7d8-1050-4dc7-bf46-ae6a86066cc0'
  },
  push: {
    android: {
      senderId: '369570063996'
    }
  }
};
