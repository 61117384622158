import {Component, Input, OnInit} from '@angular/core';
import {DataSendingService} from '@services/data-sending.service';
import {BaseComponent} from '../../../../base.component';
import {DownloadDialogComponent, FacultyDialogComponent} from '../../../conversation/conversation.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-touch-tabs',
  templateUrl: './touch-tabs.component.html',
  styleUrls: ['../../../education.component.scss', '../../touch.component.scss', './touch-tabs.component.css']
})
export class TouchTabsComponent extends BaseComponent implements OnInit {

  @Input() education: any;
  @Input() activeGroup: number;
  public activeVideo = 0;
  public activeTab = 0;

  constructor(private dataSendingService: DataSendingService, private dialog: MatDialog) {
    super();
    this.subscribe(this.dataSendingService.getData('activeVideo'), (result) => {
      this.activeVideo = result;
    });
  }

  ngOnInit(): void {
  }

  public setActiveTab(tab: number, type: string): void {
    this.activeTab = tab;
    if (type === 'dialog-faculty') {
      this.dataSendingService.sendData('openModal', 'openFaculty');
    }
    if (type === 'dialog-download') {
      this.dataSendingService.sendData('openModal', 'openDownload');
    }
  }

  isEmpty(video): boolean {
    if (typeof video === 'undefined') {
      return true;
    }
    if (video.length === 0) {
      return true;
    }
    return false;
  }

  isTab(type): boolean {
    return type.includes('tab');
  }
}
