import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {routing} from './config/routes';

@NgModule({
  imports: [RouterModule.forRoot(routing, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
