import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from '../../interfaces/notification.interface';

@Component({
 selector: 'app-notification-template',
 templateUrl: './notification.component.html'
})
export class NotificationTemplateComponent {
 constructor(@Inject(MAT_DIALOG_DATA) public data: Notification){}
}