<body class="cme-education-template-default single single-cme-education postid-33707 has-sidebar activity-template" (scroll)="onWindowScroll($event);">

<header class="c-header c-header__activity c-activity-container js-site-header" style="box-sizing: border-box;" *ngIf="templateColor" [ngClass]="getTouchMdtHeader()">
  <div class="o-wrapper c-header__wrapper"  [ngClass]="{'device': isDevice}"  style="width: 100%;max-width: 100%">
    <app-menu style="width: 100%"></app-menu>
  </div>
</header>

<!-- /.c-header -->
<div  class="c-activity-container is-active" *ngIf="education">
  <div class="desktop-view"  *ngIf="education.content_type === 'touchMDT'">
    <div id="stickybar" class="c-sticky-bar js-sticky-bar">
      <div class="o-wrapper c-sticky-bar__wrapper">
        <ul class="c-sticky-bar__list">
          <li class="c-sticky-bar__item">
            <a  (click)="showMenu(0,'c-intro-hub')" class="c-sticky-bar__link js-patient-hub-link js-sticky-link">
              <span>The MDT Hub</span>
            </a>
          </li>
          <li class="c-sticky-bar__item">
            <a  (click)="showMenu(0,'js-activity-content-tabs')"  class="c-sticky-bar__link js-sidebar-tab-link js-sticky-link">
              <span>Overview <span class="c-sticky-bar__text--limit">&amp; Learning Objectives</span></span>
            </a>
          </li>
          <li class="c-sticky-bar__item" *ngIf="education.is_faculty">
            <a (click)="showMenu(1,'js-activity-content-tabs')" class="c-sticky-bar__link js-sidebar-tab-link js-sticky-link">
              <span class="c-sticky-bar__text">Faculty &amp; Disclosures</span>
            </a>
          </li>
          <li class="c-sticky-bar__item" *ngIf="education.is_download">
            <a (click)="showMenu(2,'js-activity-content-tabs')" class="c-sticky-bar__link js-sidebar-tab-link js-sticky-link">
              <span class="c-sticky-bar__text">Downloads</span>
            </a>
          </li>
        </ul>
      </div><!-- /.o-wrapper -->
    </div><!-- /.c-sticky-bar -->
  </div>
  <!-- /.c-activity-sidebar -->
  <main class="c-activity-content js-activity-content" data-form-type="cme-test" data-video-type="both" *ngIf="education">
    <section class="o-section u-pb-0">
      <div class="o-wrapper">
        <!-- /.touchMDT Header-->
        <header id="c-intro-hub" class="c-intro c-intro--top s-standard-content"  [ngClass]="templateColor" *ngIf="education.content_type === 'touchMDT'">
          <div class="c-meta u-mb-3">
                <span class="c-intro__badge-wrap  c-intro__badge-wrap-bottom">
                   <span class="c-badge c-intro__badge">
                      <span class="c-badge__text">{{education.tags}}</span>
                  </span>
              </span>
            <span class="c-intro__right u-ml-2">
                <span class="c-meta__item c-meta__item--first">
                  <svg role="presentation" id="" class="o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-time"></use></svg>                                                <span class="c-meta__text"><strong><span class="c-meta__text--limit">Watch</span> Time:</strong> {{education.watch_time}} mins</span>
                </span>
              </span><!-- /.c-meta__right -->
          </div><!-- /.c-top-bar -->
          <div class="c-intro__title-wrap c-intro__mdt">
                <span>
                  <h1 class="h1 c-intro__title js-intro-title">
                    <span class="js-activity-type" *ngIf="education.content_title !== undefined && education.content_title !== ''">{{education.content_title}}</span>
                    <span class="js-activity-type" *ngIf="education.content_title === undefined || education.content_title === ''">{{education.content_type}}</span>
                    {{education.title}}
                  </h1>
                   <p><span [innerHTML]="education.description.description ? education.description.description : education.description"></span></p>
                </span>
            <a (click)="showMenu(0,'js-activity-content-tabs')" class="c-button has-icon c-intro__mdt-button js-sidebar-tab-link">Overview&nbsp;&amp;&nbsp;Learning&nbsp;Objectives</a>
          </div><!-- /.c-intro__title-wrap -->
        </header><!-- /.c-intro -->
        <!-- /.c-intro -->
      </div>
      <!-- /.o-wrapper -->
      <div *ngFor="let groups of education.video_groups.video_groups;let index = index">
        <section class="o-section c-spider__section js-spider-section"  [ngClass]="templateColor"  *ngIf="groups.type === 'mdt'" style="display:block">
          <div class="o-wrapper o-wrapper--medium">
            <div id="js-team-hub" class="c-spider__wrap js-spider-wrap c-spider__wrap--{{groups.videos.length}}" [ngStyle]="{'margin-bottom':blockMarginBottom(groups.videos.length)}">
              <div class="c-spider__middle js-spider-middle t-ime" [ngStyle]="{'top':blockMarginTop(groups.videos.length)}">
                <span>
                    <svg role="presentation" id="" class="o-icon o-icon c-spider__patient-icon"><use xlink:href="#icon-mdt_icon"></use></svg>
                  <p  *ngIf="education.subtitle" class="u-mb-0 c-spider__content-text" [innerHTML]="education.subtitle.subtitle ? education.subtitle.subtitle : education.subtitle"></p></span>
              </div><!-- /.c-spider__middle -->

              <div *ngFor="let video of groups.videos;let index2 = index;" class="c-spider c-spider--{{index2}} t-ime js-spider" data-card="{{index2}}" [ngStyle]="{'top':blockFromTop(index2),'height':blockHeight(groups.videos)}" style="margin-top: 0px;">
                <div class="c-spider__int js-spider-internal js-sidebar-video-link-multi is-active">
                  <div class="c-spider__default">
                            <span>
                                                                                                                                    <img width="198" height="126"  :src="{{image(video.icon)}}" class="o-icon o-icon--color c-spider__icon" alt="" loading="lazy">                                                                                                    <h6 class="h6 c-spider__title u-mb-3">{{video.group_title}}</h6>
                                                                                                    <a   (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')" class="c-button c-button--cta has-icon watch-video-cta-button" id="mdtWatch">Watch Video</a>

                                                            </span>
                  </div><!-- /.c-spider__default -->
                  <div class="c-spider__reveal">
                            <span>
                                <span class="c-meta c-meta--rows u-mb-3">
                                                                            <span class="c-meta__item c-meta__item--small">
                                            <svg role="presentation" id="" class="o-icon o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-play"></use></svg>                                            <span class="c-meta__text u-text-small-medium u-text-left u-text-500 u-text-09">{{video.title}}</span>
                                        </span><!-- /.c-meta__item -->
                                                                                                                                                                                        <span class="c-meta__item c-meta__item--small">
                                            <svg role="presentation" id="" class="o-icon o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-avatar"></use></svg>                                            <span class="c-meta__text u-text-small-medium u-text-left u-text-500 u-text-09">
                                               {{otherExperts(video.name,video.other_experts)}}                                                                                         </span>
                                        </span><!-- /.c-meta__item -->
                                                                    </span><!-- /.c-meta -->
                                                                    <p class="u-mb-3 c-spider__small-text u-text-left">{{video.description}}</p>

                               <span   (click)="videoSelect('expertPlayer', video,'group-'+index,index2,'00:00')" class="c-button c-button--cta has-icon watch-video-cta-button">Watch Video</span>
                                                            </span>
                  </div><!-- /.c-spider__reveal -->
                </div><!-- /.c-spider__int -->
              </div><!-- /.c-spider -->

            </div><!-- /.c-spider__wrap -->
          </div><!-- /.o-wrapper -->
        </section><!-- /.o-section -->
        <section class="o-section t-ime u-pt-0 js-touch-other"  [ngClass]="templateColor" *ngIf="groups.type === 'mdt'" id="{{ 'group-' + index }}" style="display:block">
          <div class="o-wrapper">

            <div class="c-video-section-wrap t-ime" *ngFor="let video of groups.videos;let index3 = index;">
              <div class="js-wistia-wrap-multi t-ime" data-order="4" *ngIf="tabs[index] && videoTab['group-' + index][index3]">
                <div class="c-wistia js-wistia-video-wrap u-mb-0 is-visible" data-video-id="8w9b8m1sje">
                  <div class="c-card c-card--row c-card--flex-top c-card--large u-justify-start s-standard-content u-mb-5 c-spider__details">
                    <img width="287" height="126" :src="{{image(video.icon)}}" class="o-icon o-icon--color c-spider__icon u-mb-0 u-mr-6" alt="" loading="lazy">                                                <span>
                                                            <h5 class="h5 u-text-600 u-mb-1">{{video.group_title}}</h5>
                                                                                        <p class="u-mb-0">{{video.description}}</p>
                                                    </span>
                  </div><!-- /.c-card -->
                  <div class="o-grid">
                    <div class="o-grid__col o-grid__col-4 o-grid__col-12@tl o-grid__order-2@tl s-standard-content u-mb-0">

                      <div class="c-card c-card--large c-card--flex c-card--full-height c-spider__spotlight u-items-start">
                        <h5 class="h6 u-text-grey-medium-light u-text-400">Expert Spotlight</h5>


                        <!-- Additional Faculty Members -->
                        <div  *ngFor="let faculty of education.faculty.faculty">
                          <span class="c-bio__author-info u-mb-4 u-mt-4 u-mr-0" *ngIf="otherExperts('noname',video.other_experts,faculty.name)">
                          <span class="o-avatar o-avatar--small u-mr-3">
                              <img :src="{{image(faculty.image)}}" class="u-cover" alt="">
                              <span class="c-activity-sidebar__progress c-activity-sidebar__progress-authors"><svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon"><use xlink:href="#icon-tick"></use></svg></span>
                          </span><!-- /.o-avatar -->
                          <span>
                            <h6 class="h6 u-mb-0">{{faculty.name}}</h6>
                             <span class="c-meta__text u-text-400 u-text-grey-medium">{{faculty.from}}</span>
                          </span>
                          </span><!-- /.c-bio__author-info -->
                        </div>
                        <span class="c-bio__author-info u-mb-4 u-mr-0">
                          <span class="o-avatar o-avatar--small u-mr-3">
                            <img  :src="{{image(video.avatar)}}" class="u-cover" alt="">
                            <span class="c-activity-sidebar__progress c-activity-sidebar__progress-authors"><svg role="presentation" id="" class="o-icon o-icon c-activity-sidebar__progress-icon"><use xlink:href="#icon-tick"></use></svg>
                          </span>
                        </span><!-- /.o-avatar -->
                          <span>
                            <h6 class="h6 u-mb-0">{{video.name}}</h6>
                            <span class="c-meta__text u-text-400 u-text-grey-medium">{{video.from}}</span>
                          </span>
                        </span><!-- /.c-bio__author-info -->
                        <span><p><span style="font-weight: 400;">{{video.about}}</span></p></span>
                        <!-- End Additional Faculty Members -->

                        <a (click)="videoSelect('expertPlayer', video,'group-'+index,index3,'00:00')"  class="c-button has-icon u-mt-5 u-mb-5 js-next-video expert-spotlight-next-video-mdt-cta-button">Learn more</a>
                        <span class="c-meta">
                            <span class="c-meta__item c-meta__item--first">
                                <svg role="presentation" id="" class="o-icon o-icon o-icon o-icon--small c-meta__icon"><use xlink:href="#icon-time"></use></svg>                                                                                        <span class="c-meta__text"><strong>Time:&nbsp;</strong>{{video.time}}</span>
                            </span><!-- /.c-meta__item -->
                        </span><!-- /.c-meta -->
                      </div><!-- /.c-card -->
                    </div><!-- /.o-grid__col -->
                    <div class="o-grid__col o-grid__col-8 o-grid__col-12@tl o-grid__order-1@tl c-spider__video-col">
                      <div class="js-wistia-video-wrap">
                        <div class="c-video-container u-mb-0">
                          <app-player [elementId]="'expertPlayer'"></app-player>
                        </div><!-- /.c-video-container -->
                      </div><!-- /.js-wistia-video -->
                    </div><!-- /.o-grid__col -->
                  </div><!-- /.o-grid -->
                </div><!-- /.js-wistia-video -->
              </div><!-- /.js-wistia-wrap-multi -->
            </div>
          </div><!-- /.o-wrapper -->
        </section><!-- /.o-section -->
      </div>
      <!-- /.o-section -->
    </section>
    <!-- /.o-section -->
    <div class="js-tabs" [ngClass]="templateColor" id="js-activity-content-tabs">
      <div class="c-tabs-bar">
        <div class="o-wrapper c-tabs-bar__wrapper">
          <ul class="c-tabs-bar__list">
            <li class="c-tabs-bar__item"  (click)="showMenu(0,'js-activity-content-tabs')">
              <button class="c-tabs-bar__tab-button js-tabs-button"   [ngClass]="{'is-active' : menuTab === 0}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Overview <span class="c-tabs-bar__text--limit">& Learning Objectives</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(1,'js-activity-content-tabs')" *ngIf="education.is_faculty">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 1}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Faculty <span class="c-tabs-bar__text--limit">& Disclosures</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(1,'js-activity-content-tabs')" *ngIf="education.is_faculty_about">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 1}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">About <span class="c-tabs-bar__text--limit">{{education.faculty.faculty[0].name}}</span></span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(3,'js-activity-content-tabs')" *ngIf="education.is_reference">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 3}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">References</span>
              </button>
            </li>
            <li class="c-tabs-bar__item"  (click)="showMenu(2,'js-activity-content-tabs')" *ngIf="education.is_download">
              <button class="c-tabs-bar__tab-button js-tabs-button"  [ngClass]="{'is-active' : menuTab === 2}"
                      data-tab-target="#js-tab-learning-objectives">
                <span class="c-tabs-bar__text" title="grid-columns">Downloads</span>
              </button>
            </li>
          </ul>
          <div class="c-tabs-bar__fade"></div>
          <!-- /.c-tabs-bar__fade -->
          <a [routerLink]="['/survey/'+education.id]" class="c-button c-button--cta c-tabs-bar__button u-flex-shrink js-overlay-toggle" *ngIf="education.survey"
             data-overlay=".js-overlay-cme-test" id="take-CME-test-button-banner">Take Survey</a>
        </div>
        <!-- /.o-wrapper -->
      </div>
      <!-- /.c-tabs-bar -->
      <section class="o-section o-section--grey" [ngClass]="templateColor">
        <div class="o-wrapper">
          <div class="c-tabs__tab js-tabs-tab" [ngClass]="templateColor" id="js-tab-learning-objectives" style="display: block;" *ngIf="menuTab === 0">
            <div class="u-pb-global">
              <h6 class="h6 u-text-grey">Overview &amp; Learning Objectives</h6>
              <div class="c-card c-card--large s-standard-content">
                <h6 class="h6 u-mb-2 u-text-grey">Overview</h6>
                <app-readmore *ngIf="education.overview" [text]="education.overview.overview ? education.overview.overview : education.overview"></app-readmore>
              </div>
              <!-- /.c-card -->
            </div>
            <!-- /.o-section -->
            <div class="u-pb-0">
              <div class="c-card c-card--large s-standard-content">
                <h6 class="h6 u-mb-2 u-text-grey">Learning Objectives</h6>
                <app-readmore *ngIf="education.learning_objectives" [text]="education.learning_objectives.learning_objectives ? education.learning_objectives.learning_objectives : education.learning_objectives"></app-readmore>
              </div>
              <!-- /.c-card -->
            </div>
            <!-- /.o-section -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-faculty" style="display: block;" *ngIf="menuTab === 1">
            <div class="u-pb-0" *ngIf="!education.is_faculty_about">
              <h6 class="h6 u-text-grey">Faculty &amp; Disclosures</h6>
            </div><!-- /.u-pb-global -->
            <div class="u-pb-5" *ngFor="let faculty of education.faculty.faculty;let facultyIndex = index">
              <div class="u-pb-0" *ngIf="education.is_faculty_about">
                <h6 class="h6 u-text-grey">About {{faculty.name}}</h6>
              </div><!-- /.u-pb-global -->
              <div class="c-card c-card--large c-card--flex c-card--flex-top c-card--flex-wrap s-standard-content">
                    <span class="c-card__details c-card__details-alt u-mb-4">
                                                    <span class="o-avatar c-card__col">
                                <img src="{{faculty.image}}" class="u-cover" alt="">
                            </span><!-- /.o-avatar -->
                                                <span class="c-card__col c-card__col--name">
                                                            <h6 class="h6 u-mb-2"> {{faculty.name}}</h6>
                                                                                        <p class="u-mb-0 c-card__sub-title"> {{faculty.from}}</p>
                                                    </span>
                    </span>
                <span class="c-card__col c-card__col--last c-card__disclosures">
                    <p><app-readmore [text]="faculty.bio"></app-readmore></p>
                <p><app-readmore [text]="faculty.discloses"></app-readmore></p>

                                                                    </span>
              </div><!-- /.c-card -->
            </div><!-- /.u-pb-global -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-references" style="display: block;" *ngIf="menuTab === 3 && education.is_reference">
            <div class="u-pb-0">
              <h6 class="h6 u-text-grey">References</h6>
            </div><!-- /.u-pb-global -->
            <div class="c-card c-card--large s-standard-content">
              <h6 class="h6 u-mb-2 u-text-grey">References</h6>
              <p [innerHTML]="education.references"></p>
            </div><!-- /.c-card -->
          </div>
          <div class="c-tabs__tab js-tabs-tab" id="js-tab-author-downloads" style="display: block;" *ngIf="menuTab === 2">
            <div class="">
              <div *ngIf="education.downloads && education.downloads.downloads && education.downloads.downloads.length > 1">
                <div class="u-pb-global">
                  <h6 class="h6 u-text-grey">Downloads</h6>
                  <div class="c-card c-card--large s-standard-content">
                    <p>View and download resources from this activity to support your learning or share with colleagues.</p>
                  </div><!-- /.c-card -->
                </div><!-- /.o-section -->
                <div class="u-pb-global">
                  <div class="o-grid">
                    <div class="o-grid__col o-grid__col-4 o-grid__col-6@dl o-grid__col-12@tm">
                      <div class="c-language-selector js-language-selector">
                        <label for="resources-language-selector" class="u-hidden">Select Language</label>
                        <select name="language-selector" id="resources-language-selector" class="c-language-selector__select js-language-selector-select"  #p (change)="pdfDownload(p.value)">
                          <option value="uk" selected="">Select Language:</option>
                          <option *ngFor="let download of education.downloads.downloads;let index3 = index" value="{{download.language}}">{{download.language}}</option>
                        </select><!--.js-language-selector-select -->
                        <div class="c-language-selector__facade c-card c-card--direction c-card--flex c-card--button u-justify-end u-mt-3">
                          <div class="c-language-selector__icon c-card__button-icon">
                            <img  *ngFor="let download of education.downloads.downloads;let index3 = index" src="https://touchoncology.com/wp-content/themes/tmm2020/images/flags/{{download.language}}.svg" class="o-icon o-icon--flag c-language-selector__flag c-language-selector__flag--{{download.language}} js-language-selector-flag" data-language="{{download.language}}">
                          </div><!-- /.flag -->
                          <span class="o-arrow o-arrow--right c-language-selector__arrow">
                                    <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-up-arrow"></use></svg>                                </span>
                        </div><!-- /.c-language-selector__facade -->
                      </div><!-- /.c-language-selector -->
                    </div><!-- /.o-grid__col -->
                  </div><!-- /.o-grid -->
                </div><!-- /.u-pb-global -->
                <div class="u-pb-0" *ngIf="selectedPdf">
                  <div class="c-tabs__tab js-language-grid" id="js-{{selectedPdfLanguage}}"  [ngStyle]="{'display':(selectedPdfLanguage !== ''?'block':'none')}">
                    <div class="o-grid">
                      <div class="o-grid__col o-grid__col-4 o-grid__col-12@tm">
                        <a href="{{this.selectedPdf}}" class="c-card c-card--direction c-card--flex c-card--button c-card__link" target="_blank">
                          <svg role="presentation" id="" class="o-icon o-icon o-icon--medium o-icon--primary c-card__button-icon"><use xlink:href="#icon-podcast"></use></svg>                                    <span class="c-card__button-text">
                                                                                    Download Slides
                                                                            </span>
                          <span class="o-arrow o-arrow--right c-card__button-arrow">
                                        <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>                                    <span>
                                </span></span></a>
                      </div><!-- /.o-grid__col -->
                    </div><!-- /.o-grid -->
                  </div><!-- /.c-tabs__tab -->
                </div><!-- /.o-section -->
              </div>
              <div *ngIf="pdf && education.downloads.downloads.length === 1">
                <h6 class="h6 u-mb-2 u-text-grey">Downloads</h6>
                <p>View and download resources from this activity to support your learning or share with colleagues.</p>
                <div class="u-pb-0">
                  <div class="o-grid">
                    <div class="o-grid__col o-grid__col-4 o-grid__col-12@tm">
                      <a href="{{this.pdf}}" class="c-card c-card--direction c-card--flex c-card--button c-card__link" target="_blank">
                        <svg role="presentation" id="" class="o-icon o-icon o-icon--medium o-icon--primary c-card__button-icon"><use xlink:href="#icon-podcast"></use></svg>                                        <span class="c-card__button-text">
                                                                                            Download Slides
                                                                                    </span>
                        <span class="o-arrow o-arrow--right c-card__button-arrow">
                                            <svg role="presentation" id="" class="o-icon o-icon o-arrow__icon"><use xlink:href="#icon-arrow-right-grey"></use></svg>                                        <span>
                                    </span></span></a>
                    </div><!-- /.o-grid__col -->
                  </div><!-- /.o-grid -->
                </div><!-- /.o-section -->
              </div>
              <div *ngIf="pdf === ''">
                <p>There is no downloadable files.</p>
              </div>
            </div><!-- /.c-card -->
          </div>
          <!-- /.c-tabs__tab -->
        </div>
        <!-- /.o-wrapper -->
      </section>
      <!-- /.o-section -->
    </div>
    <!-- /.js-tabs -->
  </main>
  <app-touch-footer [education]="education"></app-touch-footer>
</div>
<!-- /.c-activity-container -->
<!-- Load SVG template file -->
<!-- Load SVG template file -->
<app-education-svg style="display:none"></app-education-svg>

</body>
