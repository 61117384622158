import {OnInit, Inject, Component} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PopupInterface} from './popup.interface';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: PopupInterface) { }

  ngOnInit(): void {
  }

}
