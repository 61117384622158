import {Component, Input, OnInit} from '@angular/core';
import {DataSendingService} from '@services/data-sending.service';
import {BaseComponent} from '../../../../base.component';

@Component({
  selector: 'app-touch-tabs-second',
  templateUrl: './touch-tabs-second.component.html',
  styleUrls: ['../../../education.component.scss', '../../touch.component.scss', './touch-tabs-second.component.css']
})
export class TouchTabsSecondComponent extends BaseComponent implements OnInit {
  public activeTab = 0;
  @Input() education: any;
  @Input() activeGroup: number;
  private activeVideo: number;

  constructor(private dataSendingService: DataSendingService) {
    super();
    this.subscribe(this.dataSendingService.getData('activeVideo'), (result) => {
      this.activeVideo = result;
    });
  }

  ngOnInit(): void {
  }

  public setActiveTab(tab: number): void {
    this.activeTab = tab;
  }

  openDownload(): void {
    this.dataSendingService.sendData('openModal', 'openDownload');
  }

  openFaculty(): void {
    this.dataSendingService.sendData('openModal', 'openFaculty');
  }
}
