import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { MatDialog } from '@angular/material/dialog';
import { Notification } from '../../interfaces/notification.interface';
import { DataSendingService } from 'src/app/services/data-sending.service';
import { NotificationTemplateComponent } from './notification-template.component';

@Component({
 selector: 'app-notification',
 template: '<ng-container></ng-container>'
})
export class NotificationComponent extends BaseComponent implements OnInit {

 ////////////////////////////

 constructor(public dialog: MatDialog,
  protected dataSendingService: DataSendingService) {
  super();
 }

 ngOnInit(): void {
  this.subscribeToData();
 }

 ////////////////////////////


 protected subscribeToData(): void {
  this.subscribe(this.dataSendingService.getData('showNotification'), (data: Notification) => {
   this.dialog.open(NotificationTemplateComponent, { data: {...data }});
  });
 }

}
