import {Component, Inject, OnInit, Sanitizer, ViewEncapsulation} from '@angular/core';
import { EducationComponent } from '../education.component';
import { DOCUMENT, Location } from '@angular/common';
import { AuthService } from '@services/auth.service';
import { ActivityService } from '@services/activity.service';
import { ActivatedRoute } from '@angular/router';
import { DataSendingService } from '@services/data-sending.service';
import { AnalyticsService } from '@services/analytics.service';
import { DeviceService } from '@services/device.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {Api2Service} from '@services/api2.service';

@Component({
  selector: 'app-touch-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['../education.component.scss', 'conversation.component.scss']
})
export class EducationConversationComponent extends EducationComponent implements OnInit {
  public activeTab = 'objectives';
  public tipsOpen = true;
  public openGroup = 0;

  constructor(@Inject(DOCUMENT) document,
              public auth: AuthService,
              protected activity: ActivityService,
              protected api2Service: Api2Service,
              protected route: ActivatedRoute,
              protected dataSendingService: DataSendingService,
              public analytics: AnalyticsService,
              protected location: Location,
              protected device: DeviceService,
              public dialog: MatDialog,
              public sanitizer: DomSanitizer
  ) {
    super(document, auth, activity, api2Service, route, dataSendingService,  analytics, location, device, sanitizer);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  public toggleTips(): void {
    this.tipsOpen = !this.tipsOpen;
  }

  public goTo(elementId: string): void {
    this.setActiveTab(elementId);
    this.scrollTo(elementId);
  }

  public setOpenGroup(index: number): void {
    this.openGroup = index;
  }

  public openDownload(): void {
    const dialogRef = this.dialog.open(DownloadDialogComponent, {
      data: {
        downloads: this.education.downloads.downloads
      },
    });
  }

  public openFaculty(): void {
    const dialogRef = this.dialog.open(FacultyDialogComponent, {
      height: '90%',
      width: '90%',
      data: {
        faculty: this.education.faculty.faculty
      }
    });
  }
}

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./dialog.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadDialogComponent {
  public selected: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<DownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { downloads: any },
  ) {
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-faculty-dialog',
  templateUrl: './faculty-dialog.component.html',
  styleUrls: ['./dialog.scss']
})
export class FacultyDialogComponent {

  public selectedFaculty: any | null = null;

  constructor(
    public dialogRef: MatDialogRef<FacultyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { faculty: any },
  ) {
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public selectFaculty(faculty: any): void {
    this.selectedFaculty = faculty;
  }

  public back(): void {
    this.selectedFaculty = null;
  }
}
