interface Scripts {
  name: string;
  src: string;
}

export const ExternalScripts: Scripts[] = [
  {
    name: 'jwplayer',
    src: 'https://cdn.nuaxia.com/libraries/pnO4Vpf4.js'
  },
  {
    name: 'gmaps',
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBtfF_cqb6d1qoblvOvRDT0b1oNnWBN-vk&libraries=places&language=en_GB'
  },
  {
    name: 'luckyorange',
    src: 'https://app.nuaxia.com/assets/js/luckyorange.js'
  },
  {
    name: 'intercom',
    src: '../../../assets/js/intercom.js'
  },
];

