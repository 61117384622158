import { Component, Input } from '@angular/core';
import { AbstractActivityItemComponent } from '../activity-item/activity-item.component';

@Component({
  selector: 'app-survey-card',
  templateUrl: 'survey-card.component.html'
})
export class SurveyCardComponent extends AbstractActivityItemComponent {

}
