import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {environmentProd} from '@environments/environment';
import {MauticProfile, MauticPush, UserNxId} from '../interfaces/mautic.interface';
import {EventData, EventTimeUpdate} from '../interfaces/event.interface';
import {from, Observable} from 'rxjs';
import {HttpStatus} from '../interfaces/status.enum';
import {TokenInterface} from '../interfaces/token.interface';


@Injectable()
export class Api2Service extends ApiService {

  public endPoint = `${environmentProd.api.url}`;


  /**
   * Login request
   *
   * @param email
   */
  public login(email: string): Promise<any> {
    const url = `${this.endPoint}/auth/login`;
    const device = this.deviceService.platforms();
    return this.sendPost2(url, {email, device}, true).toPromise();
  }

  /**
   * Login with password
   *
   * @param email
   * @param password
   */
  public loginPassword(email: string, password: string): Promise<any> {
    const url = `${this.endPoint}/auth/login/password`;
    const device = this.deviceService.platforms();
    return this.sendPost2(url, {email, password, device}, true).toPromise();
  }
  /**
   * Login with password
   *
   * @param email
   * @param password
   */
  public passwordReset(email: string): Promise<any> {
    const url = `${this.endPoint}/auth/password/reset`;
    const device = this.deviceService.platforms();
    return this.sendPost2(url, {email, device}, true).toPromise();
  }
  /**
   * Register with signup
   *
   * @param email
   * @param password
   */
  public signup(email: string, password: string): Promise<any> {
    const url = `${this.endPoint}/auth/register`;
    const device = this.deviceService.platforms();
    return this.sendPost2(url, {email, password, device}, true).toPromise();
  }

  /**
   * Register with signup
   *
   * @param email
   * @param password
   */
  public passwordUpdate(email: string, password: string): Promise<any> {
    const url = `${this.endPoint}/auth/password/set`;
    const device = this.deviceService.platforms();
    return this.sendPost2(url, {email, password, device}, true).toPromise();
  }


  public promiseToObservable<T>(promise: Promise<T>): Observable<T> {
    return from(promise);
  }

  /**
   * Profile data
   *
   */
  public me(): Promise<any> {
    const url = `${this.endPoint}/profile`;
    return this.sendPost2(url, {}, false).toPromise();
  }

  /**
   * Profile data
   *
   */
  public countries(): Promise<any> {
    const url = `${this.endPoint}/countries`;
    return this.sendPost2(url, {}, false).toPromise();
  }

  /**
   * Profile data
   *
   */
  public specialties(): Observable<any> {
    const url = `${this.endPoint}/specialty/list`;
    return this.promiseToObservable(this.sendPost2(url, {}, false).toPromise());
  }

  /**
   * Profile data
   *
   */
  public activities(): Observable<any> {
    const url = `${this.endPoint}/activity/list`;
    const device = this.deviceService.platforms();
    return this.promiseToObservable(this.sendPost2(url, {}, false).toPromise());
  }

  public activitiesByCategory(category: [string], page: number): Observable<any> {
    const url = `${this.endPoint}/activity/list`;
    const device = this.deviceService.platforms();
    return this.promiseToObservable(this.sendPost2(url, {category, page}, false).toPromise());
  }

  public activityById(id: string): Observable<any> {
    const url = `${this.endPoint}/activity/get`;
    const device = this.deviceService.platforms();
    return this.promiseToObservable(this.sendPost2(url, {activityId: id}, false).toPromise());
  }

  public activate(): Promise<any> {
    const url = `${this.endPoint}/auth/activate`;
    return this.sendPost2(url, {}, false).toPromise();
  }

  /**
   * Updating user push
   *
   */
  public getHonoraria(): Promise<any> {
    const url = `${this.endPoint}/honoraria/get`;
    return this.sendPost2(url, {}, false).toPromise();
  }

  /**
   * Store token
   // tslint:disable-next-line:no-redundant-jsdoc
   * @param token
   */
  public storeToken(token: string): void {
    localStorage.setItem('tuser', token);
  }
  /**
   * Store token
   // tslint:disable-next-line:no-redundant-jsdoc
   * @param token
   */
  public storeTokenPromise(token: string): boolean {
    this.deleteToken();
    this.deleteUser();
    localStorage.setItem('tuser', token);
    return true;
  }
  /**
   * Store user data
   // tslint:disable-next-line:no-redundant-jsdoc
   * @param data
   */
  public storeUser(data: any): void {
    localStorage.setItem('user', data);
  }

  /**
   * Delete user data
   */
  public logout(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.deleteToken();
        this.deleteUser();
        resolve(true);
      } catch (error) {
        reject(error); // Reject the promise if any error occurs during deletion
      }
    });
  }

  /**
   * Delete token
   */
  public deleteToken(): void {
    localStorage.removeItem('tuser');
  }

  /**
   * Delete user
   */
  public deleteUser(): void {
    localStorage.removeItem('user');
  }

  /**
   * Check if user authenticated
   */
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('tuser');
    return token !== null;
  }

  /**
   * Current user
   */
  public currentUser(): any {
    return localStorage.getItem('user');
  }

  /**
   * last-step review update
   * @param data
   */
  public review(data: any): Promise<any> {
    const url = `${this.endPoint}/profile/update`;
    return this.sendPost2(url, data, false).toPromise();
  }
  /**
   * last-step review update
   * @param data
   */
  public update(data: any): Promise<any> {
    const url = `${this.endPoint}/profile/update`;
    return this.sendPost2(url, data, false).toPromise();
  }
  /**
   * Profile delete
   *
   * @param data
   */
  public deleteAccount(): Promise<any> {
    const url = `${this.endPoint}/profile/delete`;
    return this.sendPost2(url, {}, false).toPromise();
  }

  public async refreshUser(): Promise<any> {
    const result = await this.me();
    this.storeUser(JSON.stringify(result.data.user));
    return result;
  }

  public async token(): Promise<any> {
    const url = `${this.endPoint}/auth/refresh`;
    const device = this.deviceService.platforms();
    return this.sendPost2(url, {device}, false).toPromise();
  }

  public schools(): Observable<any> {
    const url = `${this.endPoint}/school/list`;
    return this.promiseToObservable(this.sendPost2(url, {}, false).toPromise());
  }

  // tslint:disable-next-line:variable-name
  public updatePassword(password_current: string, password: string, password_confirmation: string): Promise<any> {
    const url = `${this.endPoint}/auth/password/update`;
    const device = this.deviceService.platforms();
    return this.sendPost2(url, {password_current, password, password_confirmation}, false).toPromise();
  }


  /**
   * Saving event into mautic database, using cloud function for eventHandler
   */
  public saveEvent(event: EventData): Promise<any> {
    return this.sendPost2(`${this.endPoint}/event`, {...event}, false).toPromise();
  }
  /**
   * Updating event into mautic database, using cloud function for eventHandler
   */
  public updateEvent(event: EventTimeUpdate): Promise<any> {
    return this.sendPost2(`${this.endPoint}/event/update`, {...event}, false).toPromise();
  }

  public updateDeviceToken(data: any): Promise<any> {
    return this.sendPost2(`${this.endPoint}/push/update`, data, false).toPromise();
  }

  public updateNotifications(data: any): Promise<any> {
    const url = `${this.endPoint}/notification/update`;
    return this.sendPost2(url, data, false).toPromise();
  }
}
