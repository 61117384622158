<div class="nuaxia-popup">

</div>
<div class="text-center">
  <mat-icon [svgIcon]="data.icon" style="height:150px;width:150px;"></mat-icon>
</div>
<h2 class="nuaxia-popup-title" mat-dialog-title>{{data.title}}</h2>
<p class="nuaxia-popup-description" mat-dialog-content [innerHTML]="data.description|safeHtml"></p>
<p class="text-center" *ngIf="data.loader">
  <mat-icon data-testId="loading-spinner">
    <mat-spinner diameter="20"></mat-spinner>
  </mat-icon>
</p>
<a *ngIf="data.button" mat-flat-button class="fuse-mat-button-large w-full mt-6 nuaxia-button" color="primary" [attr.href]="data.button">{{data.button_text}}</a>
