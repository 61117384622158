import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';
import {DataSendingService} from '../../services/data-sending.service';
import {Video} from '../../interfaces/video.interface';

declare var jwplayer: any;

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent extends BaseComponent implements OnInit {
  protected firstLoaded = true;
  @Input() elementId: string;
  @Input() mediaId: string;
  @Input() title: string;
  protected player: any;

  /////////////////////////////

  constructor(protected dataSendingService: DataSendingService) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToVideoSelect();
    this.subscribeToVideoStart();
  }

  /////////////////////////////

  /**
   * Refreshing the player, when a video is selected
   * @protected
   */
  protected subscribeToVideoSelect(): void {
    this.subscribe(this.dataSendingService.getData('selectedVideo'), (result) => {
        if (this.elementId === result.playerId) {
          this.setupPlayer(result.video, result.startTime, result.mediaId);
        }
      }
    );
  }

  /**
   * Pausing the video, when another video starts to play
   * @protected
   */
  protected subscribeToVideoStart(): void {
    this.subscribe(this.dataSendingService.getData('videoStarted'), (result) => {
      if (this.elementId !== result.playerId) {
        this.player.pause();
      }
    });
  }


  /**
   * Setting up the jwPlayer
   * @param video
   * @param time
   * @param language
   * @protected
   */
  protected setupPlayer(video: Video, time?: string, language?: string): void {
    const mediaId = language ? language : video.media_id;

    const config = {
      title: (video.title !== undefined ? video.title : 'No title'),
      playlist: `https://cdn.nuaxia.com/v2/media/${mediaId}`,
      aspectratio: '16:9',
      mute: false,
      autostart: false,
      primary: 'html5'
    };

    this.player = jwplayer(this.elementId).setup(config);
    //this.player.stop();

    if (time && this.firstLoaded === false) {
      this.player.seek(this.timeToSec(time));
    }

    if (this.firstLoaded === true) {
      this.firstLoaded = false;
    }

    // Broadcast video time
    this.player.on('time', event => {
      this.dataSendingService.sendData('videoTime', event);
    });

    this.player.on('play', () => {
      this.dataSendingService.sendData('videoStarted', {playerId: this.elementId});
    });
  }

  protected timeToSec(time: string): number {
    const timeArray = time.split(':'); // split it at the colons
    return (+timeArray[0]) * 60 + (+timeArray[1]);
  }
}
