import { Injectable } from '@angular/core';
import { DataSendingService } from './data-sending.service';
import { Notification } from '../interfaces/notification.interface';

@Injectable()
export class NotificationService {

  //////////////////////////////

  constructor(protected dataSendingService: DataSendingService) {

  }

  //////////////////////////////

  public showNotification(notification: Notification): void {
    this.dataSendingService.sendData('showNotification', notification);
  }

}
