import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appCapslock]'
})
export class CapslockDirective {

  @Input() appCapslock;
  private capsOn: boolean;

  constructor(public ref: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('window:click', ['$event'])
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  public onCapsCheck(event: MouseEvent | KeyboardEvent): void {
    this.capsOn = !!(event.getModifierState && event.getModifierState('CapsLock'));
  }

  // tslint:disable-next-line:typedef
  @HostListener('input', ['$event']) onInput(event) {
    document.getElementById('caps-' + this.appCapslock).innerHTML = (this.capsOn ? 'Your caps on!' : '');
  }

}

