<div class="enterprise-layout" [ngStyle]="{'background-color': menuSelected[0]?.backgroundColor ? menuSelected[0]?.backgroundColor : 'white' }">
  <!-- Wrapper -->
  <div class="flex flex-col flex-auto items-center w-full min-w-0">

    <!-- Header -->
    <div class="fixed flex flex-col flex-0 justify-center w-full h-16 sm:h-20 md:h-20 overflow-hidden z-49 print:hidden" style="background:white">
      <!-- Top bar -->
      <div class="relative flex flex-auto w-full px-4 md:px-8"  *ngIf="menuSelected.length > 0 && menuSelected[0].hasTitleTop !== 'undefined' && menuSelected[0].hasTitleTop === true">
        <h2 class="nuaxia-page-title-top">{{menuSelected[0].title}}</h2>
      </div>
      <div class="relative flex flex-auto justify-center w-full px-4 md:px-8">

        <div class="flex items-center w-full max-w-360 h-16 sm:h-20 justify-center">
          <!-- Components -->

          <div class="flex items-center pl-2 space-x-8 nuaxia-menu-header" [ngClass]="{'device': isDevice}" *ngIf="menuSelected.length > 0 && menuSelected[0].header !== 'undefined'">
              <ng-container *ngFor="let header of menuSelected[0].header" >
                <button [routerLink]="[header.url]"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        [attr.data-testId]="'header-link-' + header.title | lowercase"
                        [ngClass]="{'active': menuSelected[0].title == header.title}">
                  <span class="menu-item-text"  [ngClass]="{'active': menuSelected[0].title == header.title}">{{header.title}}</span>
                </button>
              </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full  sm:p-6 sm:pb-2 md:p-8 md:pb-2" style="margin-bottom:100px;padding-top:70px">
      <div class="flex flex-col flex-auto w-full sm:max-w-360 sm:overflow-hidden px-6 lg:pt-12">
        <h2 class="nuaxia-page-title pl-6 md:pl-0 text-2xl md:text-3xl" [ngClass]="menuSelected.length > 0 && menuSelected[0].hasCenteredTitle ? 'text-center': ''" *ngIf="menuSelected.length > 0 && menuSelected[0].hasTitleBottom !== 'undefined' && menuSelected[0].hasTitleBottom === true">{{menuSelected[0].title}}</h2>
        <p class="nuaxia-page-description" *ngIf="menuSelected.length > 0 && menuSelected[0].description !== 'undefined' && menuSelected[0].description !=''">{{menuSelected[0].description}}</p>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
      </div>
    </div>

    <!-- Footer -->
    <div class="fixed flex flex-0 justify-center w-full px-6 md:px-8 z-49 bg-card print:hidden bottom-0">
      <div class="flex w-full max-w-360 h-18 sm:h-20 justify-center">
        <div class="flex pl-2 space-x-8 nuaxia-menu-bottom" [ngClass]="{'device': isDevice}"  *ngIf="menuSelected.length > 0 && menuSelected[0].footer !== 'undefined'">
          <ng-container *ngFor="let footer of menuSelected[0].footer">
            <button mat-icon-button  [routerLink]="[footer.url]" [attr.data-testId]="'footer-link-' + footer.title | lowercase" *ngIf="isLoggedIn() && hasToken()">
              <mat-icon routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [svgIcon]="footer.iconActive ? footer.iconHover : footer.icon" [ngClass]="{'home-icon': footer.title == 'Home'}"></mat-icon>
              <span class="menu-item-text" [ngClass]="{'active': footer.iconActive}">{{footer.title}}</span>
            </button>
            <button mat-icon-button (click)="showLoginModal()" [attr.data-testId]="'footer-link-' + footer.title | lowercase" *ngIf="!isLoggedIn() || !hasToken()">
              <mat-icon routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [svgIcon]="footer.iconActive ? footer.iconHover : footer.icon" [ngClass]="{'home-icon': footer.title == 'Home'}"></mat-icon>
              <span class="menu-item-text" [ngClass]="{'active': footer.iconActive}">{{footer.title}}</span>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  </div>


