<div class="w-full">
  <div class="flex justify-end">
      <span class="text-xl font-medium cursor-pointer text-neutral-300" (click)="close()">x</span>
  </div>
  <div class="text-lg text-neutral-500">
    <p>Please sign up, or  log in to access this content.</p>
    <p class="mt-4 mb-6">Enter your email address below to receive a 'magic link' for easy access.</p>
  </div>
  <form [formGroup]="form" class="mt-12">
    <p class="u-pb-2">
      <mat-error *ngIf="error" data-testId="login-error-message">{{error}}</mat-error>
    </p>
    <p class="caps-field">
      <mat-form-field class="w-full fuse-mat-no-subscript">
        <mat-label>Email</mat-label>
        <input data-testId="email-field" formControlName="email" matInput placeholder="Enter your email address" (focus)="error = null" appLowercase>
      </mat-form-field>
      <span *ngIf="post && fields.email.errors">
        <mat-error *ngIf="fields.email.errors.required">Email is required.</mat-error>
        <mat-error *ngIf="fields.email.invalid">Email is not valid.</mat-error>
      </span>
    </p>
    <div class="mt-6 button">
      <button data-testId="login-button"
              mat-flat-button class="w-full mt-6 fuse-mat-button-large nuaxia-button"
              [disabled]='isSubmitDisabled()'
              color="primary"
              (click)="submit()"><mat-icon *ngIf="loader" data-testId="loading-spinner"><mat-spinner diameter="20">
      </mat-spinner></mat-icon> Continue</button>
    </div>
  </form>
  <p class="pt-3 mt-8 text-center">{{loginType}}By signing up/logging in, you are indicating that you accept our <a target="_blank" class="nuaxia-lato nuaxia-button-text" [attr.href]="termsLink" data-testId="terms-link">Terms of Service</a> and <a class="nuaxia-lato nuaxia-button-text" target="_blank"[attr.href]="privacyLink" data-testId="privacy-link">Privacy Policy</a></p>

</div>
