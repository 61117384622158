<div class="nuaxia-popup">

</div>
<div class="text-center">
  <mat-icon [svgIcon]="data.icon" style="height:150px;width:150px;"></mat-icon>
</div>
<h2 class="nuaxia-popup-title" mat-dialog-title>{{data.title}}</h2>
<p class="nuaxia-popup-description" mat-dialog-content [innerHTML]="data.description|safeHtml"></p>
<button mat-flat-button class="fuse-mat-button-large w-full mt-6" mat-dialog-close [color]="'primary'">Keep My Account</button>
<button
  mat-flat-button class="fuse-mat-button-large w-full mt-6"
  type="button"
  (click)="deleteProfile()"
  ><mat-icon *ngIf="userDeleteLoader"><mat-spinner diameter="20">
</mat-spinner></mat-icon>  <span *ngIf="userDeleteLoader">Deleting, please wait</span> <span *ngIf="!userDeleteLoader">Delete My Account</span>
</button>
